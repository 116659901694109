import React, { useEffect, useState } from "react";
import ReportsLayout from "../../../components/reports/reports-layout";
import { overallSalesReportAction } from "../../../redux/actions/report-action";
import { useDispatch } from "react-redux";
import moment from "moment";

const OverallSalesReport = () => {
  const dispatch = useDispatch();
  const [groupByOptions, setgroupByOptions] = useState([
    { label: "None", value: "" },
    { label: "Item Code", value: "oi.item_code" },
    { label: "Bill Date", value: "so.created_date" },
  ]);

  const [loading, setLoading] = useState(false);
  const [filterOptions, setFilterOptions] = useState(
    Object.entries(tableColoumsn).map(([label, value]) => ({
      label,
      value,
    }))
  );

  console.log(filterOptions, "gsdfgfdgddfgdfg");

  const [selectedColumns, setSelectedColumns] = useState(tableHeading);
  const [filters, setFilters] = useState([]);

  const [tableData, setTableData] = useState([]);
  const [dateRange, setDateRange] = useState("");
  const [groupBy, setGroupBy] = useState("");
  const [dateRangeFilter, setDateRangeFilterValue] = useState({
    from: null,
    to: null,
  });
  useEffect(() => {
    const selectedColumnValues = selectedColumns
      .map((column) => tableColoumsn[column] ?? "")
      .filter((col) => col);
    const requestData = {
      selectedColumns: selectedColumnValues,
      filters: filters.map(({ id, ...rest }) => rest),
      filter_by: dateRange,
      group_by: groupBy,
      from: dateRangeFilter?.from,
      to: dateRangeFilter?.to,
    };

    dispatch(overallSalesReportAction(requestData, onSuccess, onError));
    setLoading(true);
  }, [selectedColumns, filters, dateRange, groupBy, dateRangeFilter]);
  const init = {
    selectedColumns: selectedColumns.map((column) => tableColoumsn[column]),
    filters: [],
    filter_by: "",
    group_by: "",
    from: null,
    to: null,
  };
  const reqData = {
    selectedColumns: selectedColumns.map((column) => tableColoumsn[column]),
    filters: filters.map(({ id, ...rest }) => rest),
    filter_by: dateRange,
    group_by: groupBy,
    from: dateRangeFilter?.from,
    to: dateRangeFilter?.to,
  };
  const isIso8601Date = (dateString) => {
    const iso8601Regex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/;
    return iso8601Regex.test(dateString);
  };

  const formatDate = (value) => {
    return moment(value).format("DD/MM/YYYY");
  };
  const transformData = (data) => {
    return data.map((item) => {
      let transformedItem = {};
      for (let [key, value] of Object.entries(tableColoumsn)) {
        const rawValue = item[value.split(".").pop()];
        transformedItem[key] = isIso8601Date(rawValue)
          ? formatDate(rawValue)
          : rawValue;
      }
      return transformedItem;
    });
  };

  const onSuccess = (data) => {
    const transformedData = transformData(data?.data?.result);
    setTableData(transformedData);
    setLoading(false);
  };

  const onError = (data) => {
    console.log("error is", data);
    setLoading(false);
  };
  return (
    <ReportsLayout
      init={init}
      reqData={reqData}
      title={"Overall Sales Report"}
      tableHeading={selectedColumns}
      tableData={tableData}
      groupByOptions={groupByOptions}
      filterOptions={filterOptions}
      availableColumns={tableHeading}
      selectedColumns={selectedColumns}
      setSelectedColumns={setSelectedColumns}
      setFilters={setFilters}
      loading={loading}
      setDateRangeFilterValue={setDateRangeFilterValue}
      setDateRange={setDateRange}
      setGroupBy={setGroupBy}
    />
  );
};

const tableHeading = [
  "Bill Date",
  "Bill Num",
  "Item Code",
  "Item Name",
  "Qty",
  "Unit Price",
  "Tax",
  "Discounts",
  "Rate",
  "Total Amount",
];

// const tableHeading = [
//   "Invoice No",
//   "Invoice Date",
//   "Customer ID",
//   "Customer Name",
//   "Item Code",
//   "Item Name",
//   "Item Type",
//   "Item Group",
//   "Item Category",
//   "HSN Code",
//   "Batch No",
//   "Qty",
//   "UOM",
//   "MRP",
//   "Unit Price",
//   "Discount Percentage",
//   "Discount Amount",
//   "CGST",
//   "SGST",
//   "IGST",
//   "CESS",
//   "Total Tax Amount",
//   "Final Price",
//   "Total Amount",
// ];

const tableColoumsn = {
  "Bill Date": "so.created_date",
  "Bill Num": "so.sot_invoice_number",
  "Item Code": "oi.item_code",
  "Item Name": "oi.item_name",
  Qty: "oi.item_quantity",
  "Unit Price": "oi.item_price_without_tax",
  Tax: "so.sot_total_gst",
  Discounts: "so.sot_total_discount",
  Rate: "oi.item_unit_price",
  "Total Amount": "so.sot_total_amount",
};

// const tableColoumsn = {
//   "Invoice No": "so.sot_invoice_number",
//   "Invoice Date": "so.created_date",
//   "Customer ID": "so.customer_id", // Assuming there's a column for customer ID
//   "Customer Name": "so.customer_name", // Assuming there's a column for customer name
//   "Item Code": "oi.item_code",
//   "Item Name": "oi.item_name",
//   "Item Type": "oi.item_type", // Assuming there's a column for item type
//   "Item Group": "oi.item_group", // Assuming there's a column for item group
//   "Item Category": "oi.item_category", // Assuming there's a column for item category
//   "HSN Code": "oi.hsn_code", // Assuming there's a column for HSN code
//   "Batch No": "oi.batch_number", // Assuming there's a column for batch number
//   Qty: "so.item_quantity",
//   UOM: "oi.unit_of_measurement", // Assuming there's a column for UOM (Unit of Measurement)
//   MRP: "oi.item_mrp", // Assuming there's a column for MRP
//   "Unit Price": "oi.item_price_without_tax",
//   "Discount Percentage": "oi.item_discount_percentage", // Assuming there's a column for discount percentage
//   "Discount Amount": "oi.item_discount_amount", // Assuming there's a column for discount amount
//   CGST: "oi.cgst", // Assuming there's a column for CGST
//   SGST: "oi.sgst", // Assuming there's a column for SGST
//   IGST: "oi.igst", // Assuming there's a column for IGST
//   CESS: "oi.cess", // Assuming there's a column for CESS
//   "Total Tax Amount": "so.sot_total_tax_amount",
//   "Final Price": "oi.item_final_price", // Assuming there's a column for final price
//   "Total Amount": "so.sot_total_amount",
// };

export default OverallSalesReport;
