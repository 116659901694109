import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./helper-components.css";

const successToast = (message = "success") => {
  toast.success(message, {
    position: "top-center",
    autoClose: 3000,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
    className: "custom-toast", // Apply the custom class
  });
};

export default successToast;
