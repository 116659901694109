import { all } from "redux-saga/effects";
import {
  acqSrcListWatcher,
  adminRolesWatcher,
  bnkNameListWatcher,
  buisnessTypeListWatcher,
  binLocationListWatcher,
  catListWatcher,
  customerListWatcher,
  departmentListWatcher,
  disCusGrpListWatcher,
  disCusListWatcher,
  doctorListWatcher,
  imageUploadWatcher,
  itemGroupListWatcher,
  itemTypeListWatcher,
  manufacturesListWatcher,
  paymentMethodListWatcher,
  phoneListWatcher,
  pincodeListWatcher,
  priceListWatcher,
  productListWatcher,
  rolesListWatcher,
  schListWatcher,
  sectorListWatcher,
  shippingTypeListWatcher,
  stateListWatcher,
  storeIdWatcher,
  taxCatListWatcher,
  uomGroupFindOneWatcher,
  uomGroupListWatcher,
  uomListWatcher,
  usersListWatcher,
  valListWatcher,
  vendorListWatcher,
  wareHouseListWatcher,
  cusTypeListWatcher,
} from "../general-saga";

export default function* generalRootSaga() {
  yield all([
    productListWatcher(),
    phoneListWatcher(),
    stateListWatcher(),
    doctorListWatcher(),
    storeIdWatcher(),
    paymentMethodListWatcher(),
    catListWatcher(),
    uomListWatcher(),
    priceListWatcher(),
    itemGroupListWatcher(),
    taxCatListWatcher(),
    manufacturesListWatcher(),
    shippingTypeListWatcher(),
    sectorListWatcher(),
    schListWatcher(),
    valListWatcher(),
    vendorListWatcher(),
    uomGroupListWatcher(),
    uomGroupFindOneWatcher(),
    imageUploadWatcher(),
    adminRolesWatcher(),
    rolesListWatcher(),
    departmentListWatcher(),
    usersListWatcher(),
    cusTypeListWatcher(),
    acqSrcListWatcher(),
    buisnessTypeListWatcher(),
    bnkNameListWatcher(),
    disCusListWatcher(),
    disCusGrpListWatcher(),
    customerListWatcher(),
    pincodeListWatcher(),
    binLocationListWatcher(),
    wareHouseListWatcher(),
    itemTypeListWatcher(),
  ]);
}
