import React, { useCallback, useEffect, useRef, useState } from "react";
import InnerLayout from "../../components/layouts/inner-layout";
import AddButton, { DeleteButton } from "../../components/buttons/button";
import Select from "react-select";
import FilterIcon from "../../assets/icons/filter-icon";
import ClearIcon from "../../assets/icons/clear-icon";
import BulkPurchaseFilter from "../../components/purchase-section/bulk/bulk-purchase-filter";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import {
  catListAction,
  itemGroupListAction,
  itemTypeListAction,
  manufacturesListAction,
  vendorListAction,
} from "../../redux/actions/general-action";
import errorToast from "../../components/helper-components/error-toast";
import { Form, Formik } from "formik";
import {
  bulkPurchaseCreateAction,
  loadMinQtyItemsAction,
} from "../../redux/actions/purchase-action";
import BulkBillingTable from "../../components/purchase-section/bulk/bulk-billing-table";
import LoadingSpinner from "../../components/helper-components/loading-spinner";
import successToast from "../../components/helper-components/success-toast";
import { useNavigate } from "react-router-dom";

const BulkPurchaseOrder = () => {
  const navigate = useNavigate();
  const filterPopupRef = useRef(null);
  const [groupByOptions, setgroupByOptions] = useState([
    { label: "None", value: "" },
    { label: "Vendor", value: "cmr_id" },
    { label: "Manufacture", value: "item_manufacturer" },
    { label: "Item Group", value: "item_group" },
    { label: "Item Type", value: "item_type" },
    { label: "Item Category", value: "item_category" },
  ]);
  const dispatch = useDispatch();
  const [reload, setReload] = useState(false);
  const [vendorList, setVendorList] = useState([]);
  const [manufacturesList, setManufacturesList] = useState([]);
  const [catList, setCatList] = useState([]);
  const [itemGroup, setItemGroup] = useState([]);
  const [itemType, setItemType] = useState([]);
  const [showFilter, setShowFilter] = useState(false);
  const [heightOfFirstDiv, setHeightOfFirstDiv] = useState(0);
  const [heightOfSecondDiv, setHeightOfSecondDiv] = useState(0);
  const [loadMinQtyLoading, setLoadMinQtyLoading] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [minQtyItems, setMinQtyItems] = useState([]);
  const [filterData, setFilterData] = useState({
    group_by: "",
    vendor: "",
    manufacturer: "",
    item_group: "",
    item_type: "",
    item_category: "",
  });

  const initFilter = {
    group_by: "",
    vendor: "",
    manufacturer: "",
    item_group: "",
    item_type: "",
    item_category: "",
  };
  useEffect(() => {
    const updateHeight = () => {
      const firstDivHeight = document.querySelector(".first-div").clientHeight;
      const secondDivHeight =
        document.querySelector(".second-div").clientHeight;
      setHeightOfFirstDiv(firstDivHeight);
      setHeightOfSecondDiv(secondDivHeight);
    };
    updateHeight();
    window.addEventListener("resize", updateHeight);
    return () => {
      window.removeEventListener("resize", updateHeight);
    };
  }, []);

  const handleGroupByChange = (selectedOption) => {
    setFilterData({ ...filterData, group_by: selectedOption.value });
  };

  const handleFilterClick = useCallback(() => {
    setShowFilter((prevShowFilter) => !prevShowFilter);
  }, []);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        showFilter &&
        filterPopupRef.current &&
        !filterPopupRef.current.contains(event.target)
      ) {
        handleFilterClick();
      }
    };
    document.addEventListener("click", handleOutsideClick);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [handleFilterClick, showFilter]);

  useEffect(() => {
    dispatch(
      manufacturesListAction(onmanufacturesSuccess, onmanufacturesError)
    );
    dispatch(vendorListAction(onVendorSuccess, onVendorError));
    dispatch(catListAction(onCatSuccess, onCatError));
    dispatch(itemGroupListAction(onitemGroupSuccess, onitemGroupError));
    dispatch(itemTypeListAction(onitemTypeSuccess, onitemTypeError));
  }, []);
  const onVendorSuccess = (data) => {
    setVendorList(
      data?.data?.vendors?.map((item) => {
        return { label: item?.vendors, value: item?.id };
      })
    );
  };
  const onVendorError = (data) => {
    errorToast(data?.message);
  };
  const onCatSuccess = (data) => {
    setCatList(
      data?.data?.map((item) => {
        return { label: item?.name, value: item?.id };
      })
    );
  };
  const onCatError = (data) => {
    // errorToast(data?.message);
  };

  const onmanufacturesSuccess = (data) => {
    setManufacturesList(
      data?.data?.map((item) => {
        return {
          label: item?.name,
          value: item?.id,
        };
      })
    );
  };
  const onmanufacturesError = (data) => {
    setManufacturesList([]);
  };

  const onitemGroupSuccess = (data) => {
    setItemGroup(
      data?.data?.map((item) => {
        return { label: item?.name, value: item?.id };
      })
    );
  };
  const onitemGroupError = (data) => {};
  const onitemTypeSuccess = (data) => {
    setItemType(
      data?.data?.map((item) => {
        return { label: item?.name, value: item?.id };
      })
    );
  };
  const onitemTypeError = (data) => {};
  const validationSchema = Yup.object().shape({});

  const initialData = {
    sl_no: 1,
    item_name: "",
    item_code: "",
    item_uom: "",
    item_minimun_purchasing: "",
    item_maximum_purchasing: "",
    item_quantity: 0,
    cmr_code: "",
    cmr_first_name: "",
    item_unit_price: 0,
    item_total_amount: 0,
    item_id: "",
    cmr_id: "",
    item_generic_name: "",
    item_manufacturer: "",
    item_group: "",
    item_type: "",
    item_categeory: "",
  };
  const initialFormValues = {
    data: [
      ...minQtyItems?.map((item, index) => ({
        sl_no: 1,
        item_name: item?.item_name,
        item_code: item?.item_code,
        item_uom: item?.item_pur_purchasing_uom_name,
        item_minimun_purchasing: item?.item_minimun_purchasing,
        item_maximum_purchasing: item?.item_maximum_purchasing,
        item_quantity:
          parseInt(item?.item_maximum_purchasing) -
          parseInt(item?.total_quantity),
        cmr_code: item?.cmr_code,
        cmr_first_name: item?.item_pur_preferred_vendor,
        item_unit_price: item?.item_unit_price,
        item_total_amount:
          parseFloat(item?.item_unit_price) *
          (parseInt(item?.item_maximum_purchasing) -
            parseInt(item?.total_quantity)),
        item_id: item?.item_id,
        cmr_id: item?.item_pur_preferred_vendor_id,
        item_generic_name: item?.item_generic_name,
        item_manufacturer: item?.item_manufacturer,
        item_group: item?.item_group,
        item_type: item?.item_type,
        item_categeory: item?.item_categeory,
        uom_dropdown: item?.uom_dropdown
          ? item?.uom_dropdown.map((uom) => ({
              label: uom?.alt_uom,
              value: uom?.alt_uom,
            }))
          : [],
      })),
      initialData,
    ],
  };

  function loadMinQtyFn(clearFil = false) {
    setLoadMinQtyLoading(true);
    dispatch(
      loadMinQtyItemsAction(
        clearFil ? initFilter : filterData,
        onSuccessLoad,
        onErrorLoad
      )
    );
  }
  const onSuccessLoad = (data) => {
    setLoadMinQtyLoading(false);
    setMinQtyItems(data?.data);
    setReload(!reload);
    if (data?.data?.length === 0) {
      errorToast("No items with below minimum qty");
    }
  };
  const onErrorLoad = (data) => {
    setLoadMinQtyLoading(false);
  };

  useEffect(() => {
    // if (JSON.stringify(filterData) !== JSON.stringify(initFilter)) {
    //   loadMinQtyFn();
    // }
  }, [filterData]);
  const handleSubmit = (values) => {
    const hasInvalidQuantity = values?.data
      ?.slice(0, -1)
      .some(
        (item) =>
          parseInt(item.item_quantity) === 0 || item.item_quantity === ""
      );

    const hasMissingCmrId = values?.data
      ?.slice(0, -1)
      .some((item) => !item.cmr_first_name);

    if (hasInvalidQuantity) {
      errorToast("Quantity cannot be zero");
    } else if (hasMissingCmrId) {
      errorToast("Vendor cannot be empty");
    } else {
      if (values?.data?.length > 1) {
        dispatch(
          bulkPurchaseCreateAction(
            {
              itemData: values?.data?.slice(0, -1).map((item, index) => ({
                item_code: item?.item_code,
                item_id: item?.item_id,
                item_name: item?.item_name,
                item_uom: item?.item_uom,
                item_unit_price: parseFloat(item?.item_unit_price),
                item_quantity: item?.item_quantity,
                item_total_amount: item?.item_total_amount,
                cmr_code: item?.cmr_code,
                cmr_name: item?.cmr_first_name,
                cmr_id: item?.cmr_id,
                item_generic_name: item?.item_generic_name,
              })),
            },
            onSuccessOrder,
            onErrorOrder
          )
        );
      }
    }
  };

  const onSuccessOrder = (data) => {
    successToast(data?.data?.message);
    navigate("/purchase");
  };
  const onErrorOrder = (data) => {};

  return (
    <InnerLayout module="product" title="Bulk Purchase Order">
      <Formik
        key={reload}
        enableReinitialize={true}
        initialValues={initialFormValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ values, setFieldValue }) => {
          return (
            <Form className="h-full">
              <div className="h-full">
                <div className="flex justify-between first-div flex-wrap">
                  <div className="p-2">
                    <AddButton
                      loading={loadMinQtyLoading}
                      onclkFn={() => {
                        loadMinQtyFn();
                      }}
                      text="Load MIN Quantity Items"
                      prefixIcon={false}
                    />
                  </div>
                  <div className="flex gap-3 items-center p-2">
                    <div
                      style={{ maxWidth: "16rem", height: "2.6rem" }}
                      className={`w-full flex items-center rounded-md ${
                        isFocused ? "border border-primary" : "input_border"
                      }`}
                    >
                      <span className="mx-2 whitespace-nowrap fs_12  text-344054 fw_700">
                        Group By:
                      </span>
                      <Select
                        className="border-0 z-20"
                        styles={customStyles}
                        value={groupByOptions.find(
                          (item) => item.value === filterData?.group_by
                        )}
                        options={groupByOptions}
                        onChange={handleGroupByChange}
                      />
                    </div>
                    <button
                      type="button"
                      style={{
                        height: "2.6rem",
                      }}
                      onClick={() => {
                        setFilterData(initFilter);
                        // loadMinQtyFn(true);
                      }}
                      className={`px-4 whitespace-nowrap fs_14  fw_600 rounded-md input_border flex items-center gap-2  h-full
             ${
               JSON.stringify(filterData) === JSON.stringify(initFilter) &&
               "bg-F2F4F5"
             }
          `}
                    >
                      <ClearIcon />
                      Clear Filter
                    </button>

                    <div ref={filterPopupRef} className="relative">
                      <button
                        type="button"
                        onClick={handleFilterClick}
                        style={{
                          height: "2.6rem",
                        }}
                        className="flex items-center whitespace-nowrap fs_14 input_border rounded-md px-3 gap-2 text-344054 fw_600"
                      >
                        <FilterIcon />
                        Filter
                      </button>
                      {showFilter ? (
                        <BulkPurchaseFilter
                          vendorList={vendorList}
                          manufacturesList={manufacturesList}
                          catList={catList}
                          itemGroup={itemGroup}
                          itemType={itemType}
                          setFilterData={setFilterData}
                          filterData={filterData}
                        />
                      ) : null}
                    </div>
                    <button
                      type="button"
                      onClick={() => {
                        setFieldValue("data", [initialData]);
                      }}
                      className="text-primary underline fs_14 fw_400 whitespace-nowrap"
                      tabIndex="-1"
                    >
                      Clear all
                    </button>
                  </div>
                </div>
                <div
                  style={{
                    height: `calc(100% -  ${heightOfFirstDiv}px)`,
                  }}
                >
                  {loadMinQtyLoading ? (
                    <LoadingSpinner />
                  ) : (
                    <>
                      <div
                        className="table-container"
                        style={{
                          height: `calc(100% -  ${heightOfSecondDiv}px)`,
                        }}
                      >
                        <BulkBillingTable
                          filterData={filterData}
                          initialData={initialData}
                        />
                      </div>
                      <div className="mt-auto second-div flex justify-between p-4">
                        <DeleteButton
                          onclkFn={() => {
                            navigate("/purchase");
                          }}
                          className=" border-51af49 text-51ae49 px-10 py-2 flex rounded-md justify-center fw_500 fs_16"
                          prefixIcon={false}
                          text="Cancel"
                        />
                        <AddButton
                          type="submit"
                          prefixIcon={false}
                          text="Create Purchase Orders"
                        />
                      </div>
                    </>
                  )}
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </InnerLayout>
  );
};

export default BulkPurchaseOrder;

export const customStyles = {
  control: (provided, state) => ({
    ...provided,
    fontSize: "12px",
    fontWeight: "600",
    color: "#344054",
    width: "10rem",
    boxShadow: "none", // Remove box shadow
    border: "none",
    borderRadius: "6px",
  }),
  placeholder: (defaultStyles) => ({
    ...defaultStyles,
    color: "gray",
    fontFamily: "Inter, sans-serif",
    fontSize: "12px",
    fontWeight: "600",
    opacity: "1",
    position: "absolute",
    whiteSpace: "nowrap",
  }),
  indicatorSeparator: (provided, state) => ({
    ...provided,
    display: "none", // Hide indicator separator
  }),
  singleValue: (base) => ({
    ...base,
    fontWeight: "600",
    color: "#344054",
    fontSize: "12px",
  }),
  option: (provided, state) => ({
    ...provided,
    ...(state.data.color ? { color: state.data.color } : { color: "black" }),
    backgroundColor: state.isSelected ? "#e5ecffb2" : null,
    borderBottom: "1px solid #F5F5F5",
    fontSize: "12px",
    fontFamily: "Inter, sans-serif",
    "&:hover": {
      backgroundColor: state.isFocused ? "#0000002E" : "#0000002E",
    },
    "&::after": {
      content: state.isSelected ? '"✓"' : '""',
      color: "#007bff", // Blue color
      fontWeight: "normal",
      fontSize: "20px", // Larger size
      position: "absolute",
      right: "0.8rem",
      marginBottom: "22px",
    },
  }),
};
