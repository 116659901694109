import moment from "moment";
import React from "react";
import ReactDOMServer from "react-dom/server";

const PrintableComponent = ({ data, storeDetails }) => {
  const name = localStorage?.getItem("user_name");
  return (
    <div
      style={{
        fontWeight: "600",
        fontSize: "30px", 
        fontFamily: "Courier, monospace", 
        lineHeight: "1.2"
      }}
    >
      <div style={{ textAlign: "center", marginBottom: "10px" }}>
        <img
         // src={`https://unwraphappiness.in/cdn/shop/files/Group_1171275741_280x.png?v=1720181874.png`}
         src={storeDetails?.store_logo}
         
          alt="Store Logo"
          style={{ width: "100px", height: "auto" }}
        />
      </div>
      <h1 style={{ fontSize: "30px", textAlign: "center", margin: "0" }}>
        {storeDetails?.store_name}
      </h1>
      <p style={{ textAlign: "center", margin: "0" }}>
        {storeDetails?.store_address}
      </p>
      <p style={{ textAlign: "center", margin: "0" }}>
        GSTIN: {storeDetails?.store_gst_no}
      </p>
      <hr
        style={{
          border: "none",
          borderTop: "1.5px dotted #000",
          margin: "10px 0",
        }}
      />
      <div>
        <p style={{ margin: "0" }}>Staff: {name}</p>
        <p style={{ margin: "0" }}>
          Trans: {data?.orderData?.[0]?.sot_invoice_number}
        </p>
        <p style={{ margin: "0" }}>
          Date: {moment(data?.orderData?.[0]?.created_date).format("DD-MM-YY")}
        </p>
        <p style={{ margin: "0" }}>
          Time: {moment(data?.orderData?.[0]?.created_date).format("hh:mm A")}
        </p>
      </div>
      <hr
        style={{
          border: "none",
          borderTop: "1.5px dotted #000",
          margin: "10px 0",
        }}
      />
      {/* Items Table */}
      <div className="items-section">
        <div className="print-header">
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div style={{ width: "40%", textAlign: "left" }}>Item</div>
            <div style={{ textAlign: "center", width: "12%" }}>Qty</div>
            <div style={{ textAlign: "right", width: "20%" }}>MRP</div>
            <div style={{ textAlign: "right", width: "28%" }}>Amount</div>
          </div>
        </div>
      </div>
      <table
        style={{
          width: "100%",
          borderCollapse: "collapse",
          fontSize: "30px",
        }}
      >
        {/* <thead className="print-header">
          <tr>
            <th style={{ textAlign: "left", width: "37%" }}>Item</th>
            <th style={{ textAlign: "center" }}>Qty</th>
            <th style={{ textAlign: "right" }}>MRP</th>
            <th style={{ textAlign: "right" }}>Amount</th>
          </tr>
        </thead> */}
        <tbody>
          {data?.items?.map((item) => (
            <tr
              style={{
                textAlign: "left",
                fontWeight: "600",
                marginBottom: "10px",
              }}
              key={item?.item_id}
            >
              <td style={{ textAlign: "left", width: "40%" }}>
                {item?.item_name}
              </td>
              <td style={{ textAlign: "center", width: "12%" }}>
                {item?.item_quantity}
              </td>
              <td style={{ textAlign: "right", width: "20%" }}>
                {/* {(
                  parseFloat(item?.item_price_wiithout_tax) /
                  parseFloat(item?.item_quantity)
                ).toFixed(2)} */}
                {item?.item_unit_price}
              </td>
              <td style={{ textAlign: "right", width: "28%" }}>
                {/* {parseFloat(item?.item_price_wiithout_tax).toFixed(2)}
                 */}
                {(
                  parseFloat(item?.item_unit_price) *
                  parseFloat(item?.item_quantity)
                ).toFixed(2)}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <hr
        style={{
          border: "none",
          borderTop: "1.5px dotted #000",
          margin: "10px 0",
        }}
      />
      {/* GST Details Section */}
      {/* GST Details Section in Table Format */}
      <div style={{ textAlign: "right" }}>
        <p style={{ margin: "0" }}>Total Amt: {calculateMrpTotal(data)}</p>
        <p style={{ margin: "0" }}>
          Total Dis Amt:{" "}
          {parseFloat(data?.orderData?.[0]?.sot_total_discount).toFixed(2)}
        </p>
        <p style={{ margin: "0", fontWeight: "bold", fontSize: "30px" }}>
          Total Payable Amt:{" "}
          {parseFloat(data?.orderData?.[0]?.sot_total_amount).toFixed(2)}
        </p>
      </div>
      <hr
        style={{
          border: "none",
          borderTop: "1.5px dotted #000",
          margin: "10px 0",
        }}
      />
      <p style={{ textAlign: "center", margin: "0" }}>Gst Details</p>
      <table
        style={{
          width: "100%",
          borderCollapse: "collapse",
          fontSize: "30px",
        }}
      >
        <thead className="print-header">
          <tr style={{ fontSize: "30px", fontWeight: "600" }}>
            <th style={{ textAlign: "left" }}>SGST</th>
            <th style={{ textAlign: "left" }}>CGST</th>
            <th style={{ textAlign: "left" }}>Total GST</th>
          </tr>
        </thead>
        <tbody>
          <tr style={{ fontSize: "30px", fontWeight: "600" }}>
            <td style={{ textAlign: "left" }}>
              {(parseFloat(data?.orderData?.[0]?.sot_total_gst) / 2).toFixed(2)}
            </td>
            <td style={{ textAlign: "left" }}>
              {(parseFloat(data?.orderData?.[0]?.sot_total_gst) / 2).toFixed(2)}
            </td>
            <td style={{ textAlign: "left" }}>
              {parseFloat(data?.orderData?.[0]?.sot_total_gst).toFixed(2)}
            </td>
          </tr>
        </tbody>
      </table>
      <hr
        style={{
          border: "none",
          borderTop: "1.5px dotted #000",
          margin: "10px 0",
        }}
      />
      <div style={{ textAlign: "center", marginBottom: "20px" }}>
        <p style={{ margin: "0" }}>www.unwraphappiness.in</p>
        <p style={{ margin: "0" }}>Info@unwraphappiness.in</p>
        <p style={{ margin: "0" }}>7778889223</p>
        <p style={{ margin: "0" }}>Follow us on Instagram & Facebook</p>
      </div>
      <hr
        style={{
          border: "none",
          borderTop: "1.5px dotted #000",
          margin: "10px 0",
        }}
      />{" "}
      <p style={{ textAlign: "center", marginBottom: "20px" }}>Visit Again!</p>
    </div>
  );
};

export default PrintableComponent;

const captureComponentAsHTML = (data, storeDetails) => {
  const htmlString = ReactDOMServer.renderToStaticMarkup(
    <PrintableComponent data={data} storeDetails={storeDetails} />
  );
  return htmlString;
};

export const handlePrint = (data, storeDetails) => {
  if (window.electron && window.electron.ipcRenderer) {
    const htmlString = captureComponentAsHTML(data, storeDetails);
    window.electron.ipcRenderer.send("print-html", htmlString);
  } else {
    console.error("ipcRenderer is not available");
  }
};

const calculateMrpTotal = (data) => {
  return (
    data?.items?.reduce((total, item) => {
      return total + (item?.item_unit_price * item?.item_quantity || 0);
    }, 0) || 0
  )?.toFixed(2);
};
