import React from "react";
import InnerLayout from "../../../components/layouts/inner-layout";
import CreateEditViewPurchasePaymentOut from "../../../components/purchase-section/purchase-payment-out/create-edit-view-payment-out";

const AddPurchasePaymentOut = () => {
  return (
    <>
      <InnerLayout title="Add Payment Out">
        <CreateEditViewPurchasePaymentOut />
      </InnerLayout>
    </>
  );
};
export default AddPurchasePaymentOut;
