import React, { useEffect, useState } from "react";
import InnerLayout from "../../../components/layouts/inner-layout";
import LoadingSpinner from "../../../components/helper-components/loading-spinner";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import CreateEditViewSales from "../../../components/sales-section/sales-order/create-edit-view-sales";
import { salesViewAction } from "../../../redux/actions/sales-action";

const SalesOrderView = () => {
  const { id } = useParams();

  const [orderDetail, setOrderDetail] = useState({});
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    setLoading(true);
    dispatch(salesViewAction(id, onSuccPur, onErrPur));
  }, []);

  const onSuccPur = (data) => {
    
    setOrderDetail(data?.data?.[0]);
    setLoading(false);
  };

  const onErrPur = (data) => {
    setLoading(false);
  };

  return (
    <>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <InnerLayout backBtn={true} title="Sales Order View">
          <CreateEditViewSales
            page="view"
            disabled={true}
            data={orderDetail}
            orderDetail={orderDetail?.itemData}
            vendorDetails={[orderDetail?.orderData]}
          />
        </InnerLayout>
      )}
    </>
  );
};
export default SalesOrderView;
