import React from "react";

const MinusIcon = ({ onClickFn = () => {} }) => {
  return (
    <svg
      onClick={onClickFn}
      width="17"
      height="3"
      viewBox="0 0 17 3"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.5 1.5H15.5"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default MinusIcon;
