import React, { useEffect, useState } from "react";
import InnerLayout from "../../../components/layouts/inner-layout";
import LoadingSpinner from "../../../components/helper-components/loading-spinner";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import CreateEditViewDelivery from "../../../components/sales-section/delivery-section/create-edit-view-delivery";
import { salesDeliveryViewAction } from "../../../redux/actions/sales-action";
const EditDelivery = () => {
  const { id } = useParams();
  const [orderDetail, setOrderDetail] = useState({});
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    setLoading(true);
    dispatch(salesDeliveryViewAction(id, onSuccPur, onErrPur));
  }, []);

  const onSuccPur = (data) => {
    setOrderDetail(data?.data?.[0]);
    setLoading(false);
  };

  const onErrPur = (data) => {
    setLoading(false);
  };

  return (
    <>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <InnerLayout backBtn={true} title="Edit Delivery">
          <CreateEditViewDelivery
            page="edit"
            disabled={true}
            orderDetail={orderDetail?.itemData}
            vendorDetails={[orderDetail?.orderData]}
          />
        </InnerLayout>
      )}
    </>
  );
};
export default EditDelivery;
