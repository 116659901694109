import { useEffect } from "react";
import errorToast from "./error-toast";

export function formatString(str) {
  // Split the string by underscore and capitalize each word
  const words = str
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1));
  // Join the words with space
  return words.join(" ");
}

const useBarcodeScanner = (onBarcodeScanned) => {
  useEffect(() => {
    let barcodeBuffer = ""; // Buffer to accumulate scanned characters
    let lastKeyPressTime = 0; // Timestamp of the last key press

    const handleBarcodeScan = (event) => {
      const currentTime = new Date().getTime();
      const scannedCharacter = event.key;
      console.log(scannedCharacter, "dssfsfdfds");

      if (
        scannedCharacter === "Shift" ||
        event.ctrlKey ||
        event.altKey ||
        event.metaKey ||
        scannedCharacter === "Enter"
      ) {
        // Ignore special keys like Shift, Ctrl, Alt, Meta (Command on Mac), and Enter key
        return;
      }

      if (currentTime - lastKeyPressTime > 50) {
        // If the time between key presses is greater than 50 milliseconds, assume it's scanner input
        barcodeBuffer = scannedCharacter; // Start a new barcode sequence
      } else {
        barcodeBuffer += scannedCharacter; // Continue accumulating characters
      }

      lastKeyPressTime = currentTime; // Update the timestamp
    };

    document.addEventListener("keydown", handleBarcodeScan);

    const handleEnterKey = (event) => {
      if (event.key === "Enter") {
        event.preventDefault(); // Prevent the default behavior of the Enter key
        onBarcodeScanned(barcodeBuffer); // Call the callback when Enter key is pressed
      }
    };

    document.addEventListener("keydown", handleEnterKey);

    return () => {
      document.removeEventListener("keydown", handleBarcodeScan);
      document.removeEventListener("keydown", handleEnterKey);
    };
  }, [onBarcodeScanned]);
};

export default useBarcodeScanner;

export const formatYAxisTick = (tick) => {
  if (tick >= 100000) {
    return `${(tick / 100000).toFixed(0)}L`;
  } else if (tick >= 1000) {
    return `${(tick / 1000).toFixed(0)}K`;
  } else {
    return tick;
  }
};

export const handleShowErrorToast = (errors) => {
  const firstErrorField = Object.values(errors)[0]; // Get the first field with an error
  if (firstErrorField) {
    errorToast(firstErrorField + " !");
  }
};
