import { call, takeLatest } from "redux-saga/effects";
import {
  doctorCreateApi,
  doctorDeleteApi,
  doctorEditApi,
  doctorTableApi,
  doctorViewApi,
} from "../apis/doctor-api";
import {
  DOCTOR_CREATE,
  DOCTOR_DELETE,
  DOCTOR_EDIT,
  DOCTOR_TABLE,
  DOCTOR_VIEW,
} from "../types";

export function* doctorCreateWorker(action) {
  try {
    const res = yield call(doctorCreateApi, action.data);
    
    if (res.data?.code === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}

export function* doctorTableWorker(action) {
  try {
    const res = yield call(doctorTableApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}

export function* doctorViewWorker(action) {
  try {
    const res = yield call(doctorViewApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}

export function* doctorDeleteWorker(action) {
  try {
    const res = yield call(doctorDeleteApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* doctorEditWorker(action) {
  try {
    const res = yield call(doctorEditApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}

export function* doctorCreateWatcher() {
  yield takeLatest(DOCTOR_CREATE, doctorCreateWorker);
}
export function* doctorTableWatcher() {
  yield takeLatest(DOCTOR_TABLE, doctorTableWorker);
}
export function* doctorViewWatcher() {
  yield takeLatest(DOCTOR_VIEW, doctorViewWorker);
}
export function* doctorEditWatcher() {
  yield takeLatest(DOCTOR_EDIT, doctorEditWorker);
}
export function* doctorDeleteWatcher() {
  yield takeLatest(DOCTOR_DELETE, doctorDeleteWorker);
}
