import React from "react";

const StoreFilter = ({ tableHeading, setTableHeading }) => {
  const handleCheckboxChange = (index) => {
    const updatedTableHeading = [...tableHeading]; // Copy the tableHeading array
    updatedTableHeading[index] = {
      ...updatedTableHeading[index], // Copy the specific column object
      isColumnneeded: !updatedTableHeading[index].isColumnneeded, // Toggle the isColumnneeded property
    };
    setTableHeading(updatedTableHeading); // Update the tableHeading state
  };

  return (
    <div
      onClick={(e) => e.stopPropagation()}
      style={{
        minWidth: "15rem",
        right: "-30px", // Adjust this value as needed
      }}
      className="absolute top-4 border-EAECF0 border right-0 mt-8 mr-8 py-4 bg-white rounded shadow-lg z-10"
    >
      <span className="color_6C7184 fs_14 fw_600 px-5">Columns to show</span>
       <hr className="border border-EAECF0 mt-3"/>
      <div className="px-3 mt-3">
        <div className="h-full">
          {/* <label className="fs_14"></label> */}
          {tableHeading.map((column, index) =>
              <div key={index} className="flex items-center">
                <input
                  type="checkbox"
                  id={column.value}
                  name={column.value}
                  checked={column.isColumnneeded} // Use isColumnneeded property to control checked state
                  onChange={() => handleCheckboxChange(index)} // Pass the index to the handler
                  className="mr-2"
                />
                <label htmlFor={column.value}>{column.label}</label>
              </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default StoreFilter;
