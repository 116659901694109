import React from "react";

const EditIcon = ({fill="#277DFE"}) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.3335 2.66714H4.5335C3.41339 2.66714 2.85334 2.66714 2.42552 2.88513C2.04919 3.07688 1.74323 3.38284 1.55148 3.75916C1.3335 4.18699 1.3335 4.74704 1.3335 5.86714V11.4671C1.3335 12.5872 1.3335 13.1473 1.55148 13.5751C1.74323 13.9514 2.04919 14.2574 2.42552 14.4492C2.85334 14.6671 3.41339 14.6671 4.5335 14.6671H10.1335C11.2536 14.6671 11.8137 14.6671 12.2415 14.4492C12.6178 14.2574 12.9238 13.9514 13.1155 13.5751C13.3335 13.1473 13.3335 12.5872 13.3335 11.4671V8.66714M5.33348 10.6671H6.44984C6.77596 10.6671 6.93902 10.6671 7.09247 10.6303C7.22852 10.5976 7.35858 10.5438 7.47788 10.4707C7.61243 10.3882 7.72773 10.2729 7.95833 10.0423L14.3335 3.66714C14.8858 3.11486 14.8858 2.21943 14.3335 1.66714C13.7812 1.11486 12.8858 1.11486 12.3335 1.66714L5.95832 8.04231C5.72772 8.27291 5.61241 8.38821 5.52996 8.52276C5.45685 8.64206 5.40298 8.77212 5.37032 8.90817C5.33348 9.06162 5.33348 9.22468 5.33348 9.5508V10.6671Z"
        stroke={fill}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default EditIcon;

export const PrintIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.0002 4.66634V3.46634C12.0002 2.7196 12.0002 2.34624 11.8548 2.06102C11.727 1.81014 11.523 1.60616 11.2722 1.47833C10.9869 1.33301 10.6136 1.33301 9.86683 1.33301H6.1335C5.38676 1.33301 5.01339 1.33301 4.72818 1.47833C4.47729 1.60616 4.27332 1.81014 4.14549 2.06102C4.00016 2.34624 4.00016 2.7196 4.00016 3.46634V4.66634M4.00016 11.9997C3.38018 11.9997 3.07019 11.9997 2.81586 11.9315C2.12567 11.7466 1.58658 11.2075 1.40164 10.5173C1.3335 10.263 1.3335 9.95299 1.3335 9.33301V7.86634C1.3335 6.74624 1.3335 6.18618 1.55148 5.75836C1.74323 5.38204 2.04919 5.07608 2.42552 4.88433C2.85334 4.66634 3.41339 4.66634 4.5335 4.66634H11.4668C12.5869 4.66634 13.147 4.66634 13.5748 4.88433C13.9511 5.07608 14.2571 5.38204 14.4488 5.75836C14.6668 6.18618 14.6668 6.74624 14.6668 7.86634V9.33301C14.6668 9.95299 14.6668 10.263 14.5987 10.5173C14.4137 11.2075 13.8747 11.7466 13.1845 11.9315C12.9301 11.9997 12.6201 11.9997 12.0002 11.9997M10.0002 6.99967H12.0002M6.1335 14.6663H9.86683C10.6136 14.6663 10.9869 14.6663 11.2722 14.521C11.523 14.3932 11.727 14.1892 11.8548 13.9383C12.0002 13.6531 12.0002 13.2797 12.0002 12.533V11.4663C12.0002 10.7196 12.0002 10.3462 11.8548 10.061C11.727 9.81014 11.523 9.60616 11.2722 9.47833C10.9869 9.33301 10.6136 9.33301 9.86683 9.33301H6.1335C5.38676 9.33301 5.01339 9.33301 4.72818 9.47833C4.47729 9.60616 4.27332 9.81014 4.14549 10.061C4.00016 10.3462 4.00016 10.7196 4.00016 11.4663V12.533C4.00016 13.2797 4.00016 13.6531 4.14549 13.9383C4.27332 14.1892 4.47729 14.3932 4.72818 14.521C5.01339 14.6663 5.38676 14.6663 6.1335 14.6663Z"
        stroke="#277DFE"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export const ShareIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14 8V10.8C14 11.9201 14 12.4802 13.782 12.908C13.5903 13.2843 13.2843 13.5903 12.908 13.782C12.4802 14 11.9201 14 10.8 14H5.2C4.07989 14 3.51984 14 3.09202 13.782C2.71569 13.5903 2.40973 13.2843 2.21799 12.908C2 12.4802 2 11.9201 2 10.8V8M10.6667 4.66667L8 2M8 2L5.33333 4.66667M8 2V10"
        stroke="#277DFE"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export const VoidIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6 6L10 10M10 6L6 10M5.2 14H10.8C11.9201 14 12.4802 14 12.908 13.782C13.2843 13.5903 13.5903 13.2843 13.782 12.908C14 12.4802 14 11.9201 14 10.8V5.2C14 4.0799 14 3.51984 13.782 3.09202C13.5903 2.71569 13.2843 2.40973 12.908 2.21799C12.4802 2 11.9201 2 10.8 2H5.2C4.0799 2 3.51984 2 3.09202 2.21799C2.71569 2.40973 2.40973 2.71569 2.21799 3.09202C2 3.51984 2 4.0799 2 5.2V10.8C2 11.9201 2 12.4802 2.21799 12.908C2.40973 13.2843 2.71569 13.5903 3.09202 13.782C3.51984 14 4.0799 14 5.2 14Z"
        stroke="#277DFE"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
