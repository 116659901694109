import React from "react";

const SettingsItemContainer = ({
  setting,
  index,
  handleItemClick = () => { },
  search = false,
}) => {
  return (
    <div
      className="mt-3 hover:bg-[#f5faff] border border-white hover:border-[#99b3ff] max-w-[380px] p-6 cursor-pointer rounded-xl hover:shadow-lg transition duration-300 ease-in-out flex gap-5 items-start"
      key={index}
      onClick={() => handleItemClick(index, setting)}
    >
      {!search && (
        <div className="bg-primary w-fit px-2 py-2 rounded h-fit">{setting.icon}</div>
      )}
      <div className="-mt-1">
        <div className="font-semibold text-md">{setting.title}</div>
        <div className="text-sm text-gray-600">{setting.desc}</div>
      </div>
      {/* <div className="text-xs text-gray-400">{setting.path}</div> */}
    </div>
  );
};

export default SettingsItemContainer;