import React from "react";

const JournalEntryIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19 10H13H14C14.7956 10 15.5587 10.3161 16.1213 10.8787C16.6839 11.4413 17 12.2044 17 13C17 13.7956 16.6839 14.5587 16.1213 15.1213C15.5587 15.6839 14.7956 16 14 16H13L16 19M13 13H19M5 3C5 3.53043 4.78929 4.03914 4.41421 4.41421C4.03914 4.78929 3.53043 5 3 5C2.46957 5 1.96086 4.78929 1.58579 4.41421C1.21071 4.03914 1 3.53043 1 3C1 2.46957 1.21071 1.96086 1.58579 1.58579C1.96086 1.21071 2.46957 1 3 1C3.53043 1 4.03914 1.21071 4.41421 1.58579C4.78929 1.96086 5 2.46957 5 3ZM5 3H13M5 3V11C5 11.7956 5.31607 12.5587 5.87868 13.1213C6.44129 13.6839 7.20435 14 8 14H9M13 3C13 3.53043 13.2107 4.03914 13.5858 4.41421C13.9609 4.78929 14.4696 5 15 5C15.5304 5 16.0391 4.78929 16.4142 4.41421C16.7893 4.03914 17 3.53043 17 3C17 2.46957 16.7893 1.96086 16.4142 1.58579C16.0391 1.21071 15.5304 1 15 1C14.4696 1 13.9609 1.21071 13.5858 1.58579C13.2107 1.96086 13 2.46957 13 3Z"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default JournalEntryIcon;
