import React, { useEffect, useState } from "react";
import FilterRadioButtons from "../../forms/filter-radio-buttons";
import { RolesListAction } from "../../../redux/actions/general-action";
import { useDispatch } from "react-redux";

const UserFilter = ({ setFilterData, filterData, setCurrentPage }) => {
  const [rolesList, setRolesList] = useState([]);
const dispatch = useDispatch()
  const handleRoleChange = (e) => {
    setFilterData({
      ...filterData,
      role: e.target.value,
    });
    setCurrentPage(1);
  };

  useEffect(() => {
    dispatch(RolesListAction(onSuccessRoles, onErrorRoles));
  }, []);
  const onSuccessRoles = (data) => {
    setRolesList(
      data?.data?.map((item) => ({
        label: item?.role_name,
        value: item?.role_name,
      }))
    );
  };
  const onErrorRoles = (data) => {};

  return (
    <div
      menuPortalTarget={document.body}
      style={{
        minWidth: "12rem",
        right: "-30px", // Adjust this value as needed
      }}
      className="absolute  border border-EAECF0 border-solid border-1 top-4 right-0 mt-8 mr-8 py-2  bg-white  rounded shadow-lg z-10"
    >
      <span className="color_6C7184 fs_12 fw_600 px-5">FILTER BY</span>
       <hr className="border border-EAECF0 mt-3"/>
      <div className="px-5 mt-3">
        <FilterRadioButtons
          name="role"
          label={"Filter Roles"}
          options={rolesList}
          handleChange={handleRoleChange}
          value={filterData?.role}
        />
      </div>
    </div>
  );
};

export default UserFilter;
