import { Field, useFormikContext } from "formik";
import React, { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import DeleteIcon from "../../../assets/icons/delete-icon";
import { url } from "../../../redux/config";
import "../../forms/form.css";
import SearchAddFeild from "../../forms/search-add-field";
import errorToast from "../../helper-components/error-toast";
import { api } from "../../../redux/apis/api";
import useBarcodeScanner from "../../helper-components/helper-function";
import { invoiceScannedItemAction } from "../../../redux/actions/invoices-action";
import { salesProductListAction } from "../../../redux/actions/sales-action";
import { productListAction } from "../../../redux/actions/general-action";
import moment from "moment";
import SelectField from "../../forms/select-field";
import { stockTransferBatchAction } from "../../../redux/actions/inventory-action";
import { purchaseProductListAction } from "../../../redux/actions/purchase-action";

const StocktransferItemTable = ({
  initialData,
  disabled = false,
  searchRef,
  addBatchFn = () => {},
}) => {
  const inputRefs = useRef(null);
  const { values, setFieldValue } = useFormikContext();

  const dispatch = useDispatch();
  const [localData, setLocalData] = useState([]);
  const tableHeading = [
    { label: "S No.", value: "sl_no" },
    { label: "Product Name", value: "item_product_name" },
    { label: "Item Code", value: "item_code" },
    { label: "Bch Details", value: "batch_details" },
    { label: "Qty", value: "item_quantity" },
    { label: "UOM", value: "item_uom" },
    { label: "Current Avail", value: "current_availability" },
    ...(disabled ? [] : [{ label: "", value: "" }]),
  ];
  const fetchData = async (inputValue, callback, field) => {
    const onSuccess = (data) => {
      const options =
        data?.data === null
          ? []
          : data.data
              .map((item) => ({
                value: item.item_id,
                label: item[field],
              }))
              .filter(
                (option) =>
                  !values?.data?.some((item) => item?.item_id === option.value)
              );
      setLocalData(data.data);
      callback(options);
    };
    const onError = (data) => {};
    if (inputValue?.length > 0) {
      dispatch(
        // productListAction(
        purchaseProductListAction(
          {
            field: field,
            value: inputValue,
          },
          onSuccess,
          onError
        )
      );
    }
  };

  const removeRow = (rowIndex) => {
    const updatedData = [...values.data];
    updatedData.splice(rowIndex, 1);
    setFieldValue("data", updatedData);
  };

  const handleQuantityChange = async (event, rowIndex) => {
    const updatedData = values.data.map((row, index) =>
      index === rowIndex
        ? { ...row, quantity: parseFloat(event.target.value) }
        : row
    );
    setFieldValue("data", updatedData);
  };

  const hasDuplicateItemId = (itemId, currentIndex) => {
    const itemIdsExceptCurrent = values.data
      .filter((_, index) => index !== currentIndex)
      .map((item) => item.item_id);

    return itemIdsExceptCurrent.includes(itemId);
  };

  useBarcodeScanner((barcode) => {
    if (barcode) {
      dispatch(
        invoiceScannedItemAction(barcode, onSuccessScanned, onErrorScanned)
      );
    }
  });

  const onSuccessScanned = (data) => {
    const itemData = data?.data?.item_information;
    if (
      !values?.data?.some(
        (item) => item?.item_id === data?.data?.item_information?.item_id
      )
    ) {
      const newdata = {
        sl_no: 1,
        item_name: itemData?.item_name,
        item_generic_name: itemData?.item_generic_name,
        item_code: itemData?.item_code,
        item_uom: itemData?.item_uom,
        item_unit_price: itemData?.item_unit_price,
        quantity: 1,
        item_total_amount:
          parseFloat(itemData?.item_unit_price) * 1 -
          data.data.discount_details?.item_discount_amount +
          data.data.discount_details?.item_tax_amount,
        item_id: itemData?.item_id,
        item_tax_amount: data.data.discount_details?.item_tax_amount,
        item_gst: data.data.discount_details?.item_total_tax_percentage,
        item_sgst: (
          (parseFloat(itemData?.item_unit_price) -
            parseFloat(data.data.discount_details?.item_discount_amount)) *
          (data.data.discount_details?.sgstRate / 100)
        )?.toFixed(2),
        item_cgst: (
          (parseFloat(itemData?.item_unit_price) -
            parseFloat(data.data.discount_details?.item_discount_amount)) *
          (data.data.discount_details?.cgstRate / 100)
        )?.toFixed(2),
        item_igst: (
          (parseFloat(itemData?.item_unit_price) -
            parseFloat(data.data.discount_details?.item_discount_amount)) *
          (data.data.discount_details?.igstRate / 100)
        )?.toFixed(2),
      };
      const oldData = [...values?.data];
      oldData.pop();
      setFieldValue("data", [...oldData, newdata, initialData]);
    } else {
      errorToast(
        "Item already added to invoice. Please adjust quantity if you wish to add more."
      );
    }
  };
  const onErrorScanned = (data) => {
    errorToast(data?.data?.message);
  };

  function fetchBatchfn(id, index) {
    dispatch(
      stockTransferBatchAction(
        id,
        (data) => {
          if (index) {
            setFieldValue(`data[${index}].itemBatchList`, data?.data);
          }
        },
        (data) => {}
      )
    );
  }

  console.log(values, "asdasdasdasdas");

  return (
    <>
      <table className="my-table m-0">
        <thead className="sticky top-0 z-10">
          <tr className="bg-F2F4F5">
            {tableHeading.map((heading, index) => (
              <th
                key={index}
                className={`${
                  index === tableHeading?.length - 1
                    ? "last-column"
                    : "table-header"
                } whitespace-nowrap fs_14 fw_500 text-secondary text-left 

                  ${index !== 1 ? "w-[5rem]" : ""}
                  ${index === 4 ? "min-w-[5rem]" : ""}
                  ${index === 5 && "min-w-[8.5rem]"}
                  ${index === 9 ? "max-w-[2rem]" : ""}
                  `}
              >
                {heading?.label}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {values?.data?.map((rowData, rowIndex) => (
            <tr key={rowIndex}>
              {Object?.values(rowData)?.map((cellData, colIndex) => {
                if (colIndex <= 6) {
                  return (
                    <td
                      key={colIndex}
                      className={`table-cell fs_14 fw_400 text-secondary  ${
                        colIndex === 3 ? "text-center" : ""
                      }
                        `}
                    >
                      {colIndex === 1 || colIndex === 2 || colIndex === 4 ? (
                        colIndex !== 4 ? (
                          <div>
                            <SearchAddFeild
                              searchRef={colIndex === 1 ? searchRef : {}}
                              isDisabled={
                                values?.data?.length - 1 !== rowIndex ||
                                disabled
                                  ? true
                                  : false
                              }
                              errorClass="hidden"
                              isRequired={false}
                              value={{
                                label:
                                  values?.data[rowIndex][
                                    Object.keys(rowData)[colIndex]
                                  ],
                                value: "",
                              }}
                              name={`data[${rowIndex}].${
                                Object.keys(rowData)[colIndex]
                              }`}
                              field={Object.keys(rowData)[colIndex]}
                              isLabelReq={false}
                              defaultSelectClass=""
                              placeholder=""
                              fetchData={fetchData}
                              onChange={async (selectedOption) => {
                                const filteredData = localData
                                  ?.filter(
                                    (item) =>
                                      item?.item_id === selectedOption?.value
                                  )
                                  .map((item) => ({
                                    sl_no: 1,
                                    item_name: item.item_name,
                                    item_code: item.item_code,
                                    item_batches: [],
                                    quantity: "",
                                    item_uom:
                                      item.item_pur_purchasing_uom_name === null
                                        ? ""
                                        : item.item_pur_purchasing_uom_name,
                                    current_avail: {
                                      item_uom_source: "",
                                      item_uom_destination: "",
                                    },
                                    item_id: item?.item_id,
                                    uom_dropdown: item?.uom_dropdown
                                      ? item?.uom_dropdown.map((uom) => ({
                                          item_uom_id: uom?.item_id,
                                          label: uom?.alt_uom,
                                          value: uom?.alt_uom,
                                        }))
                                      : [],
                                    item_uom_id:
                                      item?.item_pur_purchasing_uom_name_id ??
                                      "",
                                  }));
                                const oldData = [...values?.data];
                                oldData.pop();
                                setFieldValue("data", [
                                  ...oldData,
                                  ...filteredData,
                                  initialData,
                                ]);
                                inputRefs.current?.focus();
                              }}
                            />
                          </div>
                        ) : (
                          <div className="flex">
                            <Field name={`data[${rowIndex}].quantity`}>
                              {({ field }) => (
                                <input
                                  ref={inputRefs}
                                  {...field}
                                  type="number"
                                  step="1"
                                  placeholder=""
                                  disabled={true}
                                  readOnly={
                                    values?.data?.length - 1 === rowIndex ||
                                    hasDuplicateItemId(
                                      rowData.item_id,
                                      rowIndex
                                    ) ||
                                    disabled
                                  }
                                  onKeyDown={(event) => {
                                    if (
                                      event.key === "Tab" ||
                                      event.key === "Enter"
                                    ) {
                                      handleQuantityChange(event, rowIndex);
                                      setFieldValue(
                                        `data[${rowIndex}].quantity`,
                                        event.target.value
                                      );
                                      if (searchRef && searchRef.current) {
                                        setTimeout(() => {
                                          searchRef.current.focus();
                                        }, 0);
                                      }
                                    }
                                  }}
                                  className="focus-within:ring-2  px-2 w-full py-3 focus-within:ring-blue-500 rounded focus-within:outline-none focus-within:shadow-[0px_0px_0px_4px_#EBF2FF input-field-no-spin-buttons"
                                />
                              )}
                            </Field>
                          </div>
                        )
                      ) : colIndex === 0 ? (
                        <div>
                          <input
                            type="radio"
                            name="SerialNumberRadioGroup"
                            disabled={disabled}
                            value={rowIndex}
                            className="peer hidden"
                            id={rowIndex}
                          />
                          <label
                            htmlFor={rowIndex}
                            className="block cursor-pointer select-none rounded-xl p-3 text-center hover:bg-slate-100 peer-checked:bg-blue-100 peer-checked:font-bold peer-checked:outline-none peer-checked:ring-2 peer-checked:ring-blue-500"
                          >
                            {rowIndex + 1}
                          </label>
                        </div>
                      ) : colIndex === 6 ? (
                        <div className="flex justify-between gap-10">
                          <span>
                            {cellData?.item_uom_source && <span>Source:</span>}

                            <span>{cellData?.item_uom_source}</span>
                          </span>
                          <span>
                            {cellData?.item_uom_destination && (
                              <span>Destination:</span>
                            )}

                            <span> {cellData?.item_uom_destination}</span>
                          </span>
                        </div>
                      ) : colIndex === 3 ? (
                        <>
                          {rowIndex < values?.data?.length - 1 &&
                            values?.stock_management_action !==
                              "stock_transfer_request" && (
                              <button
                                onClick={() => addBatchFn(rowData?.item_id)}
                                type="button"
                                className="text-000000 fw_500 fs_14 opacity-30 text-nowrap"
                              >
                                {disabled
                                  ? "View batch details"
                                  : "Click to" + rowData.item_batches?.length >
                                    0
                                  ? "Update"
                                  : "Add"}
                              </button>
                            )}

                          {Array.isArray(rowData.item_batches) &&
                            rowData?.item_batches.map((batch, index) => (
                              <div className="whitespace-nowrap" key={index}>
                                {batch?.item_batch_number} -{" "}
                                {batch?.item_batch_quantity}
                                {values?.stock_management_action ===
                                  "stock_in" && <> - {batch?.item_exp_date}</>}
                              </div>
                            ))}
                        </>
                      ) : (colIndex === 7 || colIndex === 8) &&
                        rowIndex < values?.data?.length - 1 ? (
                        <>
                          <SelectField
                            labelClass="whitespace-nowrap flex fs_14 fw_600 text-secondary"
                            containerClass="w-full"
                            isDisabled={disabled}
                            placeholder={"select Bin"}
                            options={[]}
                            label={""}
                            id={"source_to"}
                            name="source_to"
                            isSearchable={true}
                            errMsgContainerClass="ps-2 hidden"
                            labelWidth="7rem"
                          />
                        </>
                      ) : colIndex === 5 &&
                        values?.data?.length - 1 !== rowIndex ? (
                        <>
                          <SelectField
                            handleSelected={(sel) => {
                              console.log(sel, "asdasdasdasdasfgfdgf");
                              setFieldValue(
                                `data[${rowIndex}].item_uom_id`,
                                sel?.item_uom_id
                              );
                            }}
                            borderclass=""
                            defaultSelectClass="border-0"
                            selectClass="w-full"
                            defaultValue={
                              values.data[rowIndex]?.item_uom
                                ? {
                                    label:
                                      values.data[rowIndex]?.item_uom ?? "",
                                  }
                                : null
                            }
                            isDisabled={disabled}
                            errMsgContainerClass="hidden"
                            id={`data[${rowIndex}].item_uom`}
                            isContainer={false}
                            options={values.data[rowIndex]?.uom_dropdown}
                            name={`data[${rowIndex}].item_uom`}
                            placeholder={"Select UOM"}
                          />
                        </>
                      ) : (
                        cellData
                      )}
                    </td>
                  );
                }
                return null;
              })}
              {!disabled && (
                <td className="last-column w-[2.5rem]">
                  {rowIndex !== values?.data?.length - 1 && (
                    <DeleteIcon onClickFn={() => removeRow(rowIndex)} />
                  )}
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

export default StocktransferItemTable;
