import React, { useState } from "react";
import TabsComponent from "../helper-components/tabs-component";
import InfraDetailsTab from "./infra-details-tab";
import BrandingDetailsTab from "./branding-details-tab";
import BrandingSoftwareDetailsTab from "./branding-software-details-tab";
import AccountDetailsTab from "./account-details-tab";
import DispatchDetailsTab from "./dispatch-details-tab";
import OpeningDetailsTab from "./opening-details-tab";
import StrFollowUpTab from "./follow-up-details-tab";
const StoreExeViewEdit = ({
  exeData,
  id,
  permissionFn = () => {},
  reload,
  setReload,
}) => {
  const [currentTab, setCurrentTab] = useState("Infrastructure Details");
  return (
    <div className="h-full flex flex-col">
      <TabsComponent
        handleClickfn={(item) => {
          setCurrentTab(item);
        }}
        itemTabs={itemTabs}
        setCurrentTab={setCurrentTab}
        currentTab={currentTab}
      />
      {currentTab === "Infrastructure Details" && (
        <InfraDetailsTab
          setReload={setReload}
          reload={reload}
          setCurrentTab={setCurrentTab}
          id={id}
          permissionFn={permissionFn}
          initialValue={exeData}
        />
      )}
      {currentTab === "Branding Details" && (
        <BrandingDetailsTab
          setReload={setReload}
          reload={reload}
          setCurrentTab={setCurrentTab}
          id={id}
          permissionFn={permissionFn}
          initialValue={exeData}
        />
      )}
      {currentTab === "Branding & Software Details" && (
        <BrandingSoftwareDetailsTab
          setReload={setReload}
          reload={reload}
          setCurrentTab={setCurrentTab}
          id={id}
          permissionFn={permissionFn}
          initialValue={exeData}
        />
      )}
      {currentTab === "Account Details" && (
        <AccountDetailsTab
          setReload={setReload}
          reload={reload}
          setCurrentTab={setCurrentTab}
          id={id}
          permissionFn={permissionFn}
          initialValue={exeData}
        />
      )}
      {currentTab === "Dispatch Details" && (
        <DispatchDetailsTab
          setReload={setReload}
          reload={reload}
          setCurrentTab={setCurrentTab}
          id={id}
          permissionFn={permissionFn}
          initialValue={exeData}
        />
      )}
      {currentTab === "Opening Details" && (
        <OpeningDetailsTab
          setReload={setReload}
          reload={reload}
          setCurrentTab={setCurrentTab}
          id={id}
          permissionFn={permissionFn}
          initialValue={exeData}
        />
      )}
      {currentTab === "Follow-ups Remarks History" && (
        <StrFollowUpTab
          setReload={setReload}
          reload={reload}
          setCurrentTab={setCurrentTab}
          id={id}
          permissionFn={permissionFn}
          initialValue={exeData}
        />
      )}
    </div>
  );
};

export default StoreExeViewEdit;

const itemTabs = [
  "Infrastructure Details",
  "Branding Details",
  "Branding & Software Details",
  "Account Details",
  "Dispatch Details",
  "Opening Details",
  "Follow-ups Remarks History",
];
