import { call, put, takeLatest } from "redux-saga/effects";
import {
  BULK_PURCHASE_CREATE,
  GOODS_ITEMS_GET,
  GOODS_RECEIPT_CREATE,
  GOODS_RECEIPT_EDIT,
  GOODS_RECEIPT_VIEW,
  GOODS_RECEIPT_VOID,
  GOODS_TABLE,
  LOADED_FAILED_MIN_QTY_ITEMS,
  LOADED_MIN_QTY_ITEMS,
  LOADING_MIN_QTY_ITEMS,
  LOAD_MIN_QTY_ITEMS,
  PAYMENT_OUT_CREATE,
  PAYMENT_OUT_TABLE,
  PAYMENT_OUT_VIEW,
  PURCHASE_CREATE,
  PURCHASE_CREDIT_EDIT,
  PURCHASE_CREDIT_RECEIPT_CREATE,
  PURCHASE_CREDIT_TABLE,
  PURCHASE_CREDIT_VIEW,
  PURCHASE_CREDIT_VOID,
  PURCHASE_EDIT,
  PURCHASE_INVOICE_EDIT,
  PURCHASE_INVOICE_ITEMS_GET,
  PURCHASE_INVOICE_RECEIPT_CREATE,
  PURCHASE_INVOICE_TABLE,
  PURCHASE_INVOICE_VIEW,
  PURCHASE_INVOICE_VOID,
  PURCHASE_ITEMS_GET,
  PURCHASE_PAYMENT_OUT_CUS,
  PURCHASE_PRODUCT_LIST,
  PURCHASE_RETURN_EDIT,
  PURCHASE_RETURN_ITEMS_GET,
  PURCHASE_RETURN_RECEIPT_CREATE,
  PURCHASE_RETURN_TABLE,
  PURCHASE_RETURN_VIEW,
  PURCHASE_RETURN_VOID,
  PURCHASE_TABLE,
  PURCHASE_VIEW,
  PURCHASE_VOID,
} from "../types";

import {
  bulkPurchaseCreateApi,
  goodsEditApi,
  goodsItemsGetApi,
  goodsReceiptCreateApi,
  goodsTableApi,
  goodsViewApi,
  goodsVoidApi,
  loadMinQtyItemsApi,
  paymentOutCreateApi,
  paymentOutTableApi,
  paymentOutViewApi,
  purchaseCreateApi,
  purchaseCreditEditApi,
  purchaseCreditReceiptCreateApi,
  purchaseCreditTableApi,
  purchaseCreditViewApi,
  purchaseCreditVoidApi,
  purchaseEditApi,
  purchaseInvoiceEditApi,
  purchaseInvoiceItemsGetApi,
  purchaseInvoiceReceiptCreateApi,
  purchaseInvoiceTableApi,
  purchaseInvoiceViewApi,
  purchaseInvoiceVoidApi,
  purchaseItemsGetApi,
  purchasePaymentOutCusApi,
  purchaseProductListApi,
  purchaseReturnEditApi,
  purchaseReturnItemsGetApi,
  purchaseReturnReceiptCreateApi,
  purchaseReturnTableApi,
  purchaseReturnViewApi,
  purchaseReturnVoidApi,
  purchaseTableApi,
  purchaseViewApi,
  purchaseVoidApi,
} from "../apis/purchase-api";

export function* purchaseCreateWorker(action) {
  try {
    const res = yield call(purchaseCreateApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* purchaseEditWorker(action) {
  try {
    const res = yield call(purchaseEditApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* purchaseTableWorker(action) {
  try {
    const res = yield call(purchaseTableApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* purchaseProductListWorker(action) {
  try {
    const res = yield call(purchaseProductListApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* purchaseViewWorker(action) {
  try {
    const res = yield call(purchaseViewApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* purchaseVoidWorker(action) {
  try {
    const res = yield call(purchaseVoidApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* purchaseItemsGetWorker(action) {
  try {
    const res = yield call(purchaseItemsGetApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}

export function* goodsReceiptCreateWorker(action) {
  try {
    const res = yield call(goodsReceiptCreateApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}

export function* goodsItemsGetWorker(action) {
  try {
    const res = yield call(goodsItemsGetApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* goodsTableWorker(action) {
  try {
    const res = yield call(goodsTableApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}

export function* goodsViewWorker(action) {
  try {
    const res = yield call(goodsViewApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* goodsEditWorker(action) {
  try {
    const res = yield call(goodsEditApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* goodsVoidWorker(action) {
  try {
    const res = yield call(goodsVoidApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}

// purchase INVOICE
export function* purchaseInvoiceReceiptCreateWorker(action) {
  try {
    const res = yield call(purchaseInvoiceReceiptCreateApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}

export function* purchaseInvoiceTableWorker(action) {
  try {
    const res = yield call(purchaseInvoiceTableApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}

export function* purchaseInvoiceViewWorker(action) {
  try {
    const res = yield call(purchaseInvoiceViewApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* purchaseInvoiceEditWorker(action) {
  try {
    const res = yield call(purchaseInvoiceEditApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* purchaseInvoiceVoidWorker(action) {
  try {
    const res = yield call(purchaseInvoiceVoidApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}

export function* purchaseInvoiceItemsGetWorker(action) {
  try {
    const res = yield call(purchaseInvoiceItemsGetApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
// purchase RETURN
export function* purchaseReturnReceiptCreateWorker(action) {
  try {
    const res = yield call(purchaseReturnReceiptCreateApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}

export function* purchaseReturnTableWorker(action) {
  try {
    const res = yield call(purchaseReturnTableApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}

export function* purchaseReturnViewWorker(action) {
  try {
    const res = yield call(purchaseReturnViewApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* purchaseReturnEditWorker(action) {
  try {
    const res = yield call(purchaseReturnEditApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* purchaseReturnVoidWorker(action) {
  try {
    const res = yield call(purchaseReturnVoidApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}

export function* purchaseReturnItemsGetWorker(action) {
  try {
    const res = yield call(purchaseReturnItemsGetApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
// purchase CREDIT
export function* purchaseCreditReceiptCreateWorker(action) {
  try {
    const res = yield call(purchaseCreditReceiptCreateApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}

export function* purchaseCreditTableWorker(action) {
  try {
    const res = yield call(purchaseCreditTableApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}

export function* purchaseCreditViewWorker(action) {
  try {
    const res = yield call(purchaseCreditViewApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* purchaseCreditEditWorker(action) {
  try {
    const res = yield call(purchaseCreditEditApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* purchaseCreditVoidWorker(action) {
  try {
    const res = yield call(purchaseCreditVoidApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}

export function* purchasePaymentOutCusWorker(action) {
  try {
    const res = yield call(purchasePaymentOutCusApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}

export function* paymentOutTableWorker(action) {
  try {
    const res = yield call(paymentOutTableApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}

export function* PaymentOutCreateWorker(action) {
  try {
    const res = yield call(paymentOutCreateApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}

export function* paymentOutViewWorker(action) {
  try {
    const res = yield call(paymentOutViewApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* loadMinQtyItemsWorker(action) {
  yield put({ type: LOADING_MIN_QTY_ITEMS, data: [] });
  try {
    const res = yield call(loadMinQtyItemsApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
      // yield put({ type: LOADED_MIN_QTY_ITEMS, data: res?.data });
    } else {
      yield action.onError(res?.data);
      // yield put({
      //   type: LOADED_FAILED_MIN_QTY_ITEMS,
      //   data: res?.data,
      // });
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* bulkPurchaseCreateWorker(action) {
  try {
    const res = yield call(bulkPurchaseCreateApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}

export function* bulkPurchaseCreateWatcher() {
  yield takeLatest(BULK_PURCHASE_CREATE, bulkPurchaseCreateWorker);
}
export function* loadMinQtyItemsWatcher() {
  yield takeLatest(LOAD_MIN_QTY_ITEMS, loadMinQtyItemsWorker);
}

export function* paymentOutViewWatcher() {
  yield takeLatest(PAYMENT_OUT_VIEW, paymentOutViewWorker);
}

export function* PaymentOutCreateWatcher() {
  yield takeLatest(PAYMENT_OUT_CREATE, PaymentOutCreateWorker);
}

export function* paymentOutTableWatcher() {
  yield takeLatest(PAYMENT_OUT_TABLE, paymentOutTableWorker);
}
export function* salesPaymentOutCusWatcher() {
  yield takeLatest(PURCHASE_PAYMENT_OUT_CUS, purchasePaymentOutCusWorker);
}
export function* purchaseCreateWatcher() {
  yield takeLatest(PURCHASE_CREATE, purchaseCreateWorker);
}
export function* purchaseEditWatcher() {
  yield takeLatest(PURCHASE_EDIT, purchaseEditWorker);
}
export function* purchaseTableWatcher() {
  yield takeLatest(PURCHASE_TABLE, purchaseTableWorker);
}
export function* purchaseProductListWatcher() {
  yield takeLatest(PURCHASE_PRODUCT_LIST, purchaseProductListWorker);
}
export function* purchaseViewWatcher() {
  yield takeLatest(PURCHASE_VIEW, purchaseViewWorker);
}
export function* purchaseVoidWatcher() {
  yield takeLatest(PURCHASE_VOID, purchaseVoidWorker);
}
export function* purchaseItemsGetWatcher() {
  yield takeLatest(PURCHASE_ITEMS_GET, purchaseItemsGetWorker);
}

export function* goodsTableWatcher() {
  yield takeLatest(GOODS_TABLE, goodsTableWorker);
}
export function* goodsReceiptCreateWatcher() {
  yield takeLatest(GOODS_RECEIPT_CREATE, goodsReceiptCreateWorker);
}
export function* goodsItemsGetWatcher() {
  yield takeLatest(GOODS_ITEMS_GET, goodsItemsGetWorker);
}

export function* goodsViewWatcher() {
  yield takeLatest(GOODS_RECEIPT_VIEW, goodsViewWorker);
}
export function* goodsVoidWatcher() {
  yield takeLatest(GOODS_RECEIPT_VOID, goodsVoidWorker);
}
export function* goodsEditWatcher() {
  yield takeLatest(GOODS_RECEIPT_EDIT, goodsEditWorker);
}
// purchase INVOICE
export function* purchaseInvoiceTableWatcher() {
  yield takeLatest(PURCHASE_INVOICE_TABLE, purchaseInvoiceTableWorker);
}

export function* purchaseInvoiceReceiptCreateWatcher() {
  yield takeLatest(
    PURCHASE_INVOICE_RECEIPT_CREATE,
    purchaseInvoiceReceiptCreateWorker
  );
}

export function* purchaseInvoiceViewWatcher() {
  yield takeLatest(PURCHASE_INVOICE_VIEW, purchaseInvoiceViewWorker);
}
export function* purchaseInvoiceEditWatcher() {
  yield takeLatest(PURCHASE_INVOICE_EDIT, purchaseInvoiceEditWorker);
}
export function* purchaseInvoiceVoidWatcher() {
  yield takeLatest(PURCHASE_INVOICE_VOID, purchaseInvoiceVoidWorker);
}
export function* purchaseInvoiceItemsGetWatcher() {
  yield takeLatest(PURCHASE_INVOICE_ITEMS_GET, purchaseInvoiceItemsGetWorker);
}

// PURCHASE RETURN
export function* purchaseReturnTableWatcher() {
  yield takeLatest(PURCHASE_RETURN_TABLE, purchaseReturnTableWorker);
}

export function* purchaseReturnReceiptCreateWatcher() {
  yield takeLatest(
    PURCHASE_RETURN_RECEIPT_CREATE,
    purchaseReturnReceiptCreateWorker
  );
}

export function* purchaseReturnViewWatcher() {
  yield takeLatest(PURCHASE_RETURN_VIEW, purchaseReturnViewWorker);
}
export function* purchaseReturnEditWatcher() {
  yield takeLatest(PURCHASE_RETURN_EDIT, purchaseReturnEditWorker);
}
export function* purchaseReturnVoidWatcher() {
  yield takeLatest(PURCHASE_RETURN_VOID, purchaseReturnVoidWorker);
}
export function* purchaseReturnItemsGetWatcher() {
  yield takeLatest(PURCHASE_RETURN_ITEMS_GET, purchaseReturnItemsGetWorker);
}
// PURCHASE CREDIT
export function* purchaseCreditTableWatcher() {
  yield takeLatest(PURCHASE_CREDIT_TABLE, purchaseCreditTableWorker);
}

export function* purchaseCreditReceiptCreateWatcher() {
  yield takeLatest(
    PURCHASE_CREDIT_RECEIPT_CREATE,
    purchaseCreditReceiptCreateWorker
  );
}

export function* purchaseCreditViewWatcher() {
  yield takeLatest(PURCHASE_CREDIT_VIEW, purchaseCreditViewWorker);
}
export function* purchaseCreditVoidWatcher() {
  yield takeLatest(PURCHASE_CREDIT_VOID, purchaseCreditVoidWorker);
}
export function* purchaseCreditEditWatcher() {
  yield takeLatest(PURCHASE_CREDIT_EDIT, purchaseCreditEditWorker);
}
