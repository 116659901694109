import React from "react";

const EditPencilIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.2501 7.91674L11.0834 4.75008M1.97925 17.0209L4.65854 16.7232C4.98589 16.6868 5.14956 16.6687 5.30254 16.6191C5.43827 16.5752 5.56744 16.5131 5.68653 16.4346C5.82077 16.3461 5.93722 16.2296 6.17011 15.9967L16.6251 5.54174C17.4996 4.66729 17.4996 3.24953 16.6251 2.37508C15.7507 1.50063 14.3329 1.50063 13.4584 2.37507L3.00345 12.83C2.77056 13.0629 2.65411 13.1794 2.56559 13.3136C2.48705 13.4327 2.42497 13.5619 2.38103 13.6976C2.3315 13.8506 2.31332 14.0143 2.27695 14.3416L1.97925 17.0209Z"
        stroke="#1570EF"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default EditPencilIcon;
