import React from "react";

const SmileIcon = () => {
  return (
    <svg
      width="18"
      height="17"
      viewBox="0 0 18 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.4997 10.6667C6.4997 10.6667 7.59345 11.9167 9.41636 11.9167C11.2393 11.9167 12.333 10.6667 12.333 10.6667M11.708 6.5H11.7164M7.1247 6.5H7.13303M9.41636 15.6667C13.3284 15.6667 16.4997 12.4954 16.4997 8.58333C16.4997 4.67132 13.3284 1.5 9.41636 1.5C5.50435 1.5 2.33303 4.67132 2.33303 8.58333C2.33303 9.375 2.4629 10.1363 2.70251 10.8472C2.79267 11.1147 2.83776 11.2484 2.84589 11.3512C2.85392 11.4527 2.84785 11.5238 2.82274 11.6225C2.79732 11.7224 2.74119 11.8262 2.62894 12.034L1.26589 14.557C1.07147 14.9168 0.974255 15.0968 0.996012 15.2356C1.01496 15.3566 1.08615 15.4631 1.19068 15.5269C1.31068 15.6001 1.51411 15.579 1.92098 15.537L6.1885 15.0958C6.31773 15.0825 6.38235 15.0758 6.44125 15.0781C6.49918 15.0803 6.54007 15.0857 6.59656 15.0987C6.65399 15.112 6.72621 15.1398 6.87066 15.1954C7.6607 15.4998 8.51905 15.6667 9.41636 15.6667ZM12.1247 6.5C12.1247 6.73012 11.9381 6.91667 11.708 6.91667C11.4779 6.91667 11.2914 6.73012 11.2914 6.5C11.2914 6.26988 11.4779 6.08333 11.708 6.08333C11.9381 6.08333 12.1247 6.26988 12.1247 6.5ZM7.54136 6.5C7.54136 6.73012 7.35482 6.91667 7.1247 6.91667C6.89458 6.91667 6.70803 6.73012 6.70803 6.5C6.70803 6.26988 6.89458 6.08333 7.1247 6.08333C7.35482 6.08333 7.54136 6.26988 7.54136 6.5Z"
        stroke="white"
        stroke-width="1.8"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default SmileIcon;
