import React, { useEffect, useState } from "react";
import CreateEditViewReturnBill from "../../components/return-bill/create-edit-view-return-bill";
import { storeIdAction } from "../../redux/actions/general-action";
import {
  returnBillItemsGetAction,
  storeDetailsAction,
} from "../../redux/actions/invoices-action";
import { useDispatch } from "react-redux";
import LoadingSpinner from "../../components/helper-components/loading-spinner";
import InnerLayout from "../../components/layouts/inner-layout";
import { useParams } from "react-router-dom";

const AddReturnBill = () => {
  const { itemId, type } = useParams();
  const itemIdArray = JSON.parse(decodeURIComponent(itemId));
  const allitems = itemIdArray.map((item) => {
    const { cmr_id, ...rest } = item;
    return rest;
  });
  const [orderDetail, setOrderDetail] = useState({});
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const [storeId, setStoreId] = useState("");
  const [storeDetails, setStoreDetails] = useState({});
  useEffect(() => {
    dispatch(storeIdAction(onSuccessStore, onErrorStore));
    dispatch(storeDetailsAction(onSuccessStoreDetails, onErrorStoreDetails));

    if (type === "bill") {
      dispatch(returnBillItemsGetAction(allitems, onSuccPur, onErrPur));
    } else {
      setLoading(false);
    }
  }, []);
  const onSuccPur = (data) => {
    setOrderDetail(data?.data);
    setLoading(false);
  };

  const onErrPur = (data) => {
    setLoading(false);
  };

  const onSuccessStore = (data) => {
    setStoreId(data?.data?.store_id);
  };
  const onErrorStore = () => {};
  const onSuccessStoreDetails = (data) => {
    setStoreDetails(data?.data);
  };
  const onErrorStoreDetails = () => {};

  console.log(orderDetail, "dfdsdssfdsfdsd");
  return (
    <>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <InnerLayout list={false} backBtn={true} title="Add Return Bill">
          <CreateEditViewReturnBill
            disabled={true}
            orderDetail={orderDetail}
            storeDetails={storeDetails}
            storeId={storeId}
          />
        </InnerLayout>
      )}
    </>
  );
};
export default AddReturnBill;
