import { Form, Formik } from "formik";
import React, { useState } from "react";
import * as Yup from "yup";
import InputField from "../forms/input-field";
import { useDispatch } from "react-redux";
import successToast from "../helper-components/success-toast";
import errorToast from "../helper-components/error-toast";
import AddButton from "../buttons/button";
import {
  addStrAccAction,
} from "../../redux/actions/store-action";
import moment from "moment";
import { ImageField1 } from "../forms/images-field";
import TextAreaField from "../forms/textarea-field";

const AccountDetailsTab = ({
  initialValue,
  id,
  permissionFn = () => {},
  setCurrentTab,
  reload,
  setReload,
}) => {
  const dispatch = useDispatch();
  const initialValues = {
    store_id: id,
    stock_payment_date: initialValue?.stock_payment_date
      ? moment(initialValue.stock_payment_date).format("YYYY-MM-DD")
      : null,
    account_details_remark: initialValue?.account_details_remark ?? null,
    stock_payment_receipt_1: initialValue?.stock_payment_receipt_1 ?? null,
    stock_payment_receipt_2: initialValue?.stock_payment_receipt_2 ?? null,
    stock_payment_receipt_3: initialValue?.stock_payment_receipt_3 ?? null,
  };

  const validationSchema = Yup.object().shape({
    stock_payment_date: Yup.date().required("Stock payment date is required"),
    account_details_remark: Yup.string().required(
      "Account details remark is required"
    ),
  });

  const onSubmit = (values, actions) => {
    dispatch(addStrAccAction(values, onSuccess, onError));
  };

  const onSuccess = (data) => {
    setReload(!reload);
    successToast(data?.message);
    setCurrentTab("Dispatch Details");
  };
  const onError = (data) => {
    errorToast(data?.message);
  };

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ values, setFieldValue, isSubmitting, errors }) => {
        return (
          <Form className="flex-grow pt-2">
            <div className="flex flex-col justify-between h-full ">
              <div>
                <div className="flex flex-wrap">
                  <div className="w-full 2xl:w-[40%] lg:w-[50%] px-5 xl:px-10">
                    <InputField
                      type="date"
                      name="stock_payment_date"
                      label="Stock payment date"
                      isRequired={true}
                      labelWidth="12rem"
                    />
                  </div>
                  <div className="w-full 2xl:w-[40%] lg:w-[50%] px-5 xl:px-10">
                    <TextAreaField
                      label="Remarks"
                      placeholder="your remarks here..."
                      name="account_details_remark"
                      id={"account_details_remark"}
                      isRequired={true}
                      labelWidth="7rem"
                      feildHeight="6rem"
                    />
                  </div>
                </div>
                <div className="px-5 xl:px-10">
                  <label className="secondary-color fs_14 fw_600">
                    Stock Payment Receipt
                  </label>
                  <div className="flex gap-5 flex-wrap">
                    <div>
                      <ImageField1
                        label="Chalan Receipt"
                        isRequired={false}
                        labelWidth=""
                        name="stock_payment_receipt_1"
                        mainLabel=""
                      />
                    </div>

                    <div>
                      <ImageField1
                        label="Chalan Receipt"
                        isRequired={false}
                        labelWidth=""
                        name="stock_payment_receipt_2"
                        mainLabel=""
                      />
                    </div>

                    <div>
                      <ImageField1
                        label="Chalan Receipt"
                        isRequired={false}
                        labelWidth=""
                        name="stock_payment_receipt_3"
                        mainLabel=""
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div
                style={{
                  bottom: "0",
                }}
                className="flex justify-end px-10 mb-5x sticky bg-white py-4 invoice-bottom-container"
              >
                <AddButton prefixIcon={false} text="Save" type="submit" />
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default AccountDetailsTab;
const items = [{ label: "Colour Pre Applied", name: "colour_pre_applied" }];
const items1 = [
  { label: "Furniture Pre Applied", name: "furniture_pre_applied" },
];
