import React, { useEffect, useRef, useState } from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import InputField from "../../components/forms/input-field";
import SelectField from "../../components/forms/select-field";
import CheckBox from "../../components/forms/check-box-field";
import AddButton from "../../components/buttons/button";
import ContactPersonsTab from "../../components/admin-customer/contact-person-tab";
import BankDetailsTab from "../../components/admin-customer/bank-details-tab";
import PaymentTermsTab from "../../components/admin-customer/payment-terms-tab";
import AccountingTab from "../../components/admin-customer/accounting-tab";
import AddressTab from "../../components/admin-customer/address-tab";
import successToast from "../../components/helper-components/success-toast";
import errorToast from "../../components/helper-components/error-toast";

import {
  adminCustomerCreateAction,
  adminCustomerEditAction,
} from "../../redux/actions/admin-customer-action";
import { useDispatch } from "react-redux";
import {
  acqSrcListAction,
  buisnessTypeListAction,
  cusTypeListAction,
  sectorListAction,
} from "../../redux/actions/general-action";
import TabsComponent from "../helper-components/tabs-component";
import { customerTypeoptions } from "../helper-components/data";

const AdminCusCreateEditView = ({
  setCustomerId,
  customerId,
  reload,
  setReload,
  customerDetails,
  disabled = false,
}) => {
  const dispatch = useDispatch();
  const [buisnessList, setBuisnessList] = useState([]);
  const [acqSrcList, setAcqSrcList] = useState([]);
  const [cusList, setCusList] = useState([]);

  useEffect(() => {
    dispatch(buisnessTypeListAction(onSuccessBuisness, onErrorBuisness));
    dispatch(acqSrcListAction(onSuccessAcqSrc, onErrorSrc));
    dispatch(cusTypeListAction(onSuccessCus, onErrorCus));
    dispatch(sectorListAction(onSuccessSec, onErrorSec));
  }, []);
  const onSuccessBuisness = (data) => {
    setBuisnessList(
      data?.data?.bussinessType?.map((item) => ({
        label: item?.vendors,
        value: item?.vendors,
      }))
    );
  };
  const onErrorBuisness = (data) => {};
  const onSuccessAcqSrc = (data) => {
    setAcqSrcList(
      data?.data?.acquiredSource?.map((item) => ({
        label: item?.vendors,
        value: item?.vendors,
      }))
    );
  };
  const onErrorSrc = (data) => {};
  const onSuccessCus = (data) => {
    setCusList(
      data?.data?.customerType?.map((item) => ({
        label: item?.vendors,
        value: item?.vendors,
      }))
    );
  };
  const onErrorCus = (data) => {};
  const onSuccessSec = (data) => {
    setCusList(
      data?.data?.customerType?.map((item) => ({
        label: item?.vendors,
        value: item?.vendors,
      }))
    );
  };
  const onErrorSec = (data) => {};

  const initialvalues = {
    cmr_first_name: customerDetails?.cmr_first_name ?? "",
    cmr_type: customerDetails?.cmr_type ?? "",
    cmr_dob: customerDetails?.cmr_dob ?? new Date().toISOString().split("T")[0],
    cmr_email: customerDetails?.cmr_email ?? "",
    cmr_phone_number: customerDetails?.cmr_phone_number ?? "",
    cmr_group_id: customerDetails?.cmr_group_id ?? "",
    cmr_pan: customerDetails?.cmr_pan ?? "",
    cmr_acquired_source: customerDetails?.cmr_acquired_source ?? "",
    cmr_balance: customerDetails?.cmr_balance ?? 0.0,
    cmr_payment_terms: customerDetails?.cmr_payment_terms ?? "",
    cmr_shipping_method: customerDetails?.cmr_shipping_method ?? "",
    cmr_sector: customerDetails?.cmr_sector ?? "",
    cmr_gstin: customerDetails?.cmr_gstin ?? "",
    cmr_tax: customerDetails?.cmr_tax ?? "",
    cmr_business_type: customerDetails?.cmr_business_type ?? "",
    cmr_remarks: customerDetails?.cmr_remarks ?? "",
    cmr_active_status: customerDetails?.cmr_active_status ?? true,
    cmr_inactive_from: customerDetails?.cmr_inactive_from ?? null,
    is_ecommerce_cmr: customerDetails?.is_ecommerce_cmr ?? false,
    is_vendor_cmr: customerDetails?.is_vendor_cmr ?? false,
    is_corporate_cmr: customerDetails?.is_corporate_cmr ?? false,
    is_store_cmr: customerDetails?.is_store_cmr ?? false,
  };
  const validationSchema = Yup.object().shape({
    cmr_first_name: Yup.string().required("Customer name is required"),
    cmr_email: Yup.string().required("Email is required"),
    cmr_phone_number: Yup.string().required("Phone number is required"),
    cmr_pan: Yup.string().required("PAN is required"),
    cmr_balance: Yup.number()
      .required("Balance is required")
      .min(0, "Balance must be at least 0"),
    cmr_gstin: Yup.string().matches(
      /^(\d{2}[A-Z]{5}\d{4}[A-Z]{1}[1-9A-Z]{1}Z\d{1})?$/,
      "Invalid GSTIN"
    ),
    cmr_dob: Yup.date().max(
      new Date(),
      "Date of birth cannot be in the future"
    ),
  });

  const [currentTab, setCurrentTab] = useState("General");

  const containerRef = useRef(null);

  const handleClick = (item) => {
    setCurrentTab(item);
    const container = containerRef.current;
    const selectedTab = document.getElementById(item);
    if (container && selectedTab) {
      const containerWidth = container.offsetWidth;
      const selectedTabWidth = selectedTab.offsetWidth;
      const selectedTabLeft = selectedTab.offsetLeft;
      const scrollTo =
        selectedTabLeft - (containerWidth - selectedTabWidth) / 2;
      container.scrollTo({
        left: scrollTo,
        behavior: "smooth",
      });
    }
  };

  const onSubmit = (values, actions) => {
    let actionType = adminCustomerCreateAction;
    if (customerId) {
      actionType = adminCustomerEditAction;
    }

    console.log("values", values);
    const submittedValues = {
      ...values,
      cmr_id: customerId,
    };
    dispatch(
      actionType(customerId ? submittedValues : values, onSuccess, onError)
    );
    console.log("abcd", submittedValues);
    actions.setSubmitting(false);
  };
  const onSuccess = (data) => {
    console.log("success", data);
    setCustomerId(data?.data?.cmr_id);
    setCurrentTab("Contact Persons");
    successToast(data?.message);
  };
  const onError = (data) => {
    console.log("error", data);
    errorToast(data?.data?.message);
    console.log("error message", data.data.message);
  };
  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialvalues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ values, setFieldValue, isSubmitting }) => {
        return (
          <Form className="h-full pt-5">
            <div className="flex flex-col h-full justify-between">
              {" "}
              <div className="flex flex-wrap">
                <div className="w-full 2xl:w-[35%] lg:w-[40%] px-5 xl:px-10">
                  {/* <InputField
                    isDisabled={disabled}
                    type="text"
                    name="cmr_code"
                    placeholder=""
                    label="Customer Code"
                    isRequired={true}
                    labelWidth="8rem"
                  /> */}
                  <InputField
                    isDisabled={disabled}
                    type="text"
                    name="cmr_first_name"
                    placeholder="Enter item name"
                    label="Name"
                    isRequired={true}
                    labelWidth="8rem"
                  />
                  <InputField
                    isDisabled={disabled}
                    errMsgContainerClass="ps-2"
                    type="date"
                    name="cmr_dob"
                    placeholder=""
                    label="DOB"
                    isRequired={false}
                    labelWidth="8rem"
                  />
                  <SelectField
                    isDisabled={disabled}
                    placeholder="Select type"
                    options={customerTypeoptions}
                    label="Type"
                    id="cmr_type"
                    name="cmr_type"
                    isSearchable={false}
                    // errMsgContainerClass="hidden"
                    isRequired={false}
                    labelWidth="8rem"
                  />

                  <InputField
                    isDisabled={disabled}
                    errMsgContainerClass="ps-2"
                    type="email"
                    name="cmr_email"
                    placeholder="Enter your email"
                    label="Email"
                    isRequired={true}
                    labelWidth="8rem"
                  />
                </div>
                <div className="w-full 2xl:w-[35%] lg:w-[40%] px-5 xl:px-10 mt-10 lg:mt-0">
                  <InputField
                    isDisabled={disabled}
                    errMsgContainerClass="ps-2"
                    type="phone"
                    name="cmr_phone_number"
                    placeholder="Enter Phone number"
                    label="Phone"
                    isRequired={true}
                    labelWidth="8rem"
                  />
                  <InputField
                    isDisabled={disabled}
                    errMsgContainerClass="ps-2"
                    type="text"
                    name="cmr_pan"
                    placeholder="XXXXX1234X"
                    label="PAN"
                    isRequired={true}
                    labelWidth="8rem"
                  />
                  <SelectField
                    isDisabled={disabled}
                    placeholder="Select Group ID"
                    options={groupID}
                    label="Group ID"
                    id="cmr_group_id"
                    name="cmr_group_id"
                    isSearchable={true}
                    errMsgContainerClass="hidden"
                    isRequired={false}
                    labelWidth="8rem"
                  />
                </div>
                <div className="w-full 2xl:w-[27%] lg:w-[20%] px-5 xl:px-10 mt-10 lg:mt-0">
                  <CheckBox
                    isDisabled={disabled}
                    items={checkboxItems}
                    conClass="flex flex-col"
                  />
                </div>
              </div>
              {/* <div
                style={{
                  height: "5rem",
                }}
                className="overflow-x-auto"
              >
                {" "}
                <div className="flex gap-10 relative mt-5">
                  <div className="absolute left-0 right-0 top-8 h-1 bg-[#f3f3f3]"></div>

                  {itemTabs.map((item) => (
                    <div
                      key={item}
                      onClick={() =>
                        customerId ? handleClick(item) : handleClick(item)
                      }
                      className={`cursor-pointer relative group `}
                    >
                      <span
                        className={`py-3 px-6    fs_14 relative z-5 ${
                          currentTab === item
                            ? "text-primary bg-primary bg-opacity-20 rounded-lg fw_700"
                            : "color-000000 fw_500"
                        }`}
                      >
                        {item}
                      </span>
                      {currentTab === item && (
                        <span className="absolute top-8 left-0 right-0 h-1 bg-[#277DFE] mx-3"></span>
                      )}
                    </div>
                  ))}
                </div>
              </div> */}
              <TabsComponent
                handleClickfn={(item) => {
                  setCurrentTab(item);
                }}
                itemTabs={itemTabs}
                setCurrentTab={setCurrentTab}
                currentTab={currentTab}
              />
              {currentTab === "General" && (
                <>
                  <div className="flex flex-wrap mt-3">
                    <div className="w-full 2xl:w-[43%] lg:w-[40%] px-5 xl:px-10">
                      {/* <SelectField
                        placeholder="Select currency"
                        options={currencyList}
                        label="Currency"
                        id="cmr_currency"
                        name="cmr_currency"
                        isSearchable={true}
                        errMsgContainerClass="hidden"
                        isRequired={false}
                        labelWidth="10rem"
                      /> */}
                      <SelectField
                        isDisabled={disabled}
                        placeholder="Select sources"
                        options={acqSrcList}
                        label="Acquired Source"
                        id="cmr_acquired_source"
                        name="cmr_acquired_source"
                        isSearchable={false}
                        labelWidth="10rem"
                      />
                      <InputField
                        isDisabled={disabled}
                        type="number"
                        name="cmr_balance"
                        placeholder=""
                        label="Balance"
                        isRequired={true}
                        labelWidth="10rem"
                      />
                      <InputField
                        isDisabled={disabled}
                        type="text"
                        name="cmr_payment_terms"
                        placeholder="Enter payment terms"
                        label="Payment Terms"
                        isRequired={false}
                        labelWidth="10rem"
                      />
                      <SelectField
                        isDisabled={disabled}
                        placeholder="Select method"
                        options={shippingMethods}
                        label="Shipping Method"
                        id="cmr_shipping_method"
                        name="cmr_shipping_method"
                        isSearchable={false}
                        errMsgContainerClass="hidden"
                        isRequired={false}
                        labelWidth="10rem"
                      />
                    </div>
                    <div className="w-full 2xl:w-[27%] lg:w-[40%] px-5 xl:px-10 mt-10 lg:mt-0">
                      <SelectField
                        isDisabled={disabled}
                        placeholder="Select Sector"
                        options={sectoerList}
                        label="Sector"
                        id="cmr_sector"
                        name="cmr_sector"
                        isSearchable={false}
                        isRequired={false}
                        labelWidth="7rem"
                      />
                      <InputField
                        isDisabled={disabled}
                        type="text"
                        name="cmr_gstin"
                        placeholder="XXAABBCC1234D1Z9"
                        label="GSTIN"
                        isRequired={true}
                        labelWidth="7rem"
                      />
                      <SelectField
                        isDisabled={disabled}
                        placeholder="Select Tax"
                        options={taxList}
                        label="Tax"
                        id="cmr_tax"
                        name="cmr_tax"
                        isSearchable={false}
                        errMsgContainerClass="ps-2"
                        isRequired={true}
                        labelWidth="7rem"
                      />
                      <SelectField
                        isDisabled={disabled}
                        placeholder="Select"
                        options={buisnessList}
                        label="Business Type"
                        id="cmr_business_type"
                        name="cmr_business_type"
                        isSearchable={false}
                        labelWidth="7rem"
                      />
                      <InputField
                        isDisabled={disabled}
                        type="text"
                        name="cmr_remarks"
                        placeholder="Enter remarks"
                        label="Remarks"
                        isRequired={false}
                        labelWidth="7rem"
                      />
                    </div>
                  </div>
                </>
              )}
              {currentTab === "Contact Persons" && (
                <ContactPersonsTab
                  isDisabled={disabled}
                  customerGeneralData={{
                    ...values,
                    cmr_id: customerId,
                  }}
                  customerDetails={customerDetails}
                  setCurrentTab={setCurrentTab}
                  customerId={customerId}
                  reload={reload}
                  setReload={setReload}
                />
              )}
              {currentTab === "Bank Details" && (
                <BankDetailsTab
                  isDisabled={disabled}
                  customerGeneralData={{
                    ...values,
                    cmr_id: customerId,
                  }}
                  customerDetails={customerDetails}
                  setCurrentTab={setCurrentTab}
                  customerId={customerId}
                  reload={reload}
                  setReload={setReload}
                />
              )}
              {currentTab === "Payment Terms" && (
                <PaymentTermsTab
                  isDisabled={disabled}
                  customerGeneralData={{
                    ...values,
                    cmr_id: customerId,
                  }}
                  customerDetails={customerDetails}
                  setCurrentTab={setCurrentTab}
                  customerId={customerId}
                  reload={reload}
                  setReload={setReload}
                />
              )}
              {currentTab === "Accounting" && (
                <AccountingTab
                  isDisabled={disabled}
                  customerGeneralData={{
                    ...values,
                    cmr_id: customerId,
                  }}
                  customerDetails={customerDetails}
                  setCurrentTab={setCurrentTab}
                  customerId={customerId}
                  reload={reload}
                  setReload={setReload}
                />
              )}
              {currentTab === "Address" && (
                <AddressTab
                  isDisabled={disabled}
                  customerGeneralData={{
                    ...values,
                    cmr_id: customerId,
                  }}
                  customerDetails={customerDetails}
                  setCurrentTab={setCurrentTab}
                  customerId={customerId}
                  reload={reload}
                  setReload={setReload}
                />
              )}
              {currentTab === "General" && !disabled && (
                <div
                  style={{
                    bottom: "0",
                  }}
                  className="flex justify-end px-10 mb-5x sticky  bg-white py-4 invoice-bottom-container"
                >
                  <AddButton prefixIcon={false} text="Save" type="submit" />
                </div>
              )}
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default AdminCusCreateEditView;

const types = [{ label: "customer", value: "customer" }];
const groupID = [
  { label: "1", value: "1" },
  { label: "2", value: "2" },
];
const checkboxItems = [
  { label: "E-commerce", name: "is_ecommerce_cmr", isRequired: false },
  { label: "Vendor", name: "is_vendor_cmr", isRequired: false },
  { label: "Corporate", name: "is_corporate_cmr", isRequired: false },
  { label: "Store", name: "is_store_cmr", isRequired: false },
];

const itemTabs = [
  "General",
  "Contact Persons",
  "Bank Details",
  "Payment Terms",
  "Accounting",
  "Address",
];

const currencyList = [
  { label: "INR", value: "INR" },
  { label: "USD", value: "USD" },
  { label: "EUR", value: "EUR" },
  { label: "GBP", value: "GBP" },
  { label: "AED", value: "AED" },
  { label: "AUD", value: "AUD" },
  { label: "CAD", value: "CAD" },
  { label: "CHF", value: "CHF" },
  { label: "CNY", value: "CNY" },
  { label: "DKK", value: "DKK" },
  { label: "HKD", value: "HKD" },
  { label: "JPY", value: "JPY" },
  { label: "KRW", value: "KRW" },
  { label: "MYR", value: "MYR" },
  { label: "NOK", value: "NOK" },
  { label: "NZD", value: "NZD" },
  { label: "RUB", value: "RUB" },
  { label: "SEK", value: "SEK" },
  { label: "SGD", value: "SGD" },
  { label: "ZAR", value: "ZAR" },
];

const acquiredSource = [
  { label: "Direct", value: "Direct" },
  { label: "Referral", value: "Referral" },
  { label: "Advertisement", value: "Advertisement" },
  { label: "Social Media", value: "Social Media" },
  { label: "Others", value: "Others" },
];

const shippingMethods = [
  { label: "Courier", value: "Courier" },
  { label: "Transport", value: "Transport" },
  { label: "Post", value: "Post" },
  { label: "Others", value: "Others" },
];

export const activeOptns = [
  { label: "Active", value: true },
  { label: "Inactive", value: false },
];

const sectoerList = [
  { label: "Agriculture", value: "Agriculture" },
  { label: "Automobile", value: "Automobile" },
  { label: "Banking", value: "Banking" },
  { label: "Construction", value: "Construction" },
  { label: "Education", value: "Education" },
  { label: "Energy", value: "Energy" },
  { label: "Entertainment", value: "Entertainment" },
  { label: "Food", value: "Food" },
  { label: "Healthcare", value: "Healthcare" },
  { label: "Hospitality", value: "Hospitality" },
  { label: "Information Technology", value: "Information Technology" },
  { label: "Manufacturing", value: "Manufacturing" },
  { label: "Media", value: "Media" },
  { label: "Real Estate", value: "Real Estate" },
  { label: "Retail", value: "Retail" },
  { label: "Telecommunication", value: "Telecommunication" },
  { label: "Transport", value: "Transport" },
  { label: "Others", value: "Others" },
];

const taxList = [
  { label: "GST", value: "GST" },
  { label: "VAT", value: "VAT" },
  { label: "Service Tax", value: "Service Tax" },
  { label: "Others", value: "Others" },
];

const businessTypes = [
  { label: "Proprietorship", value: "Proprietorship" },
  { label: "Partnership", value: "Partnership" },
  { label: "LLP", value: "LLP" },
  { label: "Private Limited", value: "Private Limited" },
  { label: "Public Limited", value: "Public Limited" },
  { label: "Others", value: "Others" },
];
