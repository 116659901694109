import { Form, Formik } from "formik";
import React from "react";
import * as Yup from "yup";
import InputField from "../forms/input-field";
import { useDispatch } from "react-redux";
import successToast from "../helper-components/success-toast";
import errorToast from "../helper-components/error-toast";
import AddButton from "../buttons/button";
import {
  addStrDispatchAction,
  addStrbrandAction,
} from "../../redux/actions/store-action";
import moment from "moment";

const DispatchDetailsTab = ({
  initialValue,
  id,
  permissionFn = () => {},
  setCurrentTab,
  reload,
  setReload,
}) => {
  const dispatch = useDispatch();
  const initialValues = {
    store_id: id,
    first_kit_generation_date: initialValue?.first_kit_generation_date
      ? moment(initialValue.first_kit_generation_date).format("YYYY-MM-DD")
      : null,
    first_invoice_date: initialValue?.first_invoice_date
      ? moment(initialValue.first_invoice_date).format("YYYY-MM-DD")
      : null,
    stock_dispatch_date: initialValue?.stock_dispatch_date
      ? moment(initialValue.stock_dispatch_date).format("YYYY-MM-DD")
      : null,
    lr_number: initialValue?.lr_number
      ? moment(initialValue.lr_number).format("YYYY-MM-DD")
      : null,
  };

  const validationSchema = Yup.object().shape({
    first_kit_generation_date: Yup.date().required(
      "First kit generation date is required"
    ),
    first_invoice_date: Yup.date().required("First invoice date is required"),
    stock_dispatch_date: Yup.date().required("Stock dispatch date is required"),
    lr_number: Yup.string().required("LR number is required"),
  });

  const onSubmit = (values, actions) => {
    dispatch(addStrDispatchAction(values, onSuccess, onError));
  };

  const onSuccess = (data) => {
    setReload(!reload);
    successToast(data?.message);
    setCurrentTab("Opening Details");
  };
  const onError = (data) => {
    errorToast(data?.message);
  };

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ values, setFieldValue, isSubmitting, errors }) => {
        return (
          <Form className="flex-grow pt-2">
            <div className="flex flex-col justify-between h-full ">
              <div>
                <div className="w-full 2xl:w-[35%] lg:w-[60%] px-5 xl:px-10">
                  <InputField
                    type="date"
                    name="first_kit_generation_date"
                    label="1st Kit Generation Date "
                    isRequired={true}
                    labelWidth="13.5rem"
                  />
                  <InputField
                    type="date"
                    name="first_invoice_date"
                    label="1st Invoice Date"
                    isRequired={true}
                    labelWidth="13.5rem"
                  />
                  <InputField
                    type="date"
                    name="stock_dispatch_date"
                    label="Stock Dispatch Date"
                    isRequired={true}
                    labelWidth="13.5rem"
                  />
                  <InputField
                    type="date"
                    name="lr_number"
                    label="LR Number"
                    isRequired={true}
                    labelWidth="13.5rem"
                  />
                  {/* <InputField
                    type="number"
                    name="lr_number"
                    placeholder="Enter lr number"
                    label="LR Number"
                    isRequired={true}
                    labelWidth="13.5rem"
                  /> */}
                </div>
              </div>
              <div
                style={{
                  bottom: "0",
                }}
                className="flex justify-end px-10 mb-5x sticky bg-white py-4 invoice-bottom-container"
              >
                <AddButton prefixIcon={false} text="Save" type="submit" />
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default DispatchDetailsTab;
const items = [{ label: "Colour Pre Applied", name: "colour_pre_applied" }];
const items1 = [
  { label: "Furniture Pre Applied", name: "furniture_pre_applied" },
];
