import React from "react";
import ErrorView from "../../routers/error-view";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError(error) {

    console.log(error, "fdsfsfdsfsdfsdf");
    
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    
    
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <ErrorView error={this.state.error} />;
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
