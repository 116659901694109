import { api } from "./api";

export const invoiceCreateApi = (params) => {
  return api.post(`/order/add`, JSON.stringify(params));
};
export const invoiceDeleteApi = (params) => {
  return api.post(`/order/draft/delete`, JSON.stringify(params));
};
export const invoiceEditApi = (params) => {
  return api.post(`/order/draft/update`, JSON.stringify(params));
};
export const invoiceDraftCreateApi = (params) => {
  return api.post(`/order/draft/create`, JSON.stringify(params));
};
export const repeatOrderApi = (params) => {
  return api.get(
    `/order/get/repeatPastOrder?cmr_phone_number=${params?.phone}`,
    JSON.stringify(params)
  );
};

export const invoiceNumberApi = (params) => {
  return api.post(`/order/invoice`, JSON.stringify(params));
};

export const invoiceTableApi = (params) => {
  return api.get(
    `/order/get?pageNumber=${params?.pageNumber}&pageSize=${
      params?.pageSize
    }&sortBy=${params?.filterData?.sort_by}&sortOrder=${
      params?.filterData?.sort_order
    }&fromDate=${params?.filterData?.from}&toDate=${
      params?.filterData?.to
    }&is_draft_order=${
      params?.filterData?.order_status === "completed"
        ? false
        : params?.filterData?.order_status === "draft"
        ? true
        : ""
    }${
      params?.filterData?.inputValue &&
      `&searchColumn=${params?.filterData?.selectColumn}&searchValue=${params?.filterData?.inputValue}`
    }&paymentStatus=${params?.filterData?.payment_status}`,
    JSON.stringify(params)
  );
};
export const invoicePastApi = (params) => {
  return api.get(
    `/order/pastorder/get?cmr_phone_number=${params?.id}&pageNumber=${params?.pageNumber}&pageSize=${params?.pageSize}`,
    JSON.stringify(params)
  );
};
export const invoiceDiscountApi = (params) => {
  return api.get(
    `/discount/get?totalAmountOfItem=${params?.amount}`,
    JSON.stringify(params)
  );
};

export const invoiceViewApi = (params) => {
  return api.get(
    `/order/getOrderById?sot_id=${params}`,
    JSON.stringify(params)
  );
};
export const invoiceAlternativeApi = (params) => {
  return api.get(
    `/product/alternative/get?item_id=${params?.alternative_id}&pageNumber=${params?.pageNumber}&pageSize=${params?.pageSize}`,
    JSON.stringify(params)
  );
};
export const storeDetailsApi = () => {
  return api.get(`/store/details/get`);
};
export const invoiceScannedItemApi = (params) => {
  return api.get(`/product/barcode/get?item_barcode=${params}`);
};

export const returnBillTableApi = (params) => {
  return api.get(
    `/order/creditNote/getall?pageNumber=${params?.pageNumber}&pageSize=${
      params?.pageSize
    }&sortBy=${params?.filterData?.sort_by}&sortOrder=${
      params?.filterData?.sort_order
    }&fromDate=${params?.filterData?.from}&toDate=${
      params?.filterData?.to
    }&is_draft_order=${
      params?.filterData?.order_status === "completed"
        ? false
        : params?.filterData?.order_status === "draft"
        ? true
        : ""
    }${
      params?.filterData?.inputValue &&
      `&searchColumn=${params?.filterData?.selectColumn}&searchValue=${params?.filterData?.inputValue}`
    }&paymentStatus=${params?.filterData?.payment_status}`,
    JSON.stringify(params)
  );
};

export const returnBillCreateApi = (params) => {
  return api.post(`/order/creditNote/add`, JSON.stringify(params));
};
export const returnBillViewApi = (params) => {
  return api.get(
    `/order/creditNote/getById?icn_id=${params}`,
    JSON.stringify(params)
  );
};

export const returnBillItemsGetApi = (params) => {
  const queryString = JSON.stringify(params);
  const encodedQuery = encodeURIComponent(queryString);
  return api.get(`/order/getInvoiceItemsList?query=${encodedQuery}`);
};
