import React, { useCallback, useContext, useEffect, useState } from "react";
import InnerLayout from "../../components/layouts/inner-layout";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  invoiceDeleteAction,
  invoiceRowClearAction,
  invoiceTableAction,
  invoiceViewAction,
  storeDetailsAction,
} from "../../redux/actions/invoices-action";
import DynamicTable from "../../components/tables/dynamic-table";
import LoadingSpinner from "../../components/helper-components/loading-spinner";
import moment from "moment";
import StatusIcons from "../../assets/icons/check-icon";
import InvoiceFilter from "../../components/tables/table-filter/invoice-filter";
import { DataContext } from "../../components/layouts/main-layout";
import { formatString } from "../../components/helper-components/helper-function";
import { useHotkeys } from "react-hotkeys-hook";
import InvoicePdfContent from "../../components/invoice-section/invoice-pdf-content";
import { pdf } from "@react-pdf/renderer";
import { saveAs } from "file-saver";

const InvoiceTable = () => {
  const { permissionFn } = useContext(DataContext);
  const [storeDetails, setStoreDetails] = useState({});
  const dispatch = useDispatch();
  const [reload, setReload] = useState(false);
  const [orders, setOrders] = useState([]);
  const [dataCount, setDataCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [showFilter, setShowFilter] = useState(false);
  const [pageSize] = useState(10);
  const [filterData, setFilterData] = useState({
    sort_by: "",
    sort_order: "",
    selectColumn: "sot_invoice_number",
    from: "",
    to: "",
    inputValue: "",
    order_status: "",
    payment_status: "",
  });

  useEffect(() => {
    dispatch(storeDetailsAction(onSuccessStoreDetails, onErrorStoreDetails));
  }, []);

  const onSuccessStoreDetails = (data) => {
    setStoreDetails(data?.data);
  };
  const onErrorStoreDetails = () => {};

  useEffect(() => {
    dispatch(
      invoiceTableAction(
        {
          pageNumber: currentPage,
          pageSize: pageSize,
          filterData,
        },
        onSuccess,
        onError
      )
    );
  }, [currentPage, filterData, reload]);
  const onSuccess = (data) => {
    setError(false);
    setLoading(false);
    setOrders(data?.data?.orderList ?? []);
    setDataCount(data?.data?.totalCount);
  };
  const onError = (data) => {
    setError(true);
    setLoading(false);
  };

  const navigate = useNavigate();

  const tableBodyData = orders?.map((item) => {
    return {
      id: item?.sot_id,
      sot_invoice_number:
        (
          <span className={`cursor-pointer text-secondary fw_500`}>
            {item?.sot_invoice_number ?? "--"}
          </span>
        ) ?? "--",
      cmr_phone_number: item?.cmr_phone_number,
      cmr_first_name: item?.cmr_first_name ?? "",
      order_status: (
        <span
          className={`${
            item?.is_draft_order
              ? "border-344054 border text-344054"
              : "text-027A48 border-027A48 border "
          } px-2 py-1 rounded-full fs_12 whitespace-nowrap`}
        >
          {item?.is_draft_order ? "Saved as Draft" : "Completed"}
        </span>
      ),
      sot_payment_status: (
        <span
          style={{
            width: "fit-content",
          }}
          className={`fs_12 ${
            item?.sot_payment_status === "paid"
              ? "text-027A48 bg-027A48 bg-opacity-15"
              : "text-B42318 bg-B42318 bg-opacity-15"
          } fw_500 flex items-center gap-2 px-3 py-1 rounded-full`}
        >
          <StatusIcons status={item?.sot_payment_status} />
          {formatString(item?.sot_payment_status)}
        </span>
      ),
      update_date: moment(item?.update_date).format("MMM D, YYYY"),

      sot_total_amount: (
        <span className="flex justify-end pe-5">
          {"₹ " + item?.sot_total_amount}
        </span>
      ),
      is_draft_order: item?.is_draft_order,
    };
  });
  const paginationProps = {
    pageSize: pageSize,
    dataCount: dataCount,
    setCurrentPage: setCurrentPage,
    currentPage: currentPage,
  };
  const handleFilterClick = useCallback(() => {
    setShowFilter((prevShowFilter) => !prevShowFilter);
  }, []);

  const filterby = showFilter ? (
    <InvoiceFilter
      setCurrentPage={setCurrentPage}
      filterData={filterData}
      setFilterData={setFilterData}
    />
  ) : null;

  const handleVoidAction = (id) => {
    setLoading(true);
    dispatch(
      invoiceDeleteAction(
        {
          sot_id: id,
        },
        onSuccessDel,
        onErrorDel
      )
    );
  };

  const onSuccessDel = () => {
    setReload(!reload);
    setLoading(false);
  };
  const onErrorDel = () => {};

  useHotkeys("escape", () => {
    setShowFilter(false);
  });
  const downloadPdf = async (data) => {
    const fileName = "test.pdf";
    const blob = await pdf(
      <InvoicePdfContent data={data} storeDetails={storeDetails} />
    ).toBlob();
    saveAs(blob, fileName);
  };
  function viewFn(id) {
    dispatch(invoiceViewAction(id, onSuccessView, onErrorView));
  }
  const onSuccessView = (data) => {
    let invoice = data?.data?.[0];
    downloadPdf({
      items: invoice?.itemData,
      orderData: [invoice?.orderData],
    });
  };
  const onErrorView = (data) => {};

  return (
    <InnerLayout
      permissionFn={permissionFn}
      module="invoice"
      list={true}
      onclkFn={() => {
        navigate("/add-invoice");
        // return () => {
        dispatch(invoiceRowClearAction());
        // };
      }}
      title="Billings"
    >
      {loading ? (
        <LoadingSpinner />
      ) : error ? (       
        <></>
      ) : (
        <>
          <DynamicTable
            invoicePrntFn={(id) => {
              viewFn(id);
            }}
            newBtnTxt="New Bill"
            onClickItemFn={(id) => navigate(`/view-invoice/${id}`)}
            list={true}
            onclkFn={() => {
              navigate("/add-invoice");
              dispatch(invoiceRowClearAction());
            }}
            permissionFn={permissionFn}
            module="invoice"
            voidFn={handleVoidAction}
            showFilter={showFilter}
            filterFn={handleFilterClick}
            setFilterData={setFilterData}
            filterData={filterData}
            paginationProps={paginationProps}
            tableHeading={tableHeading}
            tableBodyData={tableBodyData}
            placeholder="Search"
            filterBy={filterby}
            initialFilter={{
              sort_by: "",
              sort_order: "",
              selectColumn: "sot_invoice_number",
              from: "",
              to: "",
              inputValue: "",
              order_status: "",
              payment_status: "",
            }}
          />
        </>
      )}
    </InnerLayout>
  );
};

export default InvoiceTable;
const tableHeading = [
  {
    label: "BILLING NUMBER",
    value: "sot_invoice_number",
    display: true,
    column: true,
  },
  {
    label: "PHONE NUMBER",
    value: "cmr_phone_number",
    display: true,
    column: true,
  },
  {
    label: "CUSTOMER NAME",
    value: "cmr_first_name",
    display: true,
    column: true,
  },
  {
    label: "ORDER STATUS",
    value: "order_status",
    display: false,
    column: false,
  },
  {
    label: "PAYMENT STATUS",
    value: "sot_payment_status",
    display: false,
    column: false,
  },
  {
    label: "DATE",
    value: "update_date",
    display: true,
    column: false,
  },

  {
    label: <span className="flex justify-end">AMOUNT</span>,
    value: "sot_total_amount",
    display: true,
    column: false,
    className: "justify-end pe-5",
  },
  {
    label: "",
    value: "",
    column: false,
  },
  {
    label: "",
    value: "",
    column: false,
  },
];
