import React from "react";
import { Field, useFormikContext } from "formik";
import StarIcon from "../../assets/icons/req-star";
import UploadIcon from "../../assets/icons/upload-icon";
import { useDispatch } from "react-redux";
import { imageUploadAction } from "../../redux/actions/general-action";
import { url } from "../../redux/config";
import ErrorMsg from "./error-msg";

const MultipleImagesField = ({
  isRequired = false,
  labelClass = "whitespace-nowrap flex fs_14 fw_400 text-secondary",
  labelWidth = "12.8rem",
  setloading,
  isDisabled = false,
}) => {
  const { setFieldValue, values, errors } = useFormikContext();
  console.log(values, "fgddgddhhdhf");
  const dispatch = useDispatch();
  const handleFileChange = (event) => {
    setloading(true);
    const files = event.target.files;
    for (let index = 0; index < files?.length; index++) {
      dispatch(imageUploadAction(files?.[index], onSuccess, onError));
    }
  };
  const onSuccess = (data) => {
    console.log(data, "xcvcxvxcxcvxvxv");
    setloading(false);
    setFieldValue("shop_images", [
      ...values?.shop_images,
      `${url}/image/${data}`,
    ]);
  };
  const onError = (data) => {
    setFieldValue("shop_images", values?.shop_images);
    console.log(data, "xcvcxvxcxcvxvxv");
    setloading(false);
  };
  
  return (
    <div>
      <label
        htmlFor="shop_images"
        className={`flex items-center ${labelClass} w-full`}
      >
        <span
          style={{
            minWidth: labelWidth,
          }}
          className="flex"
        >
          Add Images
          {isRequired && (
            <span className="ml-2">
              <StarIcon />
            </span>
          )}
        </span>{" "}
        <div
          style={{
            height: "2.9rem",
          }}
          className={`${
            errors["shop_images"] && errors["shop_images"]
              ? ` inputField_border__error`
              : ` inputField_border`
          }   flex items-center gap-3 w-full py-10 text-667085`}
        >
          <UploadIcon fill="#6C7184" />
          Upload File
        </div>
      </label>

      <Field>
        {({ field, form }) => (
          <input
            disabled={isDisabled}
            multiple
            id="shop_images"
            type="file"
            accept=".png, .jpg, .jpeg"
            onChange={(event) => {
              handleFileChange(event);
              field.onChange(event);
            }}
            onBlur={field.onBlur}
            style={{ display: "none" }}
          />
        )}
      </Field>
      <ErrorMsg name={"shop_images"} />
    </div>
  );
};

export default MultipleImagesField;
