import React, { useState } from "react";
import InputField from "../forms/input-field";
import AsyncCreateSelect from "../forms/async-create-select-field";
import { useFormikContext } from "formik";
import { useNavigate } from "react-router-dom";
import { customerListAction } from "../../redux/actions/general-action";
import { useDispatch } from "react-redux";

const SalesPurchaseHeader = ({
  disabled = false,
  searchRef = {},
  name = "",
  section = "",
}) => {                    
  const dispatch = useDispatch();
  const [localData, setLocalData] = useState([]);
  const navigate = useNavigate();
  const { errors, values, setFieldValue, setFieldError } = useFormikContext();
  const data = useFormikContext();

  const loadOptions = (inputValue, field) => {
    return new Promise((resolve) => {
      const onSuccess = (data) => {
        const options = [
          ...data.data.map((item) => ({
            value: item.cmr_id,
            label:
              item?.cmr_code +
              "-" +
              (item?.cmr_first_name + item?.cmr_last_name),
          })),
          {
            value: "new_customer",
            label: (
              <span className="text-primary">
                {section === "sales" ? "+ New Customer" : "+ New Vendor"}
              </span>
            ),
          },
        ];
        setLocalData(data.data);
        resolve(options);
      };

      const onError = (error) => {
        resolve([
          {
            value: "new_customer",
            label: (
              <span className="text-primary">
                {section === "sales" ? "+ New Customer" : "+ New Vendor"}
              </span>
            ),
          },
        ]);
      };

      if (inputValue?.length > 0) {
        dispatch(
          customerListAction(
            {
              field: field,
              value: inputValue,
              isVendor: section === "sales" ? false : true,
            },
            onSuccess,
            onError
          )
        );
      } else {
        resolve([]);
      }
    });
  };
  return (
    <>
      <div class="w-full md:w-full lg:w-2/3 xl-1/2 2xl:w-[40%]  ">
        <div className="flex items-center gap-2 w-full  px-2 py-2">
          <div className="w-[60%]">
            <AsyncCreateSelect
              className={`z-30 ${
                errors?.cmr_code && !values?.cmr_code
                  ? "selectField_border__error"
                  : "selectField_border"
              }`}
              errorClass="hidden"
              disabled={disabled}
              marginRight=""
              minWidth="8.5rem"
              cusType={section === "sales" ? "Customer" : "Vendor"}
              labelClass="whitespace-nowrap flex fs_14 fw_600  gap-2"
              type="text"
              label={`${section === "sales" ? "Customer" : "Vendor"} Code`}
              name={"cmr_code"}
              placeholder={`Enter ${
                section === "sales" ? "Customer" : "Vendor"
              } Code`}
              onCreateOption={() => {}}
              loadOptions={(e) => loadOptions(e, "cmr_code")}
              onChange={(selectedOption, actionMeta) => {
                if (actionMeta?.action === "clear") {
                  setFieldValue("cmr_code", "");
                  setFieldValue("cmr_name", "");
                } else if (
                  selectedOption === "new_customer" ||
                  selectedOption?.value === "new_customer"
                ) {
                  navigate("/add-customer");
                } else {
                  const customerData = localData?.find(
                    (item) => item?.cmr_id === selectedOption?.value
                  );
                  setFieldValue("cmr_code", {
                    label: customerData?.cmr_code,
                    value: customerData?.cmr_id,
                  });
                  setFieldValue("cmr_id", customerData?.cmr_id);
                  setFieldValue(
                    "cmr_phone_number",
                    customerData?.cmr_phone_number
                  );
                  setFieldValue("cmr_name", {
                    value: customerData?.cmr_id,
                    label:
                      customerData?.cmr_first_name +
                      " " +
                      customerData?.cmr_last_name,
                  });

                  if (searchRef && searchRef.current) {
                    searchRef.current.focus();
                    setFieldError("cmr_name", "");
                  }
                }
              }}
            />
          </div>
          <InputField
            labelClass="whitespace-nowrap flex fs_14 fw_600 text-secondary"
            errMsgContainerClass="hidden"
            isDisabled={disabled}
            containerClass="w-[40%]"
            isRequired={false}
            type="date"
            name={`${name}_order_date`}
            placeholder=""
            label="Date"
            labelWidth="2.5rem"
          />
        </div>
        <div className="w-full px-2 py-2">
          <AsyncCreateSelect
            menuPortalTarget={document.body}
            className={`z-20 ${
              errors?.cmr_name && !values?.cmr_name
                ? "selectField_border__error"
                : "selectField_border"
            }`}
            errorClass="hidden"
            disabled={disabled}
            minWidth="8.5rem"
            marginRight=""
            cusType={section === "sales" ? "Customer" : "Vendor"}
            labelClass="whitespace-nowrap flex fs_14 fw_600   gap-2"
            type="text"
            label={`${section === "sales" ? "Customer" : "Vendor"} Name`}
            autoFocus={true}
            name={"cmr_name"}
            placeholder={`Enter ${
              section === "sales" ? "Customer" : "Vendor"
            } Name`}
            onCreateOption={() => {}}
            loadOptions={(e) => loadOptions(e, "cmr_first_name")}
            onChange={(selectedOption, actionMeta) => {
              if (actionMeta?.action === "clear") {
                setFieldValue("cmr_code", "");
                setFieldValue("cmr_name", "");
              } else if (
                selectedOption === "new_customer" ||
                selectedOption?.value === "new_customer"
              ) {
                navigate("/add-customer");
              } else {
                const customerData = localData?.find(
                  (item) => item?.cmr_id === selectedOption?.value
                );
                setFieldValue("cmr_code", {
                  label: customerData?.cmr_code,
                  value: customerData?.cmr_id,
                });
                setFieldValue(
                  "cmr_phone_number",
                  customerData?.cmr_phone_number
                );
                setFieldValue("cmr_id", customerData?.cmr_id);
                setFieldValue("cmr_name", {
                  value: customerData?.cmr_id,
                  label:
                    customerData?.cmr_first_name +
                    " " +
                    customerData?.cmr_last_name,
                });
                if (searchRef && searchRef.current) {
                  setTimeout(() => {
                    searchRef.current.focus();
                    setFieldError("cmr_name", "");
                  }, 0);
                }
              }
            }}
          />
        </div>
      </div>
      <div class="w-full md:w-full lg:w-2/3 xl:w-[30%] 2xl:w-[25%]">
        <div className="w-full">
          <div className="px-2 py-2">
            <InputField
              labelClass="whitespace-nowrap flex fs_14 fw_600 text-secondary"
              containerClass=""
              isDisabled={disabled}
              errMsgContainerClass="hidden"
              type="date"
              name={`${name}_document_date`}
              label="Document Date "
              labelWidth="9rem"
              isRequired={true}
            />
          </div>
          <div className="px-2 py-2">
            <InputField
              labelClass="whitespace-nowrap flex fs_14 fw_600 text-secondary"
              containerClass=""
              isDisabled={disabled}
              errMsgContainerClass="hidden"
              type="date"
              name={`${name}_delivery_date`}
              label="Delivery Date"
              labelWidth="9rem"
              isRequired={true}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default SalesPurchaseHeader;
