import React from "react";

const LoadingButtonSpinner = () => {
  return (
    <div className="flex justify-center items-center  h-full">
      <div className="animate-spin rounded-full h-6 w-6 border-t-2 border-white"></div>
    </div>
  );
};

export default LoadingButtonSpinner;
