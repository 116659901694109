import { Form, Formik } from "formik";
import React, { useState } from "react";
import * as Yup from "yup";
import InputField from "../forms/input-field";

import { useDispatch } from "react-redux";

import successToast from "../helper-components/success-toast";
import errorToast from "../helper-components/error-toast";
import AddButton from "../buttons/button";
import { ImageField1 } from "../forms/images-field";
import CheckBox from "../forms/check-box-field";
import {
  addStrInfraAction,
  addStrbrandAction,
} from "../../redux/actions/store-action";
import moment from "moment";

const BrandingDetailsTab = ({
  initialValue,
  id,
  permissionFn = () => {},
  setCurrentTab,
  reload,
  setReload,
}) => {
  const dispatch = useDispatch();
  const initialValues = {
    store_id: id,
    site_analysis_date: initialValue?.site_analysis_date
      ? moment(initialValue?.site_analysis_date).format("YYYY-MM-DD")
      : "",
    measurement_recevied_date: initialValue?.measurement_recevied_date
      ? moment(initialValue?.measurement_recevied_date).format("YYYY-MM-DD")
      : "",
    final_ppt_approval_date: initialValue?.final_ppt_approval_date
      ? moment(initialValue?.final_ppt_approval_date).format("YYYY-MM-DD")
      : "",
    graphics_work_done_date: initialValue?.graphics_work_done_date
      ? moment(initialValue?.graphics_work_done_date).format("YYYY-MM-DD")
      : "",
    shared_link_date: initialValue?.shared_link_date
      ? moment(initialValue?.shared_link_date).format("YYYY-MM-DD")
      : "",
  };

  const validationSchema = Yup.object().shape({});

  const onSubmit = (values, actions) => {
    dispatch(addStrbrandAction(values, onSuccess, onError));
  };

  const onSuccess = (data) => {
    setReload(!reload);
    successToast(data?.message);
    setCurrentTab("Branding & Software Details");
  };
  const onError = (data) => {
    errorToast(data?.message);
  };

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ values, setFieldValue, isSubmitting, errors }) => {
        return (
          <Form className="flex-grow pt-2">
            <div className="flex flex-col justify-between h-full ">
              <div>
                <div className="w-full 2xl:w-[35%] lg:w-[60%] px-5 xl:px-10">
                  <InputField
                    type="date"
                    name="site_analysis_date"
                    placeholder="select completion date"
                    label="Site Analysis Date"
                    isRequired={true}
                    labelWidth="13.5rem"
                  />
                  <InputField
                    type="date"
                    name="measurement_recevied_date"
                    placeholder="select completion date"
                    label="Measurement Received Date"
                    isRequired={true}
                    labelWidth="13.5rem"
                  />
                  <InputField
                    type="date"
                    name="final_ppt_approval_date"
                    placeholder="select completion date"
                    label="Final PPT Approval Date"
                    isRequired={true}
                    labelWidth="13.5rem"
                  />
                  <InputField
                    type="date"
                    name="graphics_work_done_date"
                    placeholder="select completion date"
                    label="Graphics Work Done Date"
                    isRequired={true}
                    labelWidth="13.5rem"
                  />
                  <InputField
                    type="date"
                    name="shared_link_date"
                    placeholder="select completion date"
                    label="Shared Link Date"
                    isRequired={true}
                    labelWidth="13.5rem"
                  />
                </div>
              </div>
              <div
                style={{
                  bottom: "0",
                }}
                className="flex justify-end px-10 mb-5x sticky bg-white py-4 invoice-bottom-container"
              >
                <AddButton prefixIcon={false} text="Save" type="submit" />
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default BrandingDetailsTab;
const items = [{ label: "Colour Pre Applied", name: "colour_pre_applied" }];
const items1 = [
  { label: "Furniture Pre Applied", name: "furniture_pre_applied" },
];
