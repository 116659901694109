import React from "react";
import FilterRadioButtons from "../../forms/filter-radio-buttons";
import {
  orderStatusoptions,
  paymentStatusoptions,
} from "../../helper-components/data";

const InvoiceFilter = ({ setFilterData, filterData, setCurrentPage  }) => {
  const handleOrderStatusChange = (e) => {
    setFilterData({
      ...filterData,
      order_status: e.target.value,
    });
    setCurrentPage(1)
  };

  const handlePaymentStatusChange = (e) => {
    setFilterData({
      ...filterData,
      payment_status: e.target.value,
    });
    setCurrentPage(1)
  };
  return (
    <div
    menuPortalTarget={document.body}
      style={{
        minWidth: "12rem",
        right: "-30px", // Adjust this value as needed
      }}
      className="absolute  border border-EAECF0 border-solid border-1 top-4 right-0 mt-8 mr-8 py-2  bg-white  rounded shadow-lg z-10"
    >
      <span className="color_6C7184 fs_12 fw_600 px-5">FILTER BY</span>
       <hr className="border border-EAECF0 mt-3"/>
      <div className="px-5 mt-3">
        <FilterRadioButtons
          name="order_sataus"
          label={"Order Status"}
          options={orderStatusoptions}
          handleChange={handleOrderStatusChange}
          value={filterData?.order_status}
        />
        <FilterRadioButtons
          name="payment_sataus"
          label={"Payment Status"}
          options={paymentStatusoptions}
          handleChange={handlePaymentStatusChange}
          value={filterData?.payment_status}
        />
      </div>
    </div>
  );
};

export default InvoiceFilter;
