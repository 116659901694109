import React, { useEffect, useState } from 'react';
import ReportsLayout from '../../../components/reports/reports-layout';
import { inventorySalesReportAction } from '../../../redux/actions/report-action';
import { useDispatch } from 'react-redux';
import moment from 'moment';

const StockSalesReport = () => {

    const dispatch = useDispatch()
    const [groupByOptions, setgroupByOptions] = useState([
        { label: "None", value: '' },
        // { label: "Drug Code", value: 'oi.item_code' },
    ]);

    const [loading, setLoading] = useState(false);
    const [filterOptions, setFilterOptions] = useState(Object.entries(tableColoumsn).map(([label, value]) => ({
        label,
        value
    })));
    const [selectedColumns, setSelectedColumns] = useState(tableHeading);
    const [filters, setFilters] = useState([])
    const [tableData, setTableData] = useState([]);
    const [dateRange, setDateRange] = useState('');
    const [groupBy, setGroupBy] = useState('')

    useEffect(() => {
        const selectedColumnValues = selectedColumns.map(column => tableColoumsn[column]);
        const requestData = {
            selectedColumns: selectedColumnValues,
            filters: filters.map(({ id, ...rest }) => rest),
            filter_by: dateRange,
            group_by: groupBy
        };
        dispatch(inventorySalesReportAction(requestData, onSuccess, onError))
        setLoading(true);

    }, [selectedColumns, filters, dateRange, groupBy]);


    const isIso8601Date = (dateString) => {
        const iso8601Regex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/;
        return iso8601Regex.test(dateString);
    };

    const formatDate = (value) => {
        return moment(value).format('DD-MM-YYYY');
    };

    const transformData = (data) => {
        return data.map(item => {
            let transformedItem = {};
            for (let [key, value] of Object.entries(tableColoumsn)) {
                const rawValue = item[value.split('.').pop()];
                transformedItem[key] = isIso8601Date(rawValue) ? formatDate(rawValue) : rawValue;
            }
            return transformedItem;

        });
    };

    const onSuccess = (data) => {
        console.log("data is", data);
        const transformedData = transformData(data?.data?.result);
        setTableData(transformedData);
        setLoading(false)
    }
    const onError = (data) => {
        console.log("error is", data);
        setLoading(false)
    }

    return (
        <ReportsLayout
            title={"Stock and Sales Report"}
            tableHeading={selectedColumns}
            tableData={tableData}
            groupByOptions={groupByOptions}
            filterOptions={filterOptions}
            availableColumns={tableHeading}
            selectedColumns={selectedColumns}
            setSelectedColumns={setSelectedColumns}
            setFilters={setFilters}
            loading={loading}
            setDateRange={setDateRange}
            setGroupBy={setGroupBy}
        />
    );
};

const tableHeading = ["Drug Code", "Drug Name", "Opening Stock", "Closing Stock",
    "Sales Qty", "Sales Return Qty", "Purchase Qty", "Purchase Return Qty", "Batch Number", "Exp Date", "Rate",
    "Last Sale Date", "Last Purchase Date", "Gross Amount"
];

const tableColoumsn = {
    "Drug Code": "os.item_code",
    "Drug Name": "t.item_name",
    "Opening Stock": "os.opening_stock",
    "Closing Stock": "os.closing_stock",
    "Sales Qty": "t.sales_qty",
    "Sales Return Qty": "t.sales_return_qty",
    "Purchase Qty": "t.purchase_qty",
    "Purchase Return Qty": "t.purchase_return_qty",
    // "Adjust Qty": "t.item_id",
    "Batch Number": "t.item_batch_number",
    "Exp Date": "t.item_exp_date",
    "Rate": "t.item_unit_price",
    // "UOM": "t.item_uom",
    "Last Sale Date": "t.last_sale_date",
    "Last Purchase Date": "t.last_purchase_date",
    // "Net Amount": "so.sot_sub_total",
    "Gross Amount": "t.gross_amount",
    // "Profit Amount": "so.sot_total_amount",
    // "Profit Percentage": "so.sot_total_amount"
}


export default StockSalesReport;