import React from "react";

const DownArrowIcon = ({ fill = "#838195",customStyles={} }) => {
  return (
    <svg
      width="14"
      height="8"
      viewBox="0 0 14 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{...customStyles}}
    >
      <path
        d="M0 1.34509L5.55333 7.3544C5.93671 7.76787 6.4556 8 6.9965 8C7.5374 8 8.05629 7.76787 8.43967 7.3544L14 1.33814L12.7627 0L7.203 6.01563C7.1483 6.07488 7.07413 6.10816 6.99679 6.10816C6.91945 6.10816 6.84528 6.07488 6.79058 6.01563L1.23725 0.00695312L0 1.34509Z"
        fill={fill}
      />
    </svg>
  );
};

export default DownArrowIcon;
