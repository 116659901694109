import { all } from "redux-saga/effects";
import {
  forgotPasswordWatcher,
  resetPasswordWatcher,
  storeLoginWatcher,
} from "../auth-saga";

export default function* authRootSaga() {
  yield all([
    storeLoginWatcher(),
    resetPasswordWatcher(),
    forgotPasswordWatcher(),
  ]);
}
