import React from "react";

function BinLocationManagementIcon() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        d="M15 3H11C10.7349 3.00026 10.4807 3.10571 10.2932 3.29319C10.1057 3.48066 10.0003 3.73486 10 4V16C10.0003 16.2651 10.1057 16.5193 10.2932 16.7068C10.4807 16.8943 10.7349 16.9997 11 17H15C15.2651 16.9997 15.5193 16.8943 15.7068 16.7068C15.8943 16.5193 15.9997 16.2651 16 16V4C15.9997 3.73486 15.8943 3.48066 15.7068 3.29319C15.5193 3.10571 15.2651 3.00026 15 3ZM15 4V7.5H11V4H15ZM15 8.5V11.5H11V8.5H15ZM11 16V12.5H15V16H11ZM7 9.5H6V8.5H5V9.5H4V10.5H5V11.5H6V10.5H7V9.5Z"
        fill="white"
      />
      <path
        d="M9 5V4H6C5.73487 4.00026 5.48067 4.10571 5.29319 4.29319C5.10571 4.48066 5.00027 4.73486 5 5V6.54C4.16697 6.65879 3.40477 7.07412 2.85336 7.70973C2.30196 8.34534 1.99838 9.15855 1.99838 10C1.99838 10.8415 2.30196 11.6547 2.85336 12.2903C3.40477 12.9259 4.16697 13.3412 5 13.46V15C5.00027 15.2651 5.10571 15.5193 5.29319 15.7068C5.48067 15.8943 5.73487 15.9997 6 16H9V15H6V13.46C6.83303 13.3412 7.59524 12.9259 8.14664 12.2903C8.69804 11.6547 9.00162 10.8415 9.00162 10C9.00162 9.15855 8.69804 8.34534 8.14664 7.70973C7.59524 7.07412 6.83303 6.65879 6 6.54V5H9ZM8 10C8 10.4945 7.85338 10.9778 7.57868 11.3889C7.30397 11.8 6.91353 12.1205 6.45671 12.3097C5.99989 12.4989 5.49723 12.5484 5.01228 12.452C4.52732 12.3555 4.08187 12.1174 3.73223 11.7678C3.3826 11.4181 3.1445 10.9727 3.04804 10.4877C2.95158 10.0028 3.00108 9.50011 3.1903 9.04329C3.37952 8.58648 3.69995 8.19603 4.11108 7.92133C4.5222 7.64662 5.00555 7.5 5.5 7.5C6.1628 7.50079 6.79822 7.76444 7.26689 8.23311C7.73556 8.70178 7.99921 9.3372 8 10Z"
        fill="white"
      />
    </svg>
  );
}

export default BinLocationManagementIcon;
