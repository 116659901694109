import React, { useState } from "react";

import InnerLayout from "../../components/layouts/inner-layout";
import StoreCreateViewEdit from "../../components/store-information/create-edit-view-store";

const AddStore = () => {
  const [storeData, setStoreData] = useState({
    address: {},
    personal: {},
    firm: {},
    agreement: {},
    payment: {},
    drug: {},
  });
  return (
    <InnerLayout onclkFn={"/add-store"} title="Store Information">
      <StoreCreateViewEdit setStoreData={setStoreData} storeData={storeData} />
    </InnerLayout>
  );
};

export default AddStore;
