import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./helper-components.css";

const errorToast = (message = "Something went wrong") => {
  toast.error(message, {
    position: "top-center",
    autoClose: 4000,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
    className: "custom-toast",
  });
};

export default errorToast;
