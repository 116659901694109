import {
  DASHBOARD_EXPIRATION,
  DASHBOARD_LOW_STOCK,
  DASHBOARD_NEW_CUS_GRAPH,
  DASHBOARD_SALES,
  DASHBOARD_TOP_SELLING_PRODUCTS,
} from "../types";

export const dashboardSalesAction = (data, onSuccess, onError) => {
  return {
    type: DASHBOARD_SALES,
    data,
    onSuccess,
    onError,
  };
};
export const dashboardTopSellingProductsAction = (data, onSuccess, onError) => {
  return {
    type: DASHBOARD_TOP_SELLING_PRODUCTS,
    data,
    onSuccess,
    onError,
  };
};
export const dashboardLowStockAction = (onSuccess, onError) => {
  return {
    type: DASHBOARD_LOW_STOCK,
    onSuccess,
    onError,
  };
};
export const dashboardExpStockAction = (onSuccess, onError) => {
  return {
    type: DASHBOARD_EXPIRATION,
    onSuccess,
    onError,
  };
};

export const dashboardNewCusGraphAction = (data, onSuccess, onError) => {
  return {
    type: DASHBOARD_NEW_CUS_GRAPH,
    data,
    onSuccess,
    onError,
  };
};
