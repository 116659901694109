import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import SelectField from "../forms/select-field";
import InputField from "../forms/input-field";
import TextAreaField from "../../components/forms/textarea-field";
import { useDispatch } from "react-redux";
import { stateListAction } from "../../redux/actions/general-action";
import {
  adminCustomerAddressAction,
  adminCustomerUpdateAddressAction,
} from "../../redux/actions/admin-customer-action";
import successToast from "../helper-components/success-toast";
import AddButton from "../buttons/button";

const AddressTab = ({
  setCurrentTab,
  customerGeneralData,
  customerId,
  setReload,
  reload,
  customerDetails,
  isDisabled = false,
}) => {
  const dispatch = useDispatch();
  const [stateList, setStateList] = useState([]);
  useEffect(() => {
    dispatch(stateListAction(onSuccessState, onErrorState));
  }, []);

  const onSuccessState = (data) => {
    setStateList(
      data.map((item) => {
        return { label: item?.name, value: item?.name };
      })
    );
  };
  const onErrorState = () => {};
  const initPaytoData = {
    cmr_id: customerId,
    cmr_pay_address_name: "",
    cmr_pay_address_district: "",
    cmr_pay_address_city: "",
    cmr_pay_address_town: "",
    cmr_pay_address_street: "",
    cmr_pay_address_pincode: "",
    cmr_pay_address_country: "india",
    cmr_pay_address_address: "",
    cmr_pay_address_state: "",
  };
  const initShiptoData = {
    cmr_id: customerId,
    cmr_ship_address_name: "",
    cmr_ship_address_district: "",
    cmr_ship_address_city: "",
    cmr_ship_address_town: "",
    cmr_ship_address_street: "",
    cmr_ship_address_pincode: "",
    cmr_ship_address_country: "india",
    cmr_ship_address_address: "",
    cmr_ship_address_state: "",
  };
  const initialValues = {
    payToAddress:
      customerDetails?.payToAddress?.length > 0
        ? customerDetails?.payToAddress.map((person) => ({ ...person }))
        : [initPaytoData],
    shipToAddress:
      customerDetails?.shipToAddress?.length > 0
        ? customerDetails.shipToAddress.map((person) => ({ ...person }))
        : [initShiptoData],
  };

  const validationSchema = Yup.object().shape({
    payToAddress: Yup.array().of(
      Yup.object().shape({
        cmr_pay_address_name: Yup.string().required("Name is required"),
      })
    ),
    shipToAddress: Yup.array().of(
      Yup.object().shape({
        cmr_ship_address_name: Yup.string().required("Name is required"),
      })
    ),
  });
  const [activePayToAddress, setActivePayToAddress] = useState(1);
  const [activeShipToAddress, setActiveShipToAddress] = useState(1);
  const innerOnSubmit = (values) => {
    console.log(values, "dsfsfsdfsdfsdf");
    let actionType = adminCustomerAddressAction;
    if (
      customerDetails.payToAddress?.length > 0 &&
      customerDetails.shipToAddress?.length > 0
    ) {
      actionType = adminCustomerUpdateAddressAction;
    }

    dispatch(
      actionType(
        {
          customerGeneralData: customerGeneralData,
          payToAddressData: values?.payToAddress,
          shipToAddressData: values?.shipToAddress,
        },
        onSuccess,
        onError
      )
    );
  };

  const onSuccess = (data) => {
    successToast(data?.message);
    setCurrentTab("General");
    setReload(!reload);
  };
  const onError = (data) => {};

  return (
    <>
      <div className="ml-5">
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={innerOnSubmit}
        >
          {({ values, handleSubmit, setFieldValue }) => {
            console.log(values, "dfgdsssfdsfsdf");
            return (
              <>
                <h2 className="text-xl font-bold mb-3">Pay To</h2>
                <div className="flex items-center mb-7 ">
                  {values?.payToAddress?.map((person, index) => (
                    <button
                      type="button"
                      onClick={() => setActivePayToAddress(index + 1)}
                      key={index}
                      className={`mr-4 py-2 px-6 fs_14 relative z-5 ${
                        activePayToAddress === index + 1
                          ? "text-primary bg-primary bg-opacity-20 rounded-lg fw_700"
                          : "color-000000 bg-gray bg-opacity-20 rounded-lg fw_500"
                      }`}
                    >
                      {person.name || `Contact Person ${index + 1}`}
                    </button>
                  ))}
                  {!isDisabled && (
                    <button
                      disabled={isDisabled}
                      type="button"
                      onClick={() => {
                        setFieldValue("payToAddress", [
                          ...values.payToAddress,
                          { ...initPaytoData },
                        ]);
                        setActivePayToAddress(values.payToAddress?.length + 1);
                      }}
                    >
                      +
                    </button>
                  )}
                </div>
                {values?.payToAddress?.map((person, index) => {
                  return (
                    <div
                      key={index}
                      className={
                        index + 1 === activePayToAddress
                          ? "flex flex-wrap"
                          : "hidden"
                      }
                    >
                      <div className="w-full 2xl:w-[35%] lg:w-1/2 px-5 xl:px-10">
                        <InputField
                          isDisabled={isDisabled}
                          type="text"
                          name={`payToAddress[${index}].cmr_pay_address_name`} // Dynamic name using Formik's field structure
                          placeholder="Name"
                          label="Name"
                          isRequired={true}
                          errMsgContainerClass="ps-2"
                          labelWidth="8rem"
                        />
                        <SelectField
                          isDisabled={isDisabled}
                          defaultValue={[
                            { label: "India", value: "india" },
                          ].find(
                            (option) =>
                              option.value ===
                              values.payToAddress[index].cmr_pay_address_country
                          )}
                          placeholder="Select country"
                          options={[{ label: "India", value: "india" }]}
                          label="Country"
                          id={`payToAddress[${index}].cmr_pay_address_country`}
                          name={`payToAddress[${index}].cmr_pay_address_country`}
                          isSearchable={true}
                          errMsgContainerClass="ps-2"
                          isRequired={false}
                          labelWidth="8rem"
                        />
                        <SelectField
                         isDisabled={isDisabled}
                          defaultValue={stateList.find(
                            (option) =>
                              option.value ===
                              values.payToAddress[index].cmr_pay_address_state
                          )}
                          placeholder={"Select state"}
                          options={stateList}
                          label={"State"}
                          id={`payToAddress[${index}].cmr_pay_address_state`}
                          name={`payToAddress[${index}].cmr_pay_address_state`}
                          isSearchable={true}
                          errMsgContainerClass="ps-2"
                          isRequired={false}
                          labelWidth="8rem"
                        />
                        <InputField
                          isDisabled={isDisabled}
                          type="text"
                          name={`payToAddress[${index}].cmr_pay_address_district`} // Dynamic name using Formik's field structure
                          placeholder="District"
                          label="District"
                          isRequired={false}
                          errMsgContainerClass="ps-2"
                          labelWidth="8rem"
                        />

                        <InputField
                          isDisabled={isDisabled}
                          type="text"
                          name={`payToAddress[${index}].cmr_pay_address_city`} // Dynamic name using Formik's field structure
                          placeholder="City"
                          label="City"
                          isRequired={false}
                          errMsgContainerClass="ps-2"
                          labelWidth="8rem"
                        />
                      </div>

                      <div className="w-full 2xl:w-[35%] lg:w-1/2 px-5 xl:px-10">
                        <InputField
                          isDisabled={isDisabled}
                          type="text"
                          name={`payToAddress[${index}].cmr_pay_address_town`} // Dynamic name using Formik's field structure
                          placeholder="Town"
                          label="Town"
                          isRequired={false}
                          errMsgContainerClass="ps-2"
                          labelWidth="8rem"
                        />

                        <InputField
                          isDisabled={isDisabled}
                          type="text"
                          name={`payToAddress[${index}].cmr_pay_address_street`} // Dynamic name using Formik's field structure
                          placeholder="Street"
                          label="Street"
                          isRequired={false}
                          errMsgContainerClass="ps-2"
                          labelWidth="8rem"
                        />
                        <InputField
                          isDisabled={isDisabled}
                          type="number"
                          name={`payToAddress[${index}].cmr_pay_address_pincode`} // Dynamic name using Formik's field structure
                          placeholder="Pincode"
                          label="Pincode"
                          isRequired={false}
                          errMsgContainerClass="ps-2"
                          labelWidth="8rem"
                        />

                        <TextAreaField
                          isDisabled={isDisabled}
                          name={`payToAddress[${index}].cmr_pay_address_address`} // Dynamic name using Formik's field structure
                          placeholder="Address"
                          label="Address"
                          isRequired={false}
                          errMsgContainerClass="ps-2"
                          labelWidth="8rem"
                        />
                      </div>
                      {!isDisabled && (
                        <div className="flex justify-start w-full px-5 xl:px-10">
                          <button
                            type="button"
                            onClick={() => {
                              if (values?.payToAddress.length > 1) {
                                const updatedContactPersons = [
                                  ...values.payToAddress,
                                ];
                                updatedContactPersons.splice(index, 1);
                                setFieldValue(
                                  "payToAddress",
                                  updatedContactPersons
                                );
                                setActivePayToAddress(1);
                              }
                            }}
                            className="py-2 px-6 color-ffffff bg-red text-white rounded-lg fw_700"
                          >
                            Delete
                          </button>
                        </div>
                      )}
                    </div>
                  );
                })}

                <h2 className="text-xl font-bold mb-3 mt-5">Ship To</h2>
                <div className="flex items-center mb-7 ">
                  {values?.shipToAddress?.map((person, index) => (
                    <button
                      type="button"
                      onClick={() => setActiveShipToAddress(index + 1)}
                      key={index}
                      className={`mr-4 py-2 px-6 fs_14 relative z-5 ${
                        activeShipToAddress === index + 1
                          ? "text-primary bg-primary bg-opacity-20 rounded-lg fw_700"
                          : "color-000000 bg-gray bg-opacity-20 rounded-lg fw_500"
                      }`}
                    >
                      {person.name || `Contact Person ${index + 1}`}
                    </button>
                  ))}
                  {!isDisabled && (
                    <button
                      type="button"
                      onClick={() => {
                        setFieldValue("shipToAddress", [
                          ...values.shipToAddress,
                          { ...initShiptoData },
                        ]);
                        setActiveShipToAddress(
                          values.shipToAddress?.length + 1
                        );
                      }}
                    >
                      +
                    </button>
                  )}
                </div>
                {values?.shipToAddress?.map((person, index) => {
                  return (
                    <div
                      key={index}
                      className={
                        index + 1 === activeShipToAddress
                          ? "flex flex-wrap"
                          : "hidden"
                      }
                    >
                      <div className="w-full 2xl:w-[35%] lg:w-1/2 px-5 xl:px-10">
                        <InputField
                          isDisabled={isDisabled}
                          type="text"
                          name={`shipToAddress[${index}].cmr_ship_address_name`} // Dynamic name using Formik's field structure
                          placeholder="Name"
                          label="Name"
                          isRequired={true}
                          errMsgContainerClass="ps-2"
                          labelWidth="8rem"
                        />
                        <SelectField
                          isDisabled={isDisabled}
                          defaultValue={[
                            { label: "India", value: "india" },
                          ].find(
                            (option) =>
                              option.value ===
                              values.shipToAddress[index]
                                .cmr_ship_address_country
                          )}
                          placeholder="Select country"
                          options={[{ label: "India", value: "india" }]}
                          label="Country"
                          id={`shipToAddress[${index}].cmr_ship_address_country`}
                          name={`shipToAddress[${index}].cmr_ship_address_country`}
                          isSearchable={true}
                          errMsgContainerClass="ps-2"
                          isRequired={false}
                          labelWidth="8rem"
                        />
                        <SelectField
                          isDisabled={isDisabled}
                          defaultValue={stateList.find(
                            (option) =>
                              option.value ===
                              values.shipToAddress[index].cmr_ship_address_state
                          )}
                          placeholder={"Select state"}
                          options={stateList}
                          label={"State"}
                          id={`shipToAddress[${index}].cmr_ship_address_state`}
                          name={`shipToAddress[${index}].cmr_ship_address_state`}
                          isSearchable={true}
                          errMsgContainerClass="ps-2"
                          isRequired={false}
                          labelWidth="8rem"
                        />
                        <InputField
                          isDisabled={isDisabled}
                          type="text"
                          name={`shipToAddress[${index}].cmr_ship_address_district`} // Dynamic name using Formik's field structure
                          placeholder="District"
                          label="District"
                          isRequired={false}
                          errMsgContainerClass="ps-2"
                          labelWidth="8rem"
                        />

                        <InputField
                          isDisabled={isDisabled}
                          type="text"
                          name={`shipToAddress[${index}].cmr_ship_address_city`} // Dynamic name using Formik's field structure
                          placeholder="City"
                          label="City"
                          isRequired={false}
                          errMsgContainerClass="ps-2"
                          labelWidth="8rem"
                        />
                      </div>

                      <div className="w-full 2xl:w-[35%] lg:w-1/2 px-5 xl:px-10">
                        <InputField
                          isDisabled={isDisabled}
                          type="text"
                          name={`shipToAddress[${index}].cmr_ship_address_town`} // Dynamic name using Formik's field structure
                          placeholder="Town"
                          label="Town"
                          isRequired={false}
                          errMsgContainerClass="ps-2"
                          labelWidth="8rem"
                        />

                        <InputField
                          isDisabled={isDisabled}
                          type="text"
                          name={`shipToAddress[${index}].cmr_ship_address_town`} // Dynamic name using Formik's field structure
                          placeholder="Street"
                          label="Street"
                          isRequired={false}
                          errMsgContainerClass="ps-2"
                          labelWidth="8rem"
                        />
                        <InputField
                          isDisabled={isDisabled}
                          type="number"
                          name={`shipToAddress[${index}].cmr_ship_address_pincode`} // Dynamic name using Formik's field structure
                          placeholder="Pincode"
                          label="Pincode"
                          isRequired={false}
                          errMsgContainerClass="ps-2"
                          labelWidth="8rem"
                        />

                        <TextAreaField
                          isDisabled={isDisabled}
                          name={`shipToAddress[${index}].cmr_ship_address_address`} // Dynamic name using Formik's field structure
                          placeholder="Address"
                          label="Address"
                          isRequired={false}
                          errMsgContainerClass="ps-2"
                          labelWidth="8rem"
                        />
                      </div>
                      {!isDisabled && (
                        <div className="flex justify-start w-full px-5 xl:px-10">
                          <button
                            type="button"
                            onClick={() => {
                              if (values?.shipToAddress.length > 1) {
                                const updatedContactPersons = [
                                  ...values.shipToAddress,
                                ];
                                updatedContactPersons.splice(index, 1);
                                setFieldValue(
                                  "shipToAddress",
                                  updatedContactPersons
                                );
                                setActiveShipToAddress(1);
                              }
                            }}
                            className="py-2 px-6 color-ffffff bg-red text-white rounded-lg fw_700"
                          >
                            Delete
                          </button>
                        </div>
                      )}
                    </div>
                  );
                })}
                {!isDisabled && (
                  <div
                    style={{
                      bottom: "0",
                    }}
                    className="flex w-full justify-end px-10 mb-5x sticky bg-white py-4 invoice-bottom-container"
                  >
                    <AddButton
                      onclkFn={handleSubmit}
                      prefixIcon={false}
                      text="Save"
                      type="submit"
                    />
                  </div>
                )}
              </>
            );
          }}
        </Formik>
      </div>
    </>
  );
};

export default AddressTab;
const countryList = [
  { value: "India", label: "India" },
  { value: "USA", label: "USA" },
  { value: "UK", label: "UK" },
  { value: "Australia", label: "Australia" },
  { value: "Canada", label: "Canada" },
  { value: "Germany", label: "Germany" },
  { value: "France", label: "France" },
  { value: "Italy", label: "Italy" },
];
