import React, { useEffect, useRef, useState } from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import PaymentInOutHeader from "../payment-in-out-header";
import PaymentInOutTable from "../payment-in-out-table";
import moment from "moment";
import PaymentInOutFinalBillTable, {
  amtpaid,
  balanceAmt,
  calculateTotalDueAmt,
  currentAmt,
} from "../payment-in-out-finalBill-table";
import PaymentInOutPopover from "../pay-in-out-popover";
import { paymentInCreateAction } from "../../../redux/actions/sales-action";
import successToast from "../../helper-components/success-toast";
import errorToast from "../../helper-components/error-toast";

const CreateEditViewPaymentIn = ({ data = {}, page = "", disabled }) => {
  const searchRef = useRef(null);
  const navigate = useNavigate();
  const [checkedId, setCheckedId] = useState(
    data?.invoiceData?.map((item) => item?.soit_id) ?? []
  );
  const [heightOfFirstDiv, setHeightOfFirstDiv] = useState(0);
  const [paymentMeans, setPaymentMeans] = useState(false);
  const today = new Date();
  useEffect(() => {
    const updateHeight = () => {
      const firstDivHeight = document.querySelector(".first-div").clientHeight;
      setHeightOfFirstDiv(firstDivHeight);
    };
    updateHeight();
    window.addEventListener("resize", updateHeight);
    return () => {
      window.removeEventListener("resize", updateHeight);
    };
  }, []);

  const bankData = data?.paymentData?.find(
    (payment) => payment.pmt_mode_name === "Bank Transfer"
  );
  const cashData = data?.paymentData?.find(
    (payment) => payment.pmt_mode_name === "Cash"
  );
  const upiData = data?.paymentData?.find(
    (payment) => payment.pmt_mode_name === "UPI"
  );
  const cardData = data?.paymentData?.find(
    (payment) => payment.pmt_mode_name === "Card"
  );
  const chequeData = data?.paymentData?.find(
    (payment) => payment.pmt_mode_name === "Cheque"
  );

  const initialFormValues = {
    is_customer: true,
    cmr_id: data?.orderData?.cmr_id ?? "",
    cmr_code: data?.orderData?.cmr_id
      ? {
          label: data?.orderData?.cmr_code ?? "",
          value: data?.orderData?.cmr_id ?? "",
        }
      : "",
    cmr_name: data?.orderData?.cmr_id
      ? {
          label: data?.orderData?.cmr_name,
          value: data?.orderData?.cmr_id,
        }
      : "",
    pit_posting_date: data?.orderData?.pit_posting_date
      ? moment(data?.orderData?.pit_posting_date).format("YYYY-MM-DD")
      : moment(today).format("YYYY-MM-DD"),
    pit_due_date: data?.orderData?.pit_due_date
      ? moment(data?.orderData?.pit_due_date).format("YYYY-MM-DD")
      : moment(today).format("YYYY-MM-DD"),
    pit_doc_date: data?.orderData?.pit_doc_date
      ? moment(data?.orderData?.pit_doc_date).format("YYYY-MM-DD")
      : moment(today).format("YYYY-MM-DD"),
    payment_journal_remarks: data?.orderData?.payment_journal_remarks ?? "",
    remarks: data?.orderData?.remarks ?? "",
    is_payment_on_account: false,
    pit_current_payment: data?.orderData?.pit_current_payment ?? 0,
    pit_total_amount: data?.orderData?.pit_total_amount ?? 0,
    pit_add_to_account_amount: data?.orderData?.pit_add_to_account_amount ?? 0,
    invoices:
      data?.invoiceData?.map((item) => {
        return {
          id: item?.soit_id,
          soit_invoice_number: item?.soit_invoice_number,
          soit_Doc: "Sales Invoice",
          soit_document_date:
            moment(item?.pdt_doc_date).format("YYYY-MM-DD") ??
            moment(today).format("YYYY-MM-DD"),
          soit_delivery_date:
            moment(item?.pdt_due_date).format("YYYY-MM-DD") ??
            moment(today).format("YYYY-MM-DD"),
          soit_due_days: item?.pdt_due_days ?? 0,
          soit_total_amount: item?.pdt_total_amount,
          soit_total_payment: item?.pdt_current_payment ?? 0,
          soit_due_amount: item?.pdt_balance_due ?? 0,
        };
      }) ?? [],
    pit_open_balance: data?.orderData?.pit_open_balance ?? 0,
    paymentModeData: [
      {
        account_id: bankData?.account_id ?? "",
        account_code: bankData?.account_code ?? "",
        account_name: bankData?.account_name ?? "",
        tab: "Bank Transfer",
        bank_reference_number: bankData?.bank_reference_number ?? "",
        date_of_transfer: bankData?.date_of_transfer
          ? moment(bankData?.date_of_transfer).format("YYYY-MM-DD")
          : moment(today).format("YYYY-MM-DD"),
        pmt_total_amount: bankData?.pmt_total_amount ?? 0,
      },
      {
        account_id: cashData?.account_id ?? "",
        account_code: cashData?.account_code ?? "",
        account_name: cashData?.account_name ?? "",
        tab: "Cash",
        date_of_transfer: cashData?.date_of_transfer
          ? moment(cashData?.date_of_transfer).format("YYYY-MM-DD")
          : moment(today).format("YYYY-MM-DD"),
        pmt_total_amount: cashData?.pmt_total_amount ?? 0,
      },
      {
        account_id: upiData?.account_id ?? "",
        account_code: upiData?.account_code ?? "",
        account_name: upiData?.account_name ?? "",
        tab: "UPI",
        date_of_transfer: upiData?.date_of_transfer
          ? moment(upiData?.date_of_transfer).format("YYYY-MM-DD")
          : moment(today).format("YYYY-MM-DD"),
        pmt_total_amount: upiData?.pmt_total_amount ?? 0,
        upi_reference_number: upiData?.upi_reference_number ?? "",
        pmt_id: upiData?.pmt_id ?? "",
      },
      {
        account_id: cardData?.account_id ?? "",
        account_code: cardData?.account_code ?? "",
        account_name: cardData?.account_name ?? "",
        tab: "Card",
        date_of_transfer: cardData?.date_of_transfer
          ? moment(cardData?.date_of_transfer).format("YYYY-MM-DD")
          : moment(today).format("YYYY-MM-DD"),
        pmt_total_amount: cardData?.pmt_total_amount ?? 0,
        card_number: cardData?.card_number ?? "",
        expiry_date: cardData?.expiry_date
          ? moment(cardData?.expiry_date).format("YYYY-MM-DD")
          : moment(today).format("YYYY-MM-DD"),
      },
      {
        account_id: chequeData?.account_id ?? "",
        account_code: chequeData?.account_code ?? "",
        account_name: chequeData?.account_name ?? "",
        tab: "Cheque",
        date_of_transfer: chequeData?.date_of_transfer
          ? moment(chequeData?.date_of_transfer).format("YYYY-MM-DD")
          : moment(today).format("YYYY-MM-DD"),
        pmt_total_amount: chequeData?.pmt_total_amount ?? 0,
        cheque_number: chequeData?.cheque_number ?? "",
        date_of_cheque: chequeData?.date_of_cheque
          ? moment(chequeData?.date_of_cheque).format("YYYY-MM-DD")
          : moment(today).format("YYYY-MM-DD"),
      },
    ],
  };
  const [initialValue] = useState(initialFormValues);
  const dispatch = useDispatch();
  const validationSchema = Yup.object().shape({
    cmr_code: Yup.object().required("Customer code number is required"),
    cmr_name: Yup.object().required("Customer name is required"),
    paymentModeData: Yup.array()
      .of(
        Yup.object().shape({
          pmt_total_amount: Yup.number(),
          account_id: Yup.string(),
          account_code: Yup.string(),
          account_name: Yup.string(),
          tab: Yup.string(),
          bank_reference_number: Yup.string(),
          date_of_transfer: Yup.date(),
          upi_reference_number: Yup.string(),
          pmt_id: Yup.string(),
          card_number: Yup.string(),
          expiry_date: Yup.date(),
          cheque_number: Yup.string(),
          date_of_cheque: Yup.date(),
        })
      )
      .test("paymentModeData-test", null, function (value) {
        const errors = [];
        value.forEach((paymentMode, index) => {
          if (paymentMode.pmt_total_amount > 0) {
            if (!paymentMode.account_id) {
              errors.push(
                this.createError({
                  path: `paymentModeData[${index}].account_id`,
                  message: "Account ID is required",
                })
              );
            }
            if (!paymentMode.account_code) {
              errors.push(
                this.createError({
                  path: `paymentModeData[${index}].account_code`,
                  message: "Account Code is required",
                })
              );
            }
            if (!paymentMode.account_name) {
              errors.push(
                this.createError({
                  path: `paymentModeData[${index}].account_name`,
                  message: "Account Name is required",
                })
              );
            }
            if (!paymentMode.tab) {
              errors.push(
                this.createError({
                  path: `paymentModeData[${index}].tab`,
                  message: "Tab is required",
                })
              );
            }
            if (
              paymentMode.tab === "Bank Transfer" &&
              !paymentMode.bank_reference_number
            ) {
              errors.push(
                this.createError({
                  path: `paymentModeData[${index}].bank_reference_number`,
                  message: "Bank Reference Number is required",
                })
              );
            }
            if (!paymentMode.date_of_transfer) {
              errors.push(
                this.createError({
                  path: `paymentModeData[${index}].date_of_transfer`,
                  message: "Date of Transfer is required",
                })
              );
            }
            if (paymentMode.tab === "UPI") {
              if (!paymentMode.upi_reference_number) {
                errors.push(
                  this.createError({
                    path: `paymentModeData[${index}].upi_reference_number`,
                    message: "UPI Reference Number is required",
                  })
                );
              }
              if (!paymentMode.pmt_id) {
                errors.push(
                  this.createError({
                    path: `paymentModeData[${index}].pmt_id`,
                    message: "Payment ID is required",
                  })
                );
              }
            }
            if (paymentMode.tab === "Card") {
              if (!paymentMode.card_number) {
                errors.push(
                  this.createError({
                    path: `paymentModeData[${index}].card_number`,
                    message: "Card Number is required",
                  })
                );
              }
              if (!paymentMode.expiry_date) {
                errors.push(
                  this.createError({
                    path: `paymentModeData[${index}].expiry_date`,
                    message: "Expiry Date is required",
                  })
                );
              }
            }
            if (paymentMode.tab === "Cheque") {
              if (!paymentMode.cheque_number) {
                errors.push(
                  this.createError({
                    path: `paymentModeData[${index}].cheque_number`,
                    message: "Cheque Number is required",
                  })
                );
              }
              if (!paymentMode.date_of_cheque) {
                errors.push(
                  this.createError({
                    path: `paymentModeData[${index}].date_of_cheque`,
                    message: "Date of Cheque is required",
                  })
                );
              }
            }
          }
        });
        if (errors.length) {
          throw new Yup.ValidationError(errors);
        }
        return true;
      }),
  });

  const handleSubmit = (values, actions) => {
    const hasPaymentDetails = values?.paymentModeData.some(
      (item) => item?.pmt_total_amount > 0
    );

    if (values?.submittedButton === "save") {
      if (!hasPaymentDetails) {
        errorToast("Please add payment details.");
        actions.setSubmitting(false);
        return;
      }
      dispatch(
        paymentInCreateAction(
          {
            paymentData: {
              cmr_id: values?.cmr_id,
              cmr_name: values?.cmr_name?.label,
              cmr_code: values?.cmr_code?.label,
              pit_posting_date: values?.pit_posting_date,
              pit_due_date: values?.pit_due_date,
              pit_doc_date: values?.pit_doc_date,
              pit_cmr_amount_paid: amtpaid(values, checkedId, "soit", "pit"),
              pit_current_payment: currentAmt(values, checkedId, "soit", "pit"),
              pit_total_amount: calculateTotalDueAmt(values, checkedId, "soit"),
              pit_add_to_account_amount: Math.abs(
                balanceAmt(values, checkedId, "soit", "pit")?.toFixed(2)
              ),
              payment_journal_remarks: values?.payment_journal_remarks,
              pit_open_balance: values?.pit_open_balance,
            },
            invoicePaymentData: values?.invoices.map((item) => ({
              soit_invoice_number: item?.soit_invoice_number,
              soit_id: item?.id,
              pdt_due_days: item?.pdt_due_days,
              pdt_balance_due: item?.soit_due_amount,
              pdt_total_amount: item?.soit_total_amount,
              pdt_current_payment: item?.soit_total_payment,
              pdt_doc_date: item?.soit_document_date,
              pdt_due_date: item?.soit_delivery_date,
            })),

            paymentModeData: values?.paymentModeData
              ?.filter((item) => item?.pmt_total_amount > 0)
              ?.map((item) => ({
                pmt_mode_name: item?.tab,
                account_id: item?.account_id,
                account_code: item?.account_code,
                account_name: item?.account_name,
                pmt_total_amount: item?.pmt_total_amount,
              })),
          },
          onSuccess,
          onError
        )
      );
    }
  };

  const onSuccess = (data) => {
    successToast(data?.data?.message);
    navigate("/sales-payment-in");
  };
  const onError = (data) => {
    errorToast(data?.data?.message);
  };
  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={initialValue}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ values, errors }) => {

          console.log(values, "fggdgdgdfgdfgdfg");
          return (
            <Form className="h-full">
              {paymentMeans && (
                <PaymentInOutPopover
                  disabled={disabled}
                  setPaymentMeans={setPaymentMeans}
                />
              )}

              <div className="flex flex-col h-full ">
                <div class="first-div px-3 py-1 flex flex-col gap-2">
                  <PaymentInOutHeader
                    section="pit"
                    searchRef={searchRef}
                    disabled={disabled}
                  /> 
                </div>

                <div
                  className="flex flex-col"
                  style={{
                    maxHeight: `calc(100% - ${heightOfFirstDiv}px)`,
                    height: `calc(100% - ${heightOfFirstDiv}px)`,
                  }}
                >
                  <PaymentInOutTable
                    disabled={disabled}
                    checkedId={checkedId}
                    setCheckedId={setCheckedId}
                    tableBodyData={values?.invoices}
                  />

                  <div className="h-[15rem] mt-auto">
                    <PaymentInOutFinalBillTable
                      disabled={disabled}
                      setPaymentMeans={setPaymentMeans}
                      checkedId={checkedId}
                    />
                  </div>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default CreateEditViewPaymentIn;
