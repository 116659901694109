import React from "react";
import InputField from "../../forms/input-field";
import SelectField from "../../forms/select-field";
import ImageField from "../../forms/images-field";

const StorePanGstTab = ({ disabled = false }) => {
  return (
    <div className="w-full 2xl:w-[30%] lg:w-[60%] px-5 xl:px-10">
      <SelectField
        isDisabled={disabled}
        placeholder={"Select pan type"}
        options={panTypeList}
        label={"PAN Type"}
        id={"pan_type"}
        name="pan_type"
        isSearchable={true}
        errMsgContainerClass="ps-2"
        labelWidth="8.5rem"
        isRequired={true}
      />
      <InputField
        isDisabled={disabled}
        isRequired={true}
        type="text"
        name="pan_number"
        placeholder="xxxxxxxxxxx"
        label="PAN Number"
        labelWidth="8.5rem"
      />
      <ImageField
        isDisabled={disabled}
        label="PAN Front"
        isRequired={true}
        labelWidth="8.5rem"
        name="pan_pic_front"
      />
      <ImageField
        isDisabled={disabled}
        label="PAN Back "
        isRequired={true}
        labelWidth="8.5rem"
        name="pan_pic_back"
        mainLabel=""
      />
    </div>
  );
};

export default StorePanGstTab;

const panTypeList = [{ label: "Buisness", value: "buisness" }];
