import React, { useCallback, useContext, useEffect, useState } from "react";
import InnerLayout from "../../components/layouts/inner-layout";
import emptyInvoice from "../../assets/images/empty-invoice.png";
import AddButton from "../../components/buttons/button";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import DynamicTable from "../../components/tables/dynamic-table";
import LoadingSpinner from "../../components/helper-components/loading-spinner";
import {
  productDeleteAction,
  productTableAction,
} from "../../redux/actions/product-action";
import ProductFilter from "../../components/tables/table-filter/product-filter";
import { itemGroupListAction } from "../../redux/actions/general-action";
import errorToast from "../../components/helper-components/error-toast";
import { DataContext } from "../../components/layouts/main-layout";
import { useHotkeys } from "react-hotkeys-hook";

const ProductTable = () => {
  const { permissionFn } = useContext(DataContext);
  const dispatch = useDispatch();
  const [itemGroup, setItemGroup] = useState([]);
  const [itemsList, setItemsList] = useState([]);
  const [dataCount, setDataCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [showFilter, setShowFilter] = useState(false);
  const [reload, setReload] = useState(false);
  const [pageSize] = useState(10);
  const [filterData, setFilterData] = useState({
    sort_by: "",
    sort_order: "",
    selectColumn: "item_name",
    from: "",
    to: "",
    stock: "",
  });

  useEffect(() => {
    dispatch(itemGroupListAction(onitemGroupSuccess, onitemGroupError));
  }, []);

  const onitemGroupSuccess = (data) => {
    setItemGroup(
      data?.data?.itemgrouplist?.map((item) => {
        return { label: item?.itemgroup, value: item?.id };
      })
    );
  };
  const onitemGroupError = (data) => {
    errorToast(data?.message);
  };
  useEffect(() => {
    dispatch(
      productTableAction(
        {
          pageNumber: currentPage,
          pageSize: pageSize,
          filterData,
        },
        onSuccess,
        onError
      )
    );
  }, [currentPage, filterData, reload]);
  const onSuccess = (data) => {
    setError(false);
    setLoading(false);
    setItemsList(data?.data?.getItemsList);
    setDataCount(data?.data?.totalCount);
  };
  const onError = (data) => {
    setError(true);
    setLoading(false);
  };

  const navigate = useNavigate();

  const tableBodyData = itemsList?.map((item) => {
    return {
      id: item?.item_id,
      item_code:
        <span className="fw_500">{item?.item_code ?? "--"}</span> ?? "--",
      item_name: item?.item_name ? item?.item_name : "--",
      item_generic_name: item?.item_generic_name
        ? item?.item_generic_name
        : "--",
      // item_rack_location: item?.item_rack_location ?? "--",
      total_sellable_quantity: item?.total_sellable_quantity ?? "--",
      item_unit_price: (
        <span className="flex justify-end pe-5">
          {"₹ " + parseFloat(item?.item_unit_price ?? 0)?.toFixed(2)}
        </span>
      ),
    };
  });
  const paginationProps = {
    pageSize: pageSize,
    dataCount: dataCount,
    setCurrentPage: setCurrentPage,
    currentPage: currentPage,
  };
  const handleFilterClick = useCallback(() => {
    setShowFilter((prevShowFilter) => !prevShowFilter);
  }, []);

  const filterby = showFilter ? (
    <ProductFilter
      itemGroup={itemGroup}
      filterData={filterData}
      setFilterData={setFilterData}
      setShowFilter={setShowFilter}
    />
  ) : null;
  useHotkeys("escape", () => {
    setShowFilter(false);
  });

  return (
    <InnerLayout
      list={true}
      module="product"
      permissionFn={permissionFn}
      onclkFn={() => navigate("/add-product")}
      title="All Items"
    >
      {loading ? (
        <LoadingSpinner />
      ) : error ? (
        <></>
      ) : (
        <DynamicTable
          onClickItemFn={(id) => navigate(`/view-product/${id}`)}
          list={true}
          onclkFn={() => {
            navigate("/add-product");
          }}
          permissionFn={permissionFn}
          module={"product"}
          path="product"
          id_name="item_id"
          reload={reload}
          setReload={setReload}
          loading={loading}
          setLoading={setLoading}
          deleteAction={productDeleteAction}
          label="Item"
          showFilter={showFilter}
          filterFn={handleFilterClick}
          setFilterData={setFilterData}
          filterData={filterData}
          paginationProps={paginationProps}
          tableHeading={tableHeading}
          tableBodyData={tableBodyData}
          placeholder="Search"
          filterBy={filterby}
          initialFilter={{
            sort_by: "",
            sort_order: "",
            selectColumn: "item_name",
            from: "",
            to: "",
            stock: "",
          }}
        />
      )}
    </InnerLayout>
  );
};

export default ProductTable;
const tableHeading = [
  {
    label: "ITEM CODE",
    value: "item_code",
    display: true,
    column: true,
  },
  {
    label: "ITEM NAME",
    value: "item_name",
    display: true,
    column: true,
  },
  {
    label: "GENERIC NAME",
    value: "item_generic_name",
    display: true,
    column: true,
  },
  // {
  //   label: "LOCATION",
  //   value: "item_rack_location",
  //   display: false,
  //   column: false,
  // },
  {
    label: "Avail. Qty",
    value: "total_sellable_quantity",
    display: false,
    column: false,
  },
  {
    label: "MRP",
    value: "created_date",
    display: true,
    column: false,
    className: "justify-end pe-5",
  },
  {
    label: "",
    value: "",
    column: false,
  },
];
