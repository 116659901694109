import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import "../../components/customer-information/customer-information.css";
import { useDispatch } from "react-redux";
import AddButton from "../../components/buttons/button";
import successToast from "../../components/helper-components/success-toast";
import CheckBox from "../../components/forms/check-box-field";
import errorToast from "../../components/helper-components/error-toast";
import { useNavigate } from "react-router-dom";
import LoginInputField from "../forms/login-input-field";
import LoginSelectField from "../forms/login-select-field";
import {
  RolesListAction,
  departmentListAction,
} from "../../redux/actions/general-action";
import {
  userCreateAction,
  userEditAction,
} from "../../redux/actions/user-action";

const UsersCreateViewEdit = ({ user = {}, disabled = false, page }) => {
  const dispatch = useDispatch();
  const [depList, setDepList] = useState([]);
  const [rolesList, setRolesList] = useState([]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const initialValues = {
    ...(user?.user_id ? { user_id: user?.user_id } : {}),
    user_name: user.user_name || "",
    user_store_id: user.user_store_id || "ST2345",
    user_role: user.user_role || "",
    user_email: user.user_email || "",
    user_phone_number: user.user_phone_number || "",
    user_department: user.user_department || "",
    change_password_next_login: user.change_password_next_login || false,
    password_never_expires: user.password_never_expires || false,
  };
  if (page === "edit") {
    initialValues.update_password = false;
    initialValues.old_user_password = "";
    initialValues.new_user_password = "";
    initialValues.confirm_new_user_password = "";
  } else if (page === "create") {
    initialValues.user_password = "";
    initialValues.confirmPassword = "";
  }

  const validationSchema = Yup.object().shape({
    user_name: Yup.string().required("Username is required"),
    user_role: Yup.string().required("Role is required"),
    user_department: Yup.string(),
    user_phone_number: Yup.string()
      .matches(/^\d{10}$/, "Invalid phone number")
      .required("Mobile Number is required"),
    user_email: Yup.string().email("Invalid email address"),
    user_password:
      page === "create"
        ? Yup.string().required("Password is required")
        : Yup.string(),
    confirmPassword:
      page === "create"
        ? Yup.string()
            .oneOf([Yup.ref("user_password"), null], "Passwords must match")
            .required("Confirm Password is required")
        : Yup.string(),
  });
  const onSubmit = (values) => {
    setLoading(true);
    if (page === "create") {
      const { confirmPassword, ...formData } = values;
      dispatch(userCreateAction(formData, onSuccess, onError));
    } else {
      const { confirm_new_user_password, update_password, ...formData } =
        values;
      if (!update_password) {
        delete formData.old_user_password;
        delete formData.new_user_password;
      }
      dispatch(userEditAction(formData, onSuccess, onError));
    }
  };

  const onSuccess = (data) => {
    setLoading(false);
    navigate("/user");
    successToast(data?.message);
  };
  const onError = (data) => {
    setLoading(false);
    errorToast(data?.data?.message);
  };

  useEffect(() => {
    dispatch(departmentListAction(onSuccessDep, onErrorDep));
    dispatch(RolesListAction(onSuccessRoles, onErrorRoles));
  }, []);
  const onSuccessDep = (data) => {
    setDepList(
      data?.data?.map((item) => ({
        label: item?.department_name,
        value: item?.department_name,
      }))
    );
  };
  const onErrorDep = (data) => {};
  const onSuccessRoles = (data) => {
    setRolesList(
      data?.data?.map((item) => ({
        label: item?.role_name,
        value: item?.role_name,
      }))
    );
  };
  const onErrorRoles = (data) => {};

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ values }) => {
        return (
          <Form className="h-full flex justify-center">
            <div className="2xl:w-[25%] lg:w-[40%] p-5">
              <LoginInputField
                isDisabled={disabled}
                type="text"
                name="user_name"
                placeholder="Enter your username"
                label="Username"
                isRequired={true}
              />
              <LoginSelectField
                isDisabled={disabled}
                options={rolesList}
                name="user_role"
                placeholder={"Select role"}
                isRequired={true}
                label={"Role"}
              />
              <LoginSelectField
                isDisabled={disabled}
                options={depList}
                name="user_department"
                placeholder={"Select department"}
                isRequired={false}
                label={"Department"}
              />
              <LoginInputField
                isDisabled={disabled}
                type="number"
                name="user_phone_number"
                placeholder="Enter your Mobile Number"
                label="Mobile Number"
                isRequired={true}
              />
              <LoginInputField
                isDisabled={disabled}
                type="email"
                name="user_email"
                placeholder="Enter your email"
                label="Email"
                isRequired={false}
              />
              {page === "edit" && (
                <CheckBox conClass="mb-5" items={updatePass} />
              )}
              {page === "edit" && values?.update_password && (
                <>
                  <LoginInputField
                    type="password"
                    name="old_user_password"
                    placeholder="Enter your old password"
                    label="Old Password"
                    isRequired={true}
                    isPasswordEye={true}
                  />
                  <LoginInputField
                    type="password"
                    name="new_user_password"
                    placeholder="Enter your new password"
                    label="New Password"
                    isRequired={true}
                    isPasswordEye={true}
                  />
                  <LoginInputField
                    type="password"
                    name="confirm_new_user_password"
                    placeholder="Confirm your new password"
                    label="Confirm New Password"
                    isRequired={true}
                    isPasswordEye={true}
                  />
                </>
              )}
              {page === "create" && (
                <>
                  <LoginInputField
                    type="password"
                    name="user_password"
                    placeholder="Enter your password"
                    label="Password"
                    isRequired={true}
                    isPasswordEye={true}
                  />
                  <LoginInputField
                    type="password"
                    name="confirmPassword"
                    placeholder="Confirm your password"
                    label="Confirm Password"
                    isRequired={true}
                    isPasswordEye={true}
                  />
                </>
              )}
              <CheckBox
                isDisabled={disabled}
                conClass="flex flex-col gap-3 mb-5"
                items={items}
              />

              {page !== "view" && (
                <AddButton
                  loading={loading}
                  prefixIcon={false}
                  className="bg-primary text-white w-full py-2 rounded flex justify-center fw_500 fs_16"
                  type="submit"
                  text={`${page === "create" ? "Create User" : "Update User"}`}
                />
              )}
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default UsersCreateViewEdit;
const items = [
  {
    label: "Password Never Expires",
    name: "password_never_expires",
    isRequired: false,
  },
  {
    label: "Change Password at Next Login",
    name: "change_password_next_login",
    isRequired: false,
  },
];

const updatePass = [
  {
    label: "Update Password",
    name: "update_password",
    isRequired: false,
  },
];
