import React from "react";

const StarIcon = () => {
  return (
    <svg
      width="7"
      height="8"
      viewBox="0 0 7 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.55682 7.40909L2.66477 5.01136L0.642045 6.3125L0.0227273 5.23295L2.17045 4.13636L0.0227273 3.03977L0.642045 1.96023L2.66477 3.26136L2.55682 0.863636H3.78977L3.68182 3.26136L5.70455 1.96023L6.32386 3.03977L4.17614 4.13636L6.32386 5.23295L5.70455 6.3125L3.68182 5.01136L3.78977 7.40909H2.55682Z"
        fill="#F94B4B"
      />
    </svg>
  );
};

export default StarIcon;
