import React, { useState } from "react";
import TaxTypeTable from "./tax-type-table";
import TaxCombinationTable from "./tax-combination-table";
import InnerLayout from "../../../../layouts/inner-layout";
import { useNavigate } from "react-router-dom";
import { ChevronLeftIcon } from "@heroicons/react/24/outline";
import search from "../../../../../assets/icons/searchIcon.svg";
import TaxCombination from "./tax-combination-view";

const TaxSetup = () => {
    const [activeTab, setActiveTab] = useState("Tax Type");
    const [searchValue, setSearchValue] = useState('');
    const [showAddEdit, setShowAddEdit] = useState(false)
    const navigate = useNavigate()
    return (
        <InnerLayout
            list={true}
            title="Settings"
            module="tax-setup"
        // permissionFn={permissionFn}
        >
            <div className="my-2 pl-2">
                <div className='flex justify-between pt-3'>
                    <div className="pl-2 pb-4 flex items-center gap-2">
                        <button
                            className="flex items-center gap-2"
                            onClick={() => {
                                navigate('/settings')
                            }}
                        >
                            <ChevronLeftIcon className="h-5 w-5 inline-block" />
                            Settings /
                        </button>
                        <span className="cursor-pointer" >{" " + "Tax Setup"}</span>
                    </div>
                    <div className="flex">
                        <div
                            style={{ minWidth: "18rem", height: "2.5rem" }}
                            className={`input_border flex items-center rounded-md pb-1 w-10 mx-6`}
                        >
                            <span className="px-2">
                                {" "}
                                <img width={"16px"} src={search} alt="search icon" />
                            </span>
                            <input
                                placeholder={`Search here`}
                                className=" table_search_input w-full rounded-md fs_12 placeholder:text-fs_12"
                                type="text"
                                value={searchValue}
                                onChange={(e) => {
                                    setSearchValue(e?.target?.value);
                                }}
                            />
                            <span className='fs_14 mx-3 cursor-pointer text-primary' onClick={() => setSearchValue('')} >Clear</span>
                        </div>
                        {activeTab === "Tax Combination" &&
                            <div className="px-4">
                                <button type="submit" className="bg-primary text-white px-6 py-2 rounded-md flex justify-center fw_500 fs_16 whitespace-nowrap" onClick={() => setShowAddEdit(true)}>
                                    <span className='mx-1'>+ New</span>
                                </button>
                            </div>
                        }
                    </div>

                </div>
                <div className="flex" style={{ borderBottom: 'solid 2px lightgrey', borderBottomColor: 'lightgrey' }}>
                    {tablist?.map((item, index) => (
                        <div className="text-center" style={{ width: "200px" }}>
                            <button
                                onClick={() => setActiveTab(item)}
                                className={
                                    activeTab === item
                                        ? "active-tab-style p-3 px-5 w-100"
                                        : "p-3 px-5 w-100"
                                }
                            >
                                {item}
                            </button>
                            {activeTab === item && (
                                <hr
                                    className="mx-auto"
                                    style={{ width: "80%", border: "2px solid #277DFE" }}
                                />
                            )}
                        </div>
                    ))}
                </div>
                {
                    activeTab === "Tax Type" && (
                        <div>
                            <TaxTypeTable searchValue={searchValue} />
                        </div>
                    )
                }
                {
                    activeTab === "Tax Combination" && (
                        <div>
                            {showAddEdit ? <TaxCombination isView={false} editingData={null} toogleChange={() => setShowAddEdit(!showAddEdit)} reloadData={() => setShowAddEdit(!showAddEdit)} /> :
                                <TaxCombinationTable searchValue={searchValue} />
                            }
                        </div>
                    )
                }
            </div >

        </InnerLayout>
    );
};

export default TaxSetup;

const tablist = ["Tax Type", "Tax Combination"];
