import {
  CUSTOMER_CREATE,
  CUSTOMER_DELETE,
  CUSTOMER_EDIT,
  CUSTOMER_TABLE,
  CUSTOMER_VIEW,
} from "../types";

export const customerCreateAction = (data, onSuccess, onError) => {
  return {
    type: CUSTOMER_CREATE,
    data,
    onSuccess,
    onError,
  };
};

export const customerTableAction = (data, onSuccess, onError) => {
  return {
    type: CUSTOMER_TABLE,
    data,
    onSuccess,
    onError,
  };
};

export const customerViewAction = (data, onSuccess, onError) => {
  return {
    type: CUSTOMER_VIEW,
    data,
    onSuccess,
    onError,
  };
};
export const customerEditAction = (data, onSuccess, onError) => {
  return {
    type: CUSTOMER_EDIT,
    data,
    onSuccess,
    onError,
  };
};
export const customerDeleteAction = (data, onSuccess, onError) => {
  return {
    type: CUSTOMER_DELETE,
    data,
    onSuccess,
    onError,
  };
};
