import {
  FINANCIAL_ACCOUNT_ADD,
  FINANCIAL_ACCOUNT_DELETE,
  FINANCIAL_ACCOUNT_GET,
  FINANCIAL_ACCOUNT_UPDATE,
  FINANCIAL_ACC_ALLOCATION_UPDATE,
  FINANCIAL_ACC_LIST,
  FINANCIAL_ALL_ACC,
  FINANCIAL_PERIOD_SELECTION,
  JOURNAL_ENTRY_FETCH,
  JOURNAL_ENTRY_UPDATE,
  JOURNAL_ENTRY_VIEW,
} from "../types";

export const financialAccountAddAction = (data, onSuccess, onError) => {
  return {
    type: FINANCIAL_ACCOUNT_ADD,
    data,
    onSuccess,
    onError,
  };
};
export const financialAccountUpdateAction = (data, onSuccess, onError) => {
  return {
    type: FINANCIAL_ACCOUNT_UPDATE,
    data,
    onSuccess,
    onError,
  };
};
export const financialAccountDeleteAction = (data, onSuccess, onError) => {
  return {
    type: FINANCIAL_ACCOUNT_DELETE,
    data,
    onSuccess,
    onError,
  };
};
export const financialAccountGetAction = (onSuccess, onError) => {
  return {
    type: FINANCIAL_ACCOUNT_GET,
    onSuccess,
    onError,
  };
};

export const financialPeriodSelectionAction = (onSuccess, onError) => {
  return {
    type: FINANCIAL_PERIOD_SELECTION,
    onSuccess,
    onError,
  };
};

export const financialAllAccountAction = (data, onSuccess, onError) => {
  return {
    type: FINANCIAL_ALL_ACC,
    data,
    onSuccess,
    onError,
  };
};

export const financialAccListAction = (data, onSuccess, onError) => {
  return {
    type: FINANCIAL_ACC_LIST,
    data,
    onSuccess,
    onError,
  };
};

export const financialAccAllocUpdateAction = (data, onSuccess, onError) => {
  return {
    type: FINANCIAL_ACC_ALLOCATION_UPDATE,
    data,
    onSuccess,
    onError,
  };
};
export const journalEntryFetchAction = (data, onSuccess, onError) => {
  return {
    type: JOURNAL_ENTRY_FETCH,
    data,
    onSuccess,
    onError,
  };
};
export const journalEntryUpdateAction = (data, onSuccess, onError) => {
  return {
    type: JOURNAL_ENTRY_UPDATE,
    data,
    onSuccess,
    onError,
  };
};
export const journalEntryViewAction = (data, onSuccess, onError) => {
  return {
    type: JOURNAL_ENTRY_VIEW,
    data,
    onSuccess,
    onError,
  };
};
