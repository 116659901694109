import { ErrorMessage } from "formik";
import React, { Fragment } from "react";

export default function ErrorMsg({
  containerClass = "",
  name = "",
  textClass = "text-F94B4B fs_12",
  isContainer = true,
  isDot = true,
  customStyle = { minHeight: "1.32rem" },
}) {
  const Container = isContainer ? "div" : Fragment;
  return (
    <Container style={{ ...customStyle }} className={`${containerClass} flex justify-end `}>
      <ErrorMessage name={name}>
        {(msg) => (
          <>
            <span role="alert" className={`${textClass} text-F94B4B`}>
              {isDot && <span className="fs_14 me-1 fw_600">&middot;</span>}
              {"" + msg + "!"}
            </span>
          </>
        )}
      </ErrorMessage>{" "}
    </Container>
  );
}
