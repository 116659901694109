import { Form, Formik } from "formik";
import React, { useState } from "react";
import * as Yup from "yup";
import InputField from "../forms/input-field";

import { useDispatch } from "react-redux";

import successToast from "../helper-components/success-toast";
import errorToast from "../helper-components/error-toast";
import AddButton from "../buttons/button";
import { ImageField1 } from "../forms/images-field";
import CheckBox from "../forms/check-box-field";
import { addStrInfraAction } from "../../redux/actions/store-action";
import moment from "moment";

const InfraDetailsTab = ({
  initialValue,
  id,
  permissionFn = () => {},
  setCurrentTab,
  reload,
  setReload,
}) => {
  const dispatch = useDispatch();
  const initialValues = {
    store_id: id,
    completion_date: initialValue?.completion_date
      ? moment(initialValue?.completion_date).format("YYYY-MM-DD")
      : "",
    shop_pic_1: initialValue?.shop_pics?.[0] ?? null,
    shop_pic_2: initialValue?.shop_pics?.[1] ?? null,
    shop_pic_3: initialValue?.shop_pics?.[2] ?? null,
    shop_pic_4: initialValue?.shop_pics?.[3] ?? null,
    shop_pic_5: initialValue?.shop_pics?.[4] ?? null,
    colour_pre_applied: initialValue?.colour_pre_applied ?? false,
    furniture_pre_applied: initialValue?.furniture_pre_applied ?? false,
  };

  const validationSchema = Yup.object().shape({
    completion_date: Yup.date().required("Completion date is required"),
  });
  const onSubmit = (values, actions) => {
    const shopPicsArray = [
      values?.shop_pic_1 ?? null,
      values?.shop_pic_2 ?? null,
      values?.shop_pic_3 ?? null,
      values?.shop_pic_4 ?? null,
      values?.shop_pic_5 ?? null,
    ].filter(pic => pic !== null);  // Filter out any null values
  
    // Constructing final values object
    const finalValues = {
      store_id: values?.store_id,
      completion_date: values?.completion_date ? values?.completion_date : "",
      shop_pics: shopPicsArray,
      colour_pre_applied: values?.colour_pre_applied ?? false,
      furniture_pre_applied: values?.furniture_pre_applied ?? false,
    };
    dispatch(addStrInfraAction(finalValues, onSuccess, onError));
  };

  const onSuccess = (data) => {
    successToast(data?.message);
    setCurrentTab("Branding Details");
    setReload(!reload);
  };
  const onError = (data) => {
    errorToast(data?.message);
  };

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ values, setFieldValue, isSubmitting, errors }) => {
        return (
          <Form className="flex-grow pt-2">
            <div className="flex flex-col justify-between h-full ">
              <div>
                <div className="w-full 2xl:w-[35%] lg:w-[60%] px-5 xl:px-10">
                  <InputField
                    type="date"
                    name="completion_date"
                    placeholder="select completion date"
                    label="Completion Date"
                    isRequired={true}
                    labelWidth="10.5rem"
                  />
                </div>
                <div className="px-5 xl:px-10">
                  <label className="secondary-color fs_14 fw_600">
                    Shop Pictures
                  </label>
                  <div className="flex gap-5 flex-wrap">
                    <div>
                      <ImageField1
                        label="Chalan Receipt"
                        isRequired={false}
                        labelWidth=""
                        name="shop_pic_1"
                        mainLabel=""
                      />
                    </div>

                    <div>
                      <ImageField1
                        label="Chalan Receipt"
                        isRequired={false}
                        labelWidth=""
                        name="shop_pic_2"
                        mainLabel=""
                      />
                    </div>

                    <div>
                      <ImageField1
                        label="Chalan Receipt"
                        isRequired={false}
                        labelWidth=""
                        name="shop_pic_3"
                        mainLabel=""
                      />
                    </div>

                    <div>
                      <ImageField1
                        label="Chalan Receipt"
                        isRequired={false}
                        labelWidth=""
                        name="shop_pic_4"
                        mainLabel=""
                      />
                    </div>

                    <div>
                      <ImageField1
                        label="Chalan Receipt"
                        isRequired={false}
                        labelWidth=""
                        name="shop_pic_5"
                        mainLabel=""
                      />
                    </div>
                  </div>
                  <div className="flex gap-5 mt-5 pt-5 flex-wrap">
                    <div className="border_D0D5DD_1 py-2 px-2 rounded-lg">
                      <CheckBox items={items} />
                    </div>
                    <div className="border_D0D5DD_1 py-2 px-2 rounded-lg">
                      <CheckBox items={items1} />
                    </div>
                  </div>
                </div>
                <div></div>
              </div>
              <div
                style={{
                  bottom: "0",
                }}
                className="flex justify-end px-10 mb-5x sticky bg-white py-4 invoice-bottom-container"
              >
                <AddButton prefixIcon={false} text="Save" type="submit" />
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default InfraDetailsTab;
const items = [{ label: "Colour Pre Applied", name: "colour_pre_applied" }];
const items1 = [
  { label: "Furniture Pre Applied", name: "furniture_pre_applied" },
];
