import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment } from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import AddButton, { DraftButton } from "../../buttons/button";
import CloseIcon from "../../../assets/icons/close-icon";
import InputField from "../../forms/input-field";
import CheckBox from "../../forms/check-box-field";
import { useDispatch } from "react-redux";
import { priceListItemEditAction } from "../../../redux/actions/discount-action";
import successToast from "../../helper-components/success-toast";
import errorToast from "../../helper-components/error-toast";

const UpdateDisModal = ({
  isModalOpen = false,
  setIsModalOpen,
  checkedId,
  setReload,
  reload,
  priceListData,
}) => {
  const dispatch = useDispatch();
  const initialValues = {
    is_manual: false,
    item_selling_price: "",
  };
  const validationSchema = Yup.object().shape({});
  const innerOnSubmit = (values) => {
    dispatch(
      priceListItemEditAction(
        {
          itemData: checkedId?.map((item) => {
            return {
              item_id: item,
              price_list_id: priceListData?.pricelist?.price_list_id,
              is_manual: values?.is_manual,
              item_selling_price: values?.item_selling_price,
            };
          }),
        },
        onSuccess,
        onError
      )
    );
  };
  const onSuccess = (data) => {
    successToast(data?.data?.message);
    setReload(!reload);
  };
  const onError = (data) => {
    errorToast(data?.data?.message);
    
  };
  return (
    <Transition show={isModalOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-50 overflow-y-auto"
        onClose={() => setIsModalOpen(false)}
      >
        <div className="flex items-center justify-center min-h-screen px-4 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-100"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black opacity-60" />
          </Transition.Child>

          {/* This is your modal container */}
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4"
            enterTo="opacity-100 translate-y-0"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-4"
          >
            <div className="mx-auto w-full  max-w-2xl my-8  text-left align-middle transition-all transform bg-white shadow-xl rounded-xl">
              <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={innerOnSubmit}
              >
                {({ handleSubmit, values }) => {
                  return (
                    <>
                      <div className="w-full">
                        <div className="flex justify-between px-6  py-5">
                          <h2 className="fw_500 ">Update Item PriceList</h2>
                          <button
                            className="close-button bg-transparent border-none p-0 hover:bg-gray-200"
                            onClick={() => setIsModalOpen(false)}
                          >
                            <CloseIcon />
                          </button>
                        </div>
                        <div className="px-6 w-[60%] py-5">
                          <InputField
                            type="number"
                            name="item_selling_price"
                            placeholder="Enter Selling Price"
                            label="Selling Price"
                            isRequired={true}
                            labelWidth="7rem"
                          />
                          <CheckBox conClass="flex gap-4" items={itemoptions} />
                        </div>
                        <div className="px-6 flex justify-between gap-4 py-5">
                          <DraftButton
                            onclkFn={() => setIsModalOpen(false)}
                            text="Cancel"
                          />
                          <AddButton
                            onclkFn={handleSubmit}
                            type="submit"
                            className="bg-primary text-white px-8 py-2 rounded flex justify-center fw_500 fs_16"
                            text="Done"
                            prefixIcon={false}
                          />
                        </div>
                      </div>
                    </>
                  );
                }}
              </Formik>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export default UpdateDisModal;

const itemoptions = [
  {
    label: "Manual",
    name: "is_manual",
    isRequired: false,
  },
];
