import React, { useContext, useEffect, useState } from "react";
import InnerLayout from "../../components/layouts/inner-layout";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import LoadingSpinner from "../../components/helper-components/loading-spinner";
import { strExeGetAction } from "../../redux/actions/store-action";
import { DataContext } from "../../components/layouts/main-layout";
import StoreExeViewEdit from "../../components/store-execution/edit-view-store-exe";
const EditStoreExecution = () => {
  const { permissionFn } = useContext(DataContext);
  const dispatch = useDispatch();
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [exeData, setExeData] = useState({});
  const [reload, setReload] = useState(false);

  useEffect(() => {
    dispatch(strExeGetAction(id, onSuccess, onError));
  }, [reload]);
  const onSuccess = (data) => {
    console.log(data, "gdfgdgdgdfgfdg");
    setLoading(false);
    setExeData(data?.data?.[0]);
  };
  const onError = (data) => {
    loading(false);
  };
  return (
    <>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <InnerLayout
          title={"Edit Store-Execution"}
          title1={id}
          view={true}
          onclkEditFn={() => navigate(`/edit-store/${id}`)}
        >
          <StoreExeViewEdit
            id={id}
            setReload={setReload}
            reload={reload}
            permissionFn={permissionFn}
            exeData={exeData}
          />
        </InnerLayout>
      )}
    </>
  );
};

export default EditStoreExecution;
