import React, { useContext, useEffect, useRef, useState } from "react";
import InnerLayout from "../../components/layouts/inner-layout";
import SettingsItemContainer from "../../components/settings/settings-item-container";
import search from "../../assets/icons/searchIcon.svg";
import settingsData from "./settings-data";
import { ChevronLeftIcon } from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";
import { DataContext } from "../../components/layouts/main-layout";

const Settings = () => {
  const [selectedIndex, setSelectedIndex] = useState(0); // State to track the selected quick link
  const reportRefs = useRef([]);

  const { permissionFn } = useContext(DataContext);
  const [currentSettings, setCurrentSettings] = useState(settingsData);
  const [selectedSetting, setSelectedSetting] = useState(null);
  const [selectedComponent, setSelectedComponent] = useState(null);
  const navigate = useNavigate();
  const handleItemClick = (index, settings) => {
    setCurrentSettings([]);
    if (settings.input) {
      setSelectedComponent(settings.input);
    } else {
      navigate(settings.path);
    }
    setSelectedSetting(settings);

    // const element = document.querySelector(".innerLayoutScroller");
    // if (element.style.overflow === "hidden" && element) {
    //   element.style.overflow = "auto";
    // }
  };

  const handleBackButtonClick = () => {
    setCurrentSettings(settingsData);
    setSelectedSetting(null);
  };

  const SearchBar = () => {
    const [searchValue, setSearchValue] = useState("");
    const [searchResults, setSearchResults] = useState([]);

    useEffect(() => {
      const element = document?.querySelector(".innerLayoutScroller");
      if (searchValue && element) {
        const results = settingsData
          .map((setting) => {
            const childrens = setting.childrens.filter((child) =>
              child.title.toLowerCase().includes(searchValue.toLowerCase())
            );
            if (childrens.length > 0) {
              return {
                childrens,
              };
            }
          })
          .filter((result) => result !== undefined)
          .reduce((acc, curr) => {
            return [...acc, ...curr.childrens];
          }, []);

        element.style.overflow = "hidden";

        setSearchResults(results.slice(0, 15));
      } else if (element) {
        element.style.overflow = "auto";
      }
    }, [searchValue]);

    return (
      <>
        {searchValue && (
          <div
            className="h-[120%] -mt-5 w-full bg-[#0000005a] absolute z-0 "
            onClick={() => {
              setSearchValue("");
            }}
          ></div>
        )}

        <div className="w-[calc(100%-60px)] mx-[30px] bg-[#F9F9F9] mt-8 mb-4 p-5 rounded-lg absolute">
          <div
            style={{ height: "2.5rem" }}
            className={`input_border flex items-center rounded-md w-full bg-white`}
          >
            <span className="px-2">
              <img width={"16px"} src={search} alt="search icon" />
            </span>
            <input
              placeholder={`Search settings...`}
              className=" table_search_input w-full rounded-md fs_12 placeholder:text-fs_12"
              type="text"
              onChange={(e) => {
                setTimeout(() => {
                  setSearchValue(e.target.value);
                }, 500);
              }}
              onFocus={(e) => {
                setSearchValue(e.target.value);
              }}
            />
          </div>
          {searchValue &&
            (searchResults.length > 0 ? (
              <div className="mt-5 grid grid-cols-3 gap-3 resultContainer">
                {searchResults.map((result, index) => {
                  return (
                    <>
                      {permissionFn(result?.module_name)?.all && (
                        <SettingsItemContainer
                          key={index}
                          setting={result}
                          search
                          handleItemClick={handleItemClick}
                        />
                      )}
                    </>
                  );
                })}
              </div>
            ) : (
              <div className="text-center mt-5 text-black">
                No results found
              </div>
            ))}
        </div>
      </>
    );
  };

  useEffect(() => {
    reportRefs.current = currentSettings.map(
      (_, i) => reportRefs.current[i] ?? React.createRef()
    );
  }, [currentSettings]);

  const handleQuickLinkClick = (index) => {
    setSelectedIndex(index); // Update the selected index

    const element = reportRefs.current[index]?.current;
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  useEffect(() => {
    const handleHashChange = () => {
      const hash = window.location.hash;
      if (hash.startsWith("#report-")) {
        const index = hash.replace("#report-", "");
        const element = reportRefs.current[index]?.current;
        if (element) {
          element.scrollIntoView({ behavior: "smooth", block: "start" });
        }
      }
    };

    window.addEventListener("hashchange", handleHashChange, false);

    handleHashChange();

    return () => {
      window.removeEventListener("hashchange", handleHashChange, false);
    };
  }, []);

  return (
    <InnerLayout title="Settings">
      <div className="h-full">
        <nav className="sticky top-0 z-30   bg-f3f8ff quick-div flex justify-center px-[3rem] w-full ">
          <ul className="flex gap-8 py-4 overflow-x-auto w-full  justify-center">
            {currentSettings?.map((set, setIndex) => {
              if (
                set?.childrens.some(
                  (item) => permissionFn(item?.module_name)?.all === true
                )
              )
                return (
                  <li>
                    <button
                      onClick={() => handleQuickLinkClick(setIndex)}
                      className={`text-nowrap cursor-pointer bg-transparent fs_14 fw_400 text-000000 border-none hover:text-primary hover:underline   ${
                        selectedIndex === setIndex
                          ? "text-primary font-bold underline"
                          : "text-blue-500"
                      }`}
                    >
                      {set.title}
                    </button>
                  </li>
                );
            })}
          </ul>
        </nav>

        {selectedSetting && (
          <div className="pl-6 pt-6 flex items-center gap-2">
            <button
              className="flex items-center gap-2"
              onClick={() => {
                handleBackButtonClick();
                setSelectedComponent(null);
              }}
            >
              <ChevronLeftIcon className="h-5 w-5 inline-block" />
              Settings /
            </button>
            <span>{" " + selectedSetting.title}</span>
          </div>
        )}
        <div className="w-full flex gap-10 relative h-[calc(100%-60px)]">
          {!selectedSetting && <SearchBar />}
          <div
            className={`flex flex-wrap ml-3 w-full  ${
              currentSettings.length > 0 ? "mt-32" : "mt-5"
            }`}
          >
            {currentSettings.map((setting, settingIndex) => (
              <div
                ref={reportRefs.current[settingIndex]}
                key={settingIndex}
                id={`report-${settingIndex}`} /* Add this id to target scroll position */
                className="w-full my-5"
              >
                {setting?.childrens?.length > 0 ? (
                  <>
                    {setting?.childrens.some(
                      (item) => permissionFn(item?.module_name)?.all === true
                    ) && (
                      <div className=" max-w-[1200px] m-auto w-full">
                        <div className="font-semibold text-lg">
                          {setting.title}
                        </div>
                        <div className="grid grid-cols-3 gap-3">
                          {setting?.childrens?.map((child, childIndex) => {
                            return (
                              <>
                                {permissionFn(child?.module_name)?.all && (
                                  <SettingsItemContainer
                                    key={childIndex}
                                    handleItemClick={(
                                      index,
                                      clickedSetting
                                    ) => {
                                      handleItemClick(index, clickedSetting);
                                    }}
                                    setting={child}
                                    index={childIndex}
                                  />
                                )}
                              </>
                            );
                          })}
                        </div>
                      </div>
                    )}
                  </>
                ) : (
                  <div className="">
                    <SettingsItemContainer
                      key={settingIndex}
                      handleItemClick={() => {
                        handleItemClick(settingIndex, setting);
                        setSelectedComponent(setting.input);
                      }}
                      setting={setting}
                      index={settingIndex}
                    />
                  </div>
                )}
              </div>
            ))}
            <div className="w-full">{selectedComponent}</div>
          </div>
        </div>
      </div>
    </InnerLayout>
  );
};

export default Settings;
