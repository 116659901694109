import "./App.css";
import Index from "./routers";
import { Provider } from "react-redux";
import store from "./redux/store";
import { useEffect } from "react";

function App() {
  useEffect(() => {
    const ipcRenderer = window.electron?.ipcRenderer;
  
    console.log('ipcRenderer:', ipcRenderer);
  
    if (ipcRenderer) {
      // Check if ipcRenderer has the 'on' method
      if (typeof ipcRenderer.on !== 'function') {
        console.error('ipcRenderer.on is not a function');
        return;
      }
  
      const handleUpdateAvailable = () => {
        alert('Update available! Click "OK" to download the update.');
        ipcRenderer.send("download-update");
      };
  
      const handleUpdateDownloaded = () => {
        alert('Update downloaded! Click "OK" to restart and install.');
        ipcRenderer.send("install-update");
      };
  
      ipcRenderer.on("update_available", handleUpdateAvailable);
      ipcRenderer.on("update_downloaded", handleUpdateDownloaded);
  
      // Cleanup listeners on component unmount
      return () => {
        if (ipcRenderer) {
          ipcRenderer.removeListener("update_available", handleUpdateAvailable);
          ipcRenderer.removeListener("update_downloaded", handleUpdateDownloaded);
        }
      };
    } else {
      console.error("ipcRenderer is not available");
    }
  }, []);
  

  return (
    <Provider store={store}>
      <Index />
    </Provider>
  );
}

export default App;
