import { api } from "./api";

export const customerCreateApi = (params) => {
  return api.post(`/customer/add`, JSON.stringify(params));
};

export const customerTableApi = (params) => {
  return api.get(
    `/customer/get/customerlist?pageNumber=${params?.pageNumber}&pageSize=${
      params?.pageSize
    }&sortBy=${params?.filterData?.sort_by}&sortOrder=${
      params?.filterData?.sort_order
    }&fromDate=${params?.filterData?.from}&toDate=${params?.filterData?.to}${
      params?.filterData?.inputValue &&
      `&searchColumn=${params?.filterData?.selectColumn}&searchValue=${params?.filterData?.inputValue}`
    }&cmr_active_status=${
      params?.filterData?.customer_status === "active"
        ? true
        : params?.filterData?.customer_status === "inactive"
        ? false
        : ""
    }`,
    JSON.stringify(params)
  );
};

export const customerViewApi = (params) => {
  return api.get(
    `/customer/getCustomerById?cmr_id=${params}`,
    JSON.stringify(params)
  );
};
export const customerEditApi = (params) => {
  return api.post(`/customer/update`, JSON.stringify(params));
};
export const customerDeleteApi = (params) => {
  return api.post(`/customer/delete`, JSON.stringify(params));
};
