import React from "react";

const LowStockTable = ({ lowStockPro = [] }) => {

  const getProgressBarColor = (quantity) => {
    if (quantity < 10) return "bg-red";
    if (quantity >= 10 && quantity < 50) return "bg-orange";
    return "bg-green";
  };
  return (
    <>
      <h2 className="text-center fw_500 text-000000">Low Stock Products</h2>
      <table className="w-full border-D0D5DD border border-1 rounded-lg mt-3">
        <thead>
          <tr className="bg-F6F5F9">
            <th className="py-2 px-3 w-1/2 border-b-D0D5DD border-b  text-left  text-475467 fw_400 fs_12">
              Product Name
            </th>{" "}
            <th className="py-2 px-3 border-b-D0D5DD border-b text-left  text-475467 fw_400 fs_12">
              Quantity
            </th>
          </tr>
        </thead>
        <tbody>
          {lowStockPro?.map((item) => (
            <tr>
              <td className=" py-4 px-3 text-secondary border-b-D0D5DD border-b fw_400 fs_12">
                {item?.name}
              </td>
              <td className="p-3 border-b border-[#D0D5DD] fs_12">
                <div className="flex items-center">
                  <span className="mr-3">{item?.quantity}</span>
                  <div className="relative w-full h-2 rounded-full bg-[#EFEFEF]">
                    <div
                      className={`absolute top-0 left-0 h-full rounded-full transition-width duration-300 ${getProgressBarColor(
                        item?.quantity
                      )}`}
                      style={{ width: `${item?.quantity}%` }}
                    />
                  </div>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

export default LowStockTable;
