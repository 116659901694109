import {
  UPDATE_PERMISSIONS,
  USER_CREATE,
  USER_DELETE,
  USER_EDIT,
  USER_PERMISSIONS,
  USER_TABLE,
  USER_VIEW,
} from "../types";

export const userCreateAction = (data, onSuccess, onError) => {
  return {
    type: USER_CREATE,
    data,
    onSuccess,
    onError,
  };
};

export const userTableAction = (data, onSuccess, onError) => {
  return {
    type: USER_TABLE,
    data,
    onSuccess,
    onError,
  };
};

export const userViewAction = (data, onSuccess, onError) => {
  return {
    type: USER_VIEW,
    data,
    onSuccess,
    onError,
  };
};
export const userEditAction = (data, onSuccess, onError) => {
  return {
    type: USER_EDIT,
    data,
    onSuccess,
    onError,
  };
};
export const userDeleteAction = (data, onSuccess, onError) => {
  return {
    type: USER_DELETE,
    data,
    onSuccess,
    onError,
  };
};
export const userPermissionsAction = (data, onSuccess, onError) => {
  return {
    type: USER_PERMISSIONS,
    data,
    onSuccess,
    onError,
  };
};
export const updatePermissionsAction = (data, onSuccess, onError) => {
  return {
    type: UPDATE_PERMISSIONS,
    data,
    onSuccess,
    onError,
  };
};
