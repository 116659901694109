import { Formik } from "formik";
import React from "react";
import AddButton, { DraftButton } from "../../buttons/button";
import InputField from "../../forms/input-field";
import * as Yup from "yup";
import SelectField from "../../forms/select-field";
import CheckBox from "../../forms/check-box-field";

const SelectionCriteriaPopover = ({
  setSelectionCriteria,
  setFilterData,
  filterData,
}) => {
  const initialValues = {
    item_from: "",
    item_to: "",
  };

  const validationSchema = Yup.object().shape({
    item_from: Yup.string().required("Item from is required"),
    item_to: Yup.string().required("Item to is required"),
  });

  const onSubmit = (values) => {
    setFilterData({
      ...filterData,
      item_from: values?.item_from,
      item_to: values?.item_to,
    });
    setSelectionCriteria(false)
  };
  return (
    <div className="absolute w-[-webkit-fill-available]  z-10">
      <div className="xl:h-[55vh] h-[85vh] bg-white overflow-auto">
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ handleSubmit }) => {
            return (
              <div className="flex flex-col justify-between h-full">
                <div>
                  <div className="bg-[#F4F6F9] w-full p-4 font-semibold text-xl-mb-5">
                    Price List Selection Criteria
                  </div>
                  <div className="flex flex-wrap mt-5">
                    <div className="w-[60%]  2xl:w-[25%] lg:w-[35%] px-5 xl:px-5">
                      <div className="flex mb-5">
                        <span
                          style={{
                            width: "7rem",
                          }}
                        ></span>
                        <span>From</span>
                      </div>
                      <InputField
                        type="text"
                        name="vendor_from"
                        placeholder="xxx"
                        label="Vender Code"
                        isRequired={false}
                        labelWidth="7rem"
                      />
                      <InputField
                        type="text"
                        name="item_from"
                        placeholder="xxx"
                        label="Item No."
                        isRequired={true}
                        labelWidth="7rem"
                      />
                    </div>
                    <div className="w-[40%] 2xl:w-[20%] lg:w-[30%] px-5 xl:px-5 ">
                      <div className="mb-5">
                        <span>To</span>
                      </div>
                      <InputField
                        type="number"
                        name="vendor_to"
                        placeholder="xxx"
                        label=""
                        isRequired={false}
                      />
                      <InputField
                        type="number"
                        name="item_to"
                        placeholder="xxx"
                        label=""
                        isRequired={true}
                      />
                    </div>
                  </div>
                  <div className="w-[60%]  2xl:w-[25%] lg:w-[35%] px-5 xl:px-5">
                    <SelectField
                      placeholder={"Select group"}
                      options={groupOptions}
                      label={"Group"}
                      id={"rounding_method"}
                      name="rounding_method"
                      isSearchable={true}
                      errMsgContainerClass="ps-2"
                      labelWidth="7rem"
                    />
                  </div>
                  <div className="px-5">
                    <CheckBox conClass="flex gap-4" items={itemoptions} />
                  </div>
                </div>
                <div className="flex justify-between px-10   bg-white py-4 invoice-bottom-container">
                  <DraftButton
                    onclkFn={() => setSelectionCriteria(false)}
                    text="cancel"
                  />
                  <AddButton
                    onclkFn={handleSubmit}
                    prefixIcon={false}
                    text="Save"
                    type="submit"
                  />
                </div>
              </div>
            );
          }}
        </Formik>
      </div>
      <div
        className="bg-black xl:h-[38.45vh] h-[10vh]  opacity-50"
        onClick={() => setSelectionCriteria(false)}
      ></div>
    </div>
  );
};

export default SelectionCriteriaPopover;



const groupOptions = [{ label: "All", value: "all" }];

const itemoptions = [
  {
    label: "Hide unpriced items",
    name: "is_inventory_item",
    isRequired: false,
  },
  { label: "Hide inactive items", name: "is_purchase_item", isRequired: false },
];
