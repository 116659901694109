import React from "react";

const AccountAllocationIcon = () => {
  return (
    <svg
      width="20"
      height="18"
      viewBox="0 0 20 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.4 12C13.1 12 12.8 11.7 12.8 11.4C12.8 11.1 13.1 10.8 13.4 10.8C13.7 10.8 14 11.1 14 11.4C14 11.7 13.7 12 13.4 12ZM19.8 13.8L16.2 10.2C16 10.1 15.8 10 15.6 10H12.8C12.4 10 12 10.4 12 10.8V13.6C12 13.8 12.1 14 12.2 14.2L15.8 17.8C15.9 17.9 16.1 18 16.4 18C16.7 18 16.8 17.9 17 17.8L19.8 15C19.9 14.9 20 14.7 20 14.4C20 14.2 19.9 14 19.8 13.8ZM8 0C5.8 0 4 1.8 4 4C4 6.2 5.8 8 8 8C10.2 8 12 6.2 12 4C12 1.8 10.2 0 8 0ZM8 2C9.1 2 10 2.9 10 4C10 5.1 9.1 6 8 6C6.9 6 6 5.1 6 4C6 2.9 6.9 2 8 2ZM8 9C5.3 9 0 10.3 0 13V16H11.2L10.8 15.6C10.4 15.2 10.1 14.7 10 14.1H1.9V13C1.9 12.4 5 10.9 8 10.9C8.7 10.9 9.4 11 10 11.1V10.8C10 10.2 10.2 9.7 10.5 9.3C9.6 9.1 8.7 9 8 9Z"
        fill="white"
      />
    </svg>
  );
};

export default AccountAllocationIcon;
