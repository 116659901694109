import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import InputField from "../forms/input-field";
import { useDispatch } from "react-redux";
import successToast from "../helper-components/success-toast";
import errorToast from "../helper-components/error-toast";
import AddButton from "../buttons/button";
import {
  addStrDispatchAction,
  addStrFollowUpAction,
  addStrbrandAction,
  strFollowUpGetAction,
} from "../../redux/actions/store-action";
import moment from "moment";
import SelectField from "../forms/select-field";

const StrFollowUpTab = ({
  initialValue,
  id,
  permissionFn = () => {},
  setCurrentTab,
}) => {
  const dispatch = useDispatch();
  const [reload, setReload] = useState(false);
  const [followList, setFollowList] = useState([]);
  useEffect(() => {
    dispatch(strFollowUpGetAction(id, onFetchSuccess, onFetchError));
  }, [reload]);

  const onFetchSuccess = (data) => {
    setFollowList(data?.data);
  };
  const onFetchError = (data) => {};

  const initialValues = {
    store_id: id,
    followup_date: null,
    remarks: "",
    store_execution_tab: "",
  };
  const validationSchema = Yup.object().shape({
    followup_date: Yup.date().required("Followup date is required"),
    remarks: Yup.string().required("Remarks are required"),
    store_execution_tab: Yup.string().required(
      "Store execution tab is required"
    ),
  });

  const onSubmit = (values, actions) => {
    dispatch(addStrFollowUpAction(values, onSuccess, onError));
  };

  const onSuccess = (data) => {
    setReload(!reload);
    successToast(data?.message);
  };
  const onError = (data) => {
    errorToast(data?.message);
  };

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ values, setFieldValue, isSubmitting, errors }) => {
        return (
          <Form className="flex-grow pt-2">
            <div className="flex flex-col justify-between h-full">
              <div className="flex flex-col h-full">
                <div className="flex flex-wrap items-start px-5 xl:px-10 gap-5">
                  <div className="w-full 2xl:w-[30%] lg:w-[50%]">
                    <SelectField
                      placeholder="Select tab"
                      options={itemTabs}
                      label="Store Execution Tab"
                      id="store_execution_tab"
                      name="store_execution_tab"
                      isSearchable={true}
                      errMsgContainerClass="ps-2"
                      isRequired={true}
                      labelWidth="10rem"
                    />
                  </div>
                  <div className="w-full 2xl:w-[30%] lg:w-[50%]">
                    <InputField
                      type="date"
                      name="followup_date"
                      label="Followup Date"
                      isRequired={true}
                      labelWidth="10rem"
                    />
                  </div>
                  <div className="w-full 2xl:w-[30%] lg:w-[50%]">
                    <InputField
                      placeholder="Enter remarks"
                      type="text"
                      name="remarks"
                      label="Remarks"
                      isRequired={true}
                      labelWidth="10rem"
                    />
                  </div>
                  <AddButton
                    className="primary-bg white-color px-6 py-2 rounded flex justify-center fw_500 fs_16 mt-1"
                    prefixIcon={false}
                    text="Add"
                    type="submit"
                  />
                </div>
                <div className="flex-1">
                  <table className="price-my-tab h-full">
                    <thead>
                      <tr>
                        {tableHeading.map((heading, index) => (
                          <th
                            key={index}
                            className={`${"price-tab-header"} ${
                              heading?.label === "Action" &&
                              "flex justify-end pe-10"
                            } whitespace-nowrap fs_12 fw_500 color-475467 bg-EAECF0`}
                          >
                            {heading?.label}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody className="h-full">
                      {followList?.length > 0 ? (
                        <>
                          {followList?.map((item, index) => (
                            <tr key={index}>
                              <td
                                className={`price-tab-cell fs_14 fw_400 secondary-color whitespace-nowrap`}
                              >
                                {item?.store_execution_tab
                                  ? itemTabs?.find(
                                      (tab) =>
                                        tab?.value === item?.store_execution_tab
                                    )?.label
                                  : ""}
                              </td>
                              <td
                                className={`price-tab-cell fs_14 fw_400 secondary-color whitespace-nowrap`}
                              >
                                {moment(item?.followup_date).format(
                                  "YYYY-MM-DD"
                                ) ?? ""}{" "}
                              </td>

                              <td
                                className={`price-tab-cell fs_14 fw_400 secondary-color whitespace-nowrap`}
                              >
                                {item?.remarks ?? ""}
                              </td>
                            </tr>
                          ))}
                        </>
                      ) : (
                        <tr
                          style={{
                            height: "20rem",
                          }}
                        >
                          <td
                            colSpan={tableHeading.length + 1}
                            className="text-center primary-color"
                          >
                            No Items found
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default StrFollowUpTab;
const items = [{ label: "Colour Pre Applied", name: "colour_pre_applied" }];
const items1 = [
  { label: "Furniture Pre Applied", name: "furniture_pre_applied" },
];

const itemTabs = [
  { label: "Infrastructure Details", value: "infrastructure_details" },
  { label: "Branding Details", value: "branding_details" },
  { label: "Branding & Software Details", value: "branding_software_details" },
  { label: "Account Details", value: "account_details" },
  { label: "Dispatch Details", value: "dispatch_details" },
  { label: "Opening Details", value: "opening_details" },
  { label: "Follow-ups Remarks History", value: "follow-ups_remarks_history" },
];

const tableHeading = [
  {
    label: "Tab Name",
  },
  {
    label: "Followup Date",
  },
  {
    label: "Remark",
  },
];
