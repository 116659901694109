import React from "react";

export default function LoginArrowLeft() {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.3333 9.99996H4.66667M4.66667 9.99996L10.5 15.8333M4.66667 9.99996L10.5 4.16663"
        stroke="#475467"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
