import { all } from "redux-saga/effects";
import {
  doctorCreateWatcher,
  doctorDeleteWatcher,
  doctorEditWatcher,
  doctorTableWatcher,
  doctorViewWatcher,
} from "../doctor-saga";

export default function* doctorRootSaga() {
  yield all([
    doctorCreateWatcher(),
    doctorTableWatcher(),
    doctorViewWatcher(),
    doctorEditWatcher(),
    doctorDeleteWatcher(),
  ]);
}
