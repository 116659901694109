import { api } from "./api";

export const priceListCreateApi = (params) => {
  return api.post(`/discount/pricelist/add`, JSON.stringify(params));
};
export const priceListItemsApi = (params) => {
  return api.get(`/discount/pricelist/items/get?price_list_id=${params}`);
};
export const priceListViewApi = (params) => {
  return api.get(
    `/discount/pricelist/items/get?pageNumber=${
      params?.pageNumber
    }&pageSize=${params?.pageSize}&price_list_id=${
      params?.filterData?.price_list_id
    }${
      params?.filterData?.item_from &&
      `&item_from=${params?.filterData?.item_from}`
    }${
      params?.filterData?.item_to && `&item_to=${params?.filterData?.item_to}`
    }`
  );
};
export const priceListEditApi = (params) => {
  return api.post(`/discount/pricelist/update`, JSON.stringify(params));
};
export const priceListItemEditApi = (params) => {
  return api.post(
    `/discount/pricelist/items/update`,
    JSON.stringify(params)
  );
};

export const priceListTableApi = (params) => {
  return api.get(
    `/discount/pricelist/getall?pageNumber=${params?.pageNumber}&pageSize=${
      params?.pageSize
    }&sortBy=${params?.filterData?.sort_by}&sortOrder=${
      params?.filterData?.sort_order
    }&fromDate=${params?.filterData?.from}&toDate=${params?.filterData?.to}${
      params?.filterData?.inputValue &&
      `&searchColumn=${params?.filterData?.selectColumn}&searchValue=${params?.filterData?.inputValue}`
    }`,
    JSON.stringify(params)
  );
};

export const disItemGroupCreateApi = (params) => {
  return api.post(`/discountGroup/itemsGroup/add`, JSON.stringify(params));
};
export const disManufacGroupCreateApi = (params) => {
  return api.post(
    `/discountGroup/manufactureGroup/add`,
    JSON.stringify(params)
  );
};
export const disItemsCreateApi = (params) => {
  return api.post(`/discountGroup/items/add`, JSON.stringify(params));
};
export const allDisGroupApi = (params) => {
  return api.get(
    `/discountGroup/getByCustomer?discount_type_id=${params?.discount_type_id}&customer_group_id=${params?.customer_group_id}&requestTab=${params?.requestTab}`
  );
};
export const disItemsFetchApi = (params) => {
  return api.get(
    `/discountGroup/getSelectedItems?item_from=${params?.item_from}&item_to=${params?.item_to}&discount_type_id=${params?.discount_type_id}&customer_group_id=${params?.customer_group_id}`
  );
};

export const perDisItemsListApi = (params) => {
  return api.get(
    `/discount/periodAndVolume/pricelist/items/get?price_list_id=${params?.price_list_id}`
  );
};
export const perVolumePriceListItemsApi = (params) => {
  return api.get(
    `/discount/periodAndVolume/pricelist/selecteditems/get?price_list_id=${params?.price_list_id}&item_to=${params?.item_to}&item_from=${params?.item_from}`
  );
};
export const perVolCreateApi = (params) => {
  return api.post(
    `/discount/periodAndVolume/items/add`,
    JSON.stringify(params)
  );
};
export const periodVolumeAddApi = (params) => {
  return api.post(
    `/discount/periodAndVolume/item/period/add`,
    JSON.stringify(params)
  );
};
export const periodVolumeGetApi = (params) => {
  return api.get(
    `/discount/periodAndVolume/item/period/get?period_discount_item_id=${params?.period_discount_item_id}`
  );
};
