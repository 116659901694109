import { call, takeLatest } from "redux-saga/effects";
import {
  adminCustomerAccountingApi,
  adminCustomerAddressApi,
  adminCustomerBnkDetailsApi,
  adminCustomerContactPersonApi,
  adminCustomerCreateApi,
  adminCustomerDeleteApi,
  adminCustomerPaymentTermsApi,
  adminCustomerTableApi,
  adminCustomerUpdateAccountingApi,
  adminCustomerUpdateAddressApi,
  adminCustomerUpdateApi,
  adminCustomerUpdateBnkDetailsApi,
  adminCustomerUpdateContactPersonApi,
  adminCustomerUpdatePaymentTermsApi,
  adminCustomerViewApi,
} from "../apis/admin-customer-api";
import {
  ADMIN_CUSTOMER_ACCOUNTING,
  ADMIN_CUSTOMER_ADDRESS,
  ADMIN_CUSTOMER_BNK_DTLS,
  ADMIN_CUSTOMER_CONTACT_PERSON,
  ADMIN_CUSTOMER_CREATE,
  ADMIN_CUSTOMER_DELETE,
  ADMIN_CUSTOMER_EDIT,
  ADMIN_CUSTOMER_PAYMENT_TERMS,
  ADMIN_CUSTOMER_TABLE,
  ADMIN_CUSTOMER_UPDATE_ACCOUNTING,
  ADMIN_CUSTOMER_UPDATE_ADDRESS,
  ADMIN_CUSTOMER_UPDATE_BNK_DTLS,
  ADMIN_CUSTOMER_UPDATE_CONTACT_PERSON,
  ADMIN_CUSTOMER_UPDATE_PAYMENT_TERMS,
  ADMIN_CUSTOMER_VIEW,
} from "../types";

export function* adminCustomerCreateWorker(action) {
  try {
    const res = yield call(adminCustomerCreateApi, action.data);

    if (res.data?.code === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}

export function* adminCustomerUpdateWorker(action) {
  try {
    const res = yield call(adminCustomerUpdateApi, action.data);

    if (res.data?.code === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}


export function* adminCustomerTableWorker(action) {
  try {
    const res = yield call(adminCustomerTableApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}

export function* adminCustomerViewWorker(action) {
  try {
    const res = yield call(adminCustomerViewApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}

export function* adminCustomerDeleteWorker(action) {
  try {
    const res = yield call(adminCustomerDeleteApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}

export function* adminCustomerContactPersonWorker(action) {
  try {
    const res = yield call(adminCustomerContactPersonApi, action.data);

    if (res.data?.code === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* adminCustomerBnkDetailsWorker(action) {
  try {
    const res = yield call(adminCustomerBnkDetailsApi, action.data);

    if (res.data?.code === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* adminCustomerPaymentTermsWorker(action) {
  try {
    const res = yield call(adminCustomerPaymentTermsApi, action.data);

    if (res.data?.code === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* adminCustomerAccountingWorker(action) {
  try {
    const res = yield call(adminCustomerAccountingApi, action.data);

    if (res.data?.code === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* adminCustomerAddressWorker(action) {
  try {
    const res = yield call(adminCustomerAddressApi, action.data);

    if (res.data?.code === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* adminCustomerUpdateContactPersonWorker(action) {
  try {
    const res = yield call(adminCustomerUpdateContactPersonApi, action.data);

    if (res.data?.code === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* adminCustomerUpdateBnkDetailsWorker(action) {
  try {
    const res = yield call(adminCustomerUpdateBnkDetailsApi, action.data);

    if (res.data?.code === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* adminCustomerUpdatePaymentTermsWorker(action) {
  try {
    const res = yield call(adminCustomerUpdatePaymentTermsApi, action.data);

    if (res.data?.code === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* adminCustomerUpdateAccountingWorker(action) {
  try {
    const res = yield call(adminCustomerUpdateAccountingApi, action.data);

    if (res.data?.code === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* adminCustomerUpdateAddressWorker(action) {
  try {
    const res = yield call(adminCustomerUpdateAddressApi, action.data);

    if (res.data?.code === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}

export function* adminCustomerCreateWatcher() {
  yield takeLatest(ADMIN_CUSTOMER_CREATE, adminCustomerCreateWorker);
}
export function* adminCustomerUpdateWatcher() {
  yield takeLatest(ADMIN_CUSTOMER_EDIT, adminCustomerUpdateWorker);
}
export function* adminCustomerTableWatcher() {
  yield takeLatest(ADMIN_CUSTOMER_TABLE, adminCustomerTableWorker);
}
export function* adminCustomerViewWatcher() {
  yield takeLatest(ADMIN_CUSTOMER_VIEW, adminCustomerViewWorker);
}
export function* adminCustomerDeleteWatcher() {
  yield takeLatest(ADMIN_CUSTOMER_DELETE, adminCustomerDeleteWorker);
}

export function* adminCustomerContactPersonWatcher() {
  yield takeLatest(
    ADMIN_CUSTOMER_CONTACT_PERSON,
    adminCustomerContactPersonWorker
  );
}
export function* adminCustomerBnkDetailsWatcher() {
  yield takeLatest(ADMIN_CUSTOMER_BNK_DTLS, adminCustomerBnkDetailsWorker);
}
export function* adminCustomerPaymentTermsWatcher() {
  yield takeLatest(
    ADMIN_CUSTOMER_PAYMENT_TERMS,
    adminCustomerPaymentTermsWorker
  );
}
export function* adminCustomerAccountingWatcher() {
  yield takeLatest(ADMIN_CUSTOMER_ACCOUNTING, adminCustomerAccountingWorker);
}
export function* adminCustomerAddressWatcher() {
  yield takeLatest(ADMIN_CUSTOMER_ADDRESS, adminCustomerAddressWorker);
}

export function* adminCustomerUpdateContactPersonWatcher() {
  yield takeLatest(
    ADMIN_CUSTOMER_UPDATE_CONTACT_PERSON,
    adminCustomerUpdateContactPersonWorker
  );
}
export function* adminCustomerUpdateBnkDetailsWatcher() {
  yield takeLatest(ADMIN_CUSTOMER_UPDATE_BNK_DTLS, adminCustomerUpdateBnkDetailsWorker);
}
export function* adminCustomerUpdatePaymentTermsWatcher() {
  yield takeLatest(
    ADMIN_CUSTOMER_UPDATE_PAYMENT_TERMS,
    adminCustomerUpdatePaymentTermsWorker
  );
}
export function* adminCustomerUpdateAccountingWatcher() {
  yield takeLatest(ADMIN_CUSTOMER_UPDATE_ACCOUNTING, adminCustomerUpdateAccountingWorker);
}
export function* adminCustomerUpdateAddressWatcher() {
  yield takeLatest(ADMIN_CUSTOMER_UPDATE_ADDRESS, adminCustomerUpdateAddressWorker);
}
