import React from "react";

export const CustomerIcon = () => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.49992 0.833374C7.25137 0.833374 7.97204 1.13188 8.50339 1.66324C9.03474 2.19459 9.33325 2.91526 9.33325 3.66671C9.33325 4.41815 9.03474 5.13882 8.50339 5.67018C7.97204 6.20153 7.25137 6.50004 6.49992 6.50004C5.74847 6.50004 5.0278 6.20153 4.49645 5.67018C3.9651 5.13882 3.66659 4.41815 3.66659 3.66671C3.66659 2.91526 3.9651 2.19459 4.49645 1.66324C5.0278 1.13188 5.74847 0.833374 6.49992 0.833374ZM6.49992 2.25004C6.1242 2.25004 5.76386 2.3993 5.49818 2.66497C5.23251 2.93065 5.08325 3.29098 5.08325 3.66671C5.08325 4.04243 5.23251 4.40277 5.49818 4.66844C5.76386 4.93412 6.1242 5.08337 6.49992 5.08337C6.87564 5.08337 7.23598 4.93412 7.50165 4.66844C7.76733 4.40277 7.91659 4.04243 7.91659 3.66671C7.91659 3.29098 7.76733 2.93065 7.50165 2.66497C7.23598 2.3993 6.87564 2.25004 6.49992 2.25004ZM6.49992 7.20837C8.39117 7.20837 12.1666 8.15046 12.1666 10.0417V12.1667H0.833252V10.0417C0.833252 8.15046 4.60867 7.20837 6.49992 7.20837ZM6.49992 8.55421C4.39617 8.55421 2.17909 9.58837 2.17909 10.0417V10.8209H10.8208V10.0417C10.8208 9.58837 8.60367 8.55421 6.49992 8.55421Z"
        fill="black"
      />
    </svg>
  );
};
export const AlternativeIcon = () => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 15 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 3.68754L0.496875 3.19066L2.60625 1.08129C2.73954 0.957089 2.91583 0.889474 3.09799 0.892688C3.28015 0.895902 3.45395 0.969694 3.58277 1.09852C3.7116 1.22734 3.78539 1.40114 3.7886 1.5833C3.79182 1.76546 3.7242 1.94175 3.6 2.07504L2.69156 2.98441H12.4228C12.6093 2.98441 12.7881 3.05849 12.92 3.19035C13.0519 3.32222 13.1259 3.50106 13.1259 3.68754C13.1259 3.87402 13.0519 4.05286 12.92 4.18472C12.7881 4.31659 12.6093 4.39066 12.4228 4.39066H2.69062L3.6 5.30004C3.7242 5.43333 3.79182 5.60962 3.7886 5.79178C3.78539 5.97394 3.7116 6.14774 3.58277 6.27656C3.45395 6.40538 3.28015 6.47918 3.09799 6.48239C2.91583 6.4856 2.73954 6.41799 2.60625 6.29379L0.496875 4.18441L0 3.68754ZM15 9.31254L14.5031 8.81566L12.3938 6.70629C12.3289 6.6391 12.2514 6.5855 12.1656 6.54861C12.0798 6.51172 11.9876 6.49228 11.8942 6.49143C11.8009 6.49057 11.7083 6.50832 11.6218 6.54363C11.5354 6.57895 11.4569 6.63112 11.3908 6.6971C11.3248 6.76309 11.2725 6.84157 11.2371 6.92797C11.2017 7.01436 11.1839 7.10694 11.1847 7.2003C11.1854 7.29366 11.2048 7.38593 11.2416 7.47173C11.2784 7.55754 11.3319 7.63515 11.3991 7.70004L12.3084 8.60941H2.57625C2.38977 8.60941 2.21093 8.68349 2.07907 8.81536C1.9472 8.94722 1.87313 9.12606 1.87313 9.31254C1.87313 9.49902 1.9472 9.67786 2.07907 9.80972C2.21093 9.94158 2.38977 10.0157 2.57625 10.0157H12.3075L11.3981 10.925C11.329 10.9894 11.2736 11.067 11.2352 11.1533C11.1968 11.2395 11.1761 11.3326 11.1744 11.427C11.1728 11.5215 11.1901 11.6152 11.2255 11.7028C11.2609 11.7903 11.3135 11.8699 11.3803 11.9366C11.447 12.0034 11.5266 12.056 11.6141 12.0914C11.7017 12.1268 11.7955 12.1441 11.8899 12.1425C11.9843 12.1408 12.0774 12.1201 12.1636 12.0817C12.2499 12.0433 12.3275 11.9879 12.3919 11.9188L14.5013 9.80941L15 9.31254Z"
        fill="black"
      />
    </svg>
  );
};
export const StoriesIcon = () => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.58408 2.12501C3.2795 2.12501 2.98908 2.22417 2.71992 2.42251C2.45075 2.62084 2.29492 2.87584 2.22408 3.18751L1.4945 6.31126C1.31742 7.08334 1.45908 7.73501 1.90533 8.30876C1.98325 8.38667 2.05408 8.45751 2.12492 8.52126V13.4583C2.12492 13.8125 2.26658 14.1667 2.557 14.4429C2.84742 14.7192 3.18742 14.875 3.54158 14.875H9.06658C8.81867 14.4429 8.64158 13.9683 8.55658 13.4583H3.54158V9.20834H3.69742C4.36325 9.20834 4.8945 8.91792 5.29117 8.53542C5.73742 8.97459 6.27575 9.20834 6.94158 9.20834C7.53658 9.20834 8.10325 8.94626 8.49992 8.54959C8.98158 8.99584 9.527 9.20834 10.1291 9.20834C10.7453 9.20834 11.2695 8.97459 11.7158 8.53542C12.1195 8.93917 12.6508 9.20834 13.3237 9.20834C13.9966 9.20834 14.4712 8.96042 14.8749 8.54251L14.967 8.44334L15.0733 8.33709C15.5408 7.75626 15.6824 7.08334 15.5053 6.31126L14.7758 3.18751C14.6837 2.87584 14.5208 2.62084 14.2587 2.42251C14.0204 2.22918 13.7226 2.12409 13.4158 2.12501M13.3803 3.52042L14.1453 6.64417C14.2091 6.94876 14.1453 7.22501 13.947 7.47292C13.7699 7.69251 13.5503 7.79167 13.2812 7.79167C13.0616 7.79167 12.8703 7.72084 12.7145 7.55084C12.5587 7.38792 12.4737 7.19667 12.4524 6.97001L12.0203 3.54167M3.58408 3.54167H4.9795L4.54742 6.97001C4.46242 7.52959 4.18617 7.79167 3.71867 7.79167C3.42825 7.79167 3.20867 7.69251 3.05283 7.47292C2.8545 7.22501 2.79075 6.94876 2.8545 6.64417M6.41033 3.54167H7.79158V6.87084C7.79158 7.11876 7.71367 7.33126 7.53658 7.52251C7.3595 7.70667 7.13992 7.79167 6.87075 7.79167C6.62992 7.79167 6.4245 7.70667 6.26158 7.50126C6.09867 7.29584 6.02075 7.08334 6.02075 6.84251V6.72917M9.20825 3.54167H10.5895L10.9791 6.72917C11.0358 7.02667 10.9791 7.27459 10.7737 7.48709C10.5895 7.69959 10.3487 7.79167 10.0583 7.79167C9.83867 7.79167 9.64033 7.70667 9.46325 7.52251C9.38001 7.43597 9.31465 7.33387 9.2709 7.22205C9.22714 7.11023 9.20585 6.99089 9.20825 6.87084M12.0416 14.875L10.0937 12.75L10.9153 11.9283L12.0416 13.0546L14.5845 10.5117L15.4062 11.5104L12.0416 14.875Z"
        fill="black"
      />
    </svg>
  );
};
export const PastOrderIcon = () => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 16 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.375 14.0625V10.3125C11.9219 10.3125 13.6012 11.6312 15.5 14.0625C15.5 9.40508 13.9543 4.6875 7.375 4.6875V0.9375L0.5 7.5L7.375 14.0625Z"
        stroke="#277DFE"
        strokeLinejoin="round"
      />
    </svg>
  );
};
