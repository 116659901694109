import React from "react";
import InputField from "../../forms/input-field";

const StorePayerInfoTab = ({ disabled = false }) => {
  return (
    <div className="w-full 2xl:w-[35%] lg:w-[60%] px-5 xl:px-10">
      <InputField
        isDisabled={disabled}
        isRequired={true}
        type="text"
        name="payer_name_as_per_bank"
        placeholder=""
        label="Payer Name as per Bank"
        labelWidth="11.5rem"
      />
      <InputField
        isDisabled={disabled}
        isRequired={true}
        type="text"
        name="payer_bank_name"
        placeholder=""
        label="Payer Bank Name"
        labelWidth="11.5rem"
      />
      <InputField
        isDisabled={disabled}
        isRequired={true}
        type="text"
        name="payer_bank_account_no"
        placeholder=""
        label="Payer Bank Account No."
        labelWidth="11.5rem"
      />
      <InputField
        isDisabled={disabled}
        isRequired={true}
        type="text"
        name="payer_remaining_amount"
        placeholder=""
        label="Remaining Amount"
        labelWidth="11.5rem"
      />
    </div>
  );
};

export default StorePayerInfoTab;