import React, { useEffect, useRef, useState } from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { saveAs } from "file-saver";
import { pdf } from "@react-pdf/renderer";
import { storeIdAction } from "../../../redux/actions/general-action";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  calculateTotal,
  calculateTotalTax,
} from "../../invoice-section/invoice-finalBill-table";
import { storeDetailsAction } from "../../../redux/actions/invoices-action";
import successToast from "../../helper-components/success-toast";
import errorToast from "../../helper-components/error-toast";
import {
  purchaseCreateAction,
  purchaseEditAction,
} from "../../../redux/actions/purchase-action";
import PurchaseBillingTable from "./purchase-billing-table";
import PurchasePdfContent from "./purchase-pdf-content";
import moment from "moment";
import SalesPurchaseFinalBillTable from "../../sales-section/sales-purchase-finalBill-table";
import SalesPurchaseHeader from "../../sales-section/sales-purchase-header";

const CreateEditViewPurchase = ({
  type = "",
  orderDetail = [],
  vendorDetails = [],
  disabled = false,
  page = "",
  data,
}) => {
  const rowData = useSelector((state) => state.invoiceRowReducer);
  const searchRef = useRef(null);
  const [heightOfFirstDiv, setHeightOfFirstDiv] = useState(0);
  useEffect(() => {
    const updateHeight = () => {
      const firstDivHeight = document.querySelector(".first-div").clientHeight;
      setHeightOfFirstDiv(firstDivHeight);
    };
    updateHeight();
    window.addEventListener("resize", updateHeight);
    return () => {
      window.removeEventListener("resize", updateHeight);
    };
  }, []);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const initialData = {
    sl_no: 1,
    item_name: "",
    item_generic_name: "",
    item_code: "",
    item_uom: "",
    item_unit_price: 0,
    ...(type === "sales" ? { sales_order_qty: 0 } : {}),
    quantity: 0,
    item_total_amount: 0,
    item_id: "",
    item_tax_amount: 0,
    item_gst: 0,
    item_sgst: 0,
    item_cgst: 0,
    item_price_wiithout_tax: 0,     
    item_uom_id: "",
  };
  const initialFormValues =
    rowData?.data?.length >= 1
      ? {
          ...rowData,
          data: [...rowData.data, initialData],
        }
      : {
          cmr_code: vendorDetails?.[0]?.cmr_id
            ? {
                label: vendorDetails?.[0]?.cmr_code ?? "",
                value: vendorDetails?.[0]?.cmr_id ?? "",
              }
            : "",
          cmr_name: vendorDetails?.[0]?.cmr_id
            ? {
                label: vendorDetails?.[0]?.cmr_name,
                value: vendorDetails?.[0]?.cmr_id,
              }
            : "",
          cmr_phone_number: vendorDetails?.[0]?.cmr_phone_number ?? "",
          cmr_id: vendorDetails?.[0]?.cmr_id,
          remarks: vendorDetails?.[0]?.remarks ?? "",
          pot_total_discount: vendorDetails?.[0]?.pot_total_discount ?? 0,
          ...(vendorDetails?.[0]?.pot_id
            ? { pot_id: vendorDetails?.[0]?.pot_id }
            : {}),
          pot_order_date: vendorDetails?.[0]?.pot_order_date
            ? moment(vendorDetails?.[0]?.pot_order_date).format("YYYY-MM-DD")
            : moment(new Date()).format("YYYY-MM-DD"),
          pot_document_date: vendorDetails?.[0]?.pot_document_date
            ? moment(vendorDetails?.[0]?.pot_document_date).format("YYYY-MM-DD")
            : moment(new Date()).format("YYYY-MM-DD"),
          pot_delivery_date: vendorDetails?.[0]?.pot_delivery_date
            ? moment(vendorDetails?.[0]?.pot_delivery_date).format("YYYY-MM-DD")
            : moment(new Date()).format("YYYY-MM-DD"),
          data: [
            ...orderDetail?.map((item, index) => ({
              sl_no: index + 1,
              item_name: item?.item_name ?? "",
              item_generic_name:
                item?.item_generic_name === "null"
                  ? ""
                  : item?.item_generic_name,
              item_code: item?.item_code ?? "",
              item_uom: item?.item_uom ?? "--",
              item_unit_price: item?.item_unit_price ?? 0,
              ...(type === "sales"
                ? { sales_order_qty: item?.item_quantity ?? 0 }
                : {}),
              quantity: type === "sales" ? 0 : item?.item_quantity,
              item_total_amount: type === "sales" ? 0 : item?.item_total_amount,
              item_id: item?.item_id,
              item_tax_amount: type === "sales" ? 0 : item?.item_tax_amount,
              instock: true,
              item_gst: type === "sales" ? 0 : item?.item_gst,
              item_cgst: type === "sales" ? 0 : item?.item_cgst,
              item_sgst: type === "sales" ? 0 : item?.item_sgst,
              item_price_wiithout_tax:
                type === "sales" ? 0 : item?.item_price_wiithout_tax,
              uom_dropdown: item?.uom_dropdown
                ? item?.uom_dropdown.map((uom) => ({
                    label: uom?.alt_uom,
                    value: uom?.alt_uom,
                  }))
                : [],
              item_uom_id: item?.item_uom_id ?? "",
            })),
            initialData,
          ],
        };
  const [initialValue, setInitialValue] = useState(initialFormValues);
  const [reload, setReload] = useState(false);
  const [formValues, setFormValues] = useState({});
  const [storeId, setStoreId] = useState("");
  const [storeDetails, setStoreDetails] = useState({});
  const validationSchema = Yup.object().shape({
    cmr_code: Yup.object().required("Vendor code number is required"),
    cmr_name: Yup.object().required("Vendor name is required"),
    pot_document_date: Yup.string().required("Document date is required"),
    pot_delivery_date: Yup.string().required("Delivery date is required"),
  });
  useEffect(() => {
    dispatch(storeIdAction(onSuccessStore, onErrorStore));
    dispatch(storeDetailsAction(onSuccessStoreDetails, onErrorStoreDetails));
  }, []);
  const onSuccessStore = (data) => {
    setStoreId(data?.data?.store_id);
    setReload(!reload);
  };
  const onErrorStore = () => {};
  const onSuccessStoreDetails = (data) => {
    setStoreDetails(data?.data);
  };
  const onErrorStoreDetails = () => {};

  const handleSubmit = (values, actions) => {
    let actionType = purchaseCreateAction;
    if (page === "edit") {
      actionType = purchaseEditAction;
    }
    if (
      values?.data
        ?.slice(0, -1)
        .some((item) => parseInt(item.quantity) === 0 || item.quantity === "")
    ) {
      errorToast("Quantity cannot be zero");
    } else {
      if (values?.data?.length > 1) {
        dispatch(
          actionType(
            {
              orderData: {
                store_id: storeId,
                ...(values?.pot_id ? { pot_id: values?.pot_id } : {}),
                pot_total_gst: Math.round(
                  formValues?.data
                    ?.slice(0, -1)
                    .reduce(
                      (total, item) =>
                        total + parseFloat(item?.item_tax_amount),
                      0
                    )
                ),
                pot_total_discount: values?.pot_total_discount ?? null,
                pot_payment_status: "paid",
                pot_transaction_id: "",
                pot_billing_address: "",
                pot_total_amount: parseFloat(
                  calculateTotal(values?.data) +
                    calculateTotalTax(values?.data) -
                    parseFloat(values?.pot_total_discount)
                ),
                cmr_code: values?.cmr_code?.label,
                cmr_id: values?.cmr_id,
                cmr_name: values?.cmr_name?.label,
                remarks: values?.remarks,
                pot_order_date: values?.pot_order_date,
                pot_document_date: values?.pot_document_date,
                pot_delivery_date: values?.pot_delivery_date,
              },
              itemData: values?.data?.slice(0, -1).map((item, index) => ({
                item_code: item?.item_code,
                item_id: item?.item_id,
                item_generic_name: item?.item_generic_name,
                item_name: item?.item_name,
                item_uom: item?.item_uom,
                item_unit_price: parseFloat(item?.item_unit_price),
                item_quantity: parseInt(item?.quantity),
                item_total_amount: parseFloat(
                  parseFloat(item.item_unit_price) * parseInt(item.quantity) +
                    parseFloat(item.item_tax_amount)
                ),
                item_tax_amount: parseFloat(item?.item_tax_amount),
                item_gst: parseFloat(item?.item_gst),
                item_sgst: parseFloat(item?.item_sgst),
                item_cgst: parseFloat(item?.item_cgst),
                item_uom_id: item?.item_uom_id ?? "",
              })),
            },
            onSuccessOrder,
            onErrorOrder
          )
        );
      }
    }
  };

  const downloadPdf = async (data) => {
    const fileName = "test.pdf";
    try {
      const blob = await pdf(
        <PurchasePdfContent data={data} storeDetails={storeDetails} />
      ).toBlob();
      saveAs(blob, fileName);
    } catch (error) {}
  };
  const onSuccessOrder = (data) => {
    navigate("/purchase");
    if (formValues?.submittedButton === "print") {
      downloadPdf(data?.data);
    }
    successToast(data?.message);
    setInitialValue({
      cmr_code: "",
      cmr_name: "",
      cmr_phone_number: "",
      cmr_id: "",
      remarks: "",
      pot_total_discount: 0,
      pot_order_date: null,
      pot_document_date: null,
      pot_delivery_date: null,
      data: [initialData],
    });
  };
  const onErrorOrder = () => {};
  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={initialValue}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ values, setFieldValue }) => {
          setFormValues(values);
          return (
            <Form className="h-full">
              <div className="flex flex-col h-full ">
                <div class="flex flex-wrap justify-between first-div px-3">
                  <SalesPurchaseHeader
                    section="purchase"
                    name="pot"
                    disabled={disabled}
                    searchRef={searchRef}
                  />
                </div>
                <div
                  className="flex flex-col "
                  style={{
                    maxHeight: `calc(100% - ${heightOfFirstDiv}px)`,
                    height: `calc(100% - ${heightOfFirstDiv}px)`,
                  }}
                >
                  <PurchaseBillingTable
                    disabled={disabled}
                    searchRef={searchRef}
                    initialData={initialData}
                  />
                  <div className="h-[15rem] mt-auto">
                    <SalesPurchaseFinalBillTable
                      name="pot_total_discount"
                      page={page}
                      disabled={disabled}
                      EditFn={() =>
                        navigate(`/edit-purchase/${vendorDetails?.[0]?.pot_id}`)
                      }
                      printFn={() => downloadPdf(data)}
                    />
                  </div>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default CreateEditViewPurchase;
