import React, { useEffect, useRef, useState } from "react";
import Select from "react-select";
import { PlusIcon } from "@heroicons/react/24/outline";
import AddButton from "../buttons/button";
import DeleteIcon from "../../assets/icons/delete-icon";
import FilterIcon from "../../assets/icons/filter-icon";
import InnerLayout from "../layouts/inner-layout";
import LoadingSpinner from "../helper-components/loading-spinner";
import ColoumnsButton from "../../assets/icons/coloumnButton";
import ColoumnsModal from "../modals/reports/coloumn-modal";
import exportFromJSON from "export-from-json";
import ClearIcon from "../../assets/icons/clear-icon";

const ReportsLayout = ({
  title,
  tableHeading,
  tableData,
  groupByOptions,
  filterOptions,
  selectedColumns,
  availableColumns,
  setSelectedColumns,
  setFilters,
  loading,
  setDateRange,
  setGroupBy,
  setDateRangeFilterValue,
  init = {},
  reqData = {},
}) => {
  console.log(tableData, "sdasdssadasd");
  const [error, setError] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [filterBy, setFilterBy] = useState("");
  const [groupBy, setGroupByValue] = useState("");
  const [openMoreFilter, setOpenMoreFilter] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [dateRangeFilter, setDateRangeFilter] = useState({
    from: null,
    to: null,
  });
  const [filterRows, setFilterRows] = useState([
    { column: null, condition: null, value: "", from: null, to: null },
  ]);
  const [overlayHeight, setOverlayHeight] = useState("85vh");
  const filterContainerRef = useRef(null);

  const filterDateRange = [
    { label: "This Month", value: "this_month" },
    { label: "This Year", value: "this_year" },
    { label: "All", value: "" },
    ...(title === "Daily Sales Report" || title === "Overall Sales Report"
      ? [
          { label: "This Week", value: "this_week" },
          { label: "Today", value: "today" },
        ]
      : []),
    ...(title !== "Daily Sales Report"
      ? [{ label: "Custom Date", value: "custom_date" }]
      : []),
  ];

  const exportOptions = [{ label: "CSV", value: "csv" }];
  const conditionsOptions = [
    { label: "Less Than", value: "less_than" },
    { label: "Greater Than", value: "greater_than" },
    { label: "Equal To", value: "equal_to" },
    { label: "Not Equal To", value: "not_equal_to" },
  ];

  const item_codeOptions = [
    { label: "Equal to", value: "equal_to" },
    { label: "Range", value: "range" },
  ];

  const handleFilterChange = (selectedOption) => {
    setFilterBy(selectedOption.value);
  };

  const handleGroupByChange = (selectedOption) => {
    setGroupByValue(selectedOption.value);
  };

  const handleAddFilterRow = () => {
    setFilterRows([
      ...filterRows,
      { column: null, condition: null, value: "", from: null, to: null },
    ]);
  };
  console.log(filterRows, "dgfgdfgfd");

  const handleRemoveFilterRow = (index) => {
    const updatedRow = filterRows.filter((row, idxDt) => idxDt !== index);

    setFilterRows(updatedRow);
  };

  const handleFilterRowChange = (id, key, value) => {
    console.log(value, "fgsfdfsdfsdfsdf");
    setFilterRows(
      filterRows.map((row, index) =>
        index === id ? { ...row, [key]: value } : row
      )
    );
  };

  const handleApply = () => {
    const filtersWithoutId = filterRows.map(({ id, ...rest }) => rest);
    setFilters(filtersWithoutId);
    setOpenMoreFilter(false);
    setDateRange(filterBy);
    setGroupBy(groupBy);
    if (filterBy === "custom_date") {
      setDateRangeFilterValue({
        from: dateRangeFilter?.from,
        to: dateRangeFilter?.to,
      });
    } else {
      setDateRangeFilterValue({
        from: null,
        to: null,
      });
    }
  };

  useEffect(() => {
    if (openMoreFilter && filterContainerRef.current) {
      const filterContainerHeight = filterContainerRef.current.clientHeight;
      setOverlayHeight(`calc(85vh - ${filterContainerHeight}px)`);
    } else {
      setOverlayHeight("85vh");
    }
  }, [openMoreFilter, filterRows]);

  console.log("tableData", tableData);

  const fileName = title ? title : "report";
  const exportType = exportFromJSON.types.csv;
  const onClickExportCsv = () => {
    exportFromJSON({ data: tableData, fileName, exportType });
  };

  return (
    <InnerLayout
      backBtn={true}
      module="invoice"
      list={true}
      onclkFn={() => {}}
      title={title}
    >
      {loading ? (
        <LoadingSpinner />
      ) : error ? (
        <></>
      ) : (
        <>
          <div className=" flex justify-between mx-6 flex-wrap">
            <div className="relative flex gap-3 pt-2">
              <button
                style={{
                  height: "2.6rem",
                }}
                onClick={() => null}
                className="flex items-center whitespace-nowrap fs_14 rounded-md px-3 gap-2 text-344054 fw_600"
              >
                <FilterIcon />
                {"Filters"}
              </button>
              <div
                style={{ maxWidth: "16rem", height: "2.6rem" }}
                className={`w-full flex items-center rounded-md ${
                  isFocused ? "border border-primary" : "input_border"
                }`}
              >
                <span className="mx-2 whitespace-nowrap fs_14  text-344054 fw_600">
                  Date Range:
                </span>
                <Select
                  className="border-0"
                  menuPortalTarget={document.body}
                  styles={customStylesForDateRange}
                  value={filterDateRange.find(
                    (item) => item.value === filterBy
                  )}
                  options={filterDateRange}
                  onChange={handleFilterChange}
                />
              </div>

              {filterBy === "custom_date" && (
                <div
                  style={{
                    width: "fit-content",
                    height: "2.6rem",
                  }}
                  className="input_border rounded-md text-344054 fw_600 flex items-center md:flex-wrap"
                >
                  <div className="px-1 flex items-center">
                    <span className="fs_14">From:</span>
                    <input
                      style={{
                        width: "7rem",
                      }}
                      placeholder="DD-MM-YYYY"
                      className=" table_search_input w-full rounded-md mr-4 ml-1 fs_14 placeholder:text-fs_14"
                      type="date"
                      value={dateRangeFilter?.from}
                      onChange={(e) => {
                        setDateRangeFilter({
                          ...dateRangeFilter,
                          from: e.target.value,
                        });
                      }}
                    />
                    <span className="fs_14">To:</span>
                    <input
                      style={{
                        width: "7rem",
                      }}
                      placeholder="DD-MM-YYYY"
                      className=" table_search_input w-full rounded-md ml-1 fs_14 placeholder:text-fs_14"
                      type="date"
                      value={dateRangeFilter?.to}
                      onChange={(e) => {
                        setDateRangeFilter({
                          ...dateRangeFilter,
                          to: e.target.value,
                        });
                      }}
                    />
                  </div>
                </div>
              )}

              <div
                onClick={() => setOpenMoreFilter(!openMoreFilter)}
                style={{ maxWidth: "8rem", height: "2.6rem" }}
                className={`w-full px-2 cursor-pointer flex align-middle items-center rounded-md ${
                  isFocused ? "border border-primary" : "input_border"
                }`}
              >
                <PlusIcon
                  onClick={(e) => {
                    e.preventDefault();
                  }}
                  className={`w-4 text-primary`}
                />
                <span className="mx-2 whitespace-nowrap fs_14  text-344054 fw_600">
                  More Filter
                </span>
              </div>
              <div>
                <AddButton
                  onclkFn={handleApply}
                  prefixIcon={false}
                  text="Apply"
                  type="submit"
                />
              </div>
            </div>

            <div className="flex gap-3 pt-2">
              <div
                style={{ maxWidth: "16rem", height: "2.6rem" }}
                className={`w-full flex items-center rounded-md ${
                  isFocused ? "border border-primary" : "input_border"
                }`}
              >
                <span className="mx-2 whitespace-nowrap fs_14  text-344054 fw_600">
                  Group By:
                </span>
                <Select
                  className="border-0"
                  menuPortalTarget={document.body}
                  styles={customStyles}
                  value={groupByOptions.find((item) => item.value === groupBy)}
                  options={groupByOptions}
                  onChange={handleGroupByChange}
                />
              </div>
              <div
                style={{ maxWidth: "8rem", height: "2.6rem" }}
                className={`w-full flex items-center py-3 rounded-md ${
                  isFocused ? "border border-primary" : "input_border"
                }`}
              >
                <span className="mx-2 whitespace-nowrap fs_12  text-344054 fw_600">
                  Export
                </span>
                <Select
                  className="border-0"
                  menuPortalTarget={document.body}
                  styles={customStylesForExports}
                  options={exportOptions}
                  onChange={onClickExportCsv}
                  placeholder=""
                />
              </div>
              <div
                style={{ maxWidth: "8rem", height: "2.6rem" }}
                className={`w-full flex items-center py-3 rounded-md cursor-pointer`}
                onClick={() => setIsDrawerOpen(true)}
              >
                <ColoumnsButton />
              </div>

              <button
                style={{
                  height: "2.6rem",
                }}
                onClick={() => {
                  setFilterBy("");
                  setFilterRows([
                    {
                      column: null,
                      condition: null,
                      value: "",
                      from: null,
                      to: null,
                    },
                  ]);
                  setGroupByValue("");
                  setDateRangeFilter({
                    from: null,
                    to: null,
                  });
                  setFilters([]);
                  setDateRange("");
                  setGroupBy("");
                  setDateRangeFilterValue({
                    from: null,
                    to: null,
                  });
                }}
                className={`px-4 whitespace-nowrap fs_14  fw_600 rounded-md input_border flex items-center gap-2  h-full
                ${
                  JSON.stringify(reqData) === JSON.stringify(init) &&
                  "bg-F2F4F5"
                }
                `}
              >
                <ClearIcon />
                Clear All
              </button>
            </div>
          </div>
          {openMoreFilter && (
            <div
              className="flex flex-col gap-3 pt-5 mx-6"
              ref={filterContainerRef}
            >
              {filterRows.map((row, index) => (
                <div key={row.id} className="flex gap-3">
                  <div
                    style={{ maxWidth: "11rem", height: "2.6rem" }}
                    className={`w-full flex items-center rounded-md ${
                      isFocused ? "border border-primary" : "input_border"
                    }`}
                  >
                    <Select
                      className="border-0 w-full"
                      menuPortalTarget={document.body}
                      styles={customStylesForFilter}
                      value={filterOptions.find(
                        (item) => item.value === row.column
                      )}
                      options={filterOptions}
                      placeholder="Filters"
                      onChange={(selectedOption) =>
                        handleFilterRowChange(
                          index,
                          "column",
                          selectedOption?.value
                        )
                      }
                    />
                  </div>
                  <div
                    style={{ maxWidth: "11rem", height: "2.6rem" }}
                    className={`w-full flex items-center rounded-md ${
                      isFocused ? "border border-primary" : "input_border"
                    }`}
                  >
                    <Select
                      className="border-0 w-full"
                      menuPortalTarget={document.body}
                      styles={customStylesForFilter}
                      value={(row?.column?.includes("item_code")
                        ? item_codeOptions
                        : conditionsOptions
                      ).filter((item) => item.value === row.condition)}
                      options={
                        row?.column?.includes("item_code")
                          ? item_codeOptions
                          : conditionsOptions
                      }
                      placeholder="Conditions"
                      onChange={(selectedOption) =>
                        handleFilterRowChange(
                          index,
                          "condition",
                          selectedOption?.value
                        )
                      }
                    />
                  </div>

                  {row?.condition === "range" ? (
                    <>
                      <div
                        style={{ maxWidth: "15rem", height: "2.6rem" }}
                        className={`w-full flex items-center rounded-md ${
                          isFocused ? "border border-primary" : "input_border"
                        }`}
                      >
                        <input
                          placeholder="From"
                          className="table_search_input w-full rounded-md fs_12 h-full placeholder:text-fs_12 placeholder:text-gray px-3"
                          type={"number"}
                          value={row.from}
                          onChange={(e) =>
                            handleFilterRowChange(
                              index,
                              "value",
                              e.target.value
                            )
                          }
                          onKeyDown={(e) => {
                            if (e.key === "-" || e.key === "Subtract") {
                              e.preventDefault(); // Prevent minus key
                            }
                          }}
                        />
                      </div>
                      <div
                        style={{ maxWidth: "15rem", height: "2.6rem" }}
                        className={`w-full flex items-center rounded-md ${
                          isFocused ? "border border-primary" : "input_border"
                        }`}
                      >
                        <input
                          min={0}
                          placeholder="To"
                          className="table_search_input w-full rounded-md fs_12 h-full placeholder:text-fs_12 placeholder:text-gray px-3"
                          type={"number"}
                          value={row.to}
                          onChange={(e) =>
                            handleFilterRowChange(
                              index,
                              "value",
                              e.target.value
                            )
                          }
                          onKeyDown={(e) => {
                            if (e.key === "-" || e.key === "Subtract") {
                              e.preventDefault(); // Prevent minus key
                            }
                          }}
                        />
                      </div>
                    </>
                  ) : (
                    <div
                      style={{ maxWidth: "15rem", height: "2.6rem" }}
                      className={`w-full flex items-center rounded-md ${
                        isFocused ? "border border-primary" : "input_border"
                      }`}
                    >
                      <input
                        placeholder="Enter Values"
                        className="table_search_input w-full rounded-md fs_12 h-full placeholder:text-fs_12 placeholder:text-gray px-3"
                        type={"text"}
                        value={row.value}
                        onChange={(e) =>
                          handleFilterRowChange(index, "value", e.target.value)
                        }
                      />
                    </div>
                  )}

                  {index === filterRows.length - 1 ? (
                    <PlusIcon
                      onClick={handleAddFilterRow}
                      className={`w-5 text-primary cursor-pointer`}
                    />
                  ) : (
                    <div
                      className="mt-3"
                      onClick={() => handleRemoveFilterRow(index)}
                    >
                      <DeleteIcon className="cursor-pointer" />
                    </div>
                  )}
                </div>
              ))}
            </div>
          )}
          {openMoreFilter ? (
            <div
              className="mt-6 w-full flex bg-[#0000005a] relative z-2"
              style={{ height: overlayHeight }}
              onClick={() => {
                setOpenMoreFilter(!openMoreFilter);
              }}
            ></div>
          ) : (
            <div
              className="mt-3"
              style={{
                maxWidth: "100%",
                maxHeight: "87vh",
                overflowX: "auto",
                overflowY: "auto",
              }}
            >
              <table className="w-full">
                <thead className="bg-F9F9FB sticky top-0">
                  <tr>
                    <th
                      className={`settings-table-header whitespace-nowrap fs_14 fw_500 text-secondary bg-F9F9FB text-start`}
                    >
                      Sr No
                    </th>
                    {tableHeading.map((heading, index) => (
                      <th
                        className={`settings-table-header whitespace-nowrap fs_14 fw_500 text-secondary bg-F9F9FB text-start`}
                        key={heading}
                      >
                        {heading}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {tableData.map((item, index) => (
                    <tr key={index}>
                      <td className="setting-table-cell fs_14 fw_400 text-secondary">
                        {index + 1}
                      </td>
                      {tableHeading.map((heading, idx) => (
                        <td
                          className="setting-table-cell fs_14 fw_400 text-secondary text-nowrap"
                          key={idx}
                        >
                          {item[heading]}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
          <ColoumnsModal
            isModalOpen={isDrawerOpen}
            setIsModalOpen={() => setIsDrawerOpen(!isDrawerOpen)}
            selectedColumns={selectedColumns}
            availableColumns={availableColumns}
            setSelectedColumns={setSelectedColumns}
          />
        </>
      )}
    </InnerLayout>
  );
};

export const customStyles = {
  control: (provided, state) => ({
    ...provided,
    fontSize: "12px",
    fontWeight: "600",
    color: "#344054",
    width: "10rem",
    boxShadow: "none", // Remove box shadow
    border: "none",
    borderRadius: "6px",
  }),
  placeholder: (defaultStyles) => ({
    ...defaultStyles,
    color: "gray",
    fontFamily: "Inter, sans-serif",
    fontSize: "12px",
    fontWeight: "600",
    opacity: "1",
    position: "absolute",
    whiteSpace: "nowrap",
  }),
  indicatorSeparator: (provided, state) => ({
    ...provided,
    display: "none", // Hide indicator separator
  }),
  singleValue: (base) => ({
    ...base,
    fontWeight: "600",
    color: "#344054",
    fontSize: "12px",
  }),
  option: (provided, state) => ({
    ...provided,
    ...(state.data.color ? { color: state.data.color } : { color: "black" }),
    backgroundColor: state.isSelected ? "#e5ecffb2" : null,
    borderBottom: "1px solid #F5F5F5",
    fontSize: "12px",
    fontFamily: "Inter, sans-serif",
    "&:hover": {
      backgroundColor: state.isFocused ? "#0000002E" : "#0000002E",
    },
    "&::after": {
      content: state.isSelected ? '"✓"' : '""',
      color: "#007bff", // Blue color
      fontWeight: "normal",
      fontSize: "20px", // Larger size
      position: "absolute",
      right: "0.8rem",
      marginBottom: "22px",
    },
  }),
};

export const customStylesForDateRange = {
  control: (provided, state) => ({
    ...provided,
    fontSize: "12px",
    fontWeight: "600",
    color: "#344054",
    width: "9rem",
    boxShadow: "none", // Remove box shadow
    border: "none",
    borderRadius: "6px",
  }),
  placeholder: (defaultStyles) => ({
    ...defaultStyles,
    color: "gray",
    fontFamily: "Inter, sans-serif",
    fontSize: "12px",
    fontWeight: "600",
    opacity: "1",
    position: "absolute",
    whiteSpace: "nowrap",
  }),
  indicatorSeparator: (provided, state) => ({
    ...provided,
    display: "none", // Hide indicator separator
  }),
  singleValue: (base) => ({
    ...base,
    fontWeight: "600",
    color: "#344054",
    fontSize: "12px",
  }),
  option: (provided, state) => ({
    ...provided,
    ...(state.data.color ? { color: state.data.color } : { color: "black" }),
    backgroundColor: state.isSelected ? "#e5ecffb2" : null,
    borderBottom: "1px solid #F5F5F5",
    fontSize: "12px",
    fontFamily: "Inter, sans-serif",
    "&:hover": {
      backgroundColor: state.isFocused ? "#0000002E" : "#0000002E",
    },
    "&::after": {
      content: state.isSelected ? '"✓"' : '""',
      color: "#007bff", // Blue color
      fontWeight: "normal",
      fontSize: "20px", // Larger size
      position: "absolute",
      right: "0.8rem",
      marginBottom: "22px",
    },
  }),
};

export const customStylesForFilter = {
  control: (provided, state) => ({
    ...provided,
    fontSize: "12px",
    fontWeight: "600",
    color: "#344054",
    width: "100%",
    boxShadow: "none", // Remove box shadow
    border: "none",
    borderRadius: "6px",
  }),
  placeholder: (defaultStyles) => ({
    ...defaultStyles,
    color: "gray",
    fontFamily: "Inter, sans-serif",
    fontSize: "12px",
    fontWeight: "600",
    opacity: "1",
    position: "absolute",
    whiteSpace: "nowrap",
  }),
  indicatorSeparator: (provided, state) => ({
    ...provided,
    display: "none", // Hide indicator separator
  }),
  singleValue: (base) => ({
    ...base,
    fontWeight: "600",
    color: "#344054",
    fontSize: "12px",
  }),
  option: (provided, state) => ({
    ...provided,
    ...(state.data.color ? { color: state.data.color } : { color: "black" }),
    backgroundColor: state.isSelected ? "#e5ecffb2" : null,
    borderBottom: "1px solid #F5F5F5",
    display: "flex",
    fontSize: "12px",
    fontFamily: "Inter, sans-serif",
    position: "relative",
    paddingRight: "1.5rem",
    "&:hover": {
      backgroundColor: state.isFocused ? "#0000002E" : "#0000002E",
    },
    "&::after": {
      content: state.isSelected ? '"✓"' : '""',
      color: "#007bff", // Blue color
      fontWeight: "normal",
      fontSize: "20px", // Larger size
      position: "absolute",
      right: "0.8rem",
      marginBottom: "22px",
    },
  }),
};

export const customStylesForExports = {
  control: (provided, state) => ({
    ...provided,
    fontSize: "12px",
    fontWeight: "600",
    color: "#344054",
    width: "4rem",
    boxShadow: "none", // Remove box shadow
    border: "none",
    borderRadius: "6px",
  }),
  placeholder: (defaultStyles) => ({
    ...defaultStyles,
    color: "black",
    fontFamily: "Inter, sans-serif",
    fontSize: "12px",
    fontWeight: "600",
    opacity: "1",
    position: "absolute",
    whiteSpace: "nowrap",
  }),
  indicatorSeparator: (provided, state) => ({
    ...provided,
    display: "none", // Hide indicator separator
  }),
  singleValue: (base) => ({
    ...base,
    fontWeight: "600",
    color: "#344054",
    fontSize: "12px",
  }),
  option: (provided, state) => ({
    ...provided,
    ...(state.data.color ? { color: state.data.color } : { color: "black" }),
    backgroundColor: state.isSelected ? "#e5ecffb2" : null,
    borderBottom: "1px solid #F5F5F5",
    fontSize: "12px",
    fontFamily: "Inter, sans-serif",
    "&:hover": {
      backgroundColor: state.isFocused ? "#0000002E" : "#0000002E",
    },
  }),
};

export default ReportsLayout;
