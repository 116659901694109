import React from "react";
import TextAreaField from "../../forms/textarea-field";

const StoreRemarksInfoTab = ({ disabled = false }) => {
  return (
    <div className="w-full 2xl:w-[35%] lg:w-[60%] px-5 xl:px-10">
      <TextAreaField
        isDisabled={disabled}
        label="Remarks"
        placeholder="your remarks here..."
        name="payment_table_remarks"
        id={"payment_table_remarks"}
        isRequired={false}
        labelWidth="7rem"
        feildHeight="7rem"
      />
    </div>
  );
};

export default StoreRemarksInfoTab;

