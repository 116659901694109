import React, { useState } from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import ForgotPasswordKey from "../../assets/icons/forgot-pass-key";
import PasswordResetSuccess from "../../assets/icons/pwd_reset-success";
import LoginInputField from "../../components/forms/login-input-field";
import { SignInButton } from "../../components/buttons/button";
import MainContent from "../../components/auth-section/main-content";
import LoginNavigate from "../../components/auth-section/login-navigate";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { resetPasswordAction } from "../../redux/actions/auth-action";
import successToast from "../../components/helper-components/success-toast";
import errorToast from "../../components/helper-components/error-toast";

export default function PasswordReset() {
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token") || "";
  const [loading, setLoading] = useState(false);
  const dispath = useDispatch();
  const navigate = useNavigate();
  const [isReset, setIsSet] = useState(true);
  const initialValues = {
    user_pwd: "",
    confirm_user_pwd: "",
  };
  const validationSchema = {
    user_pwd: Yup.string()
      .required("Password is required")
      .min(8, "Must be atleast 8 characters long"),
    confirm_user_pwd: Yup.string()
      .required("Password is required")
      .min(8, "Must be atleast 8 characters long")
      .oneOf([Yup.ref("user_pwd")], "Passwords do not match"),
  };
  const onSubmit = (values, { resetForm }) => {
    setLoading(true);
    dispath(
      resetPasswordAction(
        {
          token: token,
          user_password: values?.confirm_user_pwd,
        },
        onSuccess,
        onError
      )
    );
  };

  const onSuccess = (data) => {
    successToast(data?.mesage);
    setIsSet(false);
    setLoading(false);
  };
  const onError = (data) => {
    errorToast(data?.data?.message);
    setLoading(false);
  };
  return isReset ? (
    <div className="flex justify-center mt-12 ">
      <div className="flex flex-col gap-2 w-96">
        <MainContent
          heading="Set New Password"
          content="Your new password must be different to previously used passwords."
          logo={<ForgotPasswordKey />}
        />
        <div>
          <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            validationSchema={Yup.object(validationSchema)}
            onSubmit={onSubmit}
          >
            {({ values }) => {
              return (
                <Form className="flex flex-col">
                  <LoginInputField
                    type="password"
                    name="user_pwd"
                    placeholder="Enter your password"
                    label="Password"
                    isPasswordEye={true}
                  />
                  <LoginInputField
                    type="password"
                    name="confirm_user_pwd"
                    placeholder="Enter your password"
                    label="Confirm Password"
                    isPasswordEye={true}
                  />
                  <SignInButton
                    loading={loading}
                    text="Reset Password"
                    type="submit"
                  />
                </Form>
              );
            }}
          </Formik>
        </div>
        <LoginNavigate
          text="Back to log in"
          onclkFn={() => navigate("/login")}
          isExpanded={true}
        />
      </div>
    </div>
  ) : (
    <div className="flex justify-center mt-12">
      <div className="flex flex-col gap-2 w-96">
        <MainContent
          heading="Password reset"
          content="Your password has been successfully reset. Click below to log in magically."
          logo={<PasswordResetSuccess />}
        />
        <div className="flex flex-col">
          <SignInButton
            onclkFn={() => {
              navigate("/login");
            }}
            text="Continue"
            type="button"
          />
        </div>
        <LoginNavigate
          text="Back to log in"
          onclkFn={() => navigate("/login")}
          isExpanded={true}
        />
      </div>
    </div>
  );
}
