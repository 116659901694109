import React from "react";
import InputField from "../../forms/input-field";
const StoreDetailsTab = ({ disabled = false }) => {
  return (
    <div className="w-full 2xl:w-[35%] lg:w-[60%] px-5 xl:px-10 mt-5">
      <InputField
        isDisabled={disabled}
        isRequired={true}
        type="number"
        name="store_rent_per_month"
        placeholder="xxxxx "
        label="Store rent per month"
        labelWidth="12.8rem"
      />
      <InputField
        isDisabled={disabled}
        isRequired={true}
        type="text"
        name="store_duration_concurrently"
        placeholder="xxxxx"
        label="Store duration concurrently"
        labelWidth="12.8rem"
      />
      <InputField
        isDisabled={disabled}
        isRequired={true}
        type="number"
        name="store_sale_per_day"
        placeholder="xxxxx"
        label="Store sale per day"
        labelWidth="12.8rem"
      />
      <InputField
        isDisabled={disabled}
        isRequired={true}
        type="number"
        name="store_medicine_sale"
        placeholder="xxxxx"
        label="Store medicine sale"
        labelWidth="12.8rem"
      />

      <InputField
        isDisabled={disabled}
        isRequired={true}
        type="number"
        name="store_otc_sale"
        placeholder="xxxxx"
        label="Store OTC sale"
        labelWidth="12.8rem"
      />
    </div>
  );
};

export default StoreDetailsTab;

