import { api } from "./api";

export const userCreateApi = (params) => {
  return api.post(`/user/create`, JSON.stringify(params));
};

export const userTableApi = (params) => {
  return api.get(
    `/user/getAll?pageNumber=${params?.pageNumber}&pageSize=${
      params?.pageSize
    }&sortBy=${params?.filterData?.sort_by}&sortOrder=${
      params?.filterData?.sort_order
    }&fromDate=${params?.filterData?.from}&toDate=${params?.filterData?.to}${
      params?.filterData?.inputValue &&
      `&searchColumn=${params?.filterData?.selectColumn}&searchValue=${params?.filterData?.inputValue}`
    }&role=${params?.filterData?.role}`,
    JSON.stringify(params)
  );
};

export const userViewApi = (params) => {
  return api.get(`/user/getById?user_id=${params}`, JSON.stringify(params));
};
export const userEditApi = (params) => {
  return api.post(`/user/update`, JSON.stringify(params));
};
export const userDeleteApi = (params) => {
  return api.post(`/user/delete`, JSON.stringify(params));
};

export const userPermissionsApi = (params) => {
  return api.get(
    `/user/permission/getById?user_id=${params?.user}${
      params?.search && `&module_name=${params?.search}`
    }`,
    JSON.stringify(params)
  );
};
export const updatePermissionsApi = (params) => {
  return api.post(
    `/user/permission/update`,
    JSON.stringify(params)
  );
};
