import { Field, useFormikContext } from "formik";
import React, { useEffect, useState } from "react";
import AddButton, { DraftButton } from "../buttons/button";
import TabsComponent from "../helper-components/tabs-component";
import SelectField from "../forms/select-field";
import InputField from "../forms/input-field";
import { useDispatch } from "react-redux";
import { glAccListAction } from "../../redux/actions/sales-action";

const PaymentInOutPopover = ({
  setPaymentMeans,
  disabled = false,
}) => {
  const dispatch = useDispatch();
  const { values, setFieldValue, errors } = useFormikContext();
  const [currentTab, setCurrentTab] = useState("Bank Transfer");
  const [gllist, setGllist] = useState([]);
  useEffect(() => {
    dispatch(glAccListAction(onSuccess, onError));
  }, []);
  const onSuccess = (data) => {
    setGllist(
      data?.data.map((item) => {
        return {
          label: item?.account_name,
          value: item?.account_id,
          account_code: item?.account_code,
        };
      })
    );
  };
  const onError = (data) => {};

  const currentTabIndex = values?.paymentModeData?.findIndex(
    (item) => item?.tab === currentTab
  );

  return (
    <div className="absolute w-[-webkit-fill-available]  z-40 h-[calc(100vh-60px)]">
      <div
        style={{
          height: `80%`,
        }}
        className={`bg-white flex flex-col justify-between `}
      >
        <div>
          <TabsComponent
            handleClickfn={(item) => {
              setCurrentTab(item);
            }}
            itemTabs={values?.paymentModeData?.map((item) => item?.tab)}
            setCurrentTab={setCurrentTab}
            currentTab={currentTab}
          />
          <div className="flex">
            <div className="xl:w-[32%] w-[50%] px-5 flex flex-col gap-5">
              <SelectField
                isDisabled={disabled}
                errMsgContainerClass="hidden"
                handleSelected={(selected) => {
                  setFieldValue(
                    `paymentModeData[${currentTabIndex}].account_name`,
                    selected?.label
                  );
                  setFieldValue(
                    `paymentModeData[${currentTabIndex}].account_code`,
                    selected?.account_code
                  );
                }}
                defaultValue={{
                  label: values.paymentModeData[currentTabIndex]?.account_name,
                }}
                placeholder="Select account"
                options={gllist}
                label="Account Effect"
                id={`paymentModeData[${currentTabIndex}].account_id`}
                name={`paymentModeData[${currentTabIndex}].account_id`}
                isSearchable={true}
                isRequired={false}
                labelWidth="8rem"
              />
              <InputField
                isDisabled={disabled}
                errMsgContainerClass="hidden"
                type="date"
                name={`paymentModeData[${currentTabIndex}].date_of_transfer`}
                placeholder=""
                label="Date  of Transfer"
                isRequired={true}
                labelWidth="8rem"
              />
            </div>
            <div className="xl:w-[38%] w-[50%] px-5  flex flex-col gap-5">
              {currentTab === "Bank Transfer" && (
                <InputField
                  isDisabled={disabled}
                  errMsgContainerClass="hidden"
                  type="number"
                  name={`paymentModeData[${0}].bank_reference_number`}
                  placeholder=""
                  label="Bank Reference Number"
                  labelWidth="11rem"
                />
              )}
              {currentTab === "UPI" && (
                <>
                  <InputField
                    isDisabled={disabled}
                    errMsgContainerClass="hidden"
                    type="number"
                    name={`paymentModeData[${2}].upi_reference_number`}
                    placeholder=""
                    label="UPI Reference"
                    labelWidth="11rem"
                  />
                  <InputField
                    isDisabled={disabled}
                    errMsgContainerClass="hidden"
                    type="number"
                    name={`paymentModeData[${2}].pmt_id`}
                    placeholder=""
                    label="Payment ID"
                    labelWidth="11rem"
                  />
                </>
              )}
              {currentTab === "Cheque" && (
                <>
                  <InputField
                    isDisabled={disabled}
                    errMsgContainerClass="hidden"
                    type="number"
                    name={`paymentModeData[${4}].cheque_number`}
                    placeholder=""
                    label="Cheque Number"
                    labelWidth="8rem"
                  />
                  <InputField
                    isDisabled={disabled}
                    errMsgContainerClass="hidden"
                    type="date"
                    name={`paymentModeData[${4}].date_of_cheque`}
                    placeholder=""
                    label="Date of Cheque"
                    labelWidth="8rem"
                  />
                </>
              )}
              {currentTab === "Card" && (
                <>
                  <InputField
                    isDisabled={disabled}
                    errMsgContainerClass="hidden"
                    type="number"
                    name={`paymentModeData[${3}].card_number`}
                    placeholder=""
                    label="Card Number"
                    labelWidth="8rem"
                  />
                  <InputField
                    isDisabled={disabled}
                    errMsgContainerClass="hidden"
                    type="date"
                    name={`paymentModeData[${3}].expiry_date`}
                    placeholder=""
                    label="Expiry"
                    labelWidth="8rem"
                  />
                </>
              )}
            </div>
          </div>
        </div>
        <div className="flex justify-between px-10 sticky bottom-0   bg-white py-2 ">
          <div className="bg-D5E6Ff flex items-center p-3  rounded-md justify-center">
            <label className="text-secondary fw_400">Amount (₹)</label>
            <Field
              name={`paymentModeData[${currentTabIndex}].pmt_total_amount`}
            >
              {({ field, form }) => (
                <input
                  disabled={disabled}
                  placeholder=""
                  {...field}
                  type="number"
                  className={`disPerInput ps-2 max-w-[7rem] text-secondary fw_500  bg-D5E6Ff  focus_outline__none input-field-no-spin-buttons`}
                />
              )}
            </Field>
          </div>
          {!disabled && (
            <div className="flex gap-4 items-center">
              <DraftButton
                className="text-F04438 border border-F04438  border-solid px-10 py-2 items-center rounded-md flex justify-center fw_500 fs_14"
                type="button"
                onclkFn={() => {
                  setFieldValue(
                    `paymentModeData[${currentTabIndex}].pmt_total_amount`,
                    0
                  );
                  setPaymentMeans(false);
                }}
                text="cancel"
              />
              <AddButton
                onclkFn={() => {
                  if (
                    !errors?.paymentModeData?.[currentTabIndex] ||
                    Object.keys(errors?.paymentModeData?.[currentTabIndex])
                      .length === 0
                  ) {
                    setPaymentMeans(false); 
                  }
                }}
                prefixIcon={false}
                text="Save"
                type="submit"
              />
            </div>
          )}
        </div>
      </div>
      <div
        className="bg-black h-[20%] opacity-50"
        onClick={() => setPaymentMeans(false)}
      ></div>
    </div>
  );
};

export default PaymentInOutPopover;
