import React, { useEffect, useState } from 'react';
import ReportsLayout from '../../../components/reports/reports-layout';
import { branchWiseSalesReportAction } from '../../../redux/actions/report-action';
import { useDispatch } from 'react-redux';
import moment from 'moment';

const BranchWiseSalesReport = () => {
    const [dateRangeFilter, setDateRangeFilterValue] = useState({
        from: null,
        to: null,
      });
    const dispatch = useDispatch()
    const [groupByOptions, setgroupByOptions] = useState([
        { label: "None", value: '' },
        { label: "Drug Code", value: 'oi.item_code' },
        { label: "Bill Date", value: 'so.created_date' },
    ]);

    const [loading, setLoading] = useState(false);
    const [filterOptions, setFilterOptions] = useState(Object.entries(tableColoumsn).map(([label, value]) => ({
        label,
        value
    })));
    const [selectedColumns, setSelectedColumns] = useState(tableHeading);
    const [filters, setFilters] = useState([])
    const [tableData, setTableData] = useState([]);
    const [dateRange, setDateRange] = useState('');
    const [groupBy, setGroupBy] = useState('')

    useEffect(() => {
        const selectedColumnValues = selectedColumns.map(column => tableColoumsn[column]);
        const requestData = {
            selectedColumns: selectedColumnValues,
            filters: filters.map(({ id, ...rest }) => rest),
            filter_by: dateRange,
            group_by: groupBy,
            from: dateRangeFilter?.from,
            to: dateRangeFilter?.to,
        };
        dispatch(branchWiseSalesReportAction(requestData, onSuccess, onError))
        setLoading(true);

    }, [selectedColumns, filters, dateRange, groupBy]);

    const isIso8601Date = (dateString) => {
        const iso8601Regex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/;
        return iso8601Regex.test(dateString);
    };

    const formatDate = (value) => {
        return moment(value).format('DD-MM-YYYY');
    };

    const transformData = (data) => {
        return data.map(item => {
            let transformedItem = {};
            for (let [key, value] of Object.entries(tableColoumsn)) {
                const rawValue = item[value.split('.').pop()];
                transformedItem[key] = isIso8601Date(rawValue) ? formatDate(rawValue) : rawValue;
            }
            return transformedItem;

        });
    };

    const onSuccess = (data) => {
        const transformedData = transformData(data?.data?.result);
        setTableData(transformedData);
        setLoading(false)
    }
    const onError = (data) => {
        console.log("error is", data);
        setLoading(false)
    }

    return (
        <ReportsLayout
            title={"Branch Wise Sales Report"}
            tableHeading={selectedColumns}
            tableData={tableData}
            groupByOptions={groupByOptions}
            filterOptions={filterOptions}
            availableColumns={tableHeading}
            selectedColumns={selectedColumns}
            setSelectedColumns={setSelectedColumns}
            setFilters={setFilters}
            loading={loading}
            setDateRange={setDateRange}
            setGroupBy={setGroupBy}
            setDateRangeFilterValue={setDateRangeFilterValue}
        />
    );
};

const tableHeading = ["Bill Date", "Bill Num", "Net Amount", "Tax", "Discounts", "Gross Amount", "Branch Name", "Branch Address", "Party Code",
    "Party Name"];

const tableColoumsn = {
    "Bill Date": "so.created_date",
    "Bill Num": "so.sot_invoice_number",
    "Net Amount": "so.sot_sub_total",
    "Tax": "so.sot_total_gst",
    "Discounts": "so.sot_total_discount",
    "Gross Amount": "so.sot_total_amount",
    "Branch Name": "si.firm_name",
    "Branch Address": "si.firm_address",
    "Party Code": "c.cmr_code",
    "Party Name": "c.cmr_first_name",
}


export default BranchWiseSalesReport;