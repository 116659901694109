import React, { useEffect, useState } from 'react';
import ReportsLayout from '../../../components/reports/reports-layout';
import { salesReturnReportAction } from '../../../redux/actions/report-action';
import { useDispatch } from 'react-redux';
import moment from 'moment';

const SalesReturnReport = () => {

    const dispatch = useDispatch()
    const [groupByOptions, setgroupByOptions] = useState([
        { label: "None", value: '' },
        { label: "Drug Code", value: 'sri.item_code' },
        { label: "Bill Date", value: 'sr.created_date' },]);

    const [loading, setLoading] = useState(false);
    const [filterOptions, setFilterOptions] = useState(Object.entries(tableColoumsn).map(([label, value]) => ({
        label,
        value
    })));
    const [selectedColumns, setSelectedColumns] = useState(tableHeading);
    const [filters, setFilters] = useState([])
    const [tableData, setTableData] = useState([]);
    const [dateRange, setDateRange] = useState('');
    const [groupBy, setGroupBy] = useState('')

    useEffect(() => {
        const selectedColumnValues = selectedColumns.map(column => tableColoumsn[column]);
        const requestData = {
            selectedColumns: selectedColumnValues,
            filters: filters.map(({ id, ...rest }) => rest),
            filter_by: dateRange,
            group_by: groupBy
        };

        dispatch(salesReturnReportAction(requestData, onSuccess, onError))
        setLoading(true);

    }, [selectedColumns, filters, dateRange, groupBy]);


    const isIso8601Date = (dateString) => {
        const iso8601Regex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/;
        return iso8601Regex.test(dateString);
    };

    const formatDate = (value) => {
        return moment(value).format('DD-MM-YYYY');
    };

    const transformData = (data) => {
        return data.map(item => {
            let transformedItem = {};
            for (let [key, value] of Object.entries(tableColoumsn)) {
                const rawValue = item[value.split('.').pop()];
                transformedItem[key] = isIso8601Date(rawValue) ? formatDate(rawValue) : rawValue;
            }
            return transformedItem;

        });
    };

    const onSuccess = (data) => {
        const transformedData = transformData(data?.data?.result);
        setTableData(transformedData);
        setLoading(false)
    }
    const onError = (data) => {
        console.log("error is", data);
        setLoading(false)
    }

    return (
        <ReportsLayout
            title={"Sales Return Report"}
            tableHeading={selectedColumns}
            tableData={tableData}
            groupByOptions={groupByOptions}
            filterOptions={filterOptions}
            availableColumns={tableHeading}
            selectedColumns={selectedColumns}
            setSelectedColumns={setSelectedColumns}
            setFilters={setFilters}
            loading={loading}
            setDateRange={setDateRange}
            setGroupBy={setGroupBy}
        />
    );
};

const tableHeading = ["Bill Date", "Bill Num", "Customer Code", "Customer Name", "Drug Code", "Drug Name", "Return Qty",
    "Qty", "Rate", "Net Amount", "Gross Amount", "Tax", "GST", "CGST", "IGST", "Discounts", "Item Discount",
    "Branch Name", "Batch Num", "Exp Date"];

const tableColoumsn = {
    "Bill Date": "sr.created_date",
    "Bill Num": "sr.srt_invoice_number",
    "Customer Code": "sr.cmr_code",
    "Customer Name": "sr.cmr_name",
    "Drug Code": "sri.item_code",
    "Drug Name": "sri.item_name",
    "Return Qty": "sri.item_quantity",
    "Qty": "sodt.so_item_quantity",
    // "UOM": "sri.item_uom",
    "Rate": "sri.item_unit_price",
    "Net Amount": "sr.srt_sub_total",
    "Gross Amount": "sr.srt_total_amount",
    "Tax": "so.srt_total_gst",
    "GST": "sri.item_gst",
    "CGST": "sri.item_cgst",
    "SGST": "sri.item_sgst",
    "IGST": "sri.item_igst",
    "Discounts": "sr.srt_total_discount",
    "Item Discount": "sri.item_discount_amount",
    "Branch Name": "si.firm_name",
    "Batch Num": "sri.item_batch_number",
    "Exp Date": "sri.item_exp_date"
}


export default SalesReturnReport;