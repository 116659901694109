import React from "react";

const ClearIcon = () => {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 524 458"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 0H523.429V55.9721L327.143 252.258V458H196.286V252.258L0 55.9721V0ZM490.714 42.1708V32.7143H32.7143V42.1708L229 238.456V425.286H294.429V238.456L490.714 42.1708ZM500.171 294.429L523.429 317.686L464.645 376.214L523.429 434.742L500.171 458L441.643 399.217L383.115 458L359.857 434.742L418.641 376.214L359.857 317.686L383.115 294.429L441.643 353.212L500.171 294.429Z"
        fill="black"
      />
    </svg>
  );
};

export default ClearIcon;
