
import { Form, Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import InputField from '../../../forms/input-field'
import { useDispatch } from 'react-redux';
import AddButton, { DraftButton } from '../../../buttons/button';
import * as Yup from "yup";
import { useNavigate } from 'react-router-dom';
import { ChevronLeftIcon } from '@heroicons/react/24/outline';
import InnerLayout from '../../../layouts/inner-layout';
import successToast from '../../../helper-components/success-toast';
import errorToast from '../../../helper-components/error-toast';
import { smsAddAction, smsUpdateAction, smsViewAction } from '../../../../redux/actions/settings-action';

const SmsConfiguration = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate()

    const [smsConfigData, setSmsConfigData] = useState({})

    const reload = () => {
        dispatch(smsViewAction({}, onSuccess, onError))
    }

    useEffect(() => {
        dispatch(smsViewAction({}, onSuccess, onError));
    }, []);

    const onSuccess = (data) => {
        
        setSmsConfigData(data?.data);
    };
    const onError = (data) => {  errorToast(data?.message) };

    const initialValues = {
        id: smsConfigData?.id || null,
        api_key: smsConfigData?.api_key || "",
        api_url: smsConfigData?.api_url || "",
        user_name: smsConfigData?.user_name || "",
        password: smsConfigData?.password || "",
    }
    const validationSchema = Yup.object().shape({
        api_key: Yup.string().required("Required"),
        api_url: Yup.string().required("Required"),
        user_name: Yup.string().required("Required"),
        password: Yup.string().required("Required"),
    });

    const onSubmit = (values, { resetForm }) => {
        
        if (values.id) {
            dispatch(smsUpdateAction(values, onCreateSuccess, onError))
        } else {
            dispatch(smsAddAction(values, onCreateSuccess, onError))
        }
    }

    const onCreateSuccess = (data) => { successToast(data?.message); reload() };

    return (
        <InnerLayout
            list={true}
            title="Settings"
            module="sms-configuration"
        // permissionFn={permissionFn}
        >
            <div className='pl-4 h-full'>
                <div className='flex justify-between pt-3'>
                    <div className=" pb-4 flex items-center gap-2">
                        <button
                            className="flex items-center gap-2"
                            onClick={() => {
                                navigate('/settings')
                            }}
                        >
                            <ChevronLeftIcon className="h-5 w-5 inline-block" />
                            Settings /
                        </button>
                        <span className="cursor-pointer" >{" " + "Message Api Config"}</span>
                    </div>
                </div>
                <Formik
                    enableReinitialize={true}
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                >
                    {({ values, setFieldValue, handleSubmit }) => (
                        <Form className="pl-4 pt-4">
                            <div className=''
                                style={{ height: "78vh" }}
                            >

                                <div className="w-full flex flex-col gap-4">
                                    <div style={{ maxWidth: "35rem" }} className="w-full">
                                        <InputField
                                            containerClass="mt-1"
                                            isRequired={true}
                                            type="text"
                                            name="api_key"
                                            placeholder="Enter the Api Key"
                                            label="Api Key"
                                            labelWidth="10rem"
                                        />
                                    </div>
                                    <div style={{ maxWidth: "35rem" }} className="w-full">
                                        <InputField
                                            containerClass="mt-1"
                                            isRequired={true}
                                            type="text"
                                            name="api_url"
                                            placeholder="Enter the api url"
                                            label="Api Url"
                                            labelWidth="10rem"
                                        />
                                    </div>
                                    <div style={{ maxWidth: "35rem" }} className="w-full">
                                        <InputField
                                            containerClass="mt-1"
                                            isRequired={true}
                                            type="text"
                                            name="user_name"
                                            placeholder="User name"
                                            label="User Name"
                                            labelWidth="10rem"
                                        />
                                    </div>
                                    <div style={{ maxWidth: "35rem" }} className="w-full">
                                        <InputField
                                            containerClass="mt-1"
                                            isRequired={true}
                                            type="text"
                                            name="password"
                                            placeholder="Enter Password"
                                            label="Password"
                                            labelWidth="10rem"
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="flex justify-between px-6  bg-white py-4 invoice-bottom-container">
                                <DraftButton
                                    onclkFn={() => navigate(`/settings`)}
                                    text="Cancel"
                                />
                                <AddButton
                                    onclkFn={handleSubmit}
                                    prefixIcon={false}
                                    text="Save"
                                    type="submit"
                                />
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </InnerLayout>
    )
}

export default SmsConfiguration
