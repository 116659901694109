import { Formik } from "formik";
import React from "react";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { productSalesCreateAction } from "../../redux/actions/product-action";
import AddButton from "../buttons/button";
import InputField from "../forms/input-field";
import SelectField from "../forms/select-field";
import successToast from "../helper-components/success-toast";

const SalesTab = ({
  productId,
  uomList,
  setFormValues,
  formValues,
  setCurrentTab,
  product,
  disabled = false,
  itemGeneralData = {},
  uomGroupData = [],
  uomGroupID = "",
}) => {
  const dispatch = useDispatch();

  console.log(productId, "fdgdfgdfgdfg");
  const initialValues = {
    item_id: product?.item_id ?? productId,
    item_sales_uom_name: product?.item_sales_uom_name ?? "",
    item_sales_uom_name_id: product?.item_sales_uom_name_id ?? "",
    item_per_sales_unit: product?.item_per_sales_unit ?? null,
    item_sales_packing_uom_name: product?.item_sales_packing_uom_name ?? "",
    item_sales_quantity_per_package:
      product?.item_sales_quantity_per_package ?? null,
    item_sales_measurement_unit: product?.item_sales_measurement_unit ?? "",
    item_sales_length: product?.item_sales_length ?? null,
    item_sales_width: product?.item_sales_width ?? null,
    item_sales_height: product?.item_sales_height ?? null,
    item_sales_volume: product?.item_sales_volume ?? null,
    item_sales_weight: product?.item_sales_weight ?? null,
    item_sales_factor_1: product?.item_sales_factor_1 ?? null,
    item_sales_factor_2: product?.item_sales_factor_2 ?? null,
    item_sales_factor_3: product?.item_sales_factor_3 ?? null,
    item_sales_factor_4: product?.item_sales_factor_4 ?? null,
  };

  const validationSchema = Yup.object().shape({
    item_sales_uom_name_id: Yup.string().required(
      "Sales UOM is required"
    ),
  });  const innerOnSubmit = (values) => {
    setFormValues({
      ...formValues,
      sales: values,
    });
    dispatch(
      productSalesCreateAction(
        {
          itemGeneralData: itemGeneralData,
          itemSalesData: values,
        },
        onSuccess,
        onError
      )
    );
  };

  const onSuccess = (data) => {
    successToast(data?.message);
    setCurrentTab("Inventory");
  };
  const onError = (data) => {};

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={formValues?.sales ?? initialValues}
        validationSchema={validationSchema}
        onSubmit={innerOnSubmit}
      >
        {({ values, handleSubmit, errors, setFieldValue }) => {
          return (
            // <Form>
            <>
              <div className="flex flex-wrap">
                <div className="w-full 2xl:w-[35%] lg:w-1/2 px-5 xl:px-10">
                  <SelectField
                    handleSelected={(sel) => {
                      setFieldValue("item_sales_uom_name", sel?.label);
                    }}
                    isDisabled={disabled}
                    placeholder="Select uom"
                    options={uomGroupData
                      ?.find((grp) => grp?.group_id === uomGroupID)
                      ?.group_items?.map((uom) => ({
                        label: uom?.alt_uom,
                        value: uom?.item_id,
                      }))}
                    label="Sales UOM Name"
                    id="item_sales_uom_name_id"
                    name="item_sales_uom_name_id"
                    isSearchable={true}
                    errMsgContainerClass="ps-2"
                    isRequired={true}
                    labelWidth="10rem"
                  />
                  <InputField
                    isDisabled={disabled}
                    type="number"
                    name="item_per_sales_unit"
                    placeholder="xxxxxxx"
                    label="Items per Sales Unit"
                    isRequired={false}
                    labelWidth="10rem"
                  />
                </div>
                <div className="w-full 2xl:w-[35%] lg:w-1/2 px-5 xl:px-10 mt-10 lg:mt-0">
                  <SelectField
                    isDisabled={disabled}
                    placeholder="Select uom"
                    options={uomList}
                    label="Packaging UoM Name"
                    id="item_sales_packing_uom_name"
                    name="item_sales_packing_uom_name"
                    isSearchable={true}
                    errMsgContainerClass="ps-2"
                    isRequired={false}
                    labelWidth="11rem"
                  />
                  <InputField
                    isDisabled={disabled}
                    type="number"
                    name="item_sales_quantity_per_package"
                    placeholder="xxxxxxx"
                    label="Quantity per Package"
                    isRequired={false}
                    labelWidth="11rem"
                  />
                </div>
              </div>
              <div className="flex flex-wrap mt-10">
                <div className="w-full 2xl:w-[35%] lg:w-1/2 px-5 xl:px-10">
                  <SelectField
                    isDisabled={disabled}
                    placeholder="Select measurement unit"
                    options={measurementUnitOptns}
                    label="Measurement Unit"
                    id="item_sales_measurement_unit"
                    name="item_sales_measurement_unit"
                    isSearchable={true}
                    errMsgContainerClass="ps-2"
                    isRequired={false}
                    labelWidth="10rem"
                  />
                  <InputField
                    isDisabled={disabled}
                    type="number"
                    name="item_sales_length"
                    placeholder="xxxxxxx"
                    label="Length"
                    isRequired={false}
                    labelWidth="10rem"
                  />

                  <InputField
                    isDisabled={disabled}
                    type="number"
                    name="item_sales_width"
                    placeholder="xxxxxxx"
                    label="Width"
                    isRequired={false}
                    labelWidth="10rem"
                  />
                  <InputField
                    isDisabled={disabled}
                    type="number"
                    name="item_sales_height"
                    placeholder="xxxxxxx"
                    label="Height"
                    isRequired={false}
                    labelWidth="10rem"
                  />
                  <InputField
                    isDisabled={disabled}
                    type="number"
                    name="item_sales_volume"
                    placeholder="xxxxxxx"
                    label="Volume"
                    isRequired={false}
                    labelWidth="10rem"
                  />

                  <InputField
                    isDisabled={disabled}
                    type="number"
                    name="item_sales_weight"
                    placeholder="xxxxxxx"
                    label="Weight"
                    isRequired={false}
                    labelWidth="10rem"
                  />
                </div>
                <div className="w-full 2xl:w-[35%] lg:w-1/2 px-5 xl:px-10 mt-10 lg:mt-0">
                  <InputField
                    labelClass="whitespace-nowrap flex fs_14 fw_400 text-D0D5DD"
                    isDisabled={true}
                    type="number"
                    name="item_sales_factor_1"
                    placeholder="xxxxxxx"
                    label="Factor 1"
                    isRequired={false}
                    labelWidth="11rem"
                  />
                  <InputField
                    labelClass="whitespace-nowrap flex fs_14 fw_400 text-D0D5DD"
                    isDisabled={true}
                    type="number"
                    name="item_sales_factor_2"
                    placeholder="xxxxxxx"
                    label="Factor 2"
                    isRequired={false}
                    labelWidth="11rem"
                  />
                  <InputField
                    labelClass="whitespace-nowrap flex fs_14 fw_400 text-D0D5DD"
                    isDisabled={true}
                    type="number"
                    name="item_sales_factor_3"
                    placeholder="xxxxxxx"
                    label="Factor 3"
                    isRequired={false}
                    labelWidth="11rem"
                  />
                  <InputField
                    labelClass="whitespace-nowrap flex fs_14 fw_400 text-D0D5DD"
                    isDisabled={true}
                    type="number"
                    name="item_sales_factor_4"
                    placeholder="xxxxxxx"
                    label="Factor 4"
                    isRequired={false}
                    labelWidth="11rem"
                  />
                </div>
              </div>
              {!disabled && (
                <div
                  style={{
                    bottom: "0",
                  }}
                  className="flex justify-end px-10 mb-5x sticky bg-white py-4 invoice-bottom-container"
                >
                  <AddButton
                    onclkFn={handleSubmit}
                    prefixIcon={false}
                    text="Save"
                    type="submit"
                  />
                </div>
              )}
            </>
          );
        }}
      </Formik>
    </>
  );
};

export default SalesTab;

export const serialBatchesOptns = [
  { label: "Batches", value: "batches" },
  { label: "Numbers", value: "numbers" },
  { label: "Batches and Numbers", value: "batches_numbers" },
];
export const measurementUnitOptns = [
  { label: "Meter", value: "meter" },
  { label: "Centimetres", value: "centimetres" },
  { label: "Inches", value: "inches" },
];
