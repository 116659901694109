import React, { useEffect, useState } from "react";
import InnerLayout from "../../components/layouts/inner-layout";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import LoadingSpinner from "../../components/helper-components/loading-spinner";
import { userViewAction } from "../../redux/actions/user-action";
import UsersCreateViewEdit from "../../components/users/users-create-view-edit";
const ViewUser = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const navigate = useNavigate();
  const [user, setUser] = useState({});
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    dispatch(userViewAction(id, onSuccess, onError));
  }, []);
  const onSuccess = (data) => {
    setLoading(false);
    setUser(data?.data?.[0]);
  };
  const onError = (data) => {
    loading(false);
  };
  return (
    <>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <InnerLayout
          backBtn={true}
          title1={user?.user_id}
          title={"User View"}
          view={true}
          onclkEditFn={() => navigate(`/edit-user/${id}`)}
        >
          <UsersCreateViewEdit page={"view"} user={user} disabled={true} />
        </InnerLayout>
      )}
    </>
  );
};

export default ViewUser;
