import React from "react";
import InnerLayout from "../../components/layouts/inner-layout";
import "../../components/customer-information/customer-information.css";
import DoctorCreateViewEdit from "../../components/doctor-information/doctor-view-edit";
const AddDoctor = () => {
  return (
    <InnerLayout title="Add Doctor ">
      <DoctorCreateViewEdit page="add" />
    </InnerLayout>
  );
};

export default AddDoctor;
