export const initialOrderState = {
  phone: null,
  gst_tax: "",
  RepeatLastOrder: false,
  data: [],
  doctor: "",
  payment_method: "Cash",
  paymentData: [
    {
      payment_mode_name: "",
      payment_amount: null,
      transaction: "",
    },
    {
      payment_mode_name: "",
      payment_amount: null,
      transaction: "",
    },
    {
      payment_mode_name: "",
      payment_amount: null,
      transaction: "",
    },
  ],
};
