import {
  DOCTOR_CREATE,
  DOCTOR_DELETE,
  DOCTOR_EDIT,
  DOCTOR_TABLE,
  DOCTOR_VIEW,
} from "../types";

export const doctorCreateAction = (data, onSuccess, onError) => {
  return {
    type: DOCTOR_CREATE,
    data,
    onSuccess,
    onError,
  };
};

export const doctorTableAction = (data, onSuccess, onError) => {
  return {
    type: DOCTOR_TABLE,
    data,
    onSuccess,
    onError,
  };
};

export const doctorViewAction = (data, onSuccess, onError) => {
  return {
    type: DOCTOR_VIEW,
    data,
    onSuccess,
    onError,
  };
};
export const doctorEditAction = (data, onSuccess, onError) => {
  return {
    type: DOCTOR_EDIT,
    data,
    onSuccess,
    onError,
  };
};
export const doctorDeleteAction = (data, onSuccess, onError) => {
  return {
    type: DOCTOR_DELETE,
    data,
    onSuccess,
    onError,
  };
};
