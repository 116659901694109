import {
  ADMIN_CUSTOMER_ACCOUNTING,
  ADMIN_CUSTOMER_ADDRESS,
  ADMIN_CUSTOMER_BNK_DTLS,
  ADMIN_CUSTOMER_CONTACT_PERSON,
  ADMIN_CUSTOMER_CREATE,
  ADMIN_CUSTOMER_DELETE,
  ADMIN_CUSTOMER_EDIT,
  ADMIN_CUSTOMER_PAYMENT_TERMS,
  ADMIN_CUSTOMER_TABLE,
  ADMIN_CUSTOMER_UPDATE_ACCOUNTING,
  ADMIN_CUSTOMER_UPDATE_ADDRESS,
  ADMIN_CUSTOMER_UPDATE_BNK_DTLS,
  ADMIN_CUSTOMER_UPDATE_CONTACT_PERSON,
  ADMIN_CUSTOMER_UPDATE_PAYMENT_TERMS,
  ADMIN_CUSTOMER_VIEW,
} from "../types";

export const adminCustomerCreateAction = (data, onSuccess, onError) => {
  return {
    type: ADMIN_CUSTOMER_CREATE,
    data,
    onSuccess,
    onError,
  };
};

export const adminCustomerTableAction = (data, onSuccess, onError) => {
  return {
    type: ADMIN_CUSTOMER_TABLE,
    data,
    onSuccess,
    onError,
  };
};

export const adminCustomerViewAction = (data, onSuccess, onError) => {
  return {
    type: ADMIN_CUSTOMER_VIEW,
    data,
    onSuccess,
    onError,
  };
};
export const adminCustomerEditAction = (data, onSuccess, onError) => {
  return {
    type: ADMIN_CUSTOMER_EDIT,
    data,
    onSuccess,
    onError,
  };
};
export const adminCustomerDeleteAction = (data, onSuccess, onError) => {
  return {
    type: ADMIN_CUSTOMER_DELETE,
    data,
    onSuccess,
    onError,
  };
};

export const adminCustomerContactPersonAction = (data, onSuccess, onError) => {
  return {
    type: ADMIN_CUSTOMER_CONTACT_PERSON,
    data,
    onSuccess,
    onError,
  };
};
export const adminCustomerBnkDetailsAction = (data, onSuccess, onError) => {
  return {
    type: ADMIN_CUSTOMER_BNK_DTLS,
    data,
    onSuccess,
    onError,
  };
};
export const adminCustomerPaymentTermsAction = (data, onSuccess, onError) => {
  return {
    type: ADMIN_CUSTOMER_PAYMENT_TERMS,
    data,
    onSuccess,
    onError,
  };
};
export const adminCustomerAccountingAction = (data, onSuccess, onError) => {
  return {
    type: ADMIN_CUSTOMER_ACCOUNTING,
    data,
    onSuccess,
    onError,
  };
};
export const adminCustomerAddressAction = (data, onSuccess, onError) => {
  return {
    type: ADMIN_CUSTOMER_ADDRESS,
    data,
    onSuccess,
    onError,
  };
};

export const adminCustomerUpdateContactPersonAction = (
  data,
  onSuccess,
  onError
) => {
  return {
    type: ADMIN_CUSTOMER_UPDATE_CONTACT_PERSON,
    data,
    onSuccess,
    onError,
  };
};
export const adminCustomerUpdateBnkDetailsAction = (
  data,
  onSuccess,
  onError
) => {
  return {
    type: ADMIN_CUSTOMER_UPDATE_BNK_DTLS,
    data,
    onSuccess,
    onError,
  };
};
export const adminCustomerUpdatePaymentTermsAction = (
  data,
  onSuccess,
  onError
) => {
  return {
    type: ADMIN_CUSTOMER_UPDATE_PAYMENT_TERMS,
    data,
    onSuccess,
    onError,
  };
};
export const adminCustomerUpdateAccountingAction = (
  data,
  onSuccess,
  onError
) => {
  return {
    type: ADMIN_CUSTOMER_UPDATE_ACCOUNTING,
    data,
    onSuccess,
    onError,
  };
};
export const adminCustomerUpdateAddressAction = (data, onSuccess, onError) => {
  return {
    type: ADMIN_CUSTOMER_UPDATE_ADDRESS,
    data,
    onSuccess,
    onError,
  };
};
