import React from "react";

const PrintPreferenceIcon = () => {
    return (
        <svg width="22" height="22" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15 11.5H15.5C16.443 11.5 16.914 11.5 17.207 11.207C17.5 10.914 17.5 10.443 17.5 9.5V8.5C17.5 6.614 17.5 5.672 16.914 5.086C16.328 4.5 15.386 4.5 13.5 4.5H4.5C2.614 4.5 1.672 4.5 1.086 5.086C0.5 5.672 0.5 6.614 0.5 8.5V10.5C0.5 10.971 0.5 11.207 0.646 11.354C0.793 11.5 1.029 11.5 1.5 11.5H3" stroke="white" />
            <path d="M3.5 17.806V9.5C3.5 8.557 3.5 8.086 3.793 7.793C4.086 7.5 4.557 7.5 5.5 7.5H12.5C13.443 7.5 13.914 7.5 14.207 7.793C14.5 8.086 14.5 8.557 14.5 9.5V17.806C14.5 18.123 14.5 18.281 14.396 18.356C14.292 18.431 14.142 18.381 13.842 18.281L11.674 17.558C11.6191 17.5345 11.5606 17.5203 11.501 17.516C11.4415 17.5236 11.3837 17.5412 11.33 17.568L9.186 18.426C9.12791 18.4556 9.06486 18.4743 9 18.481C8.93514 18.4743 8.87209 18.4556 8.814 18.426L6.67 17.568C6.6166 17.5414 6.55915 17.5238 6.5 17.516C6.44004 17.52 6.3812 17.5342 6.326 17.558L4.158 18.281C3.858 18.381 3.708 18.431 3.604 18.356C3.5 18.281 3.5 18.123 3.5 17.806Z" stroke="white" />
            <path d="M6.5 11.5H10.5M6.5 14.5H11.5" stroke="white" stroke-linecap="round" />
            <path d="M14.5 4.5V4.1C14.5 2.403 14.5 1.554 13.973 1.027C13.446 0.5 12.597 0.5 10.9 0.5H7.1C5.403 0.5 4.554 0.5 4.027 1.027C3.5 1.554 3.5 2.403 3.5 4.1V4.5" stroke="white" />
        </svg>
    )
}

export default PrintPreferenceIcon


