import React, { useEffect, useState } from "react";
import InnerLayout from "../../../components/layouts/inner-layout";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import LoadingSpinner from "../../../components/helper-components/loading-spinner";
import CreateEditViewPurchaseInvoice from "../../../components/purchase-section/purchase-invoice/create-edit-view-purchase-invoice";
import {
  goodsItemsGetAction,
  purchaseItemsGetAction,
} from "../../../redux/actions/purchase-action";

const AddPurchaseInvoice = () => {
  const { itemId, type } = useParams();
  const itemIdArray = JSON.parse(decodeURIComponent(itemId));

  const allitems = itemIdArray.map((item) => {
    const { cmr_id, ...rest } = item;
    return rest;
  });
  const [orderDetail, setOrderDetail] = useState({});
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {}, []);

  useEffect(() => {
    if (type === "purchase") {
      setLoading(true);
      dispatch(purchaseItemsGetAction(allitems, onSuccPur, onErrPur));
    } else if (type === "goods") {
      setLoading(true);
      dispatch(goodsItemsGetAction(allitems, onSuccPur, onErrPur));
    }
  }, []);

  const onSuccPur = (data) => {
    setOrderDetail(data?.data);
    setLoading(false);
  };

  const onErrPur = (data) => {
    setLoading(false);
  };
  
  return (
    <InnerLayout title="Add Purchase Invoice">
      {loading ? (
        <LoadingSpinner />
      ) : (
        <CreateEditViewPurchaseInvoice
          type={type}
          orderDetail={orderDetail?.itemData}
          vendorDetails={orderDetail?.vendorDetails}
        />
      )}
    </InnerLayout>
  );
};

export default AddPurchaseInvoice;