import React, { Fragment, useState } from "react";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import LoginInputField from "../../components/forms/login-input-field";
import LoginLogo from "../../assets/icons/login-logo";
import MainContent from "../../components/auth-section/main-content";
import { SignInButton, NavigateButton } from "../../components/buttons/button";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { storeLoginAction } from "../../redux/actions/auth-action";
import errorToast from "../../components/helper-components/error-toast";
import successToast from "../../components/helper-components/success-toast";

export default function UserLogin({
  isContainer = true,
  containerClass = "h-full w-screen flex",
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const initialValues = {
    user_email: "",
    password: "",
    user_check: false,
  };
  const validationSchema = {
    user_email: Yup.string()
      .email("Must be a valid email")
      .required("Email is required"),
    password: Yup.string()
      .required("Password is required")
      .min(8, "Must be atleast 8 characters long"),
    user_check: Yup.boolean(),
  };
  const Container = isContainer ? "div" : Fragment;

  const onSubmit = (values, { resetForm }) => {
    setLoading(true);
    dispatch(
      storeLoginAction(
        {
          user_email: values?.user_email,
          password: values?.password,
        },
        onSuccess,
        onError
      )
    );
  };
  const onSuccess = (data) => {
    setLoading(false);
    navigate("/add-invoice");
    successToast(data?.message);
  };
  const onError = (data) => {
    setLoading(false);
    errorToast(data?.data?.message);
  };

  return (
    <Container
      {...(isContainer && containerClass ? { className: containerClass } : {})}
    >
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={Yup.object(validationSchema)}
        onSubmit={onSubmit}
      >
        {({ values }) => {
          return (
            <Form className="w-1/2 h-screen flex items-center justify-center">
              <div className="flex flex-col gap-5">
                <MainContent
                  heading="Sign In"
                  content="Welcome back! Please enter your details."
                />
                <div>
                  <LoginInputField
                    type="text"
                    name="user_email"
                    placeholder="Enter your email"
                    label="Email Address"
                    isRequired={true}
                  />
                  <LoginInputField
                    type="password"
                    name="password"
                    placeholder="Enter your password"
                    label="Password"
                    isRequired={true}
                    isPasswordEye={true}
                  />
                </div>
                <div className="flex justify-between fs_14">
                  <div className="flex items-center justify-center">
                    <Field
                      type="checkbox"
                      id="remember_checkbox"
                      name="user_check"
                    />
                    <label
                      className="text-344054 ml-2 cursor-pointer"
                      htmlFor="remember_checkbox"
                    >
                      {" "}
                      Remember for 30 days
                    </label>
                  </div>
                  <NavigateButton
                    text="Forgot password?"
                    onclkFn={() => navigate("/forgot-password")}
                  />
                </div>
                <SignInButton loading={loading} text="Sign in" type="submit" />
              </div>
            </Form>
          );
        }}
      </Formik>
      <div className="w-1/2 h-screen bg-F9FAFB">
        <div className="relative flex flex-col items-center justify-center w-full h-screen">
          <div className="mb-12 text-center">
            <div>
              <span className="fs_32 fw_500">
                Nex<span className="fw_200">Tech</span>
              </span>
            </div>
            <div>
              <LoginLogo />
            </div>
          </div>
          <div className="absolute bottom-0 w-full h-1/2 bg-gray-100 bg-opacity-20 backdrop-blur-md"></div>
        </div>
      </div>
    </Container>
  );
}
