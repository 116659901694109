import {
  ADD_STR_ACCOUNT_DETAILS,
  ADD_STR_BRANDING_DETAILS,
  ADD_STR_BRAND_SFT_DETAILS,
  ADD_STR_DISPATCH_DETAILS,
  ADD_STR_FOLLOW_UP_REMARKS,
  ADD_STR_INFRA_DETAILS,
  ADD_STR_OPENING_DETAILS,
  FOLLOW_UP_REMARKS_GET,
  STORE_ADDRESS_CREATE,
  STORE_ADDRESS_UPDATE,
  STORE_AGREEMENT_CREATE,
  STORE_DRUG_CREATE,
  STORE_EXE_GET,
  STORE_FIRM_CREATE,
  STORE_PAYMENT_CREATE,
  STORE_PERSONAL_CREATE,
  STORE_TABLE,
  STORE_VIEW,
} from "../types";

export const StoreAddressCreateAction = (data, onSuccess, onError) => {
  return {
    type: STORE_ADDRESS_CREATE,
    data,
    onSuccess,
    onError,
  };
};

export const StoreAPersonalCreateAction = (data, onSuccess, onError) => {
  return {
    type: STORE_PERSONAL_CREATE,
    data,
    onSuccess,
    onError,
  };
};

export const StoreFirmCreateAction = (data, onSuccess, onError) => {
  return {
    type: STORE_FIRM_CREATE,
    data,
    onSuccess,
    onError,
  };
};

export const StoreAgreementCreateAction = (data, onSuccess, onError) => {
  return {
    type: STORE_AGREEMENT_CREATE,
    data,
    onSuccess,
    onError,
  };
};

export const StorePaymentCreateAction = (data, onSuccess, onError) => {
  return {
    type: STORE_PAYMENT_CREATE,
    data,
    onSuccess,
    onError,
  };
};

export const StoreDrugCreateAction = (data, onSuccess, onError) => {
  return {
    type: STORE_DRUG_CREATE,
    data,
    onSuccess,
    onError,
  };
};

export const StoreAddressUpdateAction = (data, onSuccess, onError) => {
  return {
    type: STORE_ADDRESS_UPDATE,
    data,
    onSuccess,
    onError,
  };
};

export const storeTableAction = (data, onSuccess, onError) => {
  return {
    type: STORE_TABLE,
    data,
    onSuccess,
    onError,
  };
};
export const storeViewAction = (data, onSuccess, onError) => {
  return {
    type: STORE_VIEW,
    data,
    onSuccess,
    onError,
  };
};

// STORE EXECUTION



export const addStrInfraAction = (data, onSuccess, onError) => {
  return {
    type: ADD_STR_INFRA_DETAILS,
    data,
    onSuccess,
    onError,
  };
};
export const addStrbrandAction = (data, onSuccess, onError) => {
  return {
    type: ADD_STR_BRANDING_DETAILS,
    data,
    onSuccess,
    onError,
  };
};
export const addStrBrandSoftwareAction = (data, onSuccess, onError) => {
  return {
    type: ADD_STR_BRAND_SFT_DETAILS,
    data,
    onSuccess,
    onError,
  };
};
export const addStrAccAction = (data, onSuccess, onError) => {
  return {
    type: ADD_STR_ACCOUNT_DETAILS,
    data,
    onSuccess,
    onError,
  };
};
export const addStrDispatchAction = (data, onSuccess, onError) => {
  return {
    type: ADD_STR_DISPATCH_DETAILS,
    data,
    onSuccess,
    onError,
  };
};
export const addStrOpeningAction = (data, onSuccess, onError) => {
  return {
    type: ADD_STR_OPENING_DETAILS,
    data,
    onSuccess,
    onError,
  };
};
export const addStrFollowUpAction = (data, onSuccess, onError) => {
  return {
    type: ADD_STR_FOLLOW_UP_REMARKS,
    data,
    onSuccess,
    onError,
  };
};
export const strFollowUpGetAction = (data, onSuccess, onError) => {
  return {
    type: FOLLOW_UP_REMARKS_GET,
    data,
    onSuccess,
    onError,
  };
};
export const strExeGetAction = (data, onSuccess, onError) => {
  return {
    type: STORE_EXE_GET,
    data,
    onSuccess,
    onError,
  };
};





















