import { api } from "./api";

export const dashboardSalesApi = (params) => {
  return api.get(
    `/sales-dashboard/get?period=${params?.lineGraph}&from=${
      params?.lineGraphDate?.from ?? null
    }&to=${params?.lineGraphDate?.to ?? null}`
  );
};
export const dashboardNewCusGraphApi = (params) => {
  return api.get(
    `/new-customers-graph?period=${params?.cusGraph}&from=${
      params?.cusGraphDate?.from ?? null
    }&to=${params?.cusGraphDate?.to ?? null}`
  );
};
export const dashboardTopSellingProductsApi = (params) => {
  return api.get(
    `/top-selling-products?period=${params?.barGraph}&type=${
      params?.barRadio
    }&from=${params?.barGraphDate?.from ?? null}&to=${
      params?.barGraphDate?.to ?? null
    }`
  );
};
export const dashboardLowStockApi = (params) => {
  return api.get(`/low-stock-products`);
};
export const dashboardExpStockApi = (params) => {
  return api.get(`/expiring-soon-products`);
};
