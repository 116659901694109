import { Form, Formik } from "formik";
import React, { useState } from "react";
import * as Yup from "yup";
import AddButton from "../../buttons/button";
import InputField from "../../forms/input-field";
import TabsComponent from "../../helper-components/tabs-component";

import { useDispatch } from "react-redux";
import { StoreFirmCreateAction } from "../../../redux/actions/store-action";
import successToast from "../../helper-components/success-toast";
import errorToast from "../../helper-components/error-toast";
import StoreFirmShopAddressTab from "./firm-shop-address";
import SelectField from "../../forms/select-field";
import CheckBox from "../../forms/check-box-field";
import StorePanGstTab from "./pan-gst-tab";

const StoreFirmTab = ({
  id,
  setStoreData,
  storeData,
  initialValue,
  disabled = false,
}) => {
  const [currentTab, setCurrentTab] = useState("Shop Address");
  const dispatch = useDispatch();
  const initialValues = {
    store_id: id ?? null,
    firm_name: initialValue?.firm_name ?? "",
    firm_type: initialValue?.firm_type ?? "",
    firm_authorised_person: initialValue?.firm_authorised_person ?? "",
    designation: initialValue?.designation ?? "",
    can_change_firm_name: initialValue?.can_change_firm_name ?? false,
    firm_address: initialValue?.firm_address ?? "",
    firm_area: initialValue?.firm_area ?? "",
    firm_landmark: initialValue?.firm_landmark ?? "",
    firm_town_or_village: initialValue?.firm_town_or_village ?? "",
    firm_city: initialValue?.firm_city ?? "",
    firm_state: initialValue?.firm_state ?? "",
    firm_pin_code: initialValue?.firm_pin_code ?? "",
    firm_country_region: initialValue?.firm_country_region ?? "india",
    firm_agreement_with_davaindia_conditions:
      initialValue?.firm_agreement_with_davaindia_conditions ?? false,
    pan_type: initialValue?.pan_type ?? "",
    pan_number: initialValue?.pan_number ?? "",
    pan_pic_front: initialValue?.pan_pic_front ?? "",
    pan_pic_back: initialValue?.pan_pic_back ?? "",
  };

  const validationSchema = Yup.object().shape({
    pan_number: Yup.string()
      .required("PAN number is required")
      .matches(/^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/, "Invalid PAN number"),
    firm_address: Yup.string().required("Firm address is required"),
    firm_area: Yup.string().required("Firm area is required"),
    firm_landmark: Yup.string().required("Firm landmark is required"),
    firm_town_or_village: Yup.string().required(
      "Firm town or village is required"
    ),
    firm_city: Yup.string().required("Firm city is required"),
    firm_state: Yup.string().required("Firm state is required"),
    firm_pin_code: Yup.string()
      .required("Firm pin code is required")
      .matches(/^[1-9][0-9]{5}$/, "Invalid Pin Code"),
    firm_country_region: Yup.string().required(
      "Firm country or region is required"
    ),
    pan_pic_front: Yup.string().required("PAN Front image is required"),
    pan_pic_back: Yup.string().required("PAN Back image is required"),
  });

  const onSubmit = (values, actions) => {
    dispatch(StoreFirmCreateAction(values, onSuccess, onError));
  };

  const onSuccess = (data) => {
    setStoreData({
      ...storeData,
      firm: data?.data,
    });
    successToast(data?.data?.message);
  };
  const onError = (data) => {
    errorToast(data?.data?.message);
  };

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ values, setFieldValue, isSubmitting, errors }) => {
        
        
        return (
          <Form style={{ height: "calc(100% - 5rem)" }} className="pt-5">
            <div className="flex flex-col justify-between h-full ">
              <div>
                <div className="flex flex-wrap items-center">
                  <div className="w-full 2xl:w-[35%] lg:w-[50%] px-5 xl:px-10">
                    <InputField
                      isDisabled={disabled}
                      type="text"
                      name="firm_name"
                      placeholder=""
                      label="Firm Name"
                      labelWidth="10.5rem"
                    />
                    <SelectField
                      isDisabled={disabled}
                      placeholder={"Select firm type"}
                      options={firmTypeList}
                      label={"Firm Type"}
                      id={"firm_type"}
                      name="firm_type"
                      isSearchable={true}
                      errMsgContainerClass="ps-2"
                      labelWidth="10.5rem"
                    />
                    <InputField
                      isDisabled={disabled}
                      type="text"
                      name="firm_authorised_person"
                      placeholder=""
                      label="Authorised Person"
                      labelWidth="10.5rem"
                    />

                    <InputField
                      isDisabled={disabled}
                      type="text"
                      name="designation"
                      placeholder=""
                      label="Designation"
                      labelWidth="10.5rem"
                    />
                  </div>
                  <div className="w-[80%] 2xl:w-[20%] lg:w-[30%] px-5 xl:px-10 mt-10 lg:mt-0">
                    <div className="border-D0D5DD border border-1 py-2 px-2 rounded-lg">
                      <CheckBox items={items} />
                    </div>
                  </div>
                </div>
                <TabsComponent
                  handleClickfn={(item) => {
                    setCurrentTab(item);
                  }}
                  itemTabs={itemTabs}
                  setCurrentTab={setCurrentTab}
                  currentTab={currentTab}
                />
                {currentTab === "Shop Address" && (
                  <StoreFirmShopAddressTab disabled={disabled} />
                )}
                {currentTab === "Pan & GST" && (
                  <StorePanGstTab disabled={disabled} />
                )}
              </div>
              <div
                className="sticky"
                style={{
                  bottom: "0",
                }}
              >
                <div
                  style={{
                    height: "5rem",
                  }}
                  className="px-5 xl:px-10 bg-D0D5DD flex items-center"
                >
                  <CheckBox items={terms} />
                </div>

                {!disabled && (
                  <div className="flex justify-end px-10 mb-5x  bg-white py-4 invoice-bottom-container">
                    <AddButton
                      onclkFn={() => {
                        if (Object.keys(errors).length !== 0) {
                          const currentIndex = itemTabs.indexOf(currentTab);
                          let nextTab;

                          if (currentIndex !== -1) {
                            // Go to the next tab based on the current tab index
                            nextTab =
                              currentIndex === itemTabs.length - 1
                                ? itemTabs[0] // Loop back to the first tab if the last tab is reached
                                : itemTabs[currentIndex + 1];

                            setCurrentTab(nextTab);
                          }
                        }
                      }}
                      prefixIcon={false}
                      text="Save"
                      type="submit"
                    />
                  </div>
                )}
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default StoreFirmTab;
const itemTabs = ["Shop Address", "Pan & GST"];
const firmTypeList = [{ label: "Hold", value: "hold" }];
const items = [
  {
    label: "Can change firm name",
    name: "can_change_firm_name",
    isRequired: false,
  },
];
const terms = [
  {
    label:
      "I agree to apply and run my store with “Davaindia Generic Pharmacy” name. Davaindia can cancel my franchise in case DL is not as per said name.",
    name: "firm_agreement_with_davaindia_conditions",
    isRequired: false,
  },
];
