import { Field } from "formik";
import React from "react";
import StarIcon from "../../assets/icons/req-star";
import ErrorMsg from "./error-msg";

const CheckBox = ({
  items,
  conClass = "flex gap-4 ",
  isDisabled = false,
  width = "",
  height = "",
  onChange = () => {},
}) => {
  return (
    <div className={`${conClass}`}>
      {items.map((item, index) => (
        <div key={index} className="flex items-center cursor-pointer">
          <Field name={item.name} type="checkbox">
            {({ field, form }) => (
              <label
                style={{
                  width: width,
                  height: height,
                }}
                className="whitespace-nowrap cursor-pointer flex items-center pr-3 fs_14 fw_400 text-secondary"
              >
                <input
                  {...field}
                  type="checkbox"
                  disabled={isDisabled}
                  className="mr-2"
                  checked={field.value}
                  onChange={(e) => {
                    form.setFieldValue(field.name, e.target.checked);
                    onChange(field.name);
                  }}
                />
                {item.label}
                {item.isRequired && <StarIcon />}
              </label>
            )}
          </Field>
          {item.isRequired && (
            <div style={{ height: "1.5rem" }} className="text-right fs_12">
              <ErrorMsg name={item.name} />
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default CheckBox;
