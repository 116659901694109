import { api } from "./api";

export const doctorCreateApi = (params) => {
  return api.post(`/doctor/add`, JSON.stringify(params));
};

export const doctorTableApi = (params) => {
  return api.get(
    `/doctor/get/doctorlist?pageNumber=${params?.pageNumber}&pageSize=${
      params?.pageSize
    }&sortBy=${params?.filterData?.sort_by}&sortOrder=${
      params?.filterData?.sort_order
    }${
      params?.filterData?.inputValue &&
      `&searchColumn=${params?.filterData?.selectColumn}&searchValue=${params?.filterData?.inputValue}`
    }`,
    JSON.stringify(params)
  );
};

export const doctorViewApi = (params) => {
  return api.get(
    `/doctor/getDoctorById?dr_id=${params}`,
    JSON.stringify(params)
  );
};
export const doctorEditApi = (params) => {
  return api.post(`/doctor/update`, JSON.stringify(params));
};
export const doctorDeleteApi = (params) => {
  return api.post(`/doctor/delete`, JSON.stringify(params));
};
