import React, { useCallback, useContext, useEffect, useState } from "react";
import InnerLayout from "../../components/layouts/inner-layout";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import DynamicTable from "../../components/tables/dynamic-table";
import LoadingSpinner from "../../components/helper-components/loading-spinner";
import { customerDeleteAction } from "../../redux/actions/customer-action";
import { userTableAction } from "../../redux/actions/user-action";
import UserFilter from "../../components/tables/table-filter/user-filter";
import { DataContext } from "../../components/layouts/main-layout";
import { useHotkeys } from "react-hotkeys-hook";
import { ChevronLeftIcon } from "@heroicons/react/24/outline";
const UserTable = () => {
  const { permissionFn } = useContext(DataContext);
  const dispatch = useDispatch();
  const [users, setUsers] = useState([]);
  const [dataCount, setDataCount] = useState(0);
  const [reload, setReload] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [showFilter, setShowFilter] = useState(false);
  const [pageSize] = useState(10);
  const [filterData, setFilterData] = useState({
    sort_by: "",
    sort_order: "",
    selectColumn: "user_name",
    from: "",
    to: "",
    inputValue: "",
    role: "",
  });
  useEffect(() => {
    dispatch(
      userTableAction(
        {
          pageNumber: currentPage,
          pageSize: pageSize,
          filterData,
        },
        onSuccess,
        onError
      )
    );
  }, [currentPage, filterData, reload]);
  const onSuccess = (data) => {
    setError(false);
    setLoading(false);
    setUsers(data?.data?.userList);
    setDataCount(data?.data?.totalCount);
  };
  const onError = (data) => {
    setError(true);
    setLoading(false);
  };

  const navigate = useNavigate();

  const tableBodyData = users?.map((item) => {
    return {
      id: item?.user_id,
      user_name:
        (
          <span className="cursor-pointer text-secondary fw_500">
            {item?.user_name ?? "--"}
          </span>
        ) ?? "--",

      user_role: item?.user_role ?? "--",
      user_department: item?.user_department ?? "--",
      user_phone_number: item?.user_phone_number ?? "--",
      user_email: item?.user_email ?? "--",
    };
  });
  const paginationProps = {
    pageSize: pageSize,
    dataCount: dataCount,
    setCurrentPage: setCurrentPage,
    currentPage: currentPage,
  };
  const handleFilterClick = useCallback(() => {
    setShowFilter((prevShowFilter) => !prevShowFilter);
  }, []);

  const filterby = showFilter ? (
    <UserFilter
      setCurrentPage={setCurrentPage}
      filterData={filterData}
      setFilterData={setFilterData}
      setShowFilter={setShowFilter}
    />
  ) : null;
  useHotkeys("escape", () => {
    setShowFilter(false);
  });

  return (
    <InnerLayout
      list={true}
      onclkFn={() => navigate("/add-user")}
      title="Users"
      module="user"
      permissionFn={permissionFn}
    >
      {loading ? (
        <LoadingSpinner />
      ) : error ? (
        <></>
      ) : (
        <>
          <div className="pl-4 pt-3 pb-4 flex items-center gap-2">
            <button
              className="flex items-center gap-2"
              onClick={() => {
                navigate('/settings')
              }}
            >
              <ChevronLeftIcon className="h-5 w-5 inline-block" />
              Settings /
            </button>
            <span className="cursor-pointer" >{" " + "User Info"}</span>
          </div>
          <DynamicTable
            date={false}
            onClickItemFn={(id) => navigate(`/view-user/${id}`)}
            list={true}
            onclkFn={() => {
              navigate("/add-user");
            }}
            permissionFn={permissionFn}
            module={"user_info"}
            filterText={"Filter Roles"}
            path="user"
            reload={reload}
            setReload={setReload}
            loading={loading}
            setLoading={setLoading}
            deleteAction={customerDeleteAction}
            label="User"
            showFilter={showFilter}
            filterFn={handleFilterClick}
            setFilterData={setFilterData}
            filterData={filterData}
            paginationProps={paginationProps}
            tableHeading={tableHeading}
            tableBodyData={tableBodyData}
            placeholder="Search"
            filterBy={filterby}
            initialFilter={{
              sort_by: "",
              sort_order: "",
              selectColumn: "user_name",
              from: "",
              to: "",
              inputValue: "",
              role: "",
            }}
          />
        </>
      )}
    </InnerLayout>
  );
};

export default UserTable;
const tableHeading = [
  {
    label: "USER NAME",
    value: "user_name",
    display: true,
    column: true,
  },
  {
    label: "ROLE",
    value: "user_role",
    display: true,
    column: true,
  },
  {
    label: "DEPARTMENT",
    value: "user_department",
    display: true,
    column: true,
  },
  {
    label: "MOBILE NUMBER",
    value: "user_phone_number",
    display: true,
    column: true,
  },
  {
    label: "EMAIL",
    value: "user_email",
    display: true,
    column: true,
  },
  { label: "", value: "", column: false },
];
