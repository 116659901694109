import React from "react";

const ReportssItemContainer = ({
  report,
  index,
  handleItemClick = () => {},
  search = false,
}) => {
  return (
    <div
      className="mt-3 hover:bg-[#f5faff] border border-white hover:border-[#99b3ff] max-w-[380px] p-6 cursor-pointer rounded-xl hover:shadow-lg transition duration-300 ease-in-out flex gap-5 items-start"
      key={index}
      onClick={() => handleItemClick(index, report)}
    >
      <div className="-mt-1">
        <div className="font-semibold text-md">{report?.title}</div>
        <div className="text-sm text-gray-600">{report?.desc}</div>
      </div>
    </div>
  );
};

export default ReportssItemContainer;
