import { Formik } from "formik";
import React from "react";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { productRemarksCreateAction } from "../../redux/actions/product-action";
import AddButton from "../buttons/button";
import TextAreaField from "../forms/textarea-field";
import successToast from "../helper-components/success-toast";
import { useNavigate } from "react-router-dom";

const RemarksTab = ({
  productId,
  setFormValues,
  formValues,
  setCurrentTab,
  product,
  disabled = false,
  itemGeneralData = {},
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const initialValues = {
    item_id: product?.item_id ?? productId,
    item_remarks: product?.item_remarks ?? "",
  };

  const validationSchema = Yup.object().shape({});
  const innerOnSubmit = (values) => {
    setFormValues({
      ...formValues,
      remarks: values,
    });
    dispatch(
      productRemarksCreateAction(
        {
          itemGeneralData: itemGeneralData,
          itemRemarksData: values,
        },
        onSuccess,
        onError
      )
    );
  };

  const onSuccess = (data) => {
    navigate("/product");
    successToast(data?.message);
    setCurrentTab("Remarks");
  };
  const onError = (data) => {};

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={formValues?.remarks ?? initialValues}
        validationSchema={validationSchema}
        onSubmit={innerOnSubmit}
      >
        {({ handleSubmit }) => {
          return (
            <>
              <div class="w-full lg:w-[50%] xl:w-[45%] px-5 xl:px-10 pt-0 mt-5">
                <TextAreaField
                  isDisabled={disabled}
                  label="Remarks"
                  placeholder="your remarks here..."
                  name="item_remarks"
                  id={"item_remarks"}
                  isRequired={false}
                  labelWidth="7rem"
                  feildHeight="9rem"
                />
              </div>

              {!disabled && (
                <div
                  style={{
                    bottom: "0",
                  }}
                  className="flex justify-end px-10 mb-5x sticky bg-white py-4 invoice-bottom-container"
                >
                  <AddButton
                    onclkFn={handleSubmit}
                    prefixIcon={false}
                    text="Save"
                    type="submit"
                  />
                </div>
              )}
            </>
          );
        }}
      </Formik>
    </>
  );
};

export default RemarksTab;
