import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { printPreferenceUpdateAction } from '../../../redux/actions/settings-action';
import successToast from '../../helper-components/success-toast';
import { formatString } from '../../helper-components/helper-function';
import errorToast from '../../helper-components/error-toast';
import invoiceImg from '../../../assets/images/TOHV1H8D7NO0SKT1661556181.png'


const Modal = ({ isOpen, onClose, item, dispatch, reload }) => {
    if (!isOpen) return null;

    const onCLickUseTemplate = (item) => {
        dispatch(printPreferenceUpdateAction({ id: item?.id, is_current: true }, onSuccess, onError))
    }

    const onSuccess = (data) => {
        
        successToast(data?.message);
        reload()
        onClose()
    }

    const onError = (data) => {
        errorToast(formatString(data?.message))
    }
    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
            <div className="rounded-lg overflow-hidden w-4/5 h-5/6 relative" style={{ backgroundColor: '#F4F6F9' }}>
                <div className="flex justify-between items-center p-4 bg-gray-800">
                    <h2 className="text-xl">Preview</h2>
                    <div className="flex gap-4">
                        {!item.is_current &&
                            <button
                                onClick={() => onCLickUseTemplate(item)}
                                className="text-center py-2 px-4 bg-primary text-white rounded fw_600"
                            >
                                Use This Template
                            </button>
                        }
                        <button
                            onClick={onClose}
                            className="rounded px-4 py-2 bg-white"
                        >
                            Close
                        </button>
                    </div>
                </div>
                <iframe
                    src={item.pdf_url}
                    title="PDF Preview"
                    className="w-full h-full"
                />
            </div>
        </div>
    );
};

const PreviewCard = ({ data, reload }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null)

    const handlePreviewClick = (item) => {
        setSelectedItem(item)
        setIsModalOpen(true);
    };

    const dispatch = useDispatch();

    const onCLickUseTemplate = (item) => {
        dispatch(printPreferenceUpdateAction({ id: item?.id, is_current: true }, onSuccess, onError))
    }

    const onSuccess = (data) => {
        
        successToast(data?.message);
        reload()
    }

    const onError = (data) => {
        errorToast(formatString(data?.message))
    }

    const handleImageError = (event) => {
        
        event.target.src = invoiceImg; // Replace with your fallback image URL
    };
    
    return (
        <div className='pt-4'>
            <div className="flex flex-wrap gap-12">
                {data.map((item, index) => (
                    <div
                        key={index}
                        className="relative w-64 h-90 bg-white rounded-lg shadow-lg overflow-hidden transform transition-all duration-300 hover:scale-105 cursor-pointer"
                        style={{ width: '300px', height: '400px' }}
                    >
                        {item.is_current && (
                            <div className="absolute bg-primary w-full text-center text-white py-2 fw_600">
                                Currently Using
                            </div>
                        )}
                        <img
                            src={item?.image_url ? item.image_url : invoiceImg}
                            alt={`Invoice ${index}`}
                            onError={handleImageError}
                            className="object-cover w-full h-full"
                        />
                        <div className="absolute inset-0 bg-black bg-opacity-0 hover:bg-opacity-50 transition-all duration-300 flex">
                            <div className="w-full flex justify-between items-end gap-2 opacity-0 hover:opacity-100 transition-opacity duration-300 py-3 mx-2">
                                <button
                                    onClick={() => handlePreviewClick(item)}
                                    className={`text-center py-2 px-4 bg-white text-black rounded fw_600`}
                                >
                                    Preview
                                </button>
                                {!item.is_current &&
                                    <button
                                        onClick={() => onCLickUseTemplate(item)}
                                        className="text-center py-2 px-4 bg-primary text-white rounded fw_600"
                                    >
                                        Use This Template
                                    </button>
                                }
                            </div>
                        </div>
                    </div>
                ))}
            </div>

            <Modal
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                item={selectedItem}
                dispatch={dispatch}
                reload={reload}
            />
        </div>
    );
};


export default PreviewCard;