import React, { useEffect, useState } from "react";
import ViewEditInvoice from "../../components/invoice-section/view-edit-invoice";
import InnerLayout from "../../components/layouts/inner-layout";
import { useDispatch } from "react-redux";
import { invoiceViewAction } from "../../redux/actions/invoices-action";
import { useParams } from "react-router-dom";
import LoadingSpinner from "../../components/helper-components/loading-spinner";

const ViewInvoice = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [invoice, setInvoice] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    dispatch(invoiceViewAction(id, onSuccess, onError));
  }, []);
  const onSuccess = (data) => {
    setLoading(false);
    setInvoice(data?.data?.[0]);   
  };
  const onError = (data) => {
    loading(false);
  };
  return (
    <>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <InnerLayout
          backBtn={true}
          page="view"
          title="View Billing"
          title1={invoice?.orderData?.sot_invoice_number}
        >
          <ViewEditInvoice invoice={invoice} disabled={true} />
        </InnerLayout>
      )}
    </>
  );
};

export default ViewInvoice;
