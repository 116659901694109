import React, { useEffect, useState } from "react";
import InnerLayout from "../../components/layouts/inner-layout";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import LoadingSpinner from "../../components/helper-components/loading-spinner";
import ViewEditProduct from "../../components/product-information/product-view-edit";
import { storeViewAction } from "../../redux/actions/store-action";
import StoreCreateViewEdit from "../../components/store-information/create-edit-view-store";
const ViewStore = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const navigate = useNavigate();
  const [store, setStore] = useState({});
  const [loading, setLoading] = useState(true);
  const [storeData, setStoreData] = useState({
    address: {},
    personal: {},
    firm: {},
    agreement: {},
    payment: {},
    drug: {},
  });
  useEffect(() => {
    dispatch(storeViewAction(id, onSuccess, onError));
  }, []);
  const onSuccess = (data) => {
    setLoading(false);
    setStoreData({
      address: data?.data?.[0],
      personal: data?.data?.[0],
      firm: data?.data?.[0],
      agreement: data?.data?.[0],
      payment: data?.data?.[0],
      drug: data?.data?.[0],
    });
    setStore(data?.data?.[0]);
  };
  const onError = (data) => {
    loading(false);
  };
  return (
    <>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <InnerLayout
        
        backBtn={true}
          page="view"
          title={"View Store-Information"}
          title1={store?.store_id}
          view={true}
          onclkEditFn={() => navigate(`/edit-store/${id}`)}
        >
          <StoreCreateViewEdit
            disabled={true}
            store={store}
            storeData={storeData}
            setStoreData={setStoreData}
          />
        </InnerLayout>
      )}
    </>
  );
};

export default ViewStore;
