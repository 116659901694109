import { all } from "redux-saga/effects";
import {
  alternativeItemsListWatcher,
  currentPrintPreferenceeWatcher,
  deleteAlternativeTransWatcher,
  documentNumberingListWatcher,
  documentNumberingSeriesAddWatcher,
  documentNumberingSeriesListWatcher,
  documentNumberingSeriesUpdateWatcher,
  documentNumberingUpdateWatcher,
  itemCategoryAddWatcher,
  itemCategoryDeleteWatcher,
  itemCategoryListWatcher,
  itemCategoryUpdateWatcher,
  itemGroupAddWatcher,
  itemGroupDeleteWatcher,
  itemGroupListWatcher,
  itemGroupUpdateWatcher,
  itemTypeAddWatcher,
  itemTypeDeleteWatcher,
  itemTypeListWatcher,
  itemTypeUpdateWatcher,
  manufacturerAddWatcher,
  manufacturerDeleteWatcher,
  manufacturerTableListWatcher,
  manufacturerUpdateWatcher,
  mapAlternativerItemWatcher,
  paytmAddWatcher,
  paytmUpdateWatcher,
  paytmViewWatcher,
  phonepeAddWatcher,
  phonepeUpdateWatcher,
  phonepeViewWatcher,
  pineLabsAddWatcher,
  pineLabsUpdateWatcher,
  pineLabsViewWatcher,
  printPreferenceListWatcher,
  printPreferenceUpdateWatcher,
  razorpayAddWatcher,
  razorpayUpdateWatcher,
  razorpayViewWatcher,
  shippingTypeAddWatcher,
  shippingTypeDeleteWatcher,
  shippingTypeTableListWatcher,
  shippingTypeUpdateWatcher,
  smsAddWatcher,
  smsUpdateWatcher,
  smsViewWatcher,
  smtpAddWatcher,
  smtpUpdateWatcher,
  smtpViewWatcher,
  storeDetailsGetWatcher,
  storeDetailsUpdateWatcher,
  stripeAddWatcher,
  stripeUpdateWatcher,
  stripeViewWatcher,
  taxCombinationAddWatcher,
  taxCombinationDeleteWatcher,
  taxCombinationItemDeleteWatcher,
  taxCombinationTableListWatcher,
  taxCombinationUpdateWatcher,
  taxTypeAddWatcher,
  taxTypeDeleteWatcher,
  taxTypeDropDownWatcher,
  taxTypeTableListWatcher,
  taxTypeUpdateWatcher,
  uomCreateWatcher,
  uomGroupDataAddWatcher,
  uomGroupDataListWatcher,
  uomGroupDataUpdateWatcher,
  uomGroupDeleteWatcher,
  uomGroupItemDeleteWatcher,
  uomGroupUomListWatcher,
  uomListWatcher,
  uomUpdateWatcher,
  whatsappAddWatcher,
  whatsappUpdateWatcher,
  whatsappViewWatcher,
} from "../settings-saga";

export default function* settingsRootSaga() {
  yield all([
    uomCreateWatcher(),
    uomUpdateWatcher(),
    uomListWatcher(),
    uomGroupDataListWatcher(),
    uomGroupDataAddWatcher(),
    uomGroupDataUpdateWatcher(),
    uomGroupDeleteWatcher(),
    uomGroupUomListWatcher(),
    uomGroupItemDeleteWatcher(),
    itemTypeAddWatcher(),
    itemTypeUpdateWatcher(),
    itemTypeDeleteWatcher(),
    itemTypeListWatcher(),
    itemCategoryAddWatcher(),
    itemCategoryUpdateWatcher(),
    itemCategoryDeleteWatcher(),
    itemCategoryListWatcher(),
    itemGroupAddWatcher(),
    itemGroupUpdateWatcher(),
    itemGroupDeleteWatcher(),
    itemGroupListWatcher(),
    manufacturerAddWatcher(),
    manufacturerUpdateWatcher(),
    manufacturerDeleteWatcher(),
    manufacturerTableListWatcher(),
    taxTypeAddWatcher(),
    taxTypeUpdateWatcher(),
    taxTypeDeleteWatcher(),
    taxTypeTableListWatcher(),
    taxCombinationAddWatcher(),
    taxCombinationUpdateWatcher(),
    taxCombinationTableListWatcher(),
    taxTypeDropDownWatcher(),
    taxCombinationDeleteWatcher(),
    taxCombinationItemDeleteWatcher(),
    shippingTypeAddWatcher(),
    shippingTypeUpdateWatcher(),
    shippingTypeDeleteWatcher(),
    shippingTypeTableListWatcher(),

    documentNumberingListWatcher(),
    documentNumberingUpdateWatcher(),

    documentNumberingSeriesListWatcher(),
    documentNumberingSeriesAddWatcher(),
    documentNumberingSeriesUpdateWatcher(),

    smtpViewWatcher(),
    smtpAddWatcher(),
    smtpUpdateWatcher(),
    whatsappViewWatcher(),
    whatsappAddWatcher(),
    whatsappUpdateWatcher(),
    smsViewWatcher(),
    smsAddWatcher(),
    smsUpdateWatcher(),

    razorpayViewWatcher(),
    razorpayAddWatcher(),
    razorpayUpdateWatcher(),
    stripeViewWatcher(),
    stripeAddWatcher(),
    stripeUpdateWatcher(),
    phonepeViewWatcher(),
    phonepeAddWatcher(),
    phonepeUpdateWatcher(),
    paytmViewWatcher(),
    paytmAddWatcher(),
    paytmUpdateWatcher(),
    pineLabsViewWatcher(),
    pineLabsAddWatcher(),
    pineLabsUpdateWatcher(),

    printPreferenceListWatcher(),
    printPreferenceUpdateWatcher(),
    currentPrintPreferenceeWatcher(),

    storeDetailsGetWatcher(),
    storeDetailsUpdateWatcher(),

    deleteAlternativeTransWatcher(),
    alternativeItemsListWatcher(),
    mapAlternativerItemWatcher(),
  ]);
}
