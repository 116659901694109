// reducers/dataReducer.js

import {
  LOADED_FAILED_MIN_QTY_ITEMS,
  LOADED_MIN_QTY_ITEMS,
  LOADING_MIN_QTY_ITEMS,
} from "../../types";

const initialState = {
  loading: false,
  data: [],
  error: false,
};
const bulkPurchaseReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOADING_MIN_QTY_ITEMS:
      return {
        ...state,
        loading: true,
      };
    case LOADED_MIN_QTY_ITEMS:
      return {
        ...state,
        loading: false,
        data: action?.data?.data,
        message: action?.data?.message,
      };
    case LOADED_FAILED_MIN_QTY_ITEMS:
      return {
        ...state,
        loading: false,
        error: action.data,
      };
    default:
      return state;
  }
};

export default bulkPurchaseReducer;
