import { Field, Form, Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import KibabMenu from "../../../../../assets/icons/kibab-menu";
import { useDispatch } from "react-redux";
import { addTaxCombinationAction, deleteTaxCombinationItemAction, taxTypeDropDownAction, updateTaxCombinationAction } from "../../../../../redux/actions/settings-action";
import InputField from "../../../../forms/input-field";
import Select from 'react-select';
import * as Yup from "yup";
import successToast from "../../../../helper-components/success-toast";
import errorToast from "../../../../helper-components/error-toast";
import DeleteIcon from "../../../../../assets/icons/delete-icon";
import AddButton, { DraftButton } from "../../../../buttons/button";

const customStyles = {
    control: (provided, state) => ({
        ...provided,
        border: 'none',
    }),
    indicatorSeparator: (provided) => ({
        ...provided,
        display: 'none',
    }),
};

const TaxCombination = ({ isView, editingData, reloadData, toogleChange }) => {
    const tableHeading = ["Tax Type", "%", "Sales Tax Account", "Purchase Tax Account", ""];
    const [selectedDOtIndex, setSelectedDOtIndex] = useState(null);
    const [taxTypes, setTaxTypes] = useState([]);
    const [usedTaxTypes, setUsedTaxTypes] = useState([])

    const dispatch = useDispatch();

    const handleFieldChange = (index, field, value, setFieldValue) => {
        setFieldValue(`${index}.${field}`, value);
    };

    const element = document.querySelector(".innerLayoutScroller");
    if (element.style.overflow === "auto") {
        element.style.overflow = "hidden";
    }


    const handleNewLineFieldChange = (field, value, setFieldValue, values) => {
        if (field && value) {
            setFieldValue(`newLine.${field}`, value);
        }
    };
    const validationSchema = Yup.object().shape({
        code: Yup.string().required("Required")
    });

    const initialValues = {
        id: editingData?.id || '',
        code: editingData?.code || '',
        rate: editingData?.rate || '',
        effective_from: editingData?.effective_from || '',
        combination: editingData ? editingData?.tax_combination_items?.map(taxComb => taxComb?.tax_type?.name).join(' + ') : '',
        tax_combination_items: editingData?.tax_combination_items || [],
        newLine: { tax_type_id: "", percentage: "", sales_tax_acc: "", purchase_tax_acc: "" }
    };

    useEffect(() => {
        dispatch(taxTypeDropDownAction({}, onSuccess, onError));
    }, []);

    const onSuccess = (data) => {
        if (!isView) {
            const existingTaxTypeIds = initialValues.tax_combination_items.map(item => item.tax_type_id);

            const filteredData = data?.data?.filter(item => !existingTaxTypeIds.includes(item.id));
            setTaxTypes(
                filteredData?.map((item) => ({
                    label: item?.name,
                    value: item?.id,
                }))
            );
        } else {
            setTaxTypes(
                data?.data?.map((item) => ({
                    label: item?.name,
                    value: item?.id,
                }))
            );
        }
        setUsedTaxTypes(
            data?.data?.map((item) => ({
                label: item?.name,
                value: item?.id,
            }))
        )

    };

    const onError = (data) => {
        
    };


    const onSubmit = (values, { setSubmitting }) => {
        const { tax_type_id, ...rest } = values.newLine;
        rest.tax_type_id = tax_type_id.value;
        if (rest.tax_type_id) {
            values.tax_combination_items = [...values.tax_combination_items, rest];
        }
        const combinationItems = values.tax_combination_items.map((item) => {
            let updatedObj = {
                id: item?.id || '',
                tax_type_id: item?.tax_type_id,
                percentage: item?.percentage,
                sales_tax_acc: item?.sales_tax_acc,
                purchase_tax_acc: item?.purchase_tax_acc
            }
            return updatedObj
        });

        const dispatchingValues = {
            id: values.id || '',
            code: values.code,
            effective_from: values.effective_from,
            combinationItems: combinationItems
        }

        

        if (combinationItems.length > 0) {
            if (dispatchingValues.id) {
                dispatch(updateTaxCombinationAction(dispatchingValues, onUpdateSuccess, onUpdateError))
            } else {
                dispatch(addTaxCombinationAction(dispatchingValues, onCreateSuccess, onCreateError))
            }
        } else {
            errorToast("Add atleast one combination")
        }
        setSubmitting(false);
    }

    const onCreateSuccess = (data) => {
        reloadData();
        toogleChange();
        successToast(data?.message);
    }
    const onCreateError = (data) => {
        
        errorToast(data?.message);
    }

    const onUpdateSuccess = (data) => {
        reloadData();
        toogleChange();
        successToast(data?.message);
    }
    const onUpdateError = (data) => {
        
        errorToast(data?.message);
    }

    const onClickDelete = (id) => {
        dispatch(deleteTaxCombinationItemAction({ id }, onDelSuccess, onDelError))
    }

    const onDelSuccess = (data) => {
        setSelectedDOtIndex(null);
        reloadData();
        toogleChange();
        successToast(data?.message);
    }
    const onDelError = (data) => {
        
        setSelectedDOtIndex(null);
        errorToast(data?.message);
    }

    const componentRef = useRef(null);

    const handleClickOutside = (event) => {
        if (componentRef.current && !componentRef.current.contains(event.target)) {
            setSelectedDOtIndex(null);
        }
    };

    return (
        <div className="pl-2">
            <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
            >
                {({ values, setFieldValue, dirty, handleSubmit }) => (
                    <Form className="pt-4">
                        <div
                            onClick={handleClickOutside}
                            className="flex flex-col justify-between"
                            style={{ height: "70vh" }}
                        >
                            <div className="flex gap-4">
                                <div style={{ maxWidth: "12rem" }} className="w-full">
                                    <InputField
                                        isDisabled={isView}
                                        containerClass="mt-1"
                                        isRequired={true}
                                        type="text"
                                        name="code"
                                        placeholder="Code"
                                        label="Code"
                                        labelWidth="4rem"
                                    />
                                </div>
                                <div style={{ maxWidth: "12rem" }} className="w-full">
                                    <InputField
                                        isDisabled={true}
                                        containerClass="mt-1"
                                        isRequired={false}
                                        type="text"
                                        name="rate"
                                        placeholder="Rate"
                                        label="Rate"
                                        labelWidth="4rem"
                                    />
                                </div>
                                <div style={{ maxWidth: "28rem" }} className="w-full">
                                    <InputField
                                        isDisabled={true}
                                        containerClass="mt-1"
                                        isRequired={false}
                                        type="text"
                                        name="combination"
                                        placeholder="Combination"
                                        label="Combination"
                                        labelWidth="7rem"
                                    />
                                </div>
                                <div style={{ maxWidth: "18rem" }} className="w-full">
                                    <InputField
                                        isDisabled={isView}
                                        containerClass="mt-1"
                                        isRequired={false}
                                        type="date"
                                        name="effective_from"
                                        placeholder="Eff"
                                        label="Effective From"
                                        labelWidth="7rem"
                                    />
                                </div>
                            </div>
                            <div className="w-full h-full">
                                <table className="mt-2 w-full">
                                    <thead>
                                        <tr>
                                            {tableHeading.map((heading, index) => (
                                                <th
                                                    className={`settings-table-header whitespace-nowrap fs_14 fw_500 text-secondary bg-F9F9FB text-start`}
                                                    key={heading}
                                                >
                                                    {heading}
                                                </th>
                                            ))}
                                        </tr>
                                    </thead>
                                    <tbody style={{ position: "relative" }}>
                                        {values.tax_combination_items.map((item, index) => (
                                            <tr key={index}>
                                                <td style={{ width: "15rem", minWidth: "11rem" }} className={`setting-table-cell fs_14 fw_400 text-secondary`}>
                                                    <Select
                                                        isDisabled={isView}
                                                        options={taxTypes}
                                                        name={`tax_combination_items[${index}].tax_type_id`}
                                                        placeholder="Select a Tax Type"
                                                        value={usedTaxTypes.find(tax => tax.value === item.tax_type_id)}
                                                        onChange={(option) => handleFieldChange(`tax_combination_items[${index}]`, "tax_type_id", option, setFieldValue, values)}
                                                        styles={customStyles}
                                                    />
                                                </td>
                                                <td
                                                    className={`setting-table-cell fs_14 fw_400 text-secondary`}
                                                >
                                                    <Field
                                                        name={`tax_combination_items[${index}].percentage`}
                                                        placeholder="percentage"
                                                        disabled={isView}
                                                        onChange={(e) =>
                                                            handleFieldChange(
                                                                `tax_combination_items[${index}]`,
                                                                "percentage",
                                                                e.target.value,
                                                                setFieldValue
                                                            )
                                                        }
                                                        className="border-0 focus:outline-none focus:ring focus:ring-blue-500 px-1 py-2"
                                                    />
                                                </td>
                                                <td
                                                    className={`setting-table-cell fs_14 fw_400 text-secondary`}
                                                >
                                                    <Field
                                                        name={`tax_combination_items[${index}].sales_tax_acc`}
                                                        placeholder="sales_tax_acc"
                                                        disabled={isView}
                                                        onChange={(e) =>
                                                            handleFieldChange(
                                                                `tax_combination_items[${index}]`,
                                                                "sales_tax_acc",
                                                                e.target.value,
                                                                setFieldValue
                                                            )
                                                        }
                                                        className="border-0 focus:outline-none focus:ring focus:ring-blue-500 px-1 py-2"
                                                    />
                                                </td>
                                                <td
                                                    className={`setting-table-cell fs_14 fw_400 text-secondary`}
                                                >
                                                    <Field
                                                        name={`tax_combination_items[${index}].purchase_tax_acc`}
                                                        placeholder="purchase_tax_acc"
                                                        disabled={isView}
                                                        onChange={(e) =>
                                                            handleFieldChange(
                                                                `tax_combination_items[${index}]`,
                                                                "purchase_tax_acc",
                                                                e.target.value,
                                                                setFieldValue
                                                            )
                                                        }
                                                        className="border-0 focus:outline-none focus:ring focus:ring-blue-500 px-1 py-2"
                                                    />
                                                </td>

                                                <td className="setting-table-cell fs_14 fw_400 text-secondary p-8">
                                                    <button type="button" onClick={() => setSelectedDOtIndex(index)}>
                                                        <KibabMenu />
                                                    </button>
                                                    {selectedDOtIndex === index && (
                                                        <div
                                                            ref={componentRef}
                                                            style={{ position: "absolute", right: '4rem', boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px" }}
                                                            className='bg-white rounded w-38'
                                                        >
                                                            <div className='w-full p-3'>
                                                                <button className='w-full flex align-middle gap-2 px-2 py-1' type="button" onClick={() => onClickDelete(item?.id)}>
                                                                    <div className="mt-1">
                                                                        <DeleteIcon />
                                                                    </div>
                                                                    <span className='fs_16'>Delete</span>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    )}
                                                </td>
                                            </tr>
                                        ))}
                                        {!isView &&
                                            <tr>
                                                <td style={{ width: "15rem", minWidth: "11rem" }} className={`setting-table-cell fs_14 fw_400 text-secondary`}>
                                                    <Select
                                                        options={taxTypes}
                                                        name="newLine.tax_type_id"
                                                        placeholder="Select Tax Type"
                                                        value={values.newLine.tax_type_id}
                                                        onChange={(option) => handleNewLineFieldChange("tax_type_id", option, setFieldValue, values)}
                                                        styles={customStyles}
                                                        autoFocus
                                                    />
                                                </td>
                                                <td
                                                    className={`setting-table-cell fs_14 fw_400 text-secondary`}
                                                >
                                                    <Field
                                                        name="newLine.percentage"
                                                        placeholder="percentage"
                                                        onChange={(e) =>
                                                            handleNewLineFieldChange(
                                                                "percentage",
                                                                e.target.value,
                                                                setFieldValue,
                                                                values
                                                            )
                                                        }
                                                        className="border-0 focus:outline-none focus:ring focus:ring-blue-500 rounded py-2"
                                                    />
                                                </td>
                                                <td
                                                    className={`setting-table-cell fs_14 fw_400 text-secondary`}
                                                >
                                                    <Field
                                                        name="newLine.sales_tax_acc"
                                                        placeholder="Sales tax account"
                                                        onChange={(e) =>
                                                            handleNewLineFieldChange(
                                                                "sales_tax_acc",
                                                                e.target.value,
                                                                setFieldValue,
                                                                values
                                                            )
                                                        }
                                                        className="border-0 focus:outline-none focus:ring focus:ring-blue-500 rounded py-2"
                                                    />
                                                </td>
                                                <td
                                                    className={`setting-table-cell fs_14 fw_400 text-secondary`}
                                                >
                                                    <Field
                                                        name="newLine.purchase_tax_acc"
                                                        placeholder="Purchase tax account"
                                                        onChange={(e) =>
                                                            handleNewLineFieldChange(
                                                                "purchase_tax_acc",
                                                                e.target.value,
                                                                setFieldValue,
                                                                values
                                                            )
                                                        }
                                                        className="border-0 focus:outline-none focus:ring focus:ring-blue-500 rounded py-2"
                                                    />
                                                </td>
                                                <td
                                                    className={`setting-table-cell fs_14 fw_400 text-secondary`}
                                                ></td>
                                            </tr>
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div className="flex justify-between w-full bg-white mt-4 py-4 px-2 invoice-bottom-container">
                            <DraftButton
                                onclkFn={toogleChange}
                                text={isView ? "Back" : "Cancel"}
                            />
                            <AddButton
                                onclkFn={handleSubmit}
                                prefixIcon={false}
                                text="Save"
                                type="submit"
                            />
                        </div>

                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default TaxCombination;
