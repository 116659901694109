import React from "react";
import { Link } from "react-router-dom";
export default function ErrorView(props) {
  return (
    <section className="">
      <div className=" ">
        <Link to={"/"}>Navigate</Link>
        <h1 className="">
          Oops Something Went Wrong!
        </h1>{" "}
        <p className="">Please reload the page</p>
      </div>
    </section>
  );
}
