import { Field, Form, Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import InputField from "../../../forms/input-field";
import { useDispatch } from "react-redux";
import {
  deleteUomGroupItemAction,
  uomDropDownAction,
  uomGroupAddAction,
  uomGroupUpdateAction,
} from "../../../../redux/actions/settings-action";
import Select from "react-select";
import * as Yup from "yup";
import successToast from "../../../helper-components/success-toast";
import errorToast from "../../../helper-components/error-toast";
import KibabMenu from "../../../../assets/icons/kibab-menu";
import DeleteIcon from "../../../../assets/icons/delete-icon";
import AddButton, { DraftButton } from "../../../buttons/button";
const customStyles = {
  control: (provided, state) => ({
    ...provided,
    border: "none",
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    display: "none",
  }),
};

const AddEditUom = ({ editingData, toogleChange, reload }) => {
  const itemTableHeaders = ["Alt Qty", "Alt UOM", "Base Qty", "Base UOM", ""];
  const newLine = {
    alt_quantity: "",
    alt_uom: "",
    base_quantity: "",
    base_uom_id: editingData?.group_items?.[0]?.base_uom_id ?? "",
  };

  const initialValues = {
    group_id: editingData?.group_id || "",
    name: editingData?.name || "",
    description: editingData?.description || "",
    group_items: editingData?.group_items || [],
  };

  const element = document.querySelector(".innerLayoutScroller");
  if (element.style.overflow === "auto") {
    element.style.overflow = "hidden";
  }

  const [uomLists, setUomLists] = useState([]);
  const [selectedDOtIndex, setSelectedDOtIndex] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(uomDropDownAction({}, onSuccessUsers, onErrorUsers));
  }, [dispatch]);

  const onSuccessUsers = (data) => {
    setUomLists(
      data?.data?.map((item) => ({
        label: item?.name,
        value: item?.uom_id,
      }))
    );
  };

  const onErrorUsers = (data) => {};

  const handleFieldChange = (field, value, setFieldValue, values) => {
    setFieldValue(`newLine.${field}`, value);

    if (
      values.newLine.alt_quantity &&
      values.newLine.alt_uom &&
      values.newLine.base_quantity &&
      values.newLine.base_uom_id
    ) {
      const groupItems = [...values.group_items];
      const { base_uom_id, ...rest } = values.newLine;
      rest.base_uom_id = base_uom_id.value;
      groupItems.push(rest);

      setFieldValue("group_items", groupItems);
      setFieldValue("newLine", {
        alt_quantity: "",
        alt_uom: "",
        base_quantity: "",
        base_uom_id: "",
      });
    }
  };

  // const onSubmit = (values) => {
  //   if (values.group_id) {
  //     const updatedGroupItems = values.group_items.map((item) => {
  //       const { base_uom, ...rest } = item;
  //       return rest;
  //     });
  //     const dispatchingValues = {
  //       group_id: values?.group_id,
  //       name: values.name,
  //       description: values.description,
  //       group_items: updatedGroupItems,
  //     };

  //     dispatch(
  //       uomGroupUpdateAction(dispatchingValues, onAddSuccess, onAddError)
  //     );
  //   } else {
  //     values.group_items = [...values.group_items];
  //     const updatedGroupItems = values.group_items.map((item) => {
  //       const { base_uom, ...rest } = item;
  //       rest.base_uom_id = rest?.base_uom_id?.value || rest.base_uom_id;
  //       return rest;
  //     });
  //     const dispatchingValues = {
  //       name: values.name,
  //       description: values.description,
  //       group_items: updatedGroupItems,
  //     };

  //     dispatch(uomGroupAddAction(dispatchingValues, onAddSuccess, onAddError));
  //   }
  // };

  // const onSubmit = (values) => {
  //   const updatedGroupItems = values.group_items.map(({ base_uom, ...rest }) => ({
  //     ...rest,
  //     base_uom_id: rest?.base_uom_id?.value || rest.base_uom_id,
  //   }));

  //   const dispatchingValues = {
  //     group_id: values.group_id,
  //     name: values.name,
  //     description: values.description,
  //     group_items: updatedGroupItems,
  //   };

  //   const action = values.group_id
  //     ? uomGroupUpdateAction(dispatchingValues, onAddSuccess, onAddError)
  //     : uomGroupAddAction(dispatchingValues, onAddSuccess, onAddError);

  //   dispatch(action);
  // };

  const onSubmit = (values) => {
    if (!values.group_items || values.group_items.length === 0) {
      errorToast("Group items must contain at least one item.");
      return;
    }
    const invalidItem = values.group_items.some((item) => {
      return (
        item.alt_quantity === "" ||
        item.alt_uom === "" ||
        item.base_quantity === "" ||
        !item.base_uom_id
      );
    });

    if (invalidItem) {
      errorToast("UOM must have Alt Qty, Alt UOM, Base Qty and Base UOM");
      return;
    }

    const updatedGroupItems = values.group_items.map(
      ({ base_uom, ...rest }) => ({
        ...rest,
        base_uom_id: rest?.base_uom_id?.value || rest.base_uom_id,
      })
    );

    const dispatchingValues = {
      group_id: values.group_id,
      name: values.name,
      description: values.description,
      group_items: updatedGroupItems,
    };

    const action = values.group_id
      ? uomGroupUpdateAction(dispatchingValues, onAddSuccess, onAddError)
      : uomGroupAddAction(dispatchingValues, onAddSuccess, onAddError);

    dispatch(action);
  };

  const onAddSuccess = (data) => {
    toogleChange();
    reload();
    successToast(data?.message);
  };
  const onAddError = (data) => {
    errorToast(data?.message);
  };
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Required"),
  });

  const componentRef = useRef(null);

  const handleClickOutside = (event) => {
    if (componentRef.current && !componentRef.current.contains(event.target)) {
      setSelectedDOtIndex(null);
    }
  };

  const onClickDelete = (item_id) => {
    dispatch(deleteUomGroupItemAction({ item_id }, onDelSuccess, onDelError));
  };

  const onDelSuccess = (data) => {
    setSelectedDOtIndex(null);
    reload();
    toogleChange();
    successToast(data?.message);
  };
  const onDelError = (data) => {
    setSelectedDOtIndex(null);
    errorToast(data?.message);
  };

  return (
    <div className="pt-4">
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {({ values, setFieldValue, dirty, handleSubmit }) => {
          console.log(values, "dfsdfdsfdsfdsfdsf");
          return (
            <Form className="h-full">
              <div style={{ height: "70vh" }} onClick={handleClickOutside}>
                <div className="flex gap-12">
                  <div style={{ maxWidth: "18rem" }} className="w-full">
                    <InputField
                      isDisabled={false}
                      containerClass="mt-1"
                      isRequired={true}
                      type="text"
                      name="name"
                      placeholder="Name"
                      label="Name"
                      labelWidth="5rem"
                    />
                  </div>
                  <div style={{ maxWidth: "28rem" }} className="w-full">
                    <InputField
                      isDisabled={false}
                      containerClass="mt-1"
                      isRequired={false}
                      type="text"
                      name="description"
                      placeholder="Description"
                      label="Description"
                      labelWidth="7rem"
                    />
                  </div>
                </div>
                <div className="w-full pt-2" style={{ height: "70vh" }}>
                  <table className="w-3/4">
                    <thead>
                      <tr>
                        {itemTableHeaders.map((heading) => (
                          <th
                            className="table-cell-billing my-2 whitespace-nowrap fs_14 fw_500 text-secondary bg-F9F9FB text-start p-2"
                            key={heading}
                          >
                            {heading}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {values.group_items.map((subItem, subIndex) => (
                        <tr key={subIndex}>
                          <td className="table-cell-billing fs_14 fw_400 text-secondary p-2">
                            {/* {subItem.alt_quantity}
                             */}

                            <Field
                              name={`group_items[${subIndex}].alt_quantity`}
                              placeholder="Alt quantity"
                              type="number"
                              // value={values.newLine.alt_quantity}
                              // onChange={(e) =>
                              //   handleFieldChange(
                              //     "alt_quantity",
                              //     e.target.value,
                              //     setFieldValue,
                              //     values
                              //   )
                              // }
                              className="border-0 focus:outline-none focus:ring focus:ring-blue-500 rounded py-2"
                              autoFocus
                            />
                          </td>
                          <td className="table-cell-billing fs_14 fw_400 text-secondary p-2">
                            {/* {subItem.alt_uom}
                             */}
                            <Field
                              // name="newLine.alt_uom"
                              name={`group_items[${subIndex}].alt_uom`}
                              placeholder="Alt UOM"
                              // value={values.newLine.alt_uom}
                              // onChange={(e) =>
                              //   handleFieldChange(
                              //     "alt_uom",
                              //     e.target.value,
                              //     setFieldValue,
                              //     values
                              //   )
                              // }
                              className="border-0 focus:outline-none focus:ring focus:ring-blue-500 rounded py-2"
                            />
                          </td>
                          <td className="table-cell-billing fs_14 fw_400 text-secondary p-2">
                            {/* {subItem.base_quantity}
                             */}
                            <Field
                              name={`group_items[${subIndex}].base_quantity`}
                              type="number"
                              placeholder="Base quantity"
                              // value={values.newLine.base_quantity}
                              // onChange={(e) =>
                              //   handleFieldChange(
                              //     "base_quantity",
                              //     e.target.value,
                              //     setFieldValue,
                              //     values
                              //   )
                              // }
                              className="border-0 focus:outline-none focus:ring focus:ring-blue-500 rounded py-2"
                            />
                          </td>
                          <td className="table-cell-billing fs_14 fw_400 text-secondary p-2">
                            {/* {
                                uomLists.find(
                                  (item) => item.value === subItem.base_uom_id
                                )?.label
                              } */}
                            {/* {subIndex === 0 ? ( */}
                            <Select
                              isDisabled={subIndex > 0}
                              className="w-[10rem]"
                              options={uomLists}
                              name={`group_items[${subIndex}].base_uom_id`}
                              id={`group_items[${subIndex}].base_uom_id`}
                              placeholder="Select a base UOM"
                              value={
                                uomLists.find(
                                  (option) =>
                                    option.value ===
                                    values.group_items[subIndex].base_uom_id
                                ) || null
                              }
                              onChange={(option) => {
                                const updatedItems = values.group_items.map(
                                  (item) => ({
                                    ...item,
                                    base_uom_id: option?.value,
                                  })
                                );
                                setFieldValue(`group_items`, updatedItems);
                              }}
                              styles={customStyles}
                            />

                            {/* // ) : (
                            //   uomLists.find(
                            //     (item) => item.value === subItem.base_uom_id
                            //   )?.label
                            // )} */}
                          </td>
                          <td className="table-cell-billing fs_14 fw_400 text-secondary p-8">
                            {/* <button
                              type="button"
                              onClick={() => setSelectedDOtIndex(subIndex)}
                            >
                              <KibabMenu />
                            </button> */}
                            <button
                              className="h-4"
                              onClick={() => {
                                const updatedData = [...values.group_items];
                                updatedData.splice(subIndex, 1); // Remove the item at the specified index
                                setFieldValue("group_items", updatedData); // Update the formik field value
                              }}
                              type="button"
                            >
                              <DeleteIcon width="12" height="12" />
                            </button>
                            {/* {selectedDOtIndex === subIndex && (
                              <div
                                ref={componentRef}
                                style={{
                                  position: "absolute",
                                  right: "18rem",
                                  boxShadow:       
                                    "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                                }}
                                className="bg-white rounded w-38"
                              >
                                <div className="w-full p-3">
                                  <button
                                    className="w-full flex align-middle gap-2 px-2 py-1"
                                    type="button"
                                    onClick={() =>
                                      onClickDelete(subItem?.item_id)
                                    }
                                  >
                                    <div className="mt-1">
                                      <DeleteIcon />
                                    </div>
                                    <span className="fs_16">Delete</span>
                                  </button>
                                </div>
                              </div>
                            )} */}
                          </td>
                        </tr>
                      ))}
                      {/* <tr>
                          <td
                            className={`table-cell-billing fs_14 fw_400 text-secondary`}
                          >
                            <Field
                              name="newLine.alt_quantity"
                              placeholder="Alt quantity"
                              type="number"
                              value={values.newLine.alt_quantity}
                              onChange={(e) =>
                                handleFieldChange(
                                  "alt_quantity",
                                  e.target.value,
                                  setFieldValue,
                                  values
                                )
                              }
                              className="border-0 focus:outline-none focus:ring focus:ring-blue-500 rounded py-2"
                              autoFocus
                            />
                          </td>
                          <td
                            className={`table-cell-billing fs_14 fw_400 text-secondary`}
                          >
                            <Field
                              name="newLine.alt_uom"
                              placeholder="Alt UOM"
                              value={values.newLine.alt_uom}
                              onChange={(e) =>
                                handleFieldChange(
                                  "alt_uom",
                                  e.target.value,
                                  setFieldValue,
                                  values
                                )
                              }
                              className="border-0 focus:outline-none focus:ring focus:ring-blue-500 rounded py-2"
                            />
                          </td>
                          <td
                            className={`table-cell-billing fs_14 fw_400 text-secondary`}
                          >
                            <Field
                              name="newLine.base_quantity"
                              type="number"
                              placeholder="Base quantity"
                              value={values.newLine.base_quantity}
                              onChange={(e) =>
                                handleFieldChange(
                                  "base_quantity",
                                  e.target.value,
                                  setFieldValue,
                                  values
                                )
                              }
                              className="border-0 focus:outline-none focus:ring focus:ring-blue-500 rounded py-2"
                            />
                          </td>
                          <td
                            style={{ width: "15rem", minWidth: "11rem" }}
                            className={`table-cell-billing fs_14 fw_400 text-secondary`}
                          >
                            <Select
                              options={uomLists}
                              name="newLine.base_uom_id"
                              placeholder="Select a base UOM"
                              value={values.newLine.base_uom_id}
                              onChange={(option) =>
                                handleFieldChange(
                                  "base_uom_id",
                                  option,
                                  setFieldValue,
                                  values
                                )
                              }
                              styles={customStyles}
                            />
                          </td>
                          <td
                            className={`table-cell-billing fs_14 fw_400 text-secondary`}
                          ></td>
                        </tr> */}
                    </tbody>
                  </table>

                  <div className="flex justify-end w-3/4">
                    <AddButton
                      className="bg-primary text-white px-8 py-1 mt-2 rounded flex justify-center fw_500 fs_16"
                      onclkFn={() => {
                        setFieldValue(`group_items`, [
                          ...values?.group_items,
                          {
                            alt_quantity: "",
                            alt_uom: "",
                            base_quantity: "",
                            base_uom_id:
                              values?.group_items?.[0]?.base_uom_id ?? "",
                          },
                        ]);
                      }}
                      prefixIcon={true}
                      text=""
                      type="button"
                    />
                  </div>
                </div>
                <div className="flex justify-between w-full bg-white py-4 px-4">
                  <DraftButton onclkFn={toogleChange} text="Cancel" />
                  <AddButton
                    isDisabled={!dirty}
                    onclkFn={handleSubmit}
                    prefixIcon={false}
                    text="Save"
                    type="submit"
                  />
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default AddEditUom;
