import React from "react";

const FromTo = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.8336 13C14.8336 13.2449 14.7436 13.4813 14.5809 13.6644C14.4181 13.8474 14.1938 13.9643 13.9506 13.993L13.8336 14L4.2126 14L6.0406 15.828C6.22185 16.0075 6.32763 16.2496 6.33628 16.5045C6.34493 16.7595 6.2558 17.0082 6.08713 17.1996C5.91846 17.391 5.68301 17.5107 5.42897 17.5342C5.17493 17.5577 4.92152 17.4832 4.7206 17.326L4.6266 17.242L1.0906 13.707C0.918422 13.5348 0.81499 13.3057 0.799709 13.0627C0.784427 12.8197 0.858346 12.5794 1.0076 12.387L1.0906 12.293L4.6266 8.757C4.80655 8.57765 5.04803 8.47353 5.30198 8.46577C5.55593 8.45802 5.80331 8.54721 5.99388 8.71525C6.18444 8.88328 6.3039 9.11755 6.328 9.37047C6.35209 9.6234 6.27901 9.87601 6.1236 10.077L6.0406 10.172L4.2136 12L13.8336 12C14.0988 12 14.3532 12.1054 14.5407 12.2929C14.7282 12.4804 14.8336 12.7348 14.8336 13ZM18.5766 5.707L15.0406 9.242C14.9484 9.33751 14.838 9.41369 14.716 9.4661C14.594 9.51851 14.4628 9.5461 14.33 9.54725C14.1972 9.5484 14.0655 9.5231 13.9426 9.47282C13.8197 9.42254 13.7081 9.34829 13.6142 9.25439C13.5203 9.1605 13.4461 9.04885 13.3958 8.92595C13.3455 8.80306 13.3202 8.67138 13.3213 8.5386C13.3225 8.40582 13.3501 8.2746 13.4025 8.1526C13.4549 8.03059 13.5311 7.92025 13.6266 7.828L15.4546 6L5.8346 6C5.56938 6 5.31503 5.89464 5.12749 5.70711C4.93996 5.51957 4.8346 5.26522 4.8346 5C4.8346 4.73478 4.93996 4.48043 5.12749 4.29289C5.31503 4.10536 5.56938 4 5.8346 4L15.4536 4L13.6266 2.17C13.4444 1.9814 13.3436 1.7288 13.3459 1.4666C13.3482 1.2044 13.4534 0.953589 13.6388 0.768181C13.8242 0.582773 14.075 0.477604 14.3372 0.475326C14.5994 0.473048 14.852 0.573842 15.0406 0.756L18.5766 4.292C18.7641 4.47953 18.8694 4.73384 18.8694 4.999C18.8694 5.26416 18.7641 5.51947 18.5766 5.707Z"
        fill="#5D5D5D"
      />
    </svg>
  );
};

export default FromTo;
