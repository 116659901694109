import React, { useEffect, useState } from "react";
import ReportsLayout from "../../../components/reports/reports-layout";
import { productWiseSalesReportAction } from "../../../redux/actions/report-action";
import { useDispatch } from "react-redux";
import moment from "moment";

const GstHsnCodeReport = () => {
  const [dateRangeFilter, setDateRangeFilterValue] = useState({
    from: null,
    to: null,
  });
  const dispatch = useDispatch();
  const [groupByOptions, setgroupByOptions] = useState([
    { label: "None", value: "" },
    { label: "Drug Code", value: "oi.item_code" },
    { label: "Drug Name", value: "oi.item_name" },
    { label: "Bill Date", value: "so.created_date" },
    { label: "Drug Code & Bill Date", value: "oi.item_code,so.created_date" },
    { label: "Drug Name & Bill Date", value: "oi.item_name,so.created_date" },
    { label: "Drug Code & Branch", value: "oi.item_code,si.firm_name" },
    { label: "Drug Code & Customer", value: "oi.item_code,c.cmr_first_name" },
    { label: "Drug Code & UOM", value: "oi.item_code,oi.item_uom" },
    {
      label: "Drug Code & Batch No",
      value: "oi.item_code,oi.item_batch_number",
    },
    { label: "Drug Code & Qty", value: "oi.item_code,oi.item_quantity" },
    { label: "Drug Code & Rate", value: "oi.item_code,oi.item_unit_price" },
  ]);

  const [loading, setLoading] = useState(false);
  const [filterOptions, setFilterOptions] = useState(
    Object.entries(tableColoumsn).map(([label, value]) => ({
      label,
      value,
    }))
  );
  const [selectedColumns, setSelectedColumns] = useState(tableHeading);
  const [filters, setFilters] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [dateRange, setDateRange] = useState("");
  const [groupBy, setGroupBy] = useState("");

  useEffect(() => {
    const selectedColumnValues = selectedColumns.map(
      (column) => tableColoumsn[column]
    );
    const requestData = {
      selectedColumns: selectedColumnValues,
      filters: filters.map(({ id, ...rest }) => rest),
      filter_by: dateRange,
      group_by: groupBy,
      from: dateRangeFilter?.from,
      to: dateRangeFilter?.to,
    };
    dispatch(productWiseSalesReportAction(requestData, onSuccess, onError));
    setLoading(true);
  }, [selectedColumns, filters, dateRange, groupBy]);

  const isIso8601Date = (dateString) => {
    const iso8601Regex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/;
    return iso8601Regex.test(dateString);
  };

  const formatDate = (value) => {
    return moment(value).format("DD-MM-YYYY");
  };

  const transformData = (data) => {
    return data.map((item) => {
      let transformedItem = {};
      for (let [key, value] of Object.entries(tableColoumsn)) {
        const rawValue = item[value.split(".").pop()];
        transformedItem[key] = isIso8601Date(rawValue)
          ? formatDate(rawValue)
          : rawValue;
      }
      return transformedItem;
    });
  };

  const onSuccess = (data) => {
    console.log("data is", data);
    const transformedData = transformData(data?.data?.result);
    setTableData(transformedData);
    setLoading(false);
  };
  const onError = (data) => {
    console.log("error is", data);
    setLoading(false);
  };

  return (
    <ReportsLayout
      title={"HSN code Summary"}
      tableHeading={selectedColumns}
      tableData={tableData}
      groupByOptions={groupByOptions}
      filterOptions={filterOptions}
      availableColumns={tableHeading}
      selectedColumns={selectedColumns}
      setSelectedColumns={setSelectedColumns}
      setFilters={setFilters}
      loading={loading}
      setDateRange={setDateRange}
      setGroupBy={setGroupBy}
      setDateRangeFilterValue={setDateRangeFilterValue}
    />
  );
};

const tableHeading = [
  "HSN Code",                  // New entry
  "HSN Description",           // New entry
  "Total Taxable Value",       // New entry
  "GST Rate",                  // New entry
  "Total SGST",                // New entry
  "Total CGST",                // New entry
  "Total IGST",                // New entry
  "Total Invoice Amount",       // New entry
  "Cess (if applicable)"       // New entry
];

const tableColoumsn = {
  "HSN Code": "oi.hsn_code",                 // New entry for HSN Code
  "HSN Description": "oi.hsn_description",   // New entry for HSN Description
  "Total Taxable Value": "oi.total_taxable_value", // New entry for Total Taxable Value
  "GST Rate": "oi.gst_rate",                 // New entry for GST Rate
  "Total SGST": "oi.total_sgst",             // New entry for Total SGST
  "Total CGST": "oi.total_cgst",             // New entry for Total CGST
  "Total IGST": "oi.total_igst",             // New entry for Total IGST
  "Total Invoice Amount": "oi.total_invoice_amount", // New entry for Total Invoice Amount
  "Cess (if applicable)": "oi.cess_amount"    // New entry for Cess Amount
};



export default GstHsnCodeReport;
