import { call, takeLatest } from "redux-saga/effects";
import {
  financialAccAllocUpdateApi,
  financialAccListApi,
  financialAccountAddApi,
  financialAccountDeleteApi,
  financialAccountGetApi,
  financialAccountUpdateApi,
  financialAllAccountApi,
  financialPeriodSelectionApi,
  journalEntryFetchApi,
  journalEntryViewApi,
  journalEntryUpdateApi,
} from "../apis/financial-api";
import {
  FINANCIAL_ACCOUNT_ADD,
  FINANCIAL_ACCOUNT_DELETE,
  FINANCIAL_ACCOUNT_GET,
  FINANCIAL_ACCOUNT_UPDATE,
  FINANCIAL_ACC_ALLOCATION_UPDATE,
  FINANCIAL_ACC_LIST,
  FINANCIAL_ALL_ACC,
  FINANCIAL_PERIOD_SELECTION,
  JOURNAL_ENTRY_FETCH,
  JOURNAL_ENTRY_UPDATE,
  JOURNAL_ENTRY_VIEW,
} from "../types";

export function* financialAccountAddWorker(action) {
  try {
    const res = yield call(financialAccountAddApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* financialAccountUpdateWorker(action) {
  try {
    const res = yield call(financialAccountUpdateApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* financialAccountDeleteWorker(action) {
  try {
    const res = yield call(financialAccountDeleteApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}

export function* financialAccountGetWorker(action) {
  try {
    const res = yield call(financialAccountGetApi);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* financialPeriodSelectionWorker(action) {
  try {
    const res = yield call(financialPeriodSelectionApi);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* financialAllAccountWorker(action) {
  try {
    const res = yield call(financialAllAccountApi, action?.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* financialAccListWorker(action) {
  try {
    const res = yield call(financialAccListApi, action?.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* financialAccAllocUpdateWorker(action) {
  try {
    const res = yield call(financialAccAllocUpdateApi, action?.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* journalEntryFetchWorker(action) {
  try {
    const res = yield call(journalEntryFetchApi, action?.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* journalEntryUpdateWorker(action) {
  try {
    const res = yield call(journalEntryUpdateApi, action?.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* journalEntryViewWorker(action) {
  try {
    const res = yield call(journalEntryViewApi, action?.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}

export function* journalEntryViewWatcher() {
  yield takeLatest(JOURNAL_ENTRY_VIEW, journalEntryViewWorker);
}
export function* journalEntryUpdateWatcher() {
  yield takeLatest(JOURNAL_ENTRY_UPDATE, journalEntryUpdateWorker);
}
export function* journalEntryFetchWatcher() {
  yield takeLatest(JOURNAL_ENTRY_FETCH, journalEntryFetchWorker);
}
export function* financialAccAllocUpdateWatcher() {
  yield takeLatest(
    FINANCIAL_ACC_ALLOCATION_UPDATE,
    financialAccAllocUpdateWorker
  );
}
export function* financialAccListWatcher() {
  yield takeLatest(FINANCIAL_ACC_LIST, financialAccListWorker);
}
export function* financialAllAccountWatcher() {
  yield takeLatest(FINANCIAL_ALL_ACC, financialAllAccountWorker);
}
export function* financialPeriodSelectionWatcher() {
  yield takeLatest(FINANCIAL_PERIOD_SELECTION, financialPeriodSelectionWorker);
}

export function* financialAccountAddWatcher() {
  yield takeLatest(FINANCIAL_ACCOUNT_ADD, financialAccountAddWorker);
}

export function* financialAccountUpdateWatcher() {
  yield takeLatest(FINANCIAL_ACCOUNT_UPDATE, financialAccountUpdateWorker);
}
export function* financialAccountDeleteWatcher() {
  yield takeLatest(FINANCIAL_ACCOUNT_DELETE, financialAccountDeleteWorker);
}
export function* financialAccountGetWatcher() {
  yield takeLatest(FINANCIAL_ACCOUNT_GET, financialAccountGetWorker);
}
