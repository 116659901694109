import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { adminRolesAction } from "../../redux/actions/general-action";

const usePermissions = () => {
  const dispatch = useDispatch();
  const [permissionFn, setPermissionFn] = useState(() => () => ({}));

  useEffect(() => {
    const fetchPermissions = () => {
      dispatch(adminRolesAction(onSuccess, onError));
    };
    fetchPermissions();
    const intervalId = setInterval(fetchPermissions, 10 * 60 * 1000);
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const onSuccess = (data) => {
    const permissions = data?.data || [];
    localStorage.setItem("permission", JSON.stringify(permissions));
    const newPermissionFn = (section) => {
      const permission = permissions.find(
        (item) => item.module_name === section
      );
      return permission || {};
    };
    setPermissionFn(() => newPermissionFn);
  };

  const onError = (error) => {
    
  };

  return { permissionFn };
};

export default usePermissions;
