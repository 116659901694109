import React from "react";

const UomGroupIcon = () => {
    return (
        <svg width="22" height="22" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.75 12.25H1.75V1.75H15.75M15.75 0H1.75C0.77875 0 0 0.77875 0 1.75V12.25C0 12.7141 0.184374 13.1592 0.512563 13.4874C0.840752 13.8156 1.28587 14 1.75 14H15.75C16.2141 14 16.6592 13.8156 16.9874 13.4874C17.3156 13.1592 17.5 12.7141 17.5 12.25V1.75C17.5 1.28587 17.3156 0.840752 16.9874 0.512563C16.6592 0.184374 16.2141 0 15.75 0ZM8.75 5.25H7V7H8.75M5.25 5.25H3.5V7H5.25M12.25 8.75H10.5V10.5H12.25M12.25 5.25H10.5V7H12.25V5.25Z" fill="white" />
        </svg>
    )
}

export default UomGroupIcon