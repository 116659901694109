import { Form, Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import InputField from '../../forms/input-field'
import { useDispatch } from 'react-redux';
import AddButton, { DraftButton } from '../../buttons/button';
import * as Yup from "yup";
import { useNavigate } from 'react-router-dom';
import { ChevronLeftIcon } from '@heroicons/react/24/outline';
import InnerLayout from '../../layouts/inner-layout';
import successToast from '../../helper-components/success-toast';
import errorToast from '../../helper-components/error-toast';
import { paytmAddAction, paytmUpdateAction, paytmViewAction } from '../../../redux/actions/settings-action';

const PaytmConfiguration = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate()

    const [paytmData, setPaytmData] = useState({})

    useEffect(() => {
        dispatch(paytmViewAction({}, onSuccess, onError));
    }, []);

    const reload = () => {
        dispatch(paytmViewAction({}, onSuccess, onError))
    }

    const onSuccess = (data) => {
        
        setPaytmData(data?.data);
    };
    const onError = (data) => {  errorToast(data?.message) };



    const initialValues = {
        id: paytmData?.id || null,
        merchant_id: paytmData?.merchant_id || "",
        secret_key: paytmData?.secret_key || "",
        paytm_website: paytmData?.paytm_website || "",
        channel: paytmData?.channel || "",
        industry_type: paytmData?.industry_type || "Retail",
    }

    const validationSchema = Yup.object().shape({
        merchant_id: Yup.string().required("Required"),
        secret_key: Yup.string().required("Required"),
        paytm_website: Yup.string().required("Required"),
        channel: Yup.string().required("Required"),
        industry_type: Yup.string().required("Required"),
    });

    const onSubmit = (values) => {
        
        if (values.id) {
            dispatch(paytmUpdateAction(values, onCreateSuccess, onError))
        } else {
            dispatch(paytmAddAction(values, onCreateSuccess, onError))
        }
    }

    const onCreateSuccess = (data) => { successToast(data?.message); reload() };

    return (
        <InnerLayout
            list={true}
            title="Settings"
            module="pine-lab-configuration"
        // permissionFn={permissionFn}
        >
            <div className='pl-4'>
                <div className='flex justify-between pt-3'>
                    <div className=" pb-4 flex items-center gap-2">
                        <button
                            className="flex items-center gap-2"
                            onClick={() => {
                                navigate('/settings')
                            }}
                        >
                            <ChevronLeftIcon className="h-5 w-5 inline-block" />
                            Settings /
                        </button>
                        <span className="cursor-pointer" >{" " + "Paytm Configuration"}</span>
                    </div>
                </div>
                <Formik
                    enableReinitialize={true}
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                >
                    {({ values, setFieldValue, handleSubmit }) => (
                        <Form className="pl-4 pt-4">
                            <div
                                style={{ height: "78vh" }}
                            >

                                <div className="w-full flex flex-col gap-4">
                                    <div style={{ maxWidth: "30rem" }} className="w-full">
                                        <InputField
                                            containerClass="mt-1"
                                            isRequired={true}
                                            type="text"
                                            name="merchant_id"
                                            placeholder="Paytm Merchant Id"
                                            label="Merchant Id"
                                            labelWidth="8rem"
                                        />
                                    </div>
                                    <div style={{ maxWidth: "30rem" }} className="w-full">
                                        <InputField
                                            containerClass="mt-1"
                                            isRequired={true}
                                            type="text"
                                            name="secret_key"
                                            placeholder="Paytm Secret Key"
                                            label="Secret Key"
                                            labelWidth="8rem"
                                        />
                                    </div>
                                    <div style={{ maxWidth: "30rem" }} className="w-full">
                                        <InputField
                                            containerClass="mt-1"
                                            isRequired={true}
                                            type="text"
                                            name="paytm_website"
                                            placeholder="Paytm Website"
                                            label="Paytm Website"
                                            labelWidth="8rem"
                                        />
                                    </div>
                                    <div style={{ maxWidth: "30rem" }} className="w-full">
                                        <InputField
                                            containerClass="mt-1"
                                            isRequired={true}
                                            type="text"
                                            name="channel"
                                            placeholder="Paytm Channel"
                                            label="Paytm Channel"
                                            labelWidth="8rem"
                                        />
                                    </div>
                                    <div style={{ maxWidth: "30rem" }} className="w-full">
                                        <InputField
                                            containerClass="mt-1"
                                            isRequired={true}
                                            type="text"
                                            name="industry_type"
                                            placeholder="Industry Type"
                                            label="Industry Type"
                                            labelWidth="8rem"
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="flex justify-between px-6  bg-white py-4 invoice-bottom-container">
                                <DraftButton
                                    onclkFn={() => navigate(`/settings`)}
                                    text="Cancel"
                                />
                                <AddButton
                                    onclkFn={handleSubmit}
                                    prefixIcon={false}
                                    text="Save"
                                    type="submit"
                                />
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </InnerLayout>
    )
}

export default PaytmConfiguration
