import React from "react";
import InnerLayout from "../../components/layouts/inner-layout";
import "../../components/customer-information/customer-information.css";
import CustomerViewEdit from "../../components/customer-information/customer-view-edit";
const AddCustomer = () => {
  return (
    <InnerLayout   backBtn={true} title="Add Customer ">
      <CustomerViewEdit  page="add" />
    </InnerLayout>
  );
};

export default AddCustomer;
