import React, { Fragment } from "react";

export default function MainContent({
  children = "",
  heading = "Sign in",
  content = "Welcome Back",
  logo = "",
  ContainerClassStyle = "text-center space-y-2 p-4",
  HeadingStyle = "fs_26 fw_700",
  ParagraphStyle = "text-344054",
  LogoStyle = "bg-F9F9FB rounded-full self-center p-3",
  isContainer = false,
}) {
  const Container = isContainer ? "div" : Fragment;
  return (
    <Container>
      {logo && <div className={LogoStyle}>{logo}</div>}
      <div className={ContainerClassStyle}>
        <h1 className={HeadingStyle}>{heading}</h1>
        <p className={ParagraphStyle}>{content}</p>
        {children && children}
      </div>
    </Container>
  );
}
