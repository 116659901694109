import React, { useContext, useEffect, useState } from "react";
import InnerLayout from "../../../components/layouts/inner-layout";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import {
  disCusGrpListAction,
  disCusListAction,
} from "../../../redux/actions/general-action";
import { useDispatch } from "react-redux";
import SelectField from "../../../components/forms/select-field";
import TabsComponent from "../../../components/helper-components/tabs-component";
import { allDisGroupAction } from "../../../redux/actions/discount-action";
import ItemGroupTab from "../../../components/discounts/discount-group/item-group-tab";
import ManufacturerTab from "../../../components/discounts/discount-group/manufacturer-tab";
import ItemsTab from "../../../components/discounts/discount-group/items-tab";
import { DataContext } from "../../../components/layouts/main-layout";
import { ChevronLeftIcon } from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";

const DiscountGroup = () => {
  const navigate = useNavigate();
  const { permissionFn } = useContext(DataContext);
  const [loading, SetLoading] = useState(false);
  const dispatch = useDispatch();
  const [cusList, setCusList] = useState([]);
  const [cusGrpList, setCusGrpList] = useState([]);
  const [currentTab, setCurrentTab] = useState("Item Groups");
  const [initialData, setInitialData] = useState({});
  useEffect(() => {
    dispatch(disCusListAction(onCusSuccess, onCusError));
    dispatch(disCusGrpListAction(onCusGrpListSuccess, onCusGrpListError));
  }, []);

  const onCusSuccess = (data) => {
    setCusList(
      data?.data?.map((item) => {
        return {
          label: item?.customer_group_name,
          value: item?.customer_group_id,
        };
      })
    );
  };
  const onCusError = (data) => {};
  const onCusGrpListSuccess = (data) => {
    setCusGrpList(
      data?.data?.map((item) => {
        return {
          label: item?.discount_type_name,
          value: item?.discount_type_id,
        };
      })
    );
  };
  const onCusGrpListError = (data) => {};
  const initialValues = {
    customer_group_id: "",
    discount_type_id: "",
  };
  const validationSchema = Yup.object().shape({});

  function fetchDisGrpFn(dis = "", cus = "", tab = "") {
    let reqTab = "";
    if (tab.toLowerCase() === "item groups") {
      reqTab = "itemsGroupTab";
    } else if (tab.toLowerCase() === "manufacturers") {
      reqTab = "manufactureTab";
    } else if (tab.toLowerCase() === "items") {
      reqTab = "itemsTab";
    }
    if (cus && dis && reqTab) {
      SetLoading(true);
      dispatch(
        allDisGroupAction(
          { requestTab: reqTab, discount_type_id: dis, customer_group_id: cus },
          onSuccess,
          onError
        )
      );
    }
  }
  const onSuccess = (data) => {
    setInitialData(data?.data?.[0]);
    SetLoading(false);
  };
  const onError = (data) => {
    setInitialData([]);
    SetLoading(false);
  };
  return (
    <InnerLayout title="Discounts Groups">
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={validationSchema}
      >
        {({ values }) => {
          return (
            <Form className="h-full flex flex-col pt-4">
              <div className="pl-4 pb-5 flex items-center gap-2">
                <button
                  className="flex items-center gap-2"
                  onClick={() => {
                    navigate("/settings");
                  }}
                >
                  <ChevronLeftIcon className="h-5 w-5 inline-block" />
                  Settings /
                </button>
                <span className="cursor-pointer">
                  {" " + "Discounts Groups"}
                </span>
              </div>
              <div className="flex gap-5 flex-wrap">
                <div className="w-full 2xl:w-[25%] lg:w-[40%] md:w-[45%] px-5 xl:px-10">
                  <SelectField
                    handleSelected={(selected) => {
                      fetchDisGrpFn(
                        selected?.value,
                        values?.customer_group_id,
                        currentTab
                      );
                    }}
                    placeholder="Select Customer type"
                    options={cusGrpList}
                    label="Customer Type"
                    id="discount_type_id"
                    name="discount_type_id"
                    isSearchable={true}
                    errMsgContainerClass="ps-2"
                    isRequired={false}
                    labelWidth="7rem"
                  />
                </div>
                <div className="w-full 2xl:w-[25%] lg:w-[40%] md:w-[45%] px-5 xl:px-10">
                  <SelectField
                    handleSelected={(selected) => {
                      fetchDisGrpFn(
                        values?.discount_type_id,
                        selected?.value,
                        currentTab
                      );
                    }}
                    placeholder="Select item group"
                    options={cusList}
                    label="Group Name"
                    id="customer_group_id"
                    name="customer_group_id"
                    isSearchable={true}
                    errMsgContainerClass="ps-2"
                    isRequired={false}
                    labelWidth="7rem"
                  />
                </div>
              </div>
              <TabsComponent
                handleClickfn={(item) => {
                  setCurrentTab(item);
                  fetchDisGrpFn(
                    values?.discount_type_id,
                    values?.customer_group_id,
                    item
                  );
                }}
                itemTabs={itemTabs}
                setCurrentTab={setCurrentTab}
                currentTab={currentTab}
              />

              {currentTab === "Item Groups" && (
                <ItemGroupTab
                  permissionFn={permissionFn}
                  loading={loading}
                  fetchDisGrpFn={fetchDisGrpFn}
                  mastervalues={values}
                  initialData={initialData}
                />
              )}
              {currentTab === "Manufacturers" && (
                <ManufacturerTab
                  permissionFn={permissionFn}
                  loading={loading}
                  fetchDisGrpFn={fetchDisGrpFn}
                  mastervalues={values}
                  initialData={initialData}
                />
              )}
              {currentTab === "Items" && (
                <ItemsTab
                  permissionFn={permissionFn}
                  loading={loading}
                  fetchDisGrpFn={fetchDisGrpFn}
                  mastervalues={values}
                  initialData={initialData}
                />
              )}
            </Form>
          );
        }}
      </Formik>
    </InnerLayout>
  );
};

export default DiscountGroup;

const itemTabs = ["Item Groups", "Manufacturers", "Items"];
