import { Form, Formik } from "formik";
import React from "react";
import * as Yup from "yup";
import InputField from "../forms/input-field";
import "../../components/customer-information/customer-information.css";
import TextAreaField from "../forms/textarea-field";
import { useDispatch } from "react-redux";

import AddButton, { DeleteButton } from "../buttons/button";
import {
  pincodeListAction,
} from "../../redux/actions/general-action";
import successToast from "../helper-components/success-toast";
import errorToast from "../helper-components/error-toast";
import { useNavigate, useSearchParams } from "react-router-dom";
import { doctorCreateAction, doctorDeleteAction, doctorEditAction } from "../../redux/actions/doctor-action";

const DoctorCreateViewEdit = ({ doctor, disabled = false, page = "add" }) => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const phoneNumber = searchParams.get("phoneNumber") || "";
  const navigate = useNavigate();
  const initialValues = {
    dr_name: doctor?.dr_name ?? "",
    dr_email: doctor?.dr_email ?? "",
    dr_specialization: doctor?.dr_specialization ?? "",
    dr_registration_num: doctor?.dr_registration_num ?? "",
    dr_qualification: doctor?.dr_qualification ?? "",
    dr_street1: doctor?.dr_street1 ?? null,
    dr_street2: doctor?.dr_street2 ?? null,
    dr_city: doctor?.dr_city ?? "",
    dr_state: doctor?.dr_state ?? "",
    dr_pincode: doctor?.dr_pincode ?? "",
  };

  const validationSchema = Yup.object().shape({
    dr_name: Yup.string().required("Doctor name is required!"),
    dr_email:  Yup.string().email("Invalid email"),
    dr_specialization:  Yup.string(),
    dr_registration_num:  Yup.string(),
    dr_qualification:  Yup.string(),
    dr_street1: Yup.string().nullable(),
    dr_street2: Yup.string().nullable(),
    dr_city:  Yup.string(),
    dr_state: Yup.string(),
    dr_pincode:  Yup.string(),
  });
  const onSubmit = (values, { resetForm }) => {
    let actionType = doctorCreateAction;
    if (page === "edit") {
      actionType = doctorEditAction;
    }
    dispatch(
      actionType(
        {
          dr_id: doctor?.dr_id,
          dr_name: values?.dr_name ?? "",
          dr_email: values?.dr_email ?? "",
          dr_specialization: values?.dr_specialization ?? "",
          dr_registration_num: values?.dr_registration_num ?? "",
          dr_qualification: values?.dr_qualification ?? "",
          dr_street1: values?.dr_street1 ?? null,
          dr_street2: values?.dr_street2 ?? null,
          dr_city: values?.dr_city ?? "",
          dr_state: values?.dr_state ?? "",
          dr_pincode: values?.dr_pincode ?? "",
        },
        onSuccess,
        onError
      )
    );
  };

  const onSuccess = (data) => {
    if (phoneNumber) {
      navigate(
        `/add-invoice?phoneNumber=${data?.data?.dr_phone_number}&id=${data?.data?.dr_id}`
      );
    } else {
      navigate("/doctor");
    }
    successToast(data?.data?.message);
  };
  const onError = (data) => {
    errorToast(data?.message);
  };
  const handleVoidAction = (id) => {
    dispatch(
      doctorDeleteAction(
        {
          dr_id: id,
        },
        onSuccessDel,
        onErrorDel
      )
    );
  };

  const onSuccessDel = () => {
    navigate("/doctor");
  };
  const onErrorDel = () => {};


  function fetchAddressFn(inputValue, setFieldValue) {
    if (inputValue?.length === 6) {
      dispatch(
        pincodeListAction(
          inputValue,
          (response) => {
            if (response?.[0]?.Status === "Error") {
            } else {
              const firstPostOffice = response?.[0]?.PostOffice?.[0];
              setFieldValue("dr_state", firstPostOffice?.State);
              setFieldValue("dr_city", firstPostOffice?.District);
            }
          },
          (data) => {
            setFieldValue("dr_state", "");
            setFieldValue("dr_city", "");
          }
        )
      );
    } else {
      setFieldValue("dr_state", "");
      setFieldValue("dr_city", "");
    }
  }

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ values, setFieldValue }) => {
        return (
          <Form className="h-full pt-5">
            <div className="flex flex-col justify-between h-full ">
              <div class="flex flex-wrap">
                <div class="w-full lg:w-1/2 xl:w-[40%] px-9">
          
                  <InputField
                    isDisabled={disabled}
                    containerClass="mb-2 position-relative w-full"
                    type="text"
                    name="dr_name"
                    placeholder="Enter name"
                    label="Name"
                    isRequired={true}
                    labelWidth="9rem"
                  />
                  <InputField
                    isDisabled={disabled}
                    type="text"
                    name="dr_email"
                    placeholder="Enter E-mail"
                    label="Email"
                    isRequired={false}
                    labelWidth="9rem"
                  />
                  <InputField
                    isDisabled={disabled}
                    type="text"
                    name="dr_specialization"
                    placeholder="Enter specialization"
                    label="Specialization"
                    isRequired={false}
                    labelWidth="9rem"
                  />
            
                  <InputField
                    type="text"
                    name="dr_qualification"
                    placeholder="Enter qualification"
                    label="Qualification"
                    labelWidth="9rem"
                  />
                  <InputField
                    type="text"
                    name="dr_registration_num"
                    placeholder="Enter reg no."
                    label="Registration number"
                    labelWidth="9rem"
                  />
          
                </div>
                <div class="w-full lg:w-1/2 xl:w-[40%] px-9">
                  <InputField
                    labelWidth="9rem"
                    isDisabled={disabled}
                    type="text"
                    name="dr_pincode"
                    placeholder="Enter pincode"
                    label="Pin code"
                    isRequired={false}
                    handleChange={(e) => {
                      fetchAddressFn(e?.target?.value, setFieldValue);
                    }}
                  />
                  <>
                    <InputField
                      labelWidth="9rem"
                      isDisabled={true}
                      type="text"
                      name="dr_state"
                      placeholder="state"
                      label="State"
                      isRequired={false}
                    />
                    <InputField
                      labelWidth="9rem"
                      isDisabled={true}
                      type="text"
                      name="dr_city"
                      placeholder="Enter city"
                      label="City"
                      isRequired={false}
                    />

                    <TextAreaField
                      labelWidth="9rem"
                      isDisabled={disabled}
                      label="Address 1"
                      placeholder="Enter address 1"
                      name="dr_street1"
                      id={"dr_street1"}
                      isRequired={false}
                    />
                    <TextAreaField
                      labelWidth="9rem"
                      isDisabled={disabled}
                      label="Address 2"
                      placeholder="Enter address 2"
                      name="dr_street2"
                      id={"dr_street2"}
                    />
                  </>
                  {/* {loading ? (
                    <LoadingSpinner />
                  ) : (
                   
                  )} */}
                </div>
              </div>
              {!disabled && (
                <div
                  style={{
                    bottom: "0",
                  }}
                  className="flex justify-between px-10 mb-5x sticky bg-white py-4 invoice-bottom-container"
                >
                  {page !== "add" ? (
                    <DeleteButton
                      onclkFn={() => handleVoidAction(doctor?.dr_id)}
                      prefixIcon={false}
                      type="button"
                    />
                  ) : (
                    <div></div>
                  )}

                  <AddButton prefixIcon={false} text="Save" type="submit" />
                </div>
              )}
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default DoctorCreateViewEdit;

