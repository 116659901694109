import SmileIcon from "../../assets/icons/smile-icon";
import comingsoonImage from "../../assets/images/construction.jpg";
import StoreCreation from "../../components/settings/system-settings/store-creation";
import PermissionSettings from "../../components/settings/permission-settings";
import DocumentNumberingIcon from "../../assets/icons/documentNum-icon";
import PrintPreferenceIcon from "../../assets/icons/print-preference-icon";
import SetupIcon from "../../assets/icons/setup-icon";
import CompanyLevelIcon from "../../assets/icons/company-level-icon";
import StoreCreationIcon from "../../assets/icons/store-creation-icon";
import StoreLicensingIcon from "../../assets/icons/store-licencing";
import UserInfoIcon from "../../assets/icons/user-info";
import AuthorizationIcon from "../../assets/icons/authorisation";
import LicensingIcon from "../../assets/icons/licensing";
import TaxSetupIcon from "../../assets/icons/tax-setup";
import ItemSetupIcon from "../../assets/icons/item-setup";
import ManufacturerSetupIcon from "../../assets/icons/manufacturer-setup";
import UomGroupIcon from "../../assets/icons/uom-group";
import UomIcon from "../../assets/icons/uom";
import ShippingSetupIcon from "../../assets/icons/shipping-setup-icon";
import StoreDetailsIcon from "../../assets/icons/store-details-icon";
import PriceListDisIcon from "../../assets/icons/price-list-discount-icon.js";
import ChartOfAccIcon from "../../assets/icons/chart-of-acc-icon.js";
import PeriodVolumeIcon from "../../assets/icons/period-volume-icon.js";
import AccountAllocationIcon from "../../assets/icons/account-allocation-icon.js";
import JournalEntryIcon from "../../assets/icons/Journal-Entry-icon.js";
import AltItemIcon from "../../assets/icons/alt-item-icon.js";
const NoChild = () => {
  return (
    <div className="h-full flex items-center justify-center">
      <img alt="Coming soon" src={comingsoonImage} />
    </div>
  );
};

const settingsData = [
  {
    icon: <SmileIcon />,
    title: "System Settings",
    desc: "Customer settings, inventory, alerts,display, warehouse, Accounting",
    path: "settings/system-settings",
    childrens: [
      {
        icon: <CompanyLevelIcon />,
        title: "Company Level Settings",
        desc: "Customer settings, inventory, alerts,display, warehouse, Accounting",
        path: "settings/system-settings/general-settings/company-level-settings ",
        input: <NoChild />,
        module_name: "company_level_setting",
      },
      {
        icon: <StoreDetailsIcon />,
        title: "Store Details",
        desc: "Customer settings, inventory, alerts,display, warehouse, Accounting",
        path: "settings/system-settings/general-settings/store-creation",
        input: <StoreCreation />,
        module_name: "store_details",
      },
      {
        icon: <StoreLicensingIcon />,
        title: "Store Licensing",
        desc: "Customer settings, inventory, alerts,display, warehouse, Accounting",
        path: "settings/system-settings/general-settings/store-licensing ",
        input: <NoChild />,
        module_name: "store_licensing",
      },
      {
        icon: <DocumentNumberingIcon />,
        title: "Document Numbering Series",
        desc: "Customer settings, inventory, alerts,display, warehouse, Accounting",
        path: "/settings/document-numbering-series",
        input: null,
        module_name: "document_numbering",
      },
      {
        icon: <PrintPreferenceIcon />,
        title: "Print Preferences",
        desc: "Customer settings, inventory, alerts,display, warehouse, Accounting",
        path: "/settings/print-preference",
        input: null,
        module_name: "print_perfernces",
      },
    ],
  },
  {
    icon: <SmileIcon />,
    title: "User Settings",
    desc: "Customer settings, inventory, alerts,display, warehouse, Accounting",
    path: "settings/system-settings",
    childrens: [
      {
        icon: <UserInfoIcon />,
        title: "User Info",
        desc: "Customer settings, inventory, alerts,display, warehouse, Accounting",
        input: null,
        path: "/user",
        module_name: "user_info",
      },

      {
        icon: <AuthorizationIcon />,
        title: "Authorizations",
        desc: "Customer settings, inventory, alerts,display, warehouse, Accounting",
        path: "settings/system-settings/general-settings",
        input: <PermissionSettings />,
        module_name: "authorizations",
      },

      {
        icon: <LicensingIcon />,
        title: "Licensing",
        desc: "Customer settings, inventory, alerts,display, warehouse, Accounting",
        path: "settings/system-settings/store-details",
        input: <NoChild />,
        module_name: "licensing",
      },

      {
        icon: <SmileIcon />,
        title: "Display Settings",
        desc: "Customer settings, inventory, alerts,display, warehouse, Accounting",
        path: "settings/system-settings",
        input: <NoChild />,
        module_name: "display_setting",
      },
    ],
  },
  {
    icon: <SmileIcon />,
    title: "Data Setup",
    desc: "Customer settings, inventory, alerts,display, warehouse, Accounting",
    path: "settings/system-settings",
    childrens: [
      {
        icon: <TaxSetupIcon />,
        title: "Tax Setup",
        desc: "Tax setup,Tax combination, Accounting",
        path: "/settings/tax-setup",
        input: null,
        module_name: "tax_setup",
      },

      {
        icon: <ItemSetupIcon />,
        title: "Item Setup",
        desc: "Item Setup,display, warehouse, Accounting",
        path: "/settings/item-setup",
        input: null,
        childrens: [],
        module_name: "item_setup",
      },

      {
        icon: <ManufacturerSetupIcon />,
        title: "Manufacturer Setup",
        desc: "Manufacturer Setup,display, warehouse, Accounting",
        path: "/settings/manufacturer-setup",
        input: null,
        childrens: [],
        module_name: "manufacturer_setup",
      },
      {
        icon: <ShippingSetupIcon />,
        title: "Shipping Setup",
        desc: "Shipping Setup,display, warehouse, Accounting",
        path: "/settings/shipping-setup",
        input: null,
        childrens: [],
        module_name: "shipping_setup",
      },
      {
        icon: <UomIcon />,
        title: "Unit Of Measures",
        desc: "Unit of measure, inventory, alerts,display, warehouse, Accounting",
        path: "/settings/uom-setup",
        input: null,
        childrens: [],
        module_name: "uom_setup",
      },
      {
        icon: <UomGroupIcon />,
        title: "Unit Of Measure Groups",
        desc: "Unit of measure Groups, inventory, alerts,display, warehouse, Accounting",
        path: "/settings/uom-group-setup",
        input: null,
        childrens: [],
        module_name: "uom_group_setup",
      },
      {
        icon: <AltItemIcon />,
        title: "Alternative Items",
        desc: "Alternative items mapping",
        path: "/settings/alternative-item-setup",
        input: null,
        childrens: [],
        module_name: "uom_group_setup",
      },
    ],
  },
  {
    icon: <SmileIcon />,
    title: "Discount Settings",
    desc: "Pricelist discount, Period-Volume Discount, Discount Group",
    path: "settings/system-settings",
    childrens: [
      {
        icon: <PriceListDisIcon />,
        title: "Price List Discounts",
        desc: "Pricelist discount",
        input: null,
        path: "/price-list",
        module_name: "price_list",
      },
      {
        icon: <PeriodVolumeIcon />,
        title: "Period-Volume Discounts",
        desc: "Period-Volume Discount",
        input: null,
        path: "/period-volume",
        module_name: "period_and_volume_discount",
      },
      {
        icon: <LicensingIcon />,
        title: "Discount groups",
        desc: "Discount Group",
        input: null,
        path: "/discount-group",
        module_name: "discount_group",
      },
    ],
  },
  {
    icon: <SmileIcon />,
    title: "Account Settings",
    desc: "Chart of account, Account allocation, Journal Entry",
    path: "settings/system-settings",
    childrens: [
      {
        icon: <ChartOfAccIcon />,
        title: "Chart of Accounts",
        desc: "Chart of Accounts",
        input: null,
        path: "/chart-of-account",
        module_name: "chart_of_accounts",
      },
      {
        icon: <AccountAllocationIcon />,
        title: "Account Allocation",
        desc: "Account Allocation",
        input: null,
        path: "/account-allocation",
        module_name: "account_allocation",
      },
      {
        icon: <JournalEntryIcon />,
        title: "Journal Entry",
        desc: "Journal Entry",
        input: null,
        path: "/journal-entry",
        module_name: "journal_entry",
      },
    ],
  },
  {
    icon: <SmileIcon />,
    title: "Data Sync Management",
    desc: "Customer settings, inventory, alerts,display, warehouse, Accounting",
    path: "settings/system-settings",
    childrens: [
      {
        icon: <SmileIcon />,
        title: "Store wise Rules Setup",
        desc: "Customer settings, inventory, alerts,display, warehouse, Accounting",
        path: "settings/system-settings/store-details",
        input: <NoChild />,
      },
    ],
  },
  {
    icon: <SmileIcon />,
    title: "Communication",
    desc: "Customer settings, inventory, alerts,display, warehouse, Accounting",
    path: "settings/system-settings",
    childrens: [
      {
        icon: <SmileIcon />,
        title: "Whatsapp Api Config",
        desc: "Customer settings, inventory, alerts,display, warehouse, Accounting",
        path: "/settings/whatsapp-configuration",
        input: null,
      },
      {
        icon: <SmileIcon />,
        title: "Message Api Config",
        desc: "Customer settings, inventory, alerts,display, warehouse, Accounting",
        path: "/settings/sms-configuration",
        input: null,
      },
      {
        icon: <SmileIcon />,
        title: "SMTP Config",
        desc: "Customer settings, inventory, alerts,display, warehouse, Accounting",
        path: "/settings/smtp-configuration",
        input: null,
      },
    ],
  },
  {
    icon: <SmileIcon />,
    title: "Payment Settings",
    desc: "Customer settings, inventory, alerts,display, warehouse, Accounting",
    path: "settings/system-settings",
    childrens: [
      // {
      //     icon: <SmileIcon />,
      //     title: "Payment Config",
      //     desc: "Customer settings, inventory, alerts,display, warehouse, Accounting",
      //     path: "settings/system-settings/store-details",
      //     input: <NoChild />,
      // },
      {
        icon: <SmileIcon />,
        title: "Razorpay Config",
        desc: "Customer settings, inventory, alerts,display, warehouse, Accounting",
        path: "/settings/razorpay-configuration",
        input: null,
      },
      {
        icon: <SmileIcon />,
        title: "Stripe Config",
        desc: "Customer settings, inventory, alerts,display, warehouse, Accounting",
        path: "/settings/stripe-configuration",
        input: null,
      },
      {
        icon: <SmileIcon />,
        title: "Phonepe Config",
        desc: "Customer settings, inventory, alerts,display, warehouse, Accounting",
        path: "/settings/phonepe-configuration",
        input: null,
      },
      {
        icon: <SmileIcon />,
        title: "Paytm Config",
        desc: "Customer settings, inventory, alerts,display, warehouse, Accounting",
        path: "/settings/paytm-configuration",
        input: null,
      },
      {
        icon: <SmileIcon />,
        title: "Pine Labs Config",
        desc: "Customer settings, inventory, alerts,display, warehouse, Accounting",
        path: "/settings/pine-labs-configuration",
        input: null,
      },
    ],
  },
  {
    icon: <SmileIcon />,
    title: "Integrations",
    desc: "Customer settings, inventory, alerts,display, warehouse, Accounting",
    path: "settings/system-settings",
    childrens: [
      {
        icon: <SmileIcon />,
        title: "Hub Spot Setup",
        desc: "Customer settings, inventory, alerts,display, warehouse, Accounting",
        path: "settings/system-settings/store-details",
        input: <NoChild />,
      },
      {
        icon: <SmileIcon />,
        title: "ERP 1",
        desc: "Customer settings, inventory, alerts,display, warehouse, Accounting",
        path: "settings/system-settings/store-details",
        input: <NoChild />,
      },
      {
        icon: <SmileIcon />,
        title: "ERP 2",
        desc: "Customer settings, inventory, alerts,display, warehouse, Accounting",
        path: "settings/system-settings/store-details",
        input: <NoChild />,
      },
    ],
  },
  {
    icon: <SmileIcon />,
    title: "Human Resources",
    desc: "Customer settings, inventory, alerts,display, warehouse, Accounting",
    path: "settings/system-settings",
    childrens: [
      {
        icon: <SmileIcon />,
        title: "Hardware Setup",
        desc: "Customer settings, inventory, alerts,display, warehouse, Accounting",
        path: "settings/system-settings/store-details",
        input: <NoChild />,
      },
      {
        icon: <SmileIcon />,
        title: "Shift Setup",
        desc: "Customer settings, inventory, alerts,display, warehouse, Accounting",
        path: "settings/system-settings/store-details",
        input: <NoChild />,
      },
    ],
  },
];

export default settingsData;
