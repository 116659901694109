import React, { useState } from "react";

import InputField from "../../forms/input-field";
import MultipleImagesField from "../../forms/multiple-images-field";
import { useFormikContext } from "formik";
import LoadingSpinner from "../../helper-components/loading-spinner";

const StoreMapsPhotos = ({ disabled = false }) => {
  const { values, setFieldValue } = useFormikContext();
  const handleDeleteImage = (index) => {
    const updatedImages = values.shop_images.filter((_, i) => i !== index);
    setFieldValue("shop_images", updatedImages);
  };

  const [loading, setloading] = useState(false);
  return (
    <>
      <div className="w-full 2xl:w-[35%] lg:w-[60%] px-5 xl:px-10 mt-5">
        <InputField
          isDisabled={disabled}
          isRequired={true}
          type="text"
          name="store_latitude"
          placeholder="xxxxx "
          label="Store latitude"
          labelWidth="12.8rem"
        />
        <InputField
          isDisabled={disabled}
          isRequired={true}
          type="text"
          name="store_longitude"
          placeholder="xxxxx"
          label="Store longitude"
          labelWidth="12.8rem"
        />
        <MultipleImagesField
          isDisabled={disabled}
          isRequired={true}
          setloading={setloading}
        />
      </div>
      <div className="px-5 xl:px-10 mt-5 flex flex-wrap gap-10">
        {Array.isArray(values.shop_images) &&
          values.shop_images.map((im, index) => (
            <div
              key={index}
              className="relative group"
              style={{
                width: "fit-content",
              }}
            >
              <img
                src={im}
                alt={`Image ${index}`}
                className="object-cover rounded-lg"
                style={{ height: "10rem" }}
              />
              <button
                type="button"
                onClick={() => handleDeleteImage(index)}
                className="absolute bottom-0 right-0 p-2 bg-red-500 text-white text-xs hover:bg-red-600 transition duration-300 invisible group-hover:visible rounded-t-none rounded-bl-md rounded-br-md"
                style={{ width: "100%" }}
              >
                Delete
              </button>
            </div>
          ))}
        {loading && <LoadingSpinner />}
      </div>
    </>
  );
};

export default StoreMapsPhotos;

