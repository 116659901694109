import React, { useEffect, useState } from "react";
import ReportsLayout from "../../../components/reports/reports-layout";
import { productWiseSalesReportAction } from "../../../redux/actions/report-action";
import { useDispatch } from "react-redux";
import moment from "moment";

const GstEwayBillReport = () => {
  const [dateRangeFilter, setDateRangeFilterValue] = useState({
    from: null,
    to: null,
  });
  const dispatch = useDispatch();
  const [groupByOptions, setgroupByOptions] = useState([
    { label: "None", value: "" },
    { label: "Drug Code", value: "oi.item_code" },
    { label: "Drug Name", value: "oi.item_name" },
    { label: "Bill Date", value: "so.created_date" },
    { label: "Drug Code & Bill Date", value: "oi.item_code,so.created_date" },
    { label: "Drug Name & Bill Date", value: "oi.item_name,so.created_date" },
    { label: "Drug Code & Branch", value: "oi.item_code,si.firm_name" },
    { label: "Drug Code & Customer", value: "oi.item_code,c.cmr_first_name" },
    { label: "Drug Code & UOM", value: "oi.item_code,oi.item_uom" },
    {
      label: "Drug Code & Batch No",
      value: "oi.item_code,oi.item_batch_number",
    },
    { label: "Drug Code & Qty", value: "oi.item_code,oi.item_quantity" },
    { label: "Drug Code & Rate", value: "oi.item_code,oi.item_unit_price" },
  ]);

  const [loading, setLoading] = useState(false);
  const [filterOptions, setFilterOptions] = useState(
    Object.entries(tableColoumsn).map(([label, value]) => ({
      label,
      value,
    }))
  );
  const [selectedColumns, setSelectedColumns] = useState(tableHeading);
  const [filters, setFilters] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [dateRange, setDateRange] = useState("");
  const [groupBy, setGroupBy] = useState("");

  useEffect(() => {
    const selectedColumnValues = selectedColumns.map(
      (column) => tableColoumsn[column]
    );
    const requestData = {
      selectedColumns: selectedColumnValues,
      filters: filters.map(({ id, ...rest }) => rest),
      filter_by: dateRange,
      group_by: groupBy,
      from: dateRangeFilter?.from,
      to: dateRangeFilter?.to,
    };
    dispatch(productWiseSalesReportAction(requestData, onSuccess, onError));
    setLoading(true);
  }, [selectedColumns, filters, dateRange, groupBy]);

  const isIso8601Date = (dateString) => {
    const iso8601Regex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/;
    return iso8601Regex.test(dateString);
  };

  const formatDate = (value) => {
    return moment(value).format("DD-MM-YYYY");
  };

  const transformData = (data) => {
    return data.map((item) => {
      let transformedItem = {};
      for (let [key, value] of Object.entries(tableColoumsn)) {
        const rawValue = item[value.split(".").pop()];
        transformedItem[key] = isIso8601Date(rawValue)
          ? formatDate(rawValue)
          : rawValue;
      }
      return transformedItem;
    });
  };

  const onSuccess = (data) => {
    console.log("data is", data);
    const transformedData = transformData(data?.data?.result);
    setTableData(transformedData);
    setLoading(false);
  };
  const onError = (data) => {
    console.log("error is", data);
    setLoading(false);
  };

  return (
    <ReportsLayout
      title={"E-way Bill Report"}
      tableHeading={selectedColumns}
      tableData={tableData}
      groupByOptions={groupByOptions}
      filterOptions={filterOptions}
      availableColumns={tableHeading}
      selectedColumns={selectedColumns}
      setSelectedColumns={setSelectedColumns}
      setFilters={setFilters}
      loading={loading}
      setDateRange={setDateRange}
      setGroupBy={setGroupBy}
      setDateRangeFilterValue={setDateRangeFilterValue}
    />
  );
};
const tableHeading = [
  "E-Way Bill Number", // New entry
  "Invoice Number", // Already present
  "Date of Generation", // New entry
  "Transporter Name", // New entry
  "Validity Period (Start)", // New entry
  "Validity Period (End)", // New entry
  "Origin", // New entry
  "Destination", // New entry
  "Value of Goods", // New entry
  "GST Amount", // New entry
];

const tableColoumsn = {
  "E-Way Bill Number": "so.eway_bill_number", // New entry for E-Way Bill Number
  "Invoice Number": "so.sot_invoice_number", // Already present
  "Date of Generation": "so.date_of_generation", // New entry for Date of Generation of the E-Way Bill
  "Transporter Name": "so.transporter_name", // New entry for Transporter Name
  "Validity Period (Start)": "so.validity_start", // New entry for Validity Period Start
  "Validity Period (End)": "so.validity_end", // New entry for Validity Period End
  Origin: "so.origin", // New entry for the Origin location
  Destination: "so.destination", // New entry for the Destination location
  "Value of Goods": "so.value_of_goods", // New entry for Value of Goods
  "GST Amount": "so.gst_amount", // New entry for GST Amount on goods
};

export default GstEwayBillReport;
