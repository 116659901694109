import React, { Fragment, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import EditIcon, {
  PrintIcon,
  ShareIcon,
  VoidIcon,
} from "../../assets/icons/action-icons";
import SortingIcons from "../../assets/icons/arrowup-down";
import KibabMenu from "../../assets/icons/kibab-menu";
import FilterSection from "./filtering-section";
import Pagination from "./pagination";
import "./table.css";
import { invoiceRowClearAction } from "../../redux/actions/invoices-action";
const DynamicTable = ({
  tableHeading,
  tableBodyData = [],
  paginationProps,
  filterData,
  setFilterData,
  placeholder,
  filterFn = () => {},
  filterBy,
  showFilter,
  label = "Invoice",
  initialFilter,
  voidFn,
  reload,
  setReload,
  setLoading,
  deleteAction = () => {},
  id_name = "cmr_id",
  path = "invoice",
  filterText,
  date,
  module,
  permissionFn = () => {},
  filterSec = true,
  menu = true,
  filter = true,
  onclkFn,
  list,
  edit = true,
  onClickItemFn = () => {},
  usePassedComp = false,
  passedCompFn = () => {},
  newBtnTxt = "New",
  invoicePrntFn = () => {},
}) => {
  const defaultPermissionFn = () => {};
  const [checkedId, setCheckedId] = useState([]);
  const navigate = useNavigate();
  const [action, setAction] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(null);
  const dispatch = useDispatch();
  function sortFn(item) {
    let direction = "ASC";
    if (
      filterData != null &&
      filterData?.sort_by === item &&
      filterData?.sort_order === "ASC"
    ) {
      direction = "DESC";
    }
    setFilterData({ ...filterData, sort_by: item, sort_order: direction });
  }

  function checkboxChecker(e, all) {
    if (all === "all" && checkedId.length === e.length) {
      setCheckedId([]);
    } else if (all === "all") {
      setCheckedId(e);
    } else {
      if (checkedId.includes(e?.target?.id)) {
        const checkedVAlues = [...checkedId];
        const findIndex = checkedVAlues.findIndex(
          (item) => item === e.target?.id
        );
        checkedVAlues.splice(findIndex, 1);
        setCheckedId(checkedVAlues);
      } else {
        setCheckedId((previous) => {
          return [...previous, e.target.id];
        });
      }
    }
  }

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        action &&
        currentIndex != null &&
        !event.target.closest(".relative")
      ) {
        setAction(false);
        setCurrentIndex(null);
      }
    };
    document.addEventListener("click", handleOutsideClick);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [action, currentIndex]);

  const handleDeleteAction = (id) => {
    setLoading(true);
    dispatch(
      deleteAction(
        {
          [id_name]: id,
        },
        onSuccessDel,
        onErrorDel
      )
    );
  };

  const onSuccessDel = () => {
    setReload(!reload);
    setLoading(false);
  };
  const onErrorDel = () => {};
  return (
    <>
      <FilterSection
        newBtnTxt={newBtnTxt}
        list={list}
        filterSec={filterSec}
        onclkFn={onclkFn}
        module={module}
        permissionFn={permissionFn}
        filter={filter}
        date={date}
        filterText={filterText}
        paginationProps={paginationProps}
        showFilter={showFilter}
        placeholder={placeholder}
        filterFn={filterFn}
        tableHeading={
          module === "stock_transfer"
            ? [
                ...tableHeading,
                {
                  label: "ITEM NAME",
                  value: "item_name",
                  display: false,
                  column: true,
                },
              ]
            : tableHeading
        }
        filterData={filterData}
        setFilterData={setFilterData}
        filterBy={filterBy}
        initialFilter={initialFilter}
      />

      <div className="flex flex-col justify-between h-full">
        <div className="tab-container">
          <table className="my-tab h-full">
            <thead>
              <tr className="relative">
                <th
                  className={`whitespace-nowrap fs_12 fw_500 text-475467 bg-F2F4F5 tab-header`}
                >
                  <input
                    checked={
                      checkedId?.length ===
                        tableBodyData?.map((item) => item?.id).length &&
                      checkedId.length > 0
                    }
                    onChange={() =>
                      checkboxChecker(
                        tableBodyData.map((item) => item?.id),
                        "all"
                      )
                    }
                    style={{ transform: "scale(1.1)" }}
                    type="checkbox"
                    id="myCheckbox"
                    className="custom-checkbox form-check-input cursor-pointer"
                  />
                </th>
                {tableHeading.map((heading, index) => (
                  <th
                    key={index}
                    className={`${"tab-header"} whitespace-nowrap fs_12 fw_500 text-475467 bg-F2F4F5`}
                  >
                    <span
                      className={`flex items-center gap-2 ${heading?.className}`}
                    >
                      {heading?.label}
                      {heading?.display && (
                        <button onClick={() => sortFn(heading?.value)}>
                          {" "}
                          <SortingIcons
                            inc={
                              filterData?.sort_order === "ASC" &&
                              heading?.value === filterData?.sort_by
                                ? "#475467"
                                : "#9A9FA6"
                            }
                            dec={
                              filterData?.sort_order === "DESC" &&
                              heading?.value === filterData?.sort_by
                                ? "#475467"
                                : "#9A9FA6"
                            }
                          />
                        </button>
                      )}
                    </span>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="h-full">
              {tableBodyData?.length > 0 ? (
                <>
                  {tableBodyData.map((rowData, rowIndex) => (
                    <tr
                      className={`hover:bg-f3f8ff ${
                        checkedId.includes(rowData?.id) &&
                        "bg-D5E6Ff hover:bg-D5E6Ff"
                      } `}
                      key={rowIndex}
                    >
                      <td className="fs_14 tab-cell fw_400 text-secondary">
                        <input
                          checked={checkedId.includes(rowData?.id)}
                          id={rowData?.id}
                          onChange={(e) => checkboxChecker(e)}
                          style={{ transform: "scale(1.2)" }}
                          type="checkbox"
                          className="custom-checkbox form-check-input cursor-pointer"
                        />
                      </td>
                      {Object.entries(rowData).map(
                        ([key, cellData], cellIndex) => (
                          <Fragment key={cellIndex}>
                            {cellIndex !== 0 && (
                              <td
                                onClick={() => onClickItemFn(rowData?.id)}
                                className={`tab-cell fs_14 fw_400 text-secondary whitespace-nowrap cursor-pointer`}
                              >
                                {key === "total_sale_amount"
                                  ? cellData?.label
                                  : cellData}
                              </td>
                            )}
                          </Fragment>
                        )
                      )}
                      {menu && (
                        <td
                          className={`fs_14 tab-cell fw_400 text-secondary  bg-white`}
                        >
                          {((permissionFn || defaultPermissionFn)(module)
                            ?.update ||
                            (permissionFn || defaultPermissionFn)(module)
                              ?.delete ||
                            ((permissionFn || defaultPermissionFn)(module)
                              ?.print &&
                              module === "invoice") ||
                            ((permissionFn || defaultPermissionFn)(module)
                              ?.send &&
                              module === "invoice")) && (
                            <>
                              <button
                                className="relative"
                                onClick={() => {
                                  setAction(!action);
                                  setCurrentIndex(rowIndex);
                                }}
                              >
                                <KibabMenu />
                              </button>
                              {action &&
                              currentIndex === rowIndex &&
                              usePassedComp ? (
                                <>{passedCompFn(rowData?.id)}</>
                              ) : (
                                action &&
                                currentIndex === rowIndex && (
                                  <div
                                    style={{
                                      minWidth: "13rem",
                                      top: "0px",
                                      right: "0px",
                                    }}
                                    className="absolute z-40 border-EAECF0 border-solid border border-1 top-4  mt-8 mr-8 py-4 px-4 -right-80  bg-white  rounded shadow-lg flex flex-col gap-3"
                                  >
                                    {label === "Invoice" &&
                                      rowData?.is_draft_order &&
                                      (permissionFn || defaultPermissionFn)(
                                        module
                                      )?.update && (
                                        <button
                                          onClick={() => {
                                            dispatch(invoiceRowClearAction());
                                            navigate(
                                              `/edit-invoice/${rowData?.id}`
                                            );
                                          }}
                                          className="flex items-center gap-2 fw_500 fs_14"
                                        >
                                          <EditIcon />
                                          Edit {label}
                                        </button>
                                      )}
                                    {label !== "Invoice" &&
                                      edit &&
                                      (permissionFn || defaultPermissionFn)(
                                        module
                                      )?.update && (
                                        <button
                                          onClick={() => {
                                            navigate(
                                              `/edit-${path}/${rowData?.id}`
                                            );
                                          }}
                                          className="flex items-center gap-2 fw_500 fs_14"
                                        >
                                          <EditIcon />
                                          Edit {label}
                                        </button>
                                      )}

                                    {label === "Invoice" && (
                                      <>
                                        {(permissionFn || defaultPermissionFn)(
                                          module
                                        )?.print && (
                                          <button
                                            onClick={() => {
                                              invoicePrntFn(rowData?.id);
                                            }}
                                            className="flex items-center gap-2 fw_500 fs_14"
                                          >
                                            <PrintIcon />
                                            Print {label}
                                          </button>
                                        )}
                                        {(permissionFn || defaultPermissionFn)(
                                          module
                                        )?.send && (
                                          <button className="flex items-center gap-2 fw_500 fs_14">
                                            <ShareIcon />
                                            Share {label}
                                          </button>
                                        )}
                                      </>
                                    )}
                                    {label === "Invoice" &&
                                      rowData?.is_draft_order &&
                                      (permissionFn || defaultPermissionFn)(
                                        module
                                      )?.delete && (
                                        <button
                                          onClick={() => {
                                            voidFn(rowData?.id);
                                          }}
                                          className="flex items-center gap-2 fw_500 fs_14"
                                        >
                                          <VoidIcon />
                                          Void {label}
                                        </button>
                                      )}
                                    {label !== "Invoice" &&
                                      (permissionFn || defaultPermissionFn)(
                                        module
                                      )?.delete &&
                                      (module !== "customer" ||
                                        rowData?.total_sale_amount?.value <
                                          1) && (
                                        <button
                                          onClick={() => {
                                            handleDeleteAction(rowData?.id);
                                          }}
                                          className="flex items-center gap-2 fw_500 fs_14"
                                        >
                                          <VoidIcon />
                                          Void {label}
                                        </button>
                                      )}
                                  </div>
                                )
                              )}
                            </>
                          )}
                        </td>
                      )}
                    </tr>
                  ))}
                  <tr style={{ height: "10rem" }}></tr>
                </>
              ) : (
                <tr
                  style={{
                    height: "20rem",
                  }}
                >
                  <td
                    colSpan={tableHeading.length + 1}
                    className="text-center text-primary"
                  >
                    No {label + "s"} found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <Pagination paginationProps={paginationProps} />
      </div>
    </>
  );
};

export default DynamicTable;