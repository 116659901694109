import React, { useEffect, useState } from "react";
import InnerLayout from "../../../components/layouts/inner-layout";
import LoadingSpinner from "../../../components/helper-components/loading-spinner";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { salesReturnViewAction } from "../../../redux/actions/sales-action";
import CreateEditViewSalesReturn from "../../../components/sales-section/sales-return/create-edit-view-sales-return";
const SalesReturnView = () => {
  const { id } = useParams();
  const [orderDetail, setOrderDetail] = useState({});
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(salesReturnViewAction(id, onSuccPur, onErrPur));
  }, []);

  const onSuccPur = (data) => {
    setOrderDetail(data?.data?.[0]);
    setLoading(false);
  };

  const onErrPur = (data) => {
    setLoading(false);
  };

  return (
    <>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <InnerLayout backBtn={true} title="View Sales Return">
          <CreateEditViewSalesReturn
            data={orderDetail}
            page="view"
            type={"return"}
            disabled={true}
            orderDetail={orderDetail?.itemData}
            vendorDetails={[orderDetail?.orderData]}
          />
        </InnerLayout>
      )}
    </>
  );
};
export default SalesReturnView;
