import React from "react";
import numberToWords from "number-to-words";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import moment from "moment";

// Font.register({
//   family: "Bitter",
//   fonts: [
//     { src: bitterFontRegular, fontWeight: 400 },
//     { src: bitterFontBold, fontWeight: 700 },
//   ],
// });
const styles = StyleSheet.create({
  page: {
    padding: 20,
  },
  heading: {
    fontSize: 10,
    fontWeight: "bold",
    // fontFamily: "Bitter",
  },
  text: {
    fontSize: 10,
    fontWeight: "normal",
    // fontFamily: "Bitter",
    letterSpacing: 1,
  },
  sectionContainer: {
    border: "2 solid black",
    height: "100%",
  },
  table: {
    display: "table",
    width: "100%",
  },
  tableRow: {
    flexDirection: "row",
  },
  tableCell: {
    flex: 1,
  },
  tableCellWithRightBorder: {
    borderRight: "1.5 solid black",
  },
  image: {
    width: 100,
    height: 40,
  },

  itemtable: {
    // fontFamily: "Bitter",
    fontSize: 10,
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 2,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    borderLeftWidth: 0,
  },
  itemtableRow: { margin: "auto", flexDirection: "row" },
  itemtableColHeader: {
    borderStyle: "solid",
    borderWidth: 2,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    padding: 1,
    textAlign: "center",
    fontWeight: "bold",
  },
  itemtableCol: {
    fontWeight: "normal",
    borderStyle: "solid",
    borderWidth: 2,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    padding: 1,
    textAlign: "center",
    paddingVertical: 2,
  },
  lastCol: {
    borderRightWidth: 0,
  },
  firstCol: {
    borderLeftWidth: 0,
  },
  width1: {
    width: "4%",
  },
  width2: {
    width: "6%",
  },
  width3: {
    width: "10%",
  },
  width4: {
    width: "20%",
  },
});


const InvoicePdfContent = ({ data, storeDetails }) => {
  return (
    <Document>
      <Page wrap size="A4" orientation="landscape" style={styles.page}>
        <View wrap={true} style={styles.sectionContainer}>
          <View style={styles.table}>
            <View style={styles.tableRow}>
              <View style={[styles.tableCell, styles.tableCellWithRightBorder]}>
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    padding: 5,
                    alignItems: "start",
                    justifyContent: "between",
                  }}
                >
                  <View
                    style={{
                      width: "70%",
                    }}
                  >
                    <Text style={styles?.heading}>
                      {storeDetails?.store_name}
                    </Text>
                    <Text
                      style={{
                        fontSize: "10",
                        fontWeight: "normal",
                        // fontFamily: "Bitter",
                        letterSpacing: "2px",
                      }}
                    >
                      {storeDetails?.store_address}
                    </Text>
                    <Text style={styles?.heading}>
                      Phone No.:{" "}
                      <Text style={{ fontWeight: "normal", fontSize: "10" }}>
                        {storeDetails?.store_phone_numnber}
                      </Text>
                    </Text>
                    <Text
                      style={{
                        fontSize: "10",
                        fontWeight: "normal",
                        // fontFamily: "Bitter",
                        letterSpacing: "2px",
                      }}
                    >
                      FSSAI No.: <Text> {storeDetails?.store_fssai}</Text>
                    </Text>
                  </View>
                  <View style={{ marginLeft: 10, width: "30%" }}>
                    <Image
                      src={storeDetails?.store_logo}
                      style={styles.image}
                    />
                  </View>
                </View>
                <Text
                  style={{
                    ...styles?.heading,
                    borderTop: "2 solid black",
                    paddingVertical: 5,
                    paddingHorizontal: 5,
                  }}
                >
                  Customer Name :<Text style={styles?.text}>Rajesh Kumar</Text>
                </Text>
                <Text
                  style={{
                    ...styles?.heading,
                    borderTop: "2 solid black",
                    paddingVertical: 5,
                    paddingHorizontal: 5,
                  }}
                >
                  Dr. Name :
                  <Text style={styles?.text}>
                    {data?.orderData?.[0]?.doctor_name}
                  </Text>
                </Text>
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <Text
                    style={{
                      ...styles?.heading,
                      borderTop: "2 solid black",
                      width: "50%",
                      paddingVertical: 5,
                      paddingHorizontal: 5,
                      borderRight: "1 solid black",
                    }}
                  >
                    Inv. No. :
                    <Text style={styles?.text}>
                      {data?.orderData?.[0]?.sot_invoice_number}
                    </Text>
                  </Text>
                  <Text
                    style={{
                      ...styles?.heading,
                      width: "50%",
                      borderTop: "2 solid black",
                      paddingVertical: 5,
                      paddingHorizontal: 5,
                    }}
                  >
                    Inv. Date. :
                    <Text style={styles?.text}>
                      {" "}
                      {moment(data?.orderData?.[0]?.created_date).format(
                        "DD-MM-YYYY"
                      )}
                    </Text>
                  </Text>
                </View>
              </View>

              <View style={styles.tableCell}>
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <View
                    style={{
                      width: "18%",
                      borderRight: "2 solid black",
                    }}
                  >
                    <Text
                      style={{
                        ...styles?.heading,
                        textAlign: "center",
                        paddingVertical: 3,
                      }}
                    >
                      CASH MEMO
                    </Text>
                    <Text
                      style={{
                        ...styles?.heading,
                        textAlign: "center",
                        paddingVertical: 3,
                      }}
                    >
                      DUPLICATE
                    </Text>
                    <Text
                      style={{
                        ...styles?.heading,
                        textAlign: "center",
                        paddingVertical: 3,
                      }}
                    >
                      GST Invoice
                    </Text>
                  </View>
                  <View
                    style={{
                      width: "85%",
                      paddingLeft: 3,
                    }}
                  >
                    <Text
                      style={{
                        ...styles?.heading,
                        paddingVertical: 3,
                      }}
                    >
                      Gst No. :
                      <Text style={styles?.text}>
                        {" "}
                        {storeDetails?.store_gst_no}
                      </Text>
                    </Text>
                    <Text
                      style={{
                        ...styles?.heading,
                        paddingVertical: 3,
                      }}
                    >
                      Pan No. :
                      <Text style={styles?.text}>
                        {" "}
                        {storeDetails?.store_pan_no}
                      </Text>
                    </Text>
                    <Text
                      style={{
                        ...styles?.heading,
                        paddingVertical: 3,
                      }}
                    >
                      DL No. :
                      <Text style={styles?.text}>
                        {storeDetails?.store_dl_no}{" "}
                      </Text>
                    </Text>
                  </View>
                </View>
                <View
                  style={{
                    borderTop: "2 solid black",
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <Text
                    style={{
                      ...styles?.heading,
                      width: "50%",
                      paddingVertical: 5,
                      paddingHorizontal: 5,
                      borderRight: "2 solid black",
                    }}
                  >
                    Mob No. :{" "}
                    <Text style={styles?.text}>
                      {data?.orderData?.[0]?.cmr_phone_number}
                    </Text>
                  </Text>
                  <Text
                    style={{
                      ...styles?.heading,
                      width: "50%",
                      paddingVertical: 5,
                      paddingHorizontal: 5,
                    }}
                  >
                    Area : <Text style={styles?.text}></Text>
                  </Text>
                </View>
                <View
                  style={{
                    borderTop: "2 solid black",
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <View
                    style={{
                      width: "50%",
                      borderRight: "2 solid black",
                      height: 85,
                    }}
                  >
                    <Text
                      style={{
                        ...styles?.heading,
                        paddingVertical: 5,
                        paddingHorizontal: 5,
                      }}
                    >
                      Gst No. : <Text style={styles?.text}></Text>
                    </Text>
                    <Text
                      style={{
                        ...styles?.heading,
                        paddingVertical: 5,
                        paddingHorizontal: 5,
                        borderTop: "2 solid black",
                      }}
                    >
                      Pan No. : <Text style={styles?.text}></Text>
                    </Text>
                  </View>
                  <Text
                    style={{
                      ...styles?.heading,
                      width: "50%",
                      paddingVertical: 5,
                      paddingHorizontal: 5,
                    }}
                  >
                    Address : <Text style={styles?.text}></Text>
                  </Text>
                </View>
              </View>
            </View>
          </View>

          <View break style={styles.itemtable}>
            <View style={styles.itemtableRow}>
              <Text
                style={[
                  styles.itemtableColHeader,
                  styles.firstCol,
                  styles?.width1,
                ]}
              >
                SR. No.
              </Text>
              <Text style={[styles.itemtableColHeader, styles?.width1]}>
                Mfg
              </Text>
              <Text style={[styles.itemtableColHeader, styles?.width2]}>
                HSN
              </Text>
              <Text style={[styles.itemtableColHeader, styles?.width4]}>
                Product Name
              </Text>
              <Text style={[styles.itemtableColHeader, styles?.width3]}>
                Batch No.
              </Text>
              <Text style={[styles.itemtableColHeader, styles?.width1]}>
                Exp.
              </Text>
              <Text style={[styles.itemtableColHeader, styles?.width2]}>
                UOM
              </Text>
              <Text style={[styles.itemtableColHeader, styles?.width1]}>
                Qty.
              </Text>
              <Text style={[styles.itemtableColHeader, styles?.width2]}>
                MRP.
              </Text>
              <Text style={[styles.itemtableColHeader, styles?.width2]}>
                Taxable Amt.
              </Text>
              <Text style={[styles.itemtableColHeader, styles?.width2]}>
                GST (%)
              </Text>
              <Text style={[styles.itemtableColHeader, styles?.width2]}>
                SGST (Amt.)
              </Text>
              <Text style={[styles.itemtableColHeader, styles?.width2]}>
                CGST (Amt.)
              </Text>
              <Text style={[styles.itemtableColHeader, styles?.width2]}>
                Disc (%)
              </Text>
              <Text
                style={[
                  styles.itemtableColHeader,
                  styles?.lastCol,
                  styles?.width2,
                ]}
              >
                Amt.
              </Text>
            </View>
            {data?.items.map((item, index) => (
              <View key={index} style={styles.itemtableRow}>
                <Text
                  style={[styles.itemtableCol, styles?.width1, styles.firstCol]}
                >
                  {index + 1}
                </Text>
                <Text style={[styles.itemtableCol, styles?.width1]}>Zota</Text>
                <Text style={[styles.itemtableCol, styles?.width2]}>
                  {item?.item_code}
                </Text>
                <Text style={[styles.itemtableCol, styles?.width4]}>
                  {item?.item_name}
                </Text>
                <Text style={[styles.itemtableCol, styles?.width3]}>
                  {item?.item_batch_number}
                </Text>
                <Text style={[styles.itemtableCol, styles?.width1]}>
                  {moment(item?.item_exp_date).format("MM/YY")}
                </Text>
                <Text style={[styles.itemtableCol, styles?.width2]}>
                  {item?.item_uom}
                </Text>
                <Text style={[styles.itemtableCol, styles?.width1]}>
                  {item?.item_quantity}
                </Text>
                <Text style={[styles.itemtableCol, styles?.width2]}>
                  {item?.item_unit_price}
                </Text>
                <Text style={[styles.itemtableCol, styles?.width2]}>
                  {item?.item_quantity * parseFloat(item?.item_unit_price) -
                    parseFloat(item?.item_discount_amount)}
                </Text>
                <Text style={[styles.itemtableCol, styles?.width2]}>
                  {item?.item_gst}
                </Text>
                <Text style={[styles.itemtableCol, styles?.width2]}>
                  {item?.item_sgst}
                </Text>
                <Text style={[styles.itemtableCol, styles?.width2]}>
                  {item?.item_cgst}
                </Text>
                <Text style={[styles.itemtableCol, styles?.width2]}>
                  {item?.item_discount_percentage}
                </Text>
                <Text
                  style={[styles.itemtableCol, styles?.width2, styles?.lastCol]}
                >
                  {item?.item_total_amount}
                </Text>
              </View>
            ))}

            <View style={styles.itemtableRow}>
              <Text
                style={[styles.itemtableCol, styles?.width1, styles.firstCol]}
              >
                {" "}
              </Text>
              <Text style={[styles.itemtableCol, styles?.width1]}>Zota</Text>
              <Text style={[styles.itemtableCol, styles?.width2]}> </Text>
              <Text style={[styles.itemtableCol, styles?.width4]}> </Text>
              <Text style={[styles.itemtableCol, styles?.width3]}> </Text>
              <Text style={[styles.itemtableCol, styles?.width1]}> </Text>
              <Text style={[styles.itemtableCol, styles?.width2]}> </Text>
              <Text style={[styles.itemtableCol, styles?.width1]}> </Text>
              <Text style={[styles.itemtableCol, styles?.width2]}>Total:</Text>
              <Text style={[styles.itemtableCol, styles?.width2]}>
                {data?.items?.reduce(
                  (acc, item) =>
                    acc +
                    (item?.item_quantity * parseFloat(item?.item_unit_price) -
                      parseFloat(item?.item_discount_amount)),
                  0
                )}
              </Text>
              <Text style={[styles.itemtableCol, styles?.width2]}> </Text>
              <Text style={[styles.itemtableCol, styles?.width2]}>
                {data?.items
                  ?.reduce((acc, item) => acc + parseFloat(item?.item_sgst), 0)
                  .toFixed(2)}
              </Text>
              <Text style={[styles.itemtableCol, styles?.width2]}>
                {data?.items
                  ?.reduce((acc, item) => acc + parseFloat(item?.item_cgst), 0)
                  .toFixed(2)}
              </Text>
              <Text style={[styles.itemtableCol, styles?.width2]}> </Text>
              <Text
                style={[styles.itemtableCol, styles?.width2, styles?.lastCol]}
              >
                {data?.items
                  ?.reduce(
                    (acc, item) => acc + parseFloat(item?.item_total_amount),
                    0
                  )
                  .toFixed(2)}
              </Text>
            </View>
          </View>

          <View
            style={{
              display: "flex",
              flexDirection: "row",
              fontSize: "10",
              fontWeight: "bold",
              // fontFamily: "Bitter",
              borderBottom: "2 solid black",
            }}
          >
            <View
              style={{
                ...styles?.text,
                width: "40%",
                borderRight: "2 solid black",
              }}
            >
              <View
                style={{
                  marginBottom: 30,
                }}
              >
                <Text
                  style={{
                    padding: 3,
                  }}
                >
                  Please consult your doctor before using medicine
                </Text>
                <Text
                  style={{
                    marginTop: 3,
                    padding: 3,
                  }}
                >
                  E & O E
                </Text>
              </View>
              <Text
                style={{
                  borderTop: "2 solid black",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-end",
                  padding: 5,
                }}
              >
                For : {storeDetails?.store_name}
              </Text>
            </View>
            <View
              style={{
                width: "60%",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <View
                style={{
                  width: "60%",
                  borderRight: "2 solid black",
                }}
              >
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <Text
                    style={{
                      ...styles.heading,
                      textAlign: "right",
                      width: "40%",
                      borderRight: "2 solid black",
                      paddingHorizontal: 5,
                      paddingVertical: 2,
                    }}
                  >
                    Disc Amt :
                  </Text>
                  <Text
                    style={{
                      ...styles.text,
                      textAlign: "right",
                      width: "60%",
                      paddingHorizontal: 5,
                      paddingVertical: 2,
                    }}
                  >
                    {data?.orderData?.[0]?.sot_total_discount}
                  </Text>
                </View>
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    borderTop: "2 solid black",
                  }}
                >
                  <Text
                    style={{
                      ...styles.heading,
                      textAlign: "right",
                      width: "40%",
                      borderRight: "2 solid black",
                      paddingHorizontal: 5,
                      paddingVertical: 2,
                    }}
                  >
                    Other Charges :
                  </Text>
                  <Text
                    style={{
                      ...styles.text,
                      textAlign: "right",
                      width: "60%",
                      paddingHorizontal: 5,
                      paddingVertical: 2,
                    }}
                  >
                    0.00
                  </Text>
                </View>

                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    borderTop: "2 solid black",
                  }}
                >
                  <Text
                    style={{
                      ...styles.heading,
                      paddingHorizontal: 5,
                      paddingVertical: 2,
                    }}
                  >
                    {" "}
                  </Text>
                </View>
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    borderTop: "2 solid black",
                  }}
                >
                  <Text
                    style={{
                      ...styles.heading,
                      paddingHorizontal: 5,
                      paddingVertical: 2,
                    }}
                  >
                    {" "}
                  </Text>
                </View>
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    borderTop: "2 solid black",
                  }}
                >
                  <Text
                    style={{
                      ...styles.heading,
                      paddingHorizontal: 5,
                      paddingVertical: 5,
                    }}
                  >
                    Rs.{" "}
                    {numberToWords
                      .toWords(parseInt(data?.orderData?.[0]?.sot_total_amount))
                      .charAt(0)
                      .toUpperCase() +
                      numberToWords
                        .toWords(
                          parseInt(data?.orderData?.[0]?.sot_total_amount)
                        )
                        .slice(1)}{" "}
                    only
                  </Text>
                </View>
              </View>

              <View
                style={{
                  width: "40%",
                }}
              >
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <Text
                    style={{
                      ...styles.heading,
                      textAlign: "right",
                      width: "40%",
                      borderRight: "2 solid black",
                      paddingHorizontal: 5,
                      paddingVertical: 2,
                    }}
                  >
                    Gross :
                  </Text>
                  <Text
                    style={{
                      ...styles.text,
                      textAlign: "right",
                      width: "60%",
                      paddingHorizontal: 5,
                      paddingVertical: 2,
                    }}
                  >
                    {data?.items
                      ?.reduce(
                        (acc, item) =>
                          acc +
                          (item?.item_quantity *
                            parseFloat(item?.item_unit_price) -
                            parseFloat(item?.item_discount_amount)),
                        0
                      )
                      .toFixed(2)}
                  </Text>
                </View>
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    borderTop: "2 solid black",
                  }}
                >
                  <Text
                    style={{
                      ...styles.heading,
                      textAlign: "right",
                      width: "40%",
                      borderRight: "2 solid black",
                      paddingHorizontal: 5,
                      paddingVertical: 2,
                    }}
                  >
                    Total Tax :
                  </Text>
                  <Text
                    style={{
                      ...styles.text,
                      textAlign: "right",
                      width: "60%",
                      paddingHorizontal: 5,
                      paddingVertical: 2,
                    }}
                  >
                    {(
                      data?.items?.reduce(
                        (acc, item) => acc + parseFloat(item?.item_sgst),
                        0
                      ) +
                      data?.items?.reduce(
                        (acc, item) => acc + parseFloat(item?.item_cgst),
                        0
                      )
                    ).toFixed(2)}
                  </Text>
                </View>
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    borderTop: "2 solid black",
                  }}
                >
                  <Text
                    style={{
                      ...styles.heading,
                      textAlign: "right",
                      width: "40%",
                      borderRight: "2 solid black",
                      paddingHorizontal: 5,
                      paddingVertical: 2,
                    }}
                  >
                    Disc :
                  </Text>
                  <Text
                    style={{
                      ...styles.text,
                      textAlign: "right",
                      width: "60%",
                      paddingHorizontal: 5,
                      paddingVertical: 2,
                    }}
                  >
                    {data?.orderData?.[0]?.sot_total_discount}
                  </Text>
                </View>
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    borderTop: "2 solid black",
                  }}
                >
                  <Text
                    style={{
                      ...styles.heading,
                      textAlign: "right",
                      width: "40%",
                      borderRight: "2 solid black",
                      paddingHorizontal: 5,
                      paddingVertical: 2,
                    }}
                  >
                    Round Off :
                  </Text>
                  <Text
                    style={{
                      ...styles.text,
                      textAlign: "right",
                      width: "60%",
                      paddingHorizontal: 5,
                      paddingVertical: 2,
                    }}
                  >
                    0.00
                  </Text>
                </View>
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    borderTop: "2 solid black",
                  }}
                >
                  <Text
                    style={{
                      ...styles.heading,
                      textAlign: "right",
                      width: "40%",
                      borderRight: "2 solid black",
                      paddingHorizontal: 5,
                      paddingVertical: 5,
                    }}
                  >
                    Net :
                  </Text>
                  <Text
                    style={{
                      ...styles.text,
                      textAlign: "right",
                      width: "60%",
                      paddingHorizontal: 5,
                      paddingVertical: 5,
                    }}
                  >
                    {parseFloat(data?.orderData?.[0]?.sot_total_amount).toFixed(
                      2
                    )}
                  </Text>
                </View>
              </View>
            </View>
          </View>

          <View
            style={{
              borderBottom: "2 solid black",
            }}
          >
            <Text style={{ ...styles.heading, textDecoration: "underline" }}>
              Terms & Conditions :
            </Text>
            {storeDetails?.store_terms_condition?.map((item, index) => (
              <Text key={index} style={{ ...styles.text, padding: 2 }}>
                -{item}
              </Text>
            ))}
            <Text style={{ ...styles.text, padding: 3 }}>Return Policy :</Text>
            {storeDetails?.return_policy?.map((item, index) => (
              <Text key={index} style={{ ...styles.text, padding: 2 }}>
                -{item}
              </Text>
            ))}
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default InvoicePdfContent;
