import React, { useEffect, useState } from "react";
import InnerLayout from "../../components/layouts/inner-layout";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import LoadingSpinner from "../../components/helper-components/loading-spinner";
import CustomerViewEdit from "../../components/customer-information/customer-view-edit";
import { customerViewAction } from "../../redux/actions/customer-action";
const ViewCustomer = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const navigate = useNavigate();
  const [customer, setCustomer] = useState({});
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    dispatch(customerViewAction(id, onSuccess, onError));
  }, []);
  const onSuccess = (data) => {
    setLoading(false);
    setCustomer(data?.data?.[0]);
  };
  const onError = (data) => {
    loading(false);
  };
  return (
    <>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <InnerLayout
          page="view"
          title="View Customer"
          title1={customer?.cmr_code}
          view={true}
          backBtn={true}
          onclkEditFn={() => navigate(`/edit-customer/${id}`)}
        >
          <CustomerViewEdit page="view" customer={customer} disabled={true} />
        </InnerLayout>
      )}
    </>
  );
};

export default ViewCustomer;
