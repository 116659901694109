import React from "react";
import InputField from "../../forms/input-field";

const StoreGstInfoTab = ({ disabled = false }) => {
  return (
    <div className="w-full 2xl:w-[35%] lg:w-[60%] px-5 xl:px-10">
      <InputField
        isDisabled={disabled}
        isRequired={true}
        type="text"
        name="gst_number"
        placeholder="xxxxxxxxxxxxxxx"
        label="GST Number (if applicable)"
        labelWidth="12.5rem"
      />
    </div>
  );
};

export default StoreGstInfoTab;

