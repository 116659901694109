import React, { useEffect, useState } from "react";
import InnerLayout from "../../components/layouts/inner-layout";
import * as Yup from "yup";
import {
  journalEntryUpdateAction,
  journalEntryViewAction,
} from "../../redux/actions/financial-action";
import { Form, Formik } from "formik";
import { useDispatch } from "react-redux";
import LoadingSpinner from "../../components/helper-components/loading-spinner";

import InputField from "../../components/forms/input-field";
import { useParams } from "react-router-dom";
import { formatString } from "../../components/helper-components/helper-function";
import moment from "moment";

const JournalEntryView = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [journalEntryData, setJournalEntryData] = useState({});
  const [journalAccountData, setJournalAccountData] = useState({});

  useEffect(() => {
    dispatch(journalEntryViewAction(id, onSuccess, onError));
  }, []);

  const onSuccess = (data) => {
    setLoading(false);
    setJournalEntryData(data?.data?.[0]?.journalEntryData);
    setJournalAccountData(data?.data?.[0]?.accountsData);
  };
  const onError = (data) => {
    setLoading(false);
  };

  const initialValues = {
    journal_entry_no: journalEntryData?.journal_entry_no ?? "",
    batch_num: journalEntryData?.batch_num ?? "",
    remarks: journalEntryData?.remarks ?? "",
    origin_type: journalEntryData?.origin_type ?? "",
    origin_id: journalEntryData?.origin_id ?? "",
    transcation_id: journalEntryData?.transcation_id ?? "",
    update_date:
      moment(journalEntryData?.update_date).format("YYYY-MM-DD") ?? "",
    due_date: journalEntryData?.due_date
      ? moment(journalEntryData?.due_date).format("YYYY-MM-DD")
      : null,
    document_date: journalEntryData?.document_date
      ? moment(journalEntryData?.document_date).format("YYYY-MM-DD")
      : null,
  };
  const validationSchema = Yup.object().shape({});

  const onSubmit = (values) => {
    dispatch(journalEntryUpdateAction((data) => {}, onSubmitError));
  };

  const onSubmitError = (data) => {};

  return (
    <InnerLayout backBtn={true} title="Journal Entry View">
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ values, setFieldValue }) => {
          return (
            <Form className="h-full pt-5">
              <div className="flex flex-col justify-between h-full ">
                {loading ? (
                  <LoadingSpinner />
                ) : (
                  <div>
                    <div className="flex gap-10 px-10 flex-wrap">
                      <div className="flex flex-col gap-5 2xl:w-[31%] lg:w-[45%] w-full">
                        <InputField
                          placeholder=""
                          errMsgContainerClass="hidden"
                          type="text"
                          name="journal_entry_no"
                          label="Series "
                          labelWidth="6rem"
                        />
                        <InputField
                          containerClass="opacity-70"
                          labelClass="whitespace-nowrap flex fs_14 fw_400  text-secondary"
                          isDisabled={true}
                          placeholder="12345"
                          errMsgContainerClass="hidden"
                          type="text"
                          name="batch_num"
                          label="Number "
                          labelWidth="6rem"
                        />

                        <InputField
                          placeholder="your remarks here..."
                          errMsgContainerClass="hidden"
                          type="text"
                          name="remarks"
                          label="Remarks "
                          labelWidth="6rem"
                        />
                      </div>
                      <div className="flex flex-col gap-5 2xl:w-[31%] lg:w-[45%] w-full">
                        <InputField
                          placeholder=""
                          errMsgContainerClass="hidden"
                          type="text"
                          name="origin_type"
                          label="Origin "
                          labelWidth="6rem"
                        />
                        <InputField
                          containerClass="opacity-70"
                          labelClass="whitespace-nowrap flex fs_14 fw_400  text-secondary"
                          isDisabled={true}
                          placeholder="12345"
                          errMsgContainerClass="hidden"
                          type="text"
                          name="origin_id"
                          label="Origin No."
                          labelWidth="6rem"
                        />

                        <InputField
                          placeholder=""
                          errMsgContainerClass="hidden"
                          type="text"
                          name="transcation_id"
                          label="Trans. No."
                          labelWidth="6rem"
                        />
                      </div>
                      <div className="flex flex-col gap-5 2xl:w-[31%] lg:w-[45%] w-full">
                        <InputField
                          placeholder=""
                          errMsgContainerClass="hidden"
                          type="date"
                          name="update_date"
                          label="Posting Date "
                          labelWidth="6rem"
                        />
                        <InputField
                          containerClass="opacity-70"
                          labelClass="whitespace-nowrap flex fs_14 fw_400  text-secondary"
                          isDisabled={true}
                          placeholder="12345"
                          errMsgContainerClass="hidden"
                          type="date"
                          name="due_date"
                          label="Due. Date "
                          labelWidth="6rem"
                        />

                        <InputField
                          placeholder=""
                          errMsgContainerClass="hidden"
                          type="date"
                          name="document_date"
                          label="Doc. Date "
                          labelWidth="6rem"
                        />
                      </div>
                    </div>

                    <div className="overflow-x-auto overflow-y-hidden mt-10">
                      <table className="price-my-tab h-full">
                        <thead>
                          <tr>
                            {tableHeading.map((heading, index) => (
                              <th
                                key={index}
                                className={`${"price-tab-header"} ${
                                  heading?.label === "Action" &&
                                  "flex justify-end pe-10"
                                } whitespace-nowrap fs_12 fw_500 text-475467 bg-F2F4F5`}
                              >
                                {heading?.label}
                              </th>
                            ))}
                          </tr>
                        </thead>
                        <tbody className="h-full">
                          {journalAccountData?.length > 0 ? (
                            <>
                              {journalAccountData?.map((acc, index) => (
                                <tr>
                                  <td
                                    className={`price-tab-cell  fs_14 fw_400 text-secondary whitespace-nowrap`}
                                  >
                                    {acc?.account_code ?? "--"}
                                  </td>
                                  <td
                                    className={`price-tab-cell   fs_14 fw_400 text-secondary whitespace-nowrap`}
                                  >
                                    {formatString(
                                      acc?.account_name === null
                                        ? "--"
                                        : acc?.account_name
                                    ) ?? "--"}
                                  </td>
                                  <td
                                    className={`price-tab-cell   fs_14 fw_400 text-secondary whitespace-nowrap`}
                                  >
                                    {acc?.debit_amount ?? "--"}
                                  </td>
                                  <td
                                    className={`price-tab-cell   fs_14 fw_400 text-secondary whitespace-nowrap`}
                                  >
                                    {acc?.credit_amount ?? "--"}
                                  </td>
                                  <td
                                    className={`price-tab-cell   fs_14 fw_400 text-secondary whitespace-nowrap`}
                                  >
                                    {acc?.remarks ?? "--"}
                                  </td>
                                </tr>
                              ))}
                            </>
                          ) : (
                            <tr
                              style={{
                                height: "20rem",
                              }}
                            >
                              <td
                                colSpan={tableHeading.length + 1}
                                className="text-center text-primary"
                              >
                                No Items found
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                )}

                {/* <div
                  style={{
                    bottom: "0",
                  }}
                  className="flex justify-between px-10 mb-5x sticky bg-white py-4 invoice-bottom-container"
                >
                  <DraftButton type="reset" text="Cancel" />

                  <AddButton prefixIcon={false} text="Save" type="submit" />
                </div> */}
              </div>
            </Form>
          );
        }}
      </Formik>
    </InnerLayout>
  );
};

export default JournalEntryView;

const tableHeading = [
  {
    label: "G/L ACCOUNT CODE",
  },
  {
    label: "G/L ACCOUNT NAME ",
  },
  {
    label: "DEBIT ",
  },
  {
    label: "CREDIT ",
  },
  {
    label: "REMARKS",
  },
];
