import { Field, useFormikContext } from "formik";
import React, { useEffect, useRef } from "react";
import "./sales.css";
import AddButton from "../buttons/button";
import CheckBox from "../forms/check-box-field";

const PaymentInOutFinalBillTable = ({
  section = "pit",
  disabled = false,
  checkedId,
  setPaymentMeans,
}) => {
  const { values, setFieldValue } = useFormikContext();
  const isInitialRender = useRef(true);
  const prefix = section === "pit" ? "soit" : "poit";
  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false;
      return;
    }
    setFieldValue(`${section}_total_amount`);
    setFieldValue(
      `${section}_current_payment`,
      values?.invoices
        .filter((item) => checkedId?.includes(item?.id))
        .reduce((acc, curr) => {
          return acc + parseFloat(curr?.[`${prefix}_total_payment`]);
        }, 0)
        ?.toFixed(2)
    );
  }, [checkedId]);

  return (
    <>
      <div
        style={{
          height: `calc(100% - 3.5rem)`,
        }}
        className="table-container flex"
      >
        <div className="flex w-full">
          <div className="w-1/3 border-ebeaf2 border  p-5">
            <CheckBox
              conClass="flex flex-col gap-3"
              isDisabled={disabled}
              items={items}
            />
            <Field name={`${section}_add_to_account_amount`}>
              {({ field, form }) => (
                <input
                  disabled={!values?.is_payment_on_account}
                  {...field}
                  value={Math.abs(
                    balanceAmt(values, checkedId, prefix, section)?.toFixed(2)
                  )}
                  className="  px-2 py-2 mt-3 rounded-md border w-full focus:placeholder-transparent focus:outline-none border-D0D5DD input-field-no-spin-buttons max-w-[15rem]"
                  type="number"
                  onBlur={(e) => {
                    const inputValue = e.target.value;
                    if (
                      !inputValue.includes(".") &&
                      !isNaN(parseInt(inputValue))
                    ) {
                      form.setFieldValue(
                        field.name,
                        parseFloat(inputValue).toFixed(2)
                      );
                    }
                  }}
                />
              )}
            </Field>
          </div>
          <div className="w-1/3 border-ebeaf2 border  p-5 flex flex-col">
            <label className="text-secondary fs_14 fw_400">Open Balance</label>
            <input
              disabled={true}
              className=" px-2 py-2 border mt-3 border-D0D5DD rounded-md max-w-[15rem]"
              type="text"
              value={
                parseFloat(values?.[`${section}_open_balance`]).toFixed(2) ?? 0
              }
            />
          </div>
          <div className="w-1/3 border-ebeaf2 border  p-5">
            <label className="text-secondary fs_14 fw_400">Payment Means</label>
            <button
              type="button"
              onClick={() => {
                setPaymentMeans(true);
              }}
              style={{
                maxWidth: "12rem",
              }}
              className="border border-primary mt-3 fs_14 fw_500 border-solid cursor-pointer rounded-md text-primary flex whitespace-nowrap gap-3 items-center py-2 justify-center px-[45px]"
            >
              {disabled ? "View Payment Details" : "Add Payment Details"}
            </button>
          </div>
        </div>
        <div className="max-w-[30rem] min-w-[25rem]  bg-F2F4F5">
          <div className="pt-1 pb-1 px-4 flex justify-between items-center">
            <span>Total Due Balance (₹)</span>
            <span>
              <input
                disabled={true}
                className="text-end w-[8rem] px-2 py-1 border border-D0D5DD rounded-md"
                type="text"
                value={calculateTotalDueAmt(values, checkedId, prefix)}
              />
            </span>
          </div>

          <div className="pt-1 pb-1 px-4 flex justify-between items-center">
            <span>Balance Payment (₹)</span>
            <span>
              <input
                disabled={true}
                className="text-end w-[8rem] px-2 py-1 border border-D0D5DD rounded-md"
                type="text"
                value={
                  values?.paymentModeData.some(
                    (item) => item?.pmt_total_amount > 0
                  )
                    ? balanceAmt(values, checkedId, prefix, section)?.toFixed(2)
                    : amtpaid(values, checkedId, prefix, section)?.toFixed(2)
                }
              />
            </span>
          </div>
          <div className="pt-1 pb-1 px-4 flex justify-between items-center">
            <span>Current Payment (₹)</span>
            <span>
              <input
                disabled={true}
                className="text-end w-[8rem] px-2 py-1 border border-D0D5DD rounded-md"
                type="text"
                value={amtpaid(values, checkedId, prefix, section)?.toFixed(2)}
              />
            </span>
          </div>
        </div>
      </div>
      <div className="flex justify-between h-[3.5rem] invoice-bottom-container w-full px-4 items-center">
        <div className="flex gap-3 fw_600"></div>
        {!disabled && (
          <div className="flex gap-3">
            <AddButton
              prefixIcon={false}
              text="Save"
              type="submit"
              onclkFn={() => setFieldValue("submittedButton", "save")}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default PaymentInOutFinalBillTable;

const items = [{ label: "Payment On Account", name: "is_payment_on_account" }];
export const calculateTotalDueAmt = (values, checkedId, prefix) => {
  return values?.invoices
    .filter((item) => checkedId?.includes(item?.id))
    .reduce((acc, curr) => {
      return acc + parseFloat(curr?.[`${prefix}_due_amount`]);
    }, 0)
    ?.toFixed(2);
};

export const currentAmt = (values, checkedId, prefix, section) => {
  if (parseFloat(values?.[`${section}_open_balance`]) > 0) {
    return (
      parseFloat(amtpaid(values, checkedId, prefix, section)) +
      parseFloat(values?.[`${section}_open_balance`])
    );
  } else {
    return parseFloat(amtpaid(values, checkedId, prefix, section));
  }
};

export const amtpaid = (values, checkedId, prefix, section) => {
  if (
    values?.paymentModeData.reduce(
      (acc, item) => acc + parseFloat(item?.pmt_total_amount),
      0
    ) > 0
  ) {
    return values?.paymentModeData.reduce(
      (acc, item) => acc + parseFloat(item?.pmt_total_amount),
      0
    );
  } else {
    return (
      values?.invoices
        .filter((item) => checkedId?.includes(item?.id))
        .reduce((acc, curr) => {
          return acc + parseFloat(curr?.[`${prefix}_total_payment`]);
        }, 0) - parseFloat(values?.[`${section}_open_balance`])
    );
  }
};

export const balanceAmt = (values, checkedId, prefix, section) => {
  return (
    parseFloat(calculateTotalDueAmt(values, checkedId, prefix)) -
    parseFloat(currentAmt(values, checkedId, prefix, section))
  );
};
