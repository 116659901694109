import React, { useEffect, useState } from 'react';
import PreviewCard from '../template-item-container';
import { useDispatch } from 'react-redux';
import { printPreferenceListAction } from '../../../../redux/actions/settings-action';
import errorToast from '../../../helper-components/error-toast';
import { formatString } from '../../../helper-components/helper-function';

const SalesInvoice = () => {
    const [data, setData] = useState([]);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(printPreferenceListAction({ type: "sales_invoice" }, onSuccess, onError));
    }, []);

    const onSuccess = (data) => {
        
        setData(data?.data)
    }

    const onError = (data) => {
        errorToast(formatString(data?.message))
    }

    return (
        <div className='mx-8'>
            <PreviewCard data={data} />
        </div>
    )
}

export default SalesInvoice
