import { api } from "./api";

export const storeLoginApi = (params) => {
  return api.post(`/user/login`, JSON.stringify(params));
};
export const forgotPasswordApi = (params) => {
  return api.post(`/user/forgot/password`, JSON.stringify(params));
};
export const resetPasswordApi = (params) => {
  return api.post(`/user/reset/password`, JSON.stringify(params));
};
