import { Form, Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { saveAs } from "file-saver";
import * as Yup from "yup";
import ArrowDownIcon from "../../assets/icons/arrow-down";
import {
  AlternativeIcon,
  CustomerIcon,
  PastOrderIcon,
  StoriesIcon,
} from "../../assets/icons/invoice-header-icons";
import AddButton, {
  DeleteButton,
  DraftButton,
} from "../../components/buttons/button";
import AsyncCreateSelect from "../../components/forms/async-create-select-field";
import CheckBox from "../../components/forms/check-box-field";
import errorToast from "../../components/helper-components/error-toast";
import successToast from "../../components/helper-components/success-toast";
import InvoiceBillingTable from "../../components/invoice-section/invoice-billing-table";
import {
  doctorListAction,
  paymentMethodListAction,
  phoneListAction,
  storeIdAction,
} from "../../redux/actions/general-action";
import {
  invoiceDeleteAction,
  invoiceEditAction,
  invoiceRowAction,
  invoiceRowClearAction,
  repeatOrderAction,
  storeDetailsAction,
} from "../../redux/actions/invoices-action";
import AlternativesPopover from "./alternatives-popover";
import InvoicePdfContent from "./invoice-pdf-content";
import { pdf } from "@react-pdf/renderer";
import BillingFinalTable, {
  calculateSubtotal,
  calculateTotal,
  calculateTotalPayment,
  validatePaymentmethod,
} from "./billing-final-table";
import UploadIcon from "../../assets/icons/upload-icon";
import CreateSelect from "../forms/create-select-field";
import { useHotkeys } from "react-hotkeys-hook";
import { handlePrint } from "./invoice-thermal-printer";

const ViewEditInvoice = ({ invoice, disabled }) => {
  const searchRef = useRef();
  const rowData = useSelector((state) => state.invoiceRowReducer);
  const [heightOfFirstDiv, setHeightOfFirstDiv] = useState(0);
  const [isbarcodeScanning, setIsbarcodeScanning] = useState(false);

  useEffect(() => {
    const updateHeight = () => {
      const firstDivHeight =
        document?.querySelector(".first-div")?.clientHeight;
      setHeightOfFirstDiv(firstDivHeight);
    };
    updateHeight();
    window.addEventListener("resize", updateHeight);
    return () => {
      window.removeEventListener("resize", updateHeight);
    };
  }, []);
  const initialData = {
    sl_no: 1,
    item_code: "",
    item_generic_name: "",
    item_name: "",
    total_sellable_quantity: "",
    quantity: 0,
    item_uom: "",
    item_batch_number: "",
    item_exp_date: "",
    item_mfg_date: "",
    item_rack_location: "",
    item_schedule: "",
    item_unit_price: 0,
    item_price_wiithout_tax: 0,
    item_discount_amount: 0.0,
    item_discount_percentage: 0,
    item_tax_amount: 0,
    item_total_amount: 0,
    item_id: "",
    instock: true,
  };
  const initialFormValues =
    rowData?.data?.length >= 1
      ? {
          ...rowData,
          data: [...rowData.data, initialData],
        }
      : {
          phone: {
            label: invoice?.orderData?.cmr_phone_number,
            value: invoice?.orderData?.cmr_phone_number,
          },
          cmr_first_name: invoice?.orderData?.cmr_first_name,
          cmr_last_name: invoice?.orderData?.cmr_last_name,
          cmr_acquired_source: invoice?.orderData?.cmr_acquired_source,
          gst_tax: "",
          RepeatLastOrder: false,
          remarks: invoice?.orderData?.sot_remarks,
          data: [
            ...invoice?.itemData?.map((item, index) => ({
              sl_no: index + 1,
              item_code: item?.item_code,
              item_generic_name: item?.item_generic_name,
              item_name: item?.item_name,
              total_sellable_quantity: item?.total_sellable_quantity ?? "",
              quantity: item?.item_quantity,
              item_uom: item?.item_uom,
              item_batch_number: item?.item_batch_number,
              item_exp_date: item?.item_exp_date,
              item_mfg_date: item?.item_mfg_date,
              item_rack_location: item?.item_rack_location,
              item_schedule: item?.item_schedule,
              item_unit_price: item?.item_unit_price,
              item_price_wiithout_tax: item?.item_price_wiithout_tax ?? 0,
              item_discount_amount: item?.item_discount_amount,
              item_discount_percentage: item?.item_discount_percentage,
              item_tax_amount: item?.item_tax_amount ?? 0,
              item_total_amount: item?.item_total_amount,
              item_id: item?.item_id,
              to_bin_id: item?.to_bin_id,
              instock: true,
              item_gst: item?.item_gst,
              item_cgst: item?.item_cgst,
              item_sgst: item?.item_sgst,
              itemBatchList: item?.itemBatchList,
            })),
            initialData,
          ],
          paymentData: invoice?.paymentData
            ?.filter((item) => parseFloat(item?.payment_amount) > 0)
            ?.map((item) => ({
              payment_mode_name: item?.payment_mode_name,
              // transaction: item?.transaction,
              payment_amount: parseFloat(item?.payment_amount),
            })),
          doctor: {
            label:
              invoice?.orderData?.doctor_name === null
                ? ""
                : invoice?.orderData?.doctor_name,
            value:
              invoice?.orderData?.doctor_name === null
                ? ""
                : invoice?.orderData?.doctor_name,
          },
          payment_method: invoice?.orderData?.sot_payment_method,
        };

  const [initialValue, setInitialValue] = useState(initialFormValues);
  const [reload, setReload] = useState(false);
  const [docList, setDocList] = useState([]);
  const [payModesList, setPayModesList] = useState([]);
  const [formValues, setFormValues] = useState({});
  const [localData, setLocalData] = useState([]);
  const [storeId, setStoreId] = useState("");
  const [storeDetails, setStoreDetails] = useState({});
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [invoiceType, setInvoiceType] = useState("");
  const validationSchema = Yup.object().shape({
    phone: Yup.object()
      .required("Phone number is required")
      .test(
        "is-ten-digits",
        "Please enter valid phone number",
        (value) => value && value.label && /^\d{10}$/.test(value.label)
      ),
    payment_method: Yup.string().required("select payment method"),
  });
  const invoiceDetails = [
    {
      image: <CustomerIcon />,
      title: "View Customer Details",
    },
    {
      image: <AlternativeIcon />,
      title: "Alternatives",
    },
    {
      image: <StoriesIcon />,
      title: "Other Stores",
    },
  ];

  useEffect(() => {
    dispatch(doctorListAction(onSuccessDoc, onErrorDoc));
    dispatch(storeIdAction(onSuccessStore, onErrorStore));
    dispatch(paymentMethodListAction(onSuccessPayList, onErrorPayList));
    dispatch(storeDetailsAction(onSuccessStoreDetails, onErrorStoreDetails));
  }, []);
  const [alternativeID, setAlternativeID] = useState({
    itemid: "",
    index: null,
  });
  const onSuccessStore = (data) => {
    setStoreId(data?.data?.store_id);
    setReload(!reload);
  };
  const onErrorStore = () => {};
  const onSuccessStoreDetails = (data) => {
    setStoreDetails(data?.data);
  };
  const onErrorStoreDetails = () => {};

  const onSuccessDoc = (data) => {
    setDocList(
      data?.data
        ?.filter((item) => item?.doctor_name !== null)
        .map((item) => {
          return { label: item?.doctor_name, value: item?.doctor_name };
        })
    );
  };
  const onErrorPayList = () => {};
  const onSuccessPayList = (data) => {
    setPayModesList(
      data?.data?.paymentModes.map((item) => {
        return { label: item?.paymentType, value: item?.paymentType };
      })
    );
  };
  const onErrorDoc = () => {};

  const loadOptions = (inputValue) => {
    return new Promise((resolve) => {
      const onSuccess = (data) => {
        const options = [
          ...data.data.map((item) => ({
            value: item.cmr_id,
            label: item?.cmr_phone_number,
          })),
          {
            value: "new_customer",
            label: <span className="text-primary">+ New Customer</span>,
          },
        ];
        setLocalData(data.data);
        resolve(options);
      };

      const onError = (error) => {
        resolve([
          {
            value: "new_customer",
            label: <span className="text-primary">+ New Customer</span>,
          },
        ]);
      };

      if (inputValue?.length > 0) {
        dispatch(
          phoneListAction(
            {
              phone: inputValue,
            },
            onSuccess,
            onError
          )
        );
      } else {
        resolve([]);
      }
    });
  };

  useEffect(() => {
    if (formValues?.RepeatLastOrder) {
      dispatch(
        repeatOrderAction(
          {
            phone: formValues?.phone?.label ? formValues?.phone?.label : "",
          },
          onSuccess,
          onError
        )
      );
    }
  }, [formValues?.RepeatLastOrder]);

  const onSuccess = (data) => {
    setInitialValue({
      ...formValues,
      RepeatLastOrder: true,
      data: [
        ...data?.data?.map((item, index) => ({
          sl_no: index + 1,
          item_code: item.item_code,
          item_generic_name: item?.item_generic_name,
          item_name: item?.item_name,
          total_sellable_quantity: "",
          quantity: 0,
          item_uom: item.item_uom,
          item_batch_number: "",
          item_exp_date: "",
          item_mfg_date: item.item_mfg_date,
          item_rack_location: item?.item_rack_location,
          item_schedule: item?.item_schedule,
          item_unit_price: item?.item_unit_price,
          item_price_wiithout_tax: 0,
          item_discount_amount: 0,
          item_discount_percentage: 0,
          item_tax_amount: 0,
          item_total_amount: 0,
          item_id: item?.item_id,
          item_gst: 0,
          item_sgst: 0,
          item_cgst: 0,
        })),
        initialData,
      ],
      doctor: "",
      payment_method: "",
    });
  };
  const onError = () => {};

  // const initPayment = (data, resetForm, amount) => {
  //   const options = {
  //     key: "rzp_test_0tpemkHKm5K1Bc",
  //     currency: data.currency,
  //     amount: data.amount,
  //     name: "ZOTA POS",
  //     handler: async function (response) {
  //       response.order_id = data.id;
  //       dispatch(
  //         invoiceCreateAction(
  //           {
  //             orderData: {
  //               store_id: storeId,
  //               cmr_phone_number: parseInt(formValues?.phone?.label),
  //               sot_total_discount: formValues?.data
  //                 ?.slice(0, -1)
  //                 .reduce(
  //                   (total, item) =>
  //                     total + parseFloat(item?.item_discount_amount),
  //                   0
  //                 ),
  //               sot_total_gst: formValues?.data
  //                 ?.slice(0, -1)
  //                 .reduce(
  //                   (total, item) => total + parseFloat(item?.item_tax_amount),
  //                   0
  //                 ),

  //               sot_payment_status: "paid",
  //               sot_transaction_id: response?.razorpay_payment_id,
  //               sot_payment_method: formValues?.payment_method,
  //               sot_billing_address: "",
  //               sot_total_amount: amount,
  //               doctor_name: formValues?.doctor?.value,
  //             },
  //             itemData: formValues?.data?.slice(0, -1).map((item, index) => ({
  //               item_code: item?.item_code,
  //               item_generic_name: item?.item_generic_name,
  //               item_name: item?.item_name,
  //               item_quantity: parseInt(item?.quantity),
  //               item_uom: item?.item_uom,
  //               item_batch_number: item?.item_batch_number,
  //               item_exp_date: item?.item_exp_date,
  //               item_rack_location: item?.item_rack_location,
  //               item_schedule: item?.item_schedule,
  //               item_unit_price: parseFloat(item?.item_unit_price),
  //               item_discount_amount: parseFloat(item?.item_discount_amount),
  //               item_discount_percentage: parseFloat(
  //                 item?.item_discount_percentage
  //               ),
  //               item_tax_amount: parseFloat(item?.item_tax_amount),
  //               item_total_amount:
  //                 parseFloat(item.item_unit_price) * parseInt(item.quantity) -
  //                 parseFloat(item.item_discount_amount) +
  //                 parseFloat(item.item_tax_amount),
  //               itemBatchList: item?.itemBatchList,
  //               item_id: item?.item_id,
  //               item_gst: parseFloat(item?.item_gst),
  //               item_sgst: parseFloat(item?.item_sgst),
  //               item_cgst: parseFloat(item?.item_cgst),
  //               sot_id: invoice?.orderData?.sot_id,
  //             })),
  //           },
  //           onSuccessOrder,
  //           onErrorOrder
  //         )
  //       );
  //       resetForm();
  //     },
  //     prefill: {
  //       name: "Rajat",
  //       email: "rajat@rajat.com",
  //       phone_number: "9899999999",
  //     },
  //   };
  //   const rzp1 = new window.Razorpay(options);
  //   rzp1.open();
  // };
  // const handlePayment = async (amount, resetForm) => {
  //   try {
  //     const orderUrl = `${url}/order/payment`;
  //     const response = await axios.post(orderUrl, {
  //       amount: amount,
  //     });
  //     initPayment(response?.data?.order, resetForm, amount);
  //   } catch (error) {}
  // };
  const handleSubmit = (values, actions) => {
    // const totalAmount = calculateTotal(values).toFixed(0);

    console.log(values, "dsfdsfdsfdsfdsf");
    if (
      values?.data
        ?.slice(0, -1)
        .some((item) => parseInt(item.quantity) === 0 || item.quantity === "")
    ) {
      errorToast("Quantity cannot be zero");
    } else {
      if (values?.data?.length > 1) {
        // if (
        //   payModesList?.find((item) => item?.value === values?.payment_method)
        //     ?.label === "Cash" ||
        //   values.submittedButton === "draft"
        // ) {
        if (
          calculateTotalPayment(values) !== calculateTotal(values) &&
          values.submittedButton !== "draft"
        ) {
          errorToast("Amount is not matching");
        }
        // else if (
        //   values.submittedButton !== "draft" &&
        //   validatePaymentData(values?.paymentData)
        // ) {
        //   errorToast("Please make payment");
        // }
        else if (
          values.submittedButton !== "draft" &&
          validatePaymentmethod(values?.paymentData)
        ) {
          errorToast("Please select payment");
        } else {
          if (values.submittedButton !== "draft") {
            setIsbarcodeScanning(true);
          }
          dispatch(
            invoiceEditAction(
              {
                orderData: {
                  sot_id: invoice?.orderData?.sot_id,
                  is_draft_order:
                    values.submittedButton === "draft" ? true : false,
                  store_id: storeId,
                  cmr_phone_number: parseInt(values?.phone?.label),
                  sot_total_gst: parseFloat(
                    formValues?.data
                      ?.slice(0, -1)
                      .reduce(
                        (total, item) =>
                          total + parseFloat(item?.item_tax_amount),
                        0
                      )
                  ),
                  sot_total_discount: values?.data
                    ?.slice(0, -1)
                    .reduce(
                      (total, item) =>
                        total + parseFloat(item?.item_discount_amount),
                      0
                    ),
                  sot_payment_status:
                    values.submittedButton === "draft" ? "pending" : "paid",
                  sot_transaction_id: "",
                  sot_remarks: values?.remarks,
                  sot_payment_method: values?.payment_method,
                  sot_billing_address: "",
                  sot_sub_total: parseFloat(calculateSubtotal(values?.data)),

                  sot_total_amount: parseFloat(calculateTotal(values)),
                  doctor_name: values?.doctor?.value,
                },
                paymentData: values?.paymentData
                  ?.filter((item) => parseFloat(item?.payment_amount) > 0)
                  ?.map((item) => ({
                    payment_mode_name: item?.payment_mode_name,
                    payment_amount: parseFloat(item?.payment_amount),
                  })),
                itemData: values?.data?.slice(0, -1).map((item, index) => ({
                  item_code: item?.item_code,
                  item_generic_name: item?.item_generic_name,
                  item_name: item?.item_name,
                  item_quantity: parseInt(item?.quantity),
                  item_uom: item?.item_uom,
                  item_batch_number: item?.item_batch_number,
                  item_exp_date: item?.item_exp_date,
                  item_rack_location: item?.item_rack_location,
                  item_schedule: item?.item_schedule,
                  item_unit_price: parseFloat(item?.item_unit_price),
                  item_discount_amount: parseFloat(item?.item_discount_amount),
                  item_discount_percentage: parseFloat(
                    item?.item_discount_percentage
                  ),
                  item_tax_amount: parseFloat(item?.item_tax_amount),
                  item_total_amount: parseFloat(
                    parseFloat(item.item_unit_price) * parseInt(item.quantity) -
                      parseFloat(item.item_discount_amount)
                  ),
                  item_id: item?.item_id,
                  sot_id: invoice?.orderData?.sot_id,
                  item_gst: parseFloat(item?.item_gst),
                  item_sgst: parseFloat(item?.item_sgst),
                  item_cgst: parseFloat(item?.item_cgst),
                })),
              },
              onSuccessOrder,
              onErrorOrder
            )
          );
        }

        // } else {
        //   handlePayment(totalAmount, actions?.resetForm);
        // }
      }
    }
  };
  const downloadPdf = async (data) => {
    const fileName = "test.pdf";
    const blob = await pdf(
      <InvoicePdfContent data={data} storeDetails={storeDetails} />
    ).toBlob();
    saveAs(blob, fileName);
  };
  const onSuccessOrder = (data) => {
    setIsbarcodeScanning(false);
    if (formValues.submittedButton !== "draft") {
      handlePrint(data?.data, storeDetails);
    }
    setInvoiceType("");
    successToast(data?.message);
    setInitialValue({
      phone: "",
      cmr_first_name: "",
      cmr_last_name: "",
      cmr_acquired_source: "",
      gst_tax: "",
      RepeatLastOrder: false,
      data: [initialData],
      doctor: "",
      payment_method: "",
    });
    navigate("/bills");
  };
  const onErrorOrder = (data) => {
    setIsbarcodeScanning(false);
    errorToast(data?.data?.message);
  };

  const handleVoidAction = (id) => {
    dispatch(
      invoiceDeleteAction(
        {
          sot_id: id,
        },
        onSuccessDel,
        onErrorDel
      )
    );
  };

  const onSuccessDel = () => {
    setReload(!reload);
    navigate("/bills");
  };
  const onErrorDel = () => {};
  useHotkeys("escape", () => {
    setInvoiceType("");
  });

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={initialValue}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ values, setFieldValue }) => {
          setFormValues(values);
          console.log(values, "fvdfgdfgfdgdg");
          return (
            <Form className="h-full">
              <div className="flex flex-col justify-between h-full ">
                <div className="first-div">
                  <>
                    <div className="bg-F9F9FB flex flex-col lg:flex-row justify-start lg:justify-between lg:items-center px-3 border-ECECEC border py-1 whitespace-wrap">
                      <div className="flex space-x-5 h-full items-center">
                        {invoiceDetails?.map((item, index) => (
                          <button
                            key={index}
                            type="button"
                            onClick={() =>
                              setInvoiceType(
                                item?.title === invoiceType ? "" : item?.title
                              )
                            }
                            id={item?.title.toLowerCase().split(" ").join("-")}
                            disabled={
                              (index === 0 && !values?.cmr_first_name) ||
                              (index === 1 && alternativeID?.itemid === "")
                            }
                            className={`flex items-center space-x-3 fs_14 text-secondary cursor-pointer h-full ${
                              index === 0 &&
                              !values?.cmr_first_name &&
                              ` cursor-not-allowed`
                            }
                                  
                                  ${
                                    index === 1 &&
                                    alternativeID?.itemid == "" &&
                                    `cursor-not-allowed`
                                  }
                                  
                                  `}
                          >
                            {item?.image}{" "}
                            <span className="select-none">{item?.title}</span>{" "}
                            <ArrowDownIcon
                              invoiceType={item?.title === invoiceType}
                            />
                            {index < invoiceDetails.length - 1 && (
                              <span className="ml-2 h-6 bg-ECECEC w-0.5 inline-block" />
                            )}
                          </button>
                        ))}
                      </div>
                      {values?.phone?.label && !disabled && (
                        <div className="flex items-center mt-3 lg:mt-0">
                          <CheckBox items={items} />
                          <div
                            onClick={() => {
                              navigate(`/past-orders/${values?.phone?.label}`);
                              dispatch(invoiceRowClearAction());
                              setFieldValue("RepeatLastOrder", false);
                              const updatedData = values?.data.slice(0, -1);
                              dispatch(
                                invoiceRowAction({
                                  ...values,
                                  data: updatedData,
                                })
                              );
                            }}
                            id="view-past-orders"
                            className="border-ECECEC border px-3 py-1 flex items-center space-x-2 bg-white rounded-md cursor-pointer"
                          >
                            <PastOrderIcon />
                            <span className="fs_14 text-secondary whitespace-no-wrap">
                              View past orders
                            </span>
                          </div>
                        </div>
                      )}
                    </div>
                    {invoiceType === "View Customer Details" && (
                      <div className="border-ECECEC border flex gap-5 py-4 flex-wrap px-5">
                        {values?.cmr_first_name && (
                          <div className="fw_400 fs_14">
                            Name:{" "}
                            <span className="fw_500 fs_14">
                              {values?.cmr_first_name +
                                " " +
                                values?.cmr_last_name}
                            </span>
                          </div>
                        )}
                        {values?.phone?.label && (
                          <div className="fw_400 fs_14">
                            Mobile:{" "}
                            <span className="fw_500 fs_14">
                              {values?.phone?.label}
                            </span>
                          </div>
                        )}
                        {values?.cmr_acquired_source && (
                          <div className="fw_400 fs_14">
                            Total Rewards:{" "}
                            <span className="fw_500 fs_14">
                              {values?.cmr_acquired_source}
                            </span>
                          </div>
                        )}
                      </div>
                    )}
                    {invoiceType === "Alternatives" && (
                      <AlternativesPopover
                        alternativeID={alternativeID}
                        setInvoiceType={setInvoiceType}
                      />
                    )}
                  </>
                  <div className="mt-5 px-5 flex justify-between md:gap-0 gap-5">
                    <div
                      style={{
                        maxWidth: "30rem",
                      }}
                      className="w-full"
                    >
                      <AsyncCreateSelect
                        maxlength={10}
                        isValidNewOption={true}
                        disabled={true}
                        name={"phone"}
                        marginRight="26px"
                        placeholder="Enter customer Phone"
                        onCreateOption={() => {}}
                        loadOptions={loadOptions}
                        onChange={(selectedOption) => {
                          if (selectedOption === "new_customer") {
                            navigate("/add-customer");
                          } else {
                            setFieldValue("phone", selectedOption);
                            const customerData = localData?.find(
                              (item) => item?.cmr_id === selectedOption?.value
                            );

                            setFieldValue(
                              "cmr_first_name",
                              customerData?.cmr_first_name
                            );
                            setFieldValue(
                              "cmr_last_name",
                              customerData?.cmr_last_name
                            );
                            setFieldValue(
                              "cmr_acquired_source",
                              customerData?.cmr_acquired_source
                            );
                          }
                        }}
                      />
                    </div>
                    {!disabled && (
                      <button
                        type="button"
                        onClick={() => {
                          setFieldValue("data", [initialData]);
                        }}
                        className="text-primary underline fs_14 fw_400 whitespace-nowrap mb-5"
                        tabIndex="-1"
                      >
                        Clear all
                      </button>
                    )}
                  </div>
                </div>
                {heightOfFirstDiv && (
                  <div
                    style={{
                      maxHeight: `calc(100% - ${heightOfFirstDiv}px)`,
                      height: `calc(100% - ${heightOfFirstDiv}px)`,
                    }}
                    className="flex flex-col "
                  >
                    <InvoiceBillingTable
                      searchRef={searchRef}
                      disabled={disabled}
                      initialData={initialData}
                      setAlternativeID={setAlternativeID}
                    />
                    <div className=" mt-auto">
                      <BillingFinalTable
                        disabled={disabled}
                        payModesList={payModesList}
                      />
                      {/* {!disabled && (
                        <div className="flex justify-between invoice-bottom-container py-4 px-4 items-center">
                          <div className="flex gap-3 fw_600">
                            <span>TOTAL: </span>{" "}
                            <span>
                              ₹
                              {values.data
                                ?.reduce(
                                  (total, item) =>
                                    total +
                                    parseFloat(item.item_total_amount || 0),
                                  0
                                )
                                .toFixed(2)}
                            </span>
                          </div>
                          <div className="flex gap-3">
                            <DraftButton
                              type="submit"
                              onclkFn={() =>
                                setFieldValue("submittedButton", "draft")
                              }
                            />
                            <DeleteButton
                              onclkFn={() =>
                                handleVoidAction(invoice?.orderData?.sot_id)
                              }
                              prefixIcon={false}
                              type="button"
                            />
                            <AddButton
                              onclkFn={() =>
                                setFieldValue("submittedButton", "save")
                              }
                              prefixIcon={false}
                              text="Save and Print"
                              type="submit"
                            />
                          </div>
                        </div>
                      )} */}

                      <div className="flex justify-between w-full invoice-bottom-container py-4 px-4 items-center">
                        {/* NEEDED */}

                        {/* {!disabled ? (
                          <>
                            {" "}
                            <div
                              style={{
                                width: "calc((100% - 20rem)/2)",
                              }}
                              className="flex gap-3 fw_600  justify-between pe-4"
                            >
                              <div
                                style={{
                                  width: "18rem",
                                  minWidth: "18rem",
                                }}
                                className="doctorSelect"
                              >
                                <CreateSelect
                                  options={docList}
                                  name="doctor"
                                  placeholder="Select a doctor or add new"
                                />
                              </div>

                              <div
                                style={{
                                  maxWidth: "20rem",
                                }}
                                className="border border-primary fs_16 fw_500 border-solid cursor-pointer rounded-md text-primary flex whitespace-nowrap gap-3 items-center py-2 justify-center px-[45px]"
                              >
                                <UploadIcon />
                                Upload Prescription
                              </div>
                            </div>
                          </>
                        ) : (
                          <span></span>
                        )} */}
                        <div></div>

                        <div className="flex gap-3">
                          {!disabled && (
                            <>
                              <DraftButton
                                type="submit"
                                onclkFn={() =>
                                  setFieldValue("submittedButton", "draft")
                                }
                              />
                              <DeleteButton
                                onclkFn={() =>
                                  handleVoidAction(invoice?.orderData?.sot_id)
                                }
                                prefixIcon={false}
                                type="button"
                              />
                              <AddButton
                                isDisabled={isbarcodeScanning}
                                loading={isbarcodeScanning}
                                onclkFn={() =>
                                  setFieldValue("submittedButton", "save")
                                }
                                prefixIcon={false}
                                text="Save and Print"
                                type="submit"
                              />
                            </>
                          )}
                          {disabled && (
                            <>
                              {invoice?.orderData?.is_draft_order && (
                                <AddButton
                                  prefixIcon={false}
                                  text="Edit"
                                  type="button"
                                  onclkFn={() =>
                                    navigate(
                                      `/edit-invoice/${invoice?.orderData?.sot_id}`
                                    )
                                  }
                                />
                              )}
                              <AddButton
                                prefixIcon={false}
                                text="Print"
                                type="button"
                                onclkFn={() =>
                                  // downloadPdf({
                                  //   items: invoice?.itemData,
                                  //   orderData: [invoice?.orderData],
                                  // })
                                  handlePrint(
                                    {
                                      items: invoice?.itemData,
                                      orderData: [invoice?.orderData],
                                    },
                                    storeDetails
                                  )
                                }
                              />
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};
export default ViewEditInvoice;

const items = [
  { label: "Repeat last order", name: "RepeatLastOrder", isRequired: false },
];
