import React from "react";
import InputField from "../../forms/input-field";

const StorePayeeInfoTab = ({ disabled = false }) => {
  return (
    <div className="w-full 2xl:w-[35%] lg:w-[60%] px-5 xl:px-10">
      <InputField
        isDisabled={disabled}
        isRequired={true}
        type="text"
        name="payee_name_as_per_bank"
        placeholder=""
        label="Payee Name as per Bank"
        labelWidth="11.5rem"
      />
      <InputField
        isDisabled={disabled}
        isRequired={true}
        type="text"
        name="payee_bank_name"
        placeholder=""
        label="Payee Bank Name"
        labelWidth="11.5rem"
      />
      <InputField
        isDisabled={disabled}
        isRequired={true}
        type="text"
        name="payee_bank_account_no"
        placeholder=""
        label="Payee Bank Account No."
        labelWidth="11.5rem"
      />
      <InputField
        isDisabled={disabled}
        isRequired={true}
        type="text"
        name="isfc_code"
        placeholder=""
        label="ISFC Code"
        labelWidth="11.5rem"
      />
      <InputField
        isDisabled={disabled}
        isRequired={true}
        type="text"
        name="extra_payee"
        placeholder=""
        label="Extra Payee"
        labelWidth="11.5rem"
      />
    </div>
  );
};

export default StorePayeeInfoTab;
