import React from "react";
import InnerLayout from "../components/layouts/inner-layout";
import comingsoonImage from "../assets/images/construction.jpg";
import { useParams } from "react-router-dom";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

const UnderDevelopment = () => {
  const { page } = useParams();
  const data = [
    { name: "Jan", sales: 4000 },
    { name: "Feb", sales: 6000 },
    { name: "Mar", sales: 2000 },
    { name: "Apr", sales: 10000 }, // Random value for Apr
    { name: "May", sales: 1890 },
    { name: "Jun", sales: 2390 },
    { name: "Jul", sales: 3490 },
    { name: "Aug", sales: 90000 }, // Random value for Aug
    { name: "Sep", sales: 50000 }, // Random value for Sep
    { name: "Oct", sales: 35000 }, // Random value for Oct
    { name: "Nov", sales: Math.floor(Math.random() * 5000) }, // Random value for Nov
    { name: "Dec", sales: Math.floor(Math.random() * 5000) }  // Random value for Dec
  ];
  
  return (
    <>
      {page === "Dashboard" ? (
        <InnerLayout title={`Dashboard`}>
          <div className="p-8" style={{ width: "100%" }}>
            <p className="fw_600 fs_18 mb-5">Sales Report</p>
            <ResponsiveContainer width="80%" height={500}>
              <LineChart
                width={500}
                height={500}
                data={data}
                margin={{
                  top: 10,
                  right: 30,
                  left: 0,
                  bottom: 0,
                }}
              >
                <CartesianGrid strokeDasharray="10 10" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Line
                  type="monotone"
                  dataKey="sales"
                  stroke="#277DFE"
                  fill="#277DFE"
                />
              </LineChart>
            </ResponsiveContainer>
          </div>
        </InnerLayout>
      ) : (
        <InnerLayout
          title={`${
            page.charAt(0).toUpperCase() + page.slice(1)
          } Under Development`}
        >
          <div className="h-full flex items-center justify-center">
            <img  alt="comming soon" src={comingsoonImage} />
          </div>
        </InnerLayout>
      )}
    </>
  );
};

export default UnderDevelopment;
