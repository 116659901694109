import React, { useEffect, useRef, useState } from "react";
import KibabMenu from "../../../../../assets/icons/kibab-menu";
import { useDispatch } from "react-redux";
import { deleteTaxCombinationAction, taxCombinationListAction, } from "../../../../../redux/actions/settings-action";
import EditIcon from '../../../../../assets/icons/editIconPencil.svg'
import TaxCombination from "./tax-combination-view";
import DeleteIcon from "../../../../../assets/icons/delete-icon";
import successToast from "../../../../helper-components/success-toast";
import errorToast from "../../../../helper-components/error-toast";
import LoadingSpinner from "../../../../helper-components/loading-spinner";

const TaxCombinationTable = ({ searchValue }) => {
    const tableHeading = ["Code", "Rate %", "Combination", "Effective From", ""];
    const [selectedDOtIndex, setSelectedDOtIndex] = useState(null);
    const [initialData, setTaxCombinationData] = useState([]);
    const [showAddEdit, setShowAddEdit] = useState(false);
    const [isView, setIsView] = useState(false)
    const [editingData, setEditingData] = useState({});
    const [loading, setLoading] = useState(false)

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(taxCombinationListAction({}, onFetchSuccess, onFetchError));
        setLoading(true)
    }, []);

    useEffect(() => {
        dispatch(taxCombinationListAction({ searchValue }, onFetchSuccess, onFetchError));
        setLoading(true)
    }, [searchValue]);

    const reloadData = () => {
        dispatch(taxCombinationListAction({}, onFetchSuccess, onFetchError));
        setLoading(true)
    }

    const onFetchSuccess = (data) => {
        setTaxCombinationData(data?.data?.taxCombinationList);
        setLoading(false)
    }

    const onFetchError = (data) => {
        
        setLoading(false)
        errorToast(data?.message)
        setTaxCombinationData([])
    }

    const onCLickEdit = (data) => {
        setEditingData(data);
        setShowAddEdit(true);
        setSelectedDOtIndex(null);
    }

    const onClickDelete = (id) => {
        dispatch(deleteTaxCombinationAction({ id }, onDeletSuccess, onError))
    }
    const onDeletSuccess = (data) => {
        reloadData();
        successToast(data?.message);
        setSelectedDOtIndex(null)
    }

    const onError = (data) => {
        
        errorToast(data?.message)
    }

    const onCLickTr = (data) => {
        setEditingData(data);
        setShowAddEdit(true);
        setIsView(true);
    }
    const componentRef = useRef(null);

    const handleClickOutside = (event) => {
        if (componentRef.current && !componentRef.current.contains(event.target)) {
            setSelectedDOtIndex(null);
        }
    };

    const toogleChange = () => {
        setShowAddEdit(!showAddEdit)
    }

    return (
        <div>
            <div
                onClick={handleClickOutside}
                className="flex flex-col justify-between"
                style={{ height: "80vh" }}
            >
                {loading ? (
                    <LoadingSpinner />
                ) : (!showAddEdit ?
                    <>
                        <div className="overflow-auto">
                            <table className="mt-1 w-full">
                                <thead className="sticky top-0 z-10 bg-F9F9FB">
                                    <tr>
                                        {tableHeading.map((heading, index) => (
                                            <th
                                                className={`settings-table-header whitespace-nowrap fs_14 fw_500 text-secondary bg-F9F9FB text-start`}
                                                key={heading}
                                            >
                                                {heading}
                                            </th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody style={{ position: "relative" }}>
                                    {initialData?.map((item, index) => (
                                        <tr key={index}>
                                            <td
                                                className={`setting-table-cell fs_14 fw_400 text-secondary`}
                                                onClick={() => onCLickTr(item)}
                                            >
                                                {item?.code}
                                            </td>
                                            <td
                                                className={`setting-table-cell fs_14 fw_400 text-secondary`}
                                                onClick={() => onCLickTr(item)}
                                            >
                                                {item?.rate}
                                            </td>
                                            <td
                                                className={`setting-table-cell fs_14 fw_400 text-secondary`}
                                                onClick={() => onCLickTr(item)}
                                            >
                                                {item?.tax_combination_items?.map(taxComb => taxComb?.tax_type?.name).join(' + ')}
                                            </td>
                                            <td
                                                className={`setting-table-cell fs_14 fw_400 text-secondary`}
                                                onClick={() => onCLickTr(item)}
                                            >
                                                {item.effective_from}
                                            </td>
                                            <td className="setting-table-cell fs_14 fw_400 text-secondary p-8">
                                                <button onClick={() => setSelectedDOtIndex(index)}>
                                                    <KibabMenu />
                                                </button>
                                                {selectedDOtIndex === index && (
                                                    <div
                                                        ref={componentRef}
                                                        style={{ position: "absolute", right: '2rem', boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px" }}
                                                        className='bg-white rounded w-52'
                                                    >
                                                        <div className='w-full p-4'>
                                                            <button className='w-full flex align-middle gap-2 px-2 py-2' onClick={() => onCLickEdit(item)}>
                                                                <img alt="editIcon" src={EditIcon} />
                                                                <span className='fs_16'>Edit</span>
                                                            </button>
                                                            <button className='w-full flex align-middle gap-2 px-2 py-1' onClick={() => onClickDelete(item?.id)}>
                                                                <div className="mt-1">
                                                                    <DeleteIcon />
                                                                </div>
                                                                <span className='fs_16'>Delete</span>
                                                            </button>
                                                        </div>
                                                    </div>
                                                )}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </>
                    : <TaxCombination editingData={editingData} isView={isView} reloadData={reloadData} toogleChange={toogleChange} />
                )}
            </div>
        </div>
    );
};

export default TaxCombinationTable;
