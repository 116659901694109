import { call, takeLatest } from "redux-saga/effects";
import {
  customerCreateApi,
  customerDeleteApi,
  customerEditApi,
  customerTableApi,
  customerViewApi,
} from "../apis/customer-api";
import {
  CUSTOMER_CREATE,
  CUSTOMER_DELETE,
  CUSTOMER_EDIT,
  CUSTOMER_TABLE,
  CUSTOMER_VIEW,
} from "../types";

export function* customerCreateWorker(action) {
  try {
    const res = yield call(customerCreateApi, action.data);
    
    if (res.data?.code === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}

export function* customerTableWorker(action) {
  try {
    const res = yield call(customerTableApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}

export function* customerViewWorker(action) {
  try {
    const res = yield call(customerViewApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}

export function* customerDeleteWorker(action) {
  try {
    const res = yield call(customerDeleteApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* customerEditWorker(action) {
  try {
    const res = yield call(customerEditApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}

export function* customerCreateWatcher() {
  yield takeLatest(CUSTOMER_CREATE, customerCreateWorker);
}
export function* customerTableWatcher() {
  yield takeLatest(CUSTOMER_TABLE, customerTableWorker);
}
export function* customerViewWatcher() {
  yield takeLatest(CUSTOMER_VIEW, customerViewWorker);
}
export function* customerDeleteWatcher() {
  yield takeLatest(CUSTOMER_DELETE, customerDeleteWorker);
}
export function* customerEditWatcher() {
  yield takeLatest(CUSTOMER_EDIT, customerEditWorker);
}

