import { Form, Formik } from "formik";
import React, { useState } from "react";
import * as Yup from "yup";
import InputField from "../forms/input-field";
import { useDispatch } from "react-redux";
import successToast from "../helper-components/success-toast";
import errorToast from "../helper-components/error-toast";
import AddButton from "../buttons/button";
import { addStrBrandSoftwareAction, addStrbrandAction } from "../../redux/actions/store-action";
import moment from "moment";
import { ImageField1 } from "../forms/images-field";

const BrandingSoftwareDetailsTab = ({
  initialValue,
  id,
  permissionFn = () => {},
  setCurrentTab,
  reload,
  setReload,
}) => {
  const dispatch = useDispatch();
  const initialValues = {
    store_id: id,
    branding_installation: initialValue?.branding_installation
      ? moment(initialValue.branding_installation).format("YYYY-MM-DD")
      : null,
    store_approval_date: initialValue?.store_approval_date
      ? moment(initialValue.store_approval_date).format("YYYY-MM-DD")
      : null,
    dl_submit_date: initialValue?.dl_submit_date
      ? moment(initialValue.dl_submit_date).format("YYYY-MM-DD")
      : null,
    software_installation_date: initialValue?.software_installation_date
      ? moment(initialValue.software_installation_date).format("YYYY-MM-DD")
      : null,
    software_training_date: initialValue?.software_training_date
      ? moment(initialValue.software_training_date).format("YYYY-MM-DD")
      : null,
    complaint_portal_user_and_training_date:
      initialValue?.complaint_portal_user_and_training_date
        ? moment(initialValue.complaint_portal_user_and_training_date).format(
            "YYYY-MM-DD"
          )
        : null,
    software_live_date: initialValue?.software_live_date
      ? moment(initialValue.software_live_date).format()
      : null,
    brand_pest_image_1: initialValue?.brand_pest_image_1 || null,
    brand_pest_image_2: initialValue?.brand_pest_image_2 || null,
    brand_pest_image_3: initialValue?.brand_pest_image_3 || null,
    brand_pest_image_4: initialValue?.brand_pest_image_4 || null,
    brand_pest_image_5: initialValue?.brand_pest_image_5 || null,
    brand_pest_image_6: initialValue?.brand_pest_image_6 || null,
    brand_pest_image_7: initialValue?.brand_pest_image_7 || null,
  };

  const validationSchema = Yup.object().shape({
    branding_installation: Yup.date().required("Installation date is required"),
    store_approval_date: Yup.date().required("Store approval date is required"),
    dl_submit_date: Yup.date().required("DL submit date is required"),
    software_installation_date: Yup.date().required(
      "Software installation date is required"
    ),
    software_training_date: Yup.date().required(
      "Software training date is required"
    ),
    complaint_portal_user_and_training_date: Yup.date().required(
      "Complaint portal user and training date is required"
    ),
    software_live_date: Yup.date().required("Software live date is required"),
  });

  const onSubmit = (values, actions) => {
    dispatch(addStrBrandSoftwareAction(values, onSuccess, onError));
  };

  const onSuccess = (data) => {
    setReload(!reload);
    successToast(data?.message);
    setCurrentTab("Account Details");
  };
  const onError = (data) => {
    errorToast(data?.message);
  };

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ values, setFieldValue, isSubmitting, errors }) => {
        return (
          <Form className="flex-grow pt-2">
            <div className="flex flex-col justify-between h-full ">
              <div>
                <div className="flex flex-wrap">
                  <div className="w-full 2xl:w-[40%] lg:w-[50%] px-5 xl:px-10">
                    <InputField
                      type="date"
                      name="branding_installation"
                      label="Branding Installiation Date"
                      isRequired={true}
                      labelWidth="13.5rem"
                    />
                    <InputField
                      type="date"
                      name="store_approval_date"
                      label="Store Approval Date"
                      isRequired={true}
                      labelWidth="13.5rem"
                    />
                    <InputField
                      type="date"
                      name="dl_submit_date"
                      label="DL Submit Date"
                      isRequired={true}
                      labelWidth="13.5rem"
                    />
                    <InputField
                      type="date"
                      name="software_installation_date"
                      label="Software Installation Date"
                      isRequired={true}
                      labelWidth="13.5rem"
                    />
                  </div>
                  <div className="w-full 2xl:w-[40%] lg:w-[50%] px-5 xl:px-10">
                    <InputField
                      type="date"
                      name="software_training_date"
                      label="Software Training Date"
                      isRequired={true}
                      labelWidth="17rem"
                    />
                    <InputField
                      type="date"
                      name="complaint_portal_user_and_training_date"
                      label="Complaint Portal User & Training Date"
                      isRequired={true}
                      labelWidth="17rem"
                    />
                    <InputField
                      type="date"
                      name="software_live_date"
                      label="Software Live Date"
                      isRequired={true}
                      labelWidth="17rem"
                    />
                  </div>
                </div>
                <div className="px-5 xl:px-10">
                  <label className="secondary-color fs_14 fw_600">
                  Branding Pest Images
                  </label>
                  <div className="flex gap-5 flex-wrap">
                    <div>
                      <ImageField1
                        label="Chalan Receipt"
                        isRequired={false}
                        labelWidth=""
                        name="brand_pest_image_1"
                        mainLabel=""
                      />
                    </div>

                    <div>
                      <ImageField1
                        label="Chalan Receipt"
                        isRequired={false}
                        labelWidth=""
                        name="brand_pest_image_2"
                        mainLabel=""
                      />
                    </div>

                    <div>
                      <ImageField1
                        label="Chalan Receipt"
                        isRequired={false}
                        labelWidth=""
                        name="brand_pest_image_3"
                        mainLabel=""
                      />
                    </div>

                    <div>
                      <ImageField1
                        label="Chalan Receipt"
                        isRequired={false}
                        labelWidth=""
                        name="brand_pest_image_4"
                        mainLabel=""
                      />
                    </div>

                    <div>
                      <ImageField1
                        label="Chalan Receipt"
                        isRequired={false}
                        labelWidth=""
                        name="brand_pest_image_5"
                        mainLabel=""
                      />
                    </div>
                    <div>
                      <ImageField1
                        label="Chalan Receipt"
                        isRequired={false}
                        labelWidth=""
                        name="brand_pest_image_6"
                        mainLabel=""
                      />
                    </div>
                    <div>
                      <ImageField1
                        label="Chalan Receipt"
                        isRequired={false}
                        labelWidth=""
                        name="brand_pest_image_7"
                        mainLabel=""
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div
                style={{
                  bottom: "0",
                }}
                className="flex justify-end px-10 mb-5x sticky bg-white py-4 invoice-bottom-container"
              >
                <AddButton prefixIcon={false} text="Save" type="submit" />
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default BrandingSoftwareDetailsTab;
const items = [{ label: "Colour Pre Applied", name: "colour_pre_applied" }];
const items1 = [
  { label: "Furniture Pre Applied", name: "furniture_pre_applied" },
];
