import { Form, Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import InputField from '../../forms/input-field'
import { useDispatch } from 'react-redux';
import AddButton, { DraftButton } from '../../buttons/button';
import * as Yup from "yup";
import { useNavigate } from 'react-router-dom';
import { ChevronLeftIcon } from '@heroicons/react/24/outline';
import InnerLayout from '../../layouts/inner-layout';
import successToast from '../../helper-components/success-toast';
import errorToast from '../../helper-components/error-toast';
import { stripeAddAction, stripeUpdateAction, stripeViewAction } from '../../../redux/actions/settings-action';

const StripeConfiguration = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate()

    const [stripeData, setStripeData] = useState({})

    useEffect(() => {
        dispatch(stripeViewAction({}, onSuccess, onError));
    }, []);

    const reload = () => {
        dispatch(stripeViewAction({}, onSuccess, onError))
    }

    const onSuccess = (data) => {
        
        setStripeData(data?.data);
    };
    const onError = (data) => {  errorToast(data?.message) };

    const initialValues = {
        id: stripeData?.id || null,
        key_id: stripeData?.key_id || "",
        key_secret: stripeData?.key_secret || "",
    }

    const validationSchema = Yup.object().shape({
        key_id: Yup.string().required("Required"),
        key_secret: Yup.string().required("Required"),
    });

    const onSubmit = (values) => {
        
        if (values.id) {
            dispatch(stripeUpdateAction(values, onCreateSuccess, onError))
        } else {
            dispatch(stripeAddAction(values, onCreateSuccess, onError))
        }
    }

    const onCreateSuccess = (data) => { successToast(data?.message); reload() };

    return (
        <InnerLayout
            list={true}
            title="Settings"
            module="stripe-configuration"
        // permissionFn={permissionFn}
        >
            <div className='pl-4'>
                <div className='flex justify-between pt-3'>
                    <div className=" pb-4 flex items-center gap-2">
                        <button
                            className="flex items-center gap-2"
                            onClick={() => {
                                navigate('/settings')
                            }}
                        >
                            <ChevronLeftIcon className="h-5 w-5 inline-block" />
                            Settings /
                        </button>
                        <span className="cursor-pointer" >{" " + "Stripe Configuration"}</span>
                    </div>
                </div>
                <Formik
                    enableReinitialize={true}
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                >
                    {({ values, setFieldValue, handleSubmit }) => (
                        <Form className="pl-4 pt-4">
                            <div
                                style={{ height: "78vh" }}
                            >

                                <div className="w-full flex flex-col gap-4">
                                    <div style={{ maxWidth: "30rem" }} className="w-full">
                                        <InputField
                                            containerClass="mt-1"
                                            isRequired={true}
                                            type="text"
                                            name="key_id"
                                            placeholder="Key"
                                            label="Key Id"
                                            labelWidth="6rem"
                                        />
                                    </div>
                                    <div style={{ maxWidth: "30rem" }} className="w-full">
                                        <InputField
                                            containerClass="mt-1"
                                            isRequired={true}
                                            type="text"
                                            name="key_secret"
                                            placeholder="Key Secret"
                                            label="Key Secret"
                                            labelWidth="6rem"
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="flex justify-between px-6  bg-white py-4 invoice-bottom-container">
                                <DraftButton
                                    onclkFn={() => navigate(`/settings`)}
                                    text="Cancel"
                                />
                                <AddButton
                                    onclkFn={handleSubmit}
                                    prefixIcon={false}
                                    text="Save"
                                    type="submit"
                                />
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </InnerLayout>
    )
}

export default StripeConfiguration
