import {
  ACQ_SRC_LIST,
  ADMIN_ROLES,
  BANK_NAME_LIST,
  BUISNESS_TYPE_LIST,
  CATEGORY_LIST,
  CUSTOMER_LIST,
  DEPARTMENR_LIST,
  DIS_CUS_GROUP_LIST,
  DIS_CUS_LIST,
  DOCTOR_LIST,
  IMAGE_UPLOAD,
  ITEM_GROUP_LIST,
  MANUFACTURERS_LIST,
  PAYMENT_METHODS_LIST,
  PHONE_LIST,
  PRICE_LIST,
  PRODUCT_LIST,
  ROLES_LIST,
  SCH_LIST,
  SECTOR_LIST,
  SHIPPING_TYPE_LIST,
  STATE_LIST,
  STORE_ID,
  TAX_CAT_LIST,
  UOM_GROUP_FIND_ONE,
  UOM_GROUP_LIST,
  UOM_LIST,
  USERS_LIST,
  VALUATION_LIST,
  VENDOR_LIST,
  PINCODE_LIST,
  BIN_LOCATION_LIST,
  WARE_HOUSE_LIST,
  CUSTOMER_TYPE_LIST,
  ITEM_TYPE_DROP_DOWN,
} from "../types";

export const productListAction = (data, onSuccess, onError) => {
  return {
    type: PRODUCT_LIST,
    data,
    onSuccess,
    onError,
  };
};
export const phoneListAction = (data, onSuccess, onError) => {
  return {
    type: PHONE_LIST,
    data,
    onSuccess,
    onError,
  };
};
export const stateListAction = (onSuccess, onError) => {
  return {
    type: STATE_LIST,
    onSuccess,
    onError,
  };
};
export const doctorListAction = (onSuccess, onError) => {
  return {
    type: DOCTOR_LIST,
    onSuccess,
    onError,
  };
};
export const storeIdAction = (onSuccess, onError) => {
  return {
    type: STORE_ID,
    onSuccess,
    onError,
  };
};
export const paymentMethodListAction = (onSuccess, onError) => {
  return {
    type: PAYMENT_METHODS_LIST,
    onSuccess,
    onError,
  };
};

export const catListAction = (onSuccess, onError) => {
  return {
    type: CATEGORY_LIST,
    onSuccess,
    onError,
  };
};
export const uomListAction = (onSuccess, onError) => {
  return {
    type: UOM_LIST,
    onSuccess,
    onError,
  };
};
export const priceListAction = (onSuccess, onError) => {
  return {
    type: PRICE_LIST,
    onSuccess,
    onError,
  };
};
export const itemGroupListAction = (onSuccess, onError) => {
  return {
    type: ITEM_GROUP_LIST,
    onSuccess,
    onError,
  };
};
export const itemTypeListAction = (onSuccess, onError) => {
  return {
    type: ITEM_TYPE_DROP_DOWN,
    onSuccess,
    onError,
  };
};
export const taxCatListAction = (onSuccess, onError) => {
  return {
    type: TAX_CAT_LIST,
    onSuccess,
    onError,
  };
};
export const manufacturesListAction = (onSuccess, onError) => {
  return {
    type: MANUFACTURERS_LIST,
    onSuccess,
    onError,
  };
};
export const shippingTypeListAction = (onSuccess, onError) => {
  return {
    type: SHIPPING_TYPE_LIST,
    onSuccess,
    onError,
  };
};
export const sectorListAction = (onSuccess, onError) => {
  return {
    type: SECTOR_LIST,
    onSuccess,
    onError,
  };
};
export const schListAction = (onSuccess, onError) => {
  return {
    type: SCH_LIST,
    onSuccess,
    onError,
  };
};
export const vendorListAction = (onSuccess, onError) => {
  return {
    type: VENDOR_LIST,
    onSuccess,
    onError,
  };
};
export const valListAction = (onSuccess, onError) => {
  return {
    type: VALUATION_LIST,
    onSuccess,
    onError,
  };
};

export const uomGroupListAction = (onSuccess, onError) => {
  return {
    type: UOM_GROUP_LIST,
    onSuccess,
    onError,
  };
};
export const uomGroupFindOneAction = (data, onSuccess, onError) => {
  return {
    type: UOM_GROUP_FIND_ONE,
    data,
    onSuccess,
    onError,
  };
};
export const imageUploadAction = (data, onSuccess, onError) => {
  return {
    type: IMAGE_UPLOAD,
    data,
    onSuccess,
    onError,
  };
};
export const adminRolesAction = (onSuccess, onError) => {
  return {
    type: ADMIN_ROLES,
    onSuccess,
    onError,
  };
};
export const departmentListAction = (onSuccess, onError) => {
  return {
    type: DEPARTMENR_LIST,
    onSuccess,
    onError,
  };
};
export const RolesListAction = (onSuccess, onError) => {
  return {
    type: ROLES_LIST,
    onSuccess,
    onError,
  };
};
export const UsersListAction = (data, onSuccess, onError) => {
  return {
    type: USERS_LIST,
    data,
    onSuccess,
    onError,
  };
};
export const cusTypeListAction = (onSuccess, onError) => {
  return {
    type: CUSTOMER_TYPE_LIST,
    onSuccess,
    onError,
  };
};
export const disCusListAction = (onSuccess, onError) => {
  return {
    type: DIS_CUS_LIST,
    onSuccess,
    onError,
  };
};

export const acqSrcListAction = (onSuccess, onError) => {
  return {
    type: ACQ_SRC_LIST,
    onSuccess,
    onError,
  };
};
export const buisnessTypeListAction = (onSuccess, onError) => {
  return {
    type: BUISNESS_TYPE_LIST,
    onSuccess,
    onError,
  };
};
export const bnkNameListAction = (onSuccess, onError) => {
  return {
    type: BANK_NAME_LIST,
    onSuccess,
    onError,
  };
};
    
export const disCusGrpListAction = (onSuccess, onError) => {
  return {
    type: DIS_CUS_GROUP_LIST,
    onSuccess,
    onError,
  };
};

export const customerListAction = (data, onSuccess, onError) => {
  return {
    type: CUSTOMER_LIST,
    data,
    onSuccess,
    onError,
  };
};
export const pincodeListAction = (data, onSuccess, onError) => {
  return {
    type: PINCODE_LIST,
    data,
    onSuccess,
    onError,
  };
};

export const binLocationListAction = (onSuccess, onError) => {
  return {
    type: BIN_LOCATION_LIST,
    onSuccess,
    onError,
  };
};
export const wareHouseListAction = (onSuccess, onError) => {
  return {
    type: WARE_HOUSE_LIST,
    onSuccess,
    onError,
  };
};
