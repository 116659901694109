import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import InputField from "../forms/input-field";
import { useDispatch } from "react-redux";
import successToast from "../helper-components/success-toast";
import errorToast from "../helper-components/error-toast";
import AddButton from "../buttons/button";
import {
  addStrBrandSoftwareAction,
  addStrOpeningAction,
} from "../../redux/actions/store-action";
import moment from "moment";
import { ImageField1 } from "../forms/images-field";
import TextAreaField from "../forms/textarea-field";
import SelectField from "../forms/select-field";
import { stateListAction } from "../../redux/actions/general-action";
import GeneratedPDFViewer from "./opening-invi-tab";

const OpeningDetailsTab = ({
  initialValue,
  id,
  permissionFn = () => {},
  setCurrentTab,
  reload,
  setReload,
}) => {
  const dispatch = useDispatch();
  const [stateList, setStateList] = useState([]);

  useEffect(() => {
    dispatch(stateListAction(onSuccessState, onErrorState));
  }, []);

  const onSuccessState = (data) => {
    setStateList(
      data.map((item) => {
        return { label: item?.name, value: item?.name };
      })
    );
  };
  const onErrorState = () => {};
  const initialValues = {
    store_id: id,
    stock_recevied_date: initialValue?.stock_recevied_date
      ? moment(initialValue.stock_recevied_date).format("YYYY-MM-DD")
      : null,
    opening_date: initialValue?.opening_date
      ? moment(initialValue.opening_date).format("YYYY-MM-DD")
      : null,
    opening_time: initialValue?.opening_time
      ? moment(initialValue.opening_time, "HH:mm:ss").format("HH:mm:ss")
      : null,

    cheif_guest_name: initialValue?.cheif_guest_name ?? null,
    cheif_guest_desgination: initialValue?.cheif_guest_desgination ?? null,
    dl_address: initialValue?.address ?? "",
    dl_area: initialValue?.area ?? "",
    dl_landmark: initialValue?.landmark ?? "",
    dl_town_or_village: initialValue?.town_or_village ?? "",
    dl_city: initialValue?.city ?? "",
    dl_state: initialValue?.state ?? "",
    dl_pin_code: initialValue?.pin_code ?? "",
    dl_country_region: initialValue?.country_region ?? "",
  };

  const validationSchema = Yup.object().shape({
    store_id: Yup.string().required("Store ID is required"),
    stock_recevied_date: Yup.date().required("Stock received date is required"),
    opening_date: Yup.date().required("Opening date is required"),
    opening_time: Yup.string().required("Opening time is required"),
    cheif_guest_name: Yup.string().required("Chief guest name is required"),
    cheif_guest_desgination: Yup.string().required(
      "Chief guest designation is required"
    ),
    // dl_address: Yup.string().required("Address is required"),
    // dl_area: Yup.string().required("Area is required"),
    // dl_landmark: Yup.string().required("Landmark is required"),
    // dl_town_or_village: Yup.string().required("Town or Village is required"),
    // dl_city: Yup.string().required("City is required"),
    // dl_state: Yup.string().required("State is required"),
    // dl_pin_code: Yup.string().required("Pin code is required"),
    // dl_country_region: Yup.string().required("Country/Region is required"),
  });

  const onSubmit = (values, actions) => {
    const {
      dl_address,
      dl_area,
      dl_landmark,
      dl_town_or_village,
      dl_city,
      dl_state,
      dl_pin_code,
      dl_country_region,
      ...filteredValues
    } = values;
    dispatch(addStrOpeningAction(filteredValues, onSuccess, onError));
  };

  const onSuccess = (data) => {
    setReload(!reload);
    successToast(data?.message);
    setCurrentTab("Follow-ups Remarks History");
  };
  const onError = (data) => {
    errorToast(data?.message);
  };

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ values, setFieldValue, isSubmitting, errors }) => {
        return (
          <Form className="flex-grow pt-2">
            <div className="flex flex-col justify-between h-full ">
              <div>
                <div className="flex flex-wrap">
                  <div className="w-full 2xl:w-[40%] lg:w-[50%] px-5 xl:px-10">
                    <InputField
                      type="date"
                      name="stock_recevied_date"
                      label="Stock Received Date"
                      isRequired={true}
                      labelWidth="13.5rem"
                    />
                    <InputField
                      type="date"
                      name="opening_date"
                      label="Opening Date"
                      isRequired={true}
                      labelWidth="13.5rem"
                    />
                    <InputField
                      type="time"
                      name="opening_time"
                      label="Opening Time"
                      isRequired={true}
                      labelWidth="13.5rem"
                    />
                  </div>
                  <div className="w-full 2xl:w-[40%] lg:w-[50%] px-5 xl:px-10">
                    <InputField
                      type="text"
                      placeholder="Enter name"
                      name="cheif_guest_name"
                      label="Chief Guest Name"
                      isRequired={true}
                      labelWidth="13.5rem"
                    />
                    <InputField
                      type="text"
                      placeholder="Enter name"
                      name="cheif_guest_desgination"
                      label="Chief Guest Designation"
                      isRequired={true}
                      labelWidth="13.5rem"
                    />
                  </div>
                </div>
                <div className="px-5 xl:px-10">
                  <label className="secondary-color fs_14 fw_600">
                    Address Details as per DL
                  </label>

                  <div className="flex flex-wrap mt-10 gap-10">
                    <div className="w-full 2xl:w-[35%] lg:w-[50%] ">
                      <TextAreaField
                        label="Address"
                        placeholder="Street 1"
                        name="dl_address"
                        id={"dl_address"}
                        // isRequired={true}
                        labelWidth="8.5rem"
                        feildHeight="5rem"
                      />
                      <InputField
                        // isRequired={true}
                        type="text"
                        name="dl_area"
                        placeholder=""
                        label="Area"
                        labelWidth="8.5rem"
                      />
                      <InputField
                        // isRequired={true}
                        type="text"
                        name="dl_landmark"
                        placeholder=""
                        label="Landmark"
                        labelWidth="8.5rem"
                      />
                    </div>
                    <div className="w-full 2xl:w-[35%] lg:w-[50%] ">
                      <InputField
                        // isRequired={true}
                        type="text"
                        name="dl_town_or_village"
                        placeholder=""
                        label="Town/ Village"
                        labelWidth="8.5rem"
                      />
                      <InputField
                        // isRequired={true}
                        type="text"
                        name="dl_city"
                        placeholder=""
                        label="City"
                        labelWidth="8.5rem"
                      />
                      <SelectField
                        placeholder={"Select state"}
                        options={stateList}
                        label={"State"}
                        id={"dl_state"}
                        name="dl_state"
                        isSearchable={true}
                        errMsgContainerClass="ps-2"
                        // isRequired={true}
                        labelWidth="8.5rem"
                      />

                      <SelectField
                        // isRequired={true}
                        placeholder={"Select country"}
                        options={countryList}
                        label={"Country/Region"}
                        id={"dl_country_region"}
                        name="dl_country_region"
                        isSearchable={true}
                        errMsgContainerClass="ps-2"
                        labelWidth="8.5rem"
                      />
                      <InputField
                        // isRequired={true}
                        type="text"
                        name="dl_pin_code"
                        placeholder=""
                        label="Pin Code"
                        labelWidth="8.5rem"
                      />
                    </div>
                  </div>
                </div>
                <GeneratedPDFViewer initialValue={initialValue} />
              </div>
              <div
                style={{
                  bottom: "0",
                }}
                className="flex justify-end px-10 mb-5x sticky bg-white py-4 invoice-bottom-container"
              >
                <AddButton prefixIcon={false} text="Save" type="submit" />
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default OpeningDetailsTab;
const items = [{ label: "Colour Pre Applied", name: "colour_pre_applied" }];
const items1 = [
  { label: "Furniture Pre Applied", name: "furniture_pre_applied" },
];

const countryList = [{ label: "India", value: "india" }];
