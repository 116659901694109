import React, { useEffect, useState } from "react";
import InnerLayout from "../../components/layouts/inner-layout";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import LoadingSpinner from "../../components/helper-components/loading-spinner";
import {
  returnBillViewAction,
  storeDetailsAction,
} from "../../redux/actions/invoices-action";
import { storeIdAction } from "../../redux/actions/general-action";
import CreateEditViewReturnBill from "../../components/return-bill/create-edit-view-return-bill";

const ViewReturnBill = () => {
  const { id } = useParams();
  const [orderDetail, setOrderDetail] = useState({});
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const [storeId, setStoreId] = useState("");
  const [storeDetails, setStoreDetails] = useState({});
  useEffect(() => {
    dispatch(storeIdAction(onSuccessStore, onErrorStore));
    dispatch(storeDetailsAction(onSuccessStoreDetails, onErrorStoreDetails));
    dispatch(returnBillViewAction(id, onSuccPur, onErrPur));
  }, []);

  const onSuccPur = (data) => {
    setOrderDetail(data?.data?.[0]);
    setLoading(false);
  };

  const onErrPur = (data) => {
    setLoading(false);
  };
  const onSuccessStore = (data) => {
    setStoreId(data?.data?.store_id);
  };
  const onErrorStore = () => {};
  const onSuccessStoreDetails = (data) => {
    setStoreDetails(data?.data);
  };
  const onErrorStoreDetails = () => {};

  return (
    <InnerLayout list={false} backBtn={true} title="Return Bill View">
      {loading ? (
        <LoadingSpinner />
      ) : (
        // <></>
        <CreateEditViewReturnBill
          page="view"
          disabled={true}
          orderDetail={{
            itemData: orderDetail?.itemData,
            vendorDetails: orderDetail?.orderData,
          }}
          storeDetails={storeDetails}
          storeId={storeId}
        />
      )}
    </InnerLayout>
  );
};

export default ViewReturnBill;
