import { api } from "./api";

export const financialAccountAddApi = (params) => {
  return api.post(`/financialLedger/account/add`, JSON.stringify(params));
};
export const financialAccountUpdateApi = (params) => {
  return api.post(`/financialLedger/account/update`, JSON.stringify(params));
};
export const financialAccountDeleteApi = (params) => {
  return api.post(`/financialLedger/account/delete`, JSON.stringify(params));
};

export const financialAccountGetApi = () => {
  return api.get(`/financialLedger/account/get`);
};

export const financialPeriodSelectionApi = (params) => {
  return api.get(`/financialLedger/yearList`);
};
export const financialAllAccountApi = (params) => {
  return api.get(
    `/financialLedger/generalAccountsOfYears/get?gcct_id=${params?.gcct_id}&tab=${params?.tab}`
  );
};
export const financialAccListApi = (params) => {
  return api.get(
    `/financialLedger/searchGeneralAccount/get?field=${params?.field}&value=${params?.value}`
  );
};
export const financialAccAllocUpdateApi = (params) => {
  return api.post(
    `/financialLedger/generalAccount/update`,
    JSON.stringify(params)
  );
};
export const journalEntryFetchApi = (params) => {
  return api.get(
    `/financialLedger/journEntry/search?pageNumber=${params?.pageNumber}&pageSize=${
      params?.pageSize
    }&sortBy=${params?.filterData?.sort_by}&sortOrder=${
      params?.filterData?.sort_order
    }&fromDate=${params?.filterData?.from}&toDate=${
      params?.filterData?.to
    }${
      params?.filterData?.inputValue &&
      `&searchColumn=${params?.filterData?.selectColumn}&searchValue=${params?.filterData?.inputValue}`
    }`,
    JSON.stringify(params)
  );
};
export const journalEntryViewApi = (params) => {
  return api.get(`/financialLedger/journEntry/get?transcation_id=${params}`);
};
export const journalEntryUpdateApi = (params) => {
  return api.post(
    `/financialLedger/generalAccount/update`,
    JSON.stringify(params)
  );
};
