import React, { useEffect, useState } from "react";
import InnerLayout from "../../components/layouts/inner-layout";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import LoadingSpinner from "../../components/helper-components/loading-spinner";
import DoctorCreateViewEdit from "../../components/doctor-information/doctor-view-edit";
import { doctorViewAction } from "../../redux/actions/doctor-action";
const ViewDoctor = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const navigate = useNavigate();
  const [doctor, setDoctor] = useState({});
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    dispatch(doctorViewAction(id, onSuccess, onError));
  }, []);
  const onSuccess = (data) => {
    setLoading(false);
    setDoctor(data?.data);
  };
  const onError = (data) => {
    loading(false);
  };
  return (
    <>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <InnerLayout
          page="view"
          title="View doctor"
          title1={doctor?.dr_id}
          view={true}
          onclkEditFn={() => navigate(`/edit-doctor/${id}`)}
        >
          <DoctorCreateViewEdit doctor={doctor} disabled={true} />
        </InnerLayout>
      )}
    </>
  );
};

export default ViewDoctor;
