import React from "react";

const UploadIcon = ({ fill = "#277DFE" }) => {
  return (
    <div
      style={{
        width: "15px",
        height: "15px",
      }}
    >
      <svg
        width="15"
        height="14"
        viewBox="0 0 15 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7 10.577V1.927L4.67 4.257L3.962 3.538L7.5 0L11.038 3.538L10.331 4.258L8 1.927V10.577H7ZM2.115 14C1.655 14 1.271 13.846 0.963 13.538C0.654333 13.2293 0.5 12.845 0.5 12.385V9.962H1.5V12.385C1.5 12.5383 1.564 12.6793 1.692 12.808C1.82067 12.936 1.96167 13 2.115 13H12.885C13.0383 13 13.1793 12.936 13.308 12.808C13.436 12.6793 13.5 12.5383 13.5 12.385V9.962H14.5V12.385C14.5 12.845 14.346 13.229 14.038 13.537C13.7293 13.8457 13.345 14 12.885 14H2.115Z"
          fill={fill}
        />
      </svg>
    </div>
  );
};

export default UploadIcon;

export const UploadIcon1 = ({ fill = "#277DFE" }) => {
  return (

      <svg
        width="32"
        height="36"
        viewBox="0 0 32 36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M29.3279 1.35209C28.0541 0.440224 26.4702 0.0716051 24.9247 0.327299C23.3791 0.582992 21.9984 1.44206 21.0861 2.71559L6.18214 23.5225C5.843 23.9718 5.59722 24.4844 5.45927 25.0302C5.32132 25.576 5.29399 26.1439 5.37891 26.7004C5.46382 27.2569 5.65925 27.7907 5.95368 28.2705C6.24811 28.7503 6.6356 29.1664 7.0933 29.4941C7.551 29.8218 8.06967 30.0547 8.61874 30.1788C9.16782 30.303 9.73619 30.3161 10.2904 30.2172C10.8446 30.1183 11.3734 29.9095 11.8456 29.6031C12.3179 29.2967 12.724 28.8989 13.0401 28.4331L23.5634 13.7434L22.1915 12.7596L11.6699 27.4527C11.2787 27.9975 10.6875 28.3651 10.0258 28.4748C9.36404 28.5846 8.68581 28.4276 8.13964 28.0382C7.59436 27.6464 7.2268 27.0543 7.11764 26.3918C7.00849 25.7293 7.16667 25.0506 7.55745 24.5046L22.4581 3.69772H22.4615L22.4581 3.69434C23.1115 2.78625 24.0982 2.1741 25.202 1.99192C26.3058 1.80974 27.4369 2.07239 28.3475 2.72234C29.2555 3.37579 29.8677 4.36245 30.0499 5.46627C30.2321 6.57009 29.9694 7.70113 29.3195 8.61172V8.60834L12.9845 31.4166C12.0713 32.6888 10.691 33.5471 9.14606 33.8033C7.60115 34.0595 6.01768 33.6928 4.74271 32.7835C3.4707 31.8692 2.61307 30.4879 2.3578 28.9424C2.10253 27.3968 2.47044 25.8131 3.38089 24.5383L15.2525 7.96372L13.8805 6.98159L2.00895 23.5562C1.42824 24.3668 1.01297 25.2839 0.786881 26.2551C0.56079 27.2262 0.528309 28.2324 0.691296 29.2161C0.854284 30.1999 1.20954 31.1418 1.73677 31.9882C2.26399 32.8345 2.95285 33.5687 3.76395 34.1487C4.57405 34.7299 5.49078 35.1458 6.46173 35.3726C7.43267 35.5993 8.43878 35.6324 9.42254 35.4701C10.4063 35.3077 11.3484 34.953 12.195 34.4263C13.0416 33.8996 13.776 33.2112 14.3564 32.4004L30.6914 9.59216C31.6024 8.31805 31.9701 6.73433 31.7138 5.18915C31.4575 3.64398 30.5981 2.26382 29.3245 1.35209H29.3279Z"
          fill="black"
        />
      </svg>
  );
};
