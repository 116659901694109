import { all } from "redux-saga/effects";
import {
  customerCreateWatcher,
  customerDeleteWatcher,
  customerEditWatcher,
  customerTableWatcher,
  customerViewWatcher,
} from "../customer-saga";

export default function* customerRootSaga() {
  yield all([
    customerCreateWatcher(),
    customerTableWatcher(),
    customerViewWatcher(),
    customerEditWatcher(),
    customerDeleteWatcher(),
  ]);
}
