import { all } from "redux-saga/effects";
import {
  dashboardExpStockWatcher,
  dashboardLowStockWatcher,
  dashboardNewCusGraphWatcher,
  dashboardSalesWatcher,
  dashboardTopSellingProductsWatcher,
} from "../dashboard-saga";

export default function* dashboardRootSaga() {
  yield all([
    dashboardSalesWatcher(),
    dashboardTopSellingProductsWatcher(),
    dashboardLowStockWatcher(),
    dashboardExpStockWatcher(),
    dashboardNewCusGraphWatcher(),
  ]);
}
