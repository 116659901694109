import { all } from "redux-saga/effects";
import {
  invoiceCreateWatcher,
  invoiceDeleteWatcher,
  invoiceDiscountWatcher,
  invoiceDraftCreateWatcher,
  invoiceEditWatcher,
  invoiceNumberWatcher,
  invoicePastWatcher,
  invoiceTableWatcher,
  invoiceViewWatcher,
  invoiceAlternativeWatcher,
  repeatOrderWatcher,
  storeDetailsWatcher,
  invoiceScannedItemWatcher,
  returnBillTableWatcher,
  returnBillCreateWatcher,
  returnBillViewWatcher,
  returnBillItemsGetWatcher,
} from "../invoice-saga";

export default function* invoiceRootSaga() {
  yield all([
    invoiceCreateWatcher(),
    repeatOrderWatcher(),
    invoiceNumberWatcher(),
    invoiceTableWatcher(),
    invoiceDiscountWatcher(),
    invoiceViewWatcher(),
    invoiceDraftCreateWatcher(),
    invoiceEditWatcher(),
    invoicePastWatcher(),
    invoiceDeleteWatcher(),
    invoiceAlternativeWatcher(),
    storeDetailsWatcher(),
    invoiceScannedItemWatcher(),
    returnBillTableWatcher(),
    returnBillCreateWatcher(),
    returnBillViewWatcher(),
    returnBillItemsGetWatcher(),
  ]);
}
