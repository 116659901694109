import { call, takeLatest } from "redux-saga/effects";
import {
  INVOICE_ALTERNATIVE,
  INVOICE_CREATE,
  INVOICE_DELETE,
  INVOICE_DISCOUNT,
  INVOICE_DRAFT_CREATE,
  INVOICE_EDIT,
  INVOICE_NUMBER,
  INVOICE_PAST,
  INVOICE_SCANNED_ITEM,
  INVOICE_TABLE,
  INVOICE_VIEW,
  REPEAT_ORDER,
  RETURN_BILL_ADD,
  RETURN_BILL_ITEMS_GET,
  RETURN_BILL_TABLE,
  RETURN_BILL_VIEW,
  STORE_DETAILS,
} from "../types";
import {
  invoiceAlternativeApi,
  invoiceCreateApi,
  invoiceDeleteApi,
  invoiceDiscountApi,
  invoiceDraftCreateApi,
  invoiceEditApi,
  invoiceNumberApi,
  invoicePastApi,
  invoiceScannedItemApi,
  invoiceTableApi,
  invoiceViewApi,
  repeatOrderApi,
  returnBillCreateApi,
  returnBillItemsGetApi,
  returnBillTableApi,
  returnBillViewApi,
  storeDetailsApi,
} from "../apis/invoice-api";

export function* invoiceCreateWorker(action) {
  try {
    const res = yield call(invoiceCreateApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* invoiceDeleteWorker(action) {
  try {
    const res = yield call(invoiceDeleteApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* invoiceEditWorker(action) {
  try {
    const res = yield call(invoiceEditApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* invoiceDraftCreateWorker(action) {
  try {
    const res = yield call(invoiceDraftCreateApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* repeatOrderWorker(action) {
  try {
    const res = yield call(repeatOrderApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* invoiceNumberWorker(action) {
  try {
    const res = yield call(invoiceNumberApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* invoiceTableWorker(action) {
  try {
    const res = yield call(invoiceTableApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* invoicePastWorker(action) {
  try {
    const res = yield call(invoicePastApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* invoiceDiscountWorker(action) {
  try {
    const res = yield call(invoiceDiscountApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* invoiceViewWorker(action) {
  try {
    const res = yield call(invoiceViewApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* invoiceAlternativeWorker(action) {
  try {
    const res = yield call(invoiceAlternativeApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* storeDetailsWorker(action) {
  try {
    const res = yield call(storeDetailsApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* invoiceScannedItemWorker(action) {
  try {
    const res = yield call(invoiceScannedItemApi, action.data);

    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}

export function* returnBillTableWorker(action) {
  try {
    const res = yield call(returnBillTableApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* returnBillCreateWorker(action) {
  try {
    const res = yield call(returnBillCreateApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* returnBillViewWorker(action) {
  try {
    const res = yield call(returnBillViewApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* returnBillItemsGetWorker(action) {
  try {
    const res = yield call(returnBillItemsGetApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}

export function* returnBillItemsGetWatcher() {
  yield takeLatest(RETURN_BILL_ITEMS_GET, returnBillItemsGetWorker);
}
export function* returnBillViewWatcher() {
  yield takeLatest(RETURN_BILL_VIEW, returnBillViewWorker);
}
export function* returnBillCreateWatcher() {
  yield takeLatest(RETURN_BILL_ADD, returnBillCreateWorker);
}
export function* returnBillTableWatcher() {
  yield takeLatest(RETURN_BILL_TABLE, returnBillTableWorker);
}

export function* invoiceCreateWatcher() {
  yield takeLatest(INVOICE_CREATE, invoiceCreateWorker);
}
export function* invoiceDeleteWatcher() {
  yield takeLatest(INVOICE_DELETE, invoiceDeleteWorker);
}
export function* invoiceEditWatcher() {
  yield takeLatest(INVOICE_EDIT, invoiceEditWorker);
}
export function* invoiceDraftCreateWatcher() {
  yield takeLatest(INVOICE_DRAFT_CREATE, invoiceDraftCreateWorker);
}
export function* repeatOrderWatcher() {
  yield takeLatest(REPEAT_ORDER, repeatOrderWorker);
}
export function* invoiceNumberWatcher() {
  yield takeLatest(INVOICE_NUMBER, invoiceNumberWorker);
}
export function* invoiceTableWatcher() {
  yield takeLatest(INVOICE_TABLE, invoiceTableWorker);
}
export function* invoicePastWatcher() {
  yield takeLatest(INVOICE_PAST, invoicePastWorker);
}
export function* invoiceDiscountWatcher() {
  yield takeLatest(INVOICE_DISCOUNT, invoiceDiscountWorker);
}
export function* invoiceViewWatcher() {
  yield takeLatest(INVOICE_VIEW, invoiceViewWorker);
}
export function* invoiceAlternativeWatcher() {
  yield takeLatest(INVOICE_ALTERNATIVE, invoiceAlternativeWorker);
}
export function* storeDetailsWatcher() {
  yield takeLatest(STORE_DETAILS, storeDetailsWorker);
}
export function* invoiceScannedItemWatcher() {
  yield takeLatest(INVOICE_SCANNED_ITEM, invoiceScannedItemWorker);
}
