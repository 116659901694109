import React from "react";

const PagRightIcon = ({ fill = "#344054", width = "15", height = "15" }) => {
  return (
<svg
  width={width}
  height={height}
  viewBox="0 0 20 20"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M4.16667 10H15.8333M15.8333 10L10 4.16667M15.8333 10L10 15.8333"
    stroke={fill}
    strokeWidth="1.66667"
    strokeLinecap="round"
    strokeLinejoin="round"
  />
</svg>

  );
};

export default PagRightIcon;

export const PagLeftIcon = ({
  fill = "#344054",
  width = "20",
  height = "20",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.8332 9.99935H4.1665M4.1665 9.99935L9.99984 15.8327M4.1665 9.99935L9.99984 4.16602"
        stroke={fill}
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
