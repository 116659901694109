import React from "react";
import UsersCreateViewEdit from "../../components/users/users-create-view-edit";
import InnerLayout from "../../components/layouts/inner-layout";
const AddUser = () => {
  return (
    <InnerLayout title="Add User">
      <UsersCreateViewEdit page="create" />
    </InnerLayout>
  );
};

export default AddUser;
