import React, { useEffect, useState } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import SelectOptions from "../forms/select-options";
import { formatYAxisTick } from "../helper-components/helper-function";
const primaryColor = "var(--color-primary)";

const LineGraph = ({
  setSelectedOption,
  selectedOption,
  lineGraphData,
  options,
  total_sales = 0,
  total_orders = 0,
  graphLabel = "Daily Sales",
}) => {
  const handleChange = (option) => {
    setSelectedOption({ ...selectedOption, lineGraph: option?.value });
  };
  return (
    <div className="border border-ebeaf2 p-3">
      <div className="flex justify-between items-center px-5 flex-wrap">
        <span className="text-000000 fw_600 fs_16 text-nowrap ">
          {" "}
          {graphLabel}
        </span>
        <div className=" flex items-center gap-2">
          <div className="w-[8rem]">
            <SelectOptions
              value={selectedOption?.value}
              handleChange={handleChange}
              options={options}
            />
          </div>
          {selectedOption?.lineGraph === "custom_date" && (
            <div className="flex items-center border-D0D5DD border-2 py-1 px-1 rounded-md gap-2">
              <input
                className="fs_14"
                value={selectedOption?.lineGraphDate?.from}
                onChange={(e) => {
                  const fromDate = e.target.value;
                  setSelectedOption({
                    ...selectedOption,
                    lineGraphDate: {
                      ...selectedOption.lineGraphDate,
                      from: fromDate,
                    },
                  });
                }}
                type="date"
              />
              -
              <input
                className="fs_14"
                value={selectedOption?.lineGraphDate?.to}
                onChange={(e) => {
                  const toDate = e.target.value;
                  setSelectedOption({
                    ...selectedOption,
                    lineGraphDate: {
                      ...selectedOption.lineGraphDate,
                      to: toDate,
                    },
                  });
                }}
                type="date"
              />
            </div>
          )}
        </div>
      </div>
      <div className="flex justify-between items-center p-5">
        <span className="text-primary fw_800 fs_18 text-nowrap ">
          {" "}
          ₹ {new Intl.NumberFormat("en-IN").format(total_sales)}
        </span>
        <div className="w-[7rem]">{total_orders} Orders</div>
      </div>

      <ResponsiveContainer width="100%" height={320}>
        <LineChart
          data={lineGraphData}
          margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis tick={{ fontSize: 12 }} dataKey="name" />
          <YAxis tick={{ fontSize: 12 }} tickFormatter={formatYAxisTick} />
          <Tooltip />
          <Line
            type="linear"
            dataKey="sales"
            stroke={primaryColor}
            strokeWidth={2}
            dot={false}
          />
        </LineChart>
      </ResponsiveContainer>

      <div className="flex justify-center items-center p-5 text-000000 fw_400 fs_14">
        <span>X-time period, Y-sales-amount</span>
      </div>
    </div>
  );
};

export default LineGraph;
