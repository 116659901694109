import React, { useEffect, useState } from 'react';
import ReportsLayout from '../../../components/reports/reports-layout';
import { partyWiseSalesReportAction } from '../../../redux/actions/report-action';
import { useDispatch } from 'react-redux';
import moment from 'moment';

const PartyWiseProfitReport = () => {

    const dispatch = useDispatch()
    const [groupByOptions, setgroupByOptions] = useState([
        { label: "None", value: '' },
        { label: "Drug Code", value: 'oi.item_code' },
        { label: "Bill Date", value: 'so.created_date' },
        { label: "Party Name", value: 'c.cmr_first_name' },
    ]);

    const [loading, setLoading] = useState(false);
    const [filterOptions, setFilterOptions] = useState(Object.entries(tableColoumsn).map(([label, value]) => ({
        label,
        value
    })));
    const [selectedColumns, setSelectedColumns] = useState(tableHeading);
    const [filters, setFilters] = useState([])
    const [tableData, setTableData] = useState([]);
    const [dateRange, setDateRange] = useState('');
    const [groupBy, setGroupBy] = useState('')

    useEffect(() => {
        const selectedColumnValues = selectedColumns.map(column => tableColoumsn[column]);
        const requestData = {
            selectedColumns: selectedColumnValues,
            filters: filters.map(({ id, ...rest }) => rest),
            filter_by: dateRange,
            group_by: groupBy
        };
        dispatch(partyWiseSalesReportAction(requestData, onSuccess, onError))
        setLoading(true);

    }, [selectedColumns, filters, dateRange, groupBy]);


    const isIso8601Date = (dateString) => {
        const iso8601Regex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/;
        return iso8601Regex.test(dateString);
    };

    const formatDate = (value) => {
        return moment(value).format('DD-MM-YYYY');
    };

    const transformData = (data) => {
        return data.map(item => {
            let transformedItem = {};
            for (let [key, value] of Object.entries(tableColoumsn)) {
                const rawValue = item[value.split('.').pop()];
                transformedItem[key] = isIso8601Date(rawValue) ? formatDate(rawValue) : rawValue;
            }
            return transformedItem;

        });
    };

    const onSuccess = (data) => {
        console.log("data is", data);
        const transformedData = transformData(data?.data?.result);
        setTableData(transformedData);
        setLoading(false)
    }
    const onError = (data) => {
        console.log("error is", data);
        setLoading(false)
    }

    return (
        <ReportsLayout
            title={"Party Wise Profit Report"}
            tableHeading={selectedColumns}
            tableData={tableData}
            groupByOptions={groupByOptions}
            filterOptions={filterOptions}
            availableColumns={tableHeading}
            selectedColumns={selectedColumns}
            setSelectedColumns={setSelectedColumns}
            setFilters={setFilters}
            loading={loading}
            setDateRange={setDateRange}
            setGroupBy={setGroupBy}
        />
    );
};

const tableHeading = ["Party Code", "Party Name", "Mobile Number", "Address",
    "Bill Num", "Bill Date", "Drug Code", "Drug Name", "Drug Generic Name",
    "Qty", "Rate", "Net Amount", "Gross Amount", "Profit Amount", "Profit Percentage", "Tax Amount", "Discounts",
    "Branch Name", "Branch Address", "Total Amount"];

const tableColoumsn = {
    "Party Code": "c.cmr_code",
    "Party Name": "c.cmr_first_name",
    "Mobile Number": "c.cmr_phone_number",
    "Address": "c.cmr_address",
    "Bill Num": "so.sot_invoice_number",
    "Bill Date": "so.created_date",
    "Drug Code": "oi.item_code",
    "Drug Name": "oi.item_name",
    "Drug Generic Name": "oi.item_generic_name",
    "Qty": "oi.item_quantity",
    "Rate": "oi.item_unit_price",
    "Net Amount": "so.sot_sub_total",
    "Gross Amount": "so.sot_total_amount",
    "Profit Amount": "so.sot_profit_amount",
    "Profit Percentage": "so.sot_profit_percentage",
    "Tax Amount": "oi.item_tax_amount",
    "Discounts": "oi.item_discount_amount",
    "Branch Name": "si.firm_name",
    "Branch Address": "si.firm_address",
    "Total Amount": "oi.item_total_amount"
}


export default PartyWiseProfitReport;