import { call, takeLatest } from "redux-saga/effects";
import {
  dashboardExpStockApi,
  dashboardLowStockApi,
  dashboardNewCusGraphApi,
  dashboardSalesApi,
  dashboardTopSellingProductsApi,
} from "../apis/dashboard-api";
import {
  DASHBOARD_EXPIRATION,
  DASHBOARD_LOW_STOCK,
  DASHBOARD_NEW_CUS_GRAPH,
  DASHBOARD_SALES,
  DASHBOARD_TOP_SELLING_PRODUCTS,
} from "../types";

export function* dashboardSalesWorker(action) {
  try {
    const res = yield call(dashboardSalesApi, action.data);

    if (res.data?.code === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* dashboardNewCusGraphWorker(action) {
  try {
    const res = yield call(dashboardNewCusGraphApi, action.data);

    if (res.data?.code === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* dashboardTopSellingProductsWorker(action) {
  try {
    const res = yield call(dashboardTopSellingProductsApi, action.data);

    if (res.data?.code === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* dashboardLowStockWorker(action) {
  try {
    const res = yield call(dashboardLowStockApi);

    if (res.data?.code === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* dashboardExpStockWorker(action) {
  try {
    const res = yield call(dashboardExpStockApi);

    if (res.data?.code === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}

export function* dashboardExpStockWatcher() {
  yield takeLatest(DASHBOARD_EXPIRATION, dashboardExpStockWorker);
}
export function* dashboardLowStockWatcher() {
  yield takeLatest(DASHBOARD_LOW_STOCK, dashboardLowStockWorker);
}

export function* dashboardTopSellingProductsWatcher() {
  yield takeLatest(
    DASHBOARD_TOP_SELLING_PRODUCTS,
    dashboardTopSellingProductsWorker
  );
}
export function* dashboardSalesWatcher() {
  yield takeLatest(DASHBOARD_SALES, dashboardSalesWorker);
}
export function* dashboardNewCusGraphWatcher() {
  yield takeLatest(DASHBOARD_NEW_CUS_GRAPH, dashboardNewCusGraphWorker);
}
