import { call, takeLatest } from "redux-saga/effects";
import {
  updatePermissionsApi,
  userCreateApi,
  userDeleteApi,
  userEditApi,
  userPermissionsApi,
  userTableApi,
  userViewApi,
} from "../apis/user-api";
import {
  UPDATE_PERMISSIONS,
  USER_CREATE,
  USER_DELETE,
  USER_EDIT,
  USER_PERMISSIONS,
  USER_TABLE,
  USER_VIEW,
} from "../types";

export function* userCreateWorker(action) {
  try {
    const res = yield call(userCreateApi, action.data);

    if (res.data?.code === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}

export function* userTableWorker(action) {
  try {
    const res = yield call(userTableApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}

export function* userViewWorker(action) {
  try {
    const res = yield call(userViewApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}

export function* userDeleteWorker(action) {
  try {
    const res = yield call(userDeleteApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* userEditWorker(action) {
  try {
    const res = yield call(userEditApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* userPermissionsWorker(action) {
  try {
    const res = yield call(userPermissionsApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* updatePermissionsWorker(action) {
  try {
    const res = yield call(updatePermissionsApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}

export function* userCreateWatcher() {
  yield takeLatest(USER_CREATE, userCreateWorker);
}
export function* userTableWatcher() {
  yield takeLatest(USER_TABLE, userTableWorker);
}
export function* userViewWatcher() {
  yield takeLatest(USER_VIEW, userViewWorker);
}
export function* userEditWatcher() {
  yield takeLatest(USER_EDIT, userEditWorker);
}
export function* userDeleteWatcher() {
  yield takeLatest(USER_DELETE, userDeleteWorker);
}
export function* userPermissionsWatcher() {
  yield takeLatest(USER_PERMISSIONS, userPermissionsWorker);
}
export function* updatePermissionsWatcher() {
  yield takeLatest(UPDATE_PERMISSIONS, updatePermissionsWorker);
}
