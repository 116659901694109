import React from "react";

export default function PasswordResetSuccess() {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_1354_13388)">
        <path
          d="M8.25016 13L11.7502 16.5L18.7502 9.49998M25.1668 13C25.1668 19.4433 19.9435 24.6666 13.5002 24.6666C7.05684 24.6666 1.8335 19.4433 1.8335 13C1.8335 6.55666 7.05684 1.33331 13.5002 1.33331C19.9435 1.33331 25.1668 6.55666 25.1668 13Z"
          stroke="#039855"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1354_13388">
          <rect width="28" height="28" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
