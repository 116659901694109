import React from "react";

const SortingIcons = ({inc, dec}) => {
  return (
    <div className="flex flex-col">
      <svg
        width="10"
        height="9"
        viewBox="0 0 7 4"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5.79183 3.58333L3.50016 0.875L1.2085 3.58333"
          stroke={inc}
          strokeWidth="1"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
      <svg
        width="10"
        height="9"
        viewBox="0 0 7 4"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.20817 0.416667L3.49984 3.125L5.7915 0.416667"
          stroke={dec}
          strokeWidth="1"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};

export default SortingIcons;
// #475467