import React, { useCallback, useContext, useEffect, useState } from "react";
import InnerLayout from "../../components/layouts/inner-layout";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { invoiceDeleteAction } from "../../redux/actions/invoices-action";
import DynamicTable from "../../components/tables/dynamic-table";
import LoadingSpinner from "../../components/helper-components/loading-spinner";
import moment from "moment";
import InvoiceFilter from "../../components/tables/table-filter/invoice-filter";
import { DataContext } from "../../components/layouts/main-layout";
import { journalEntryFetchAction } from "../../redux/actions/financial-action";

const JournalTable = () => {
  const { permissionFn } = useContext(DataContext);
  const dispatch = useDispatch();
  const [reload, setReload] = useState(false);
  const [journalEntryList, setJournalEntryList] = useState([]);
  const [dataCount, setDataCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [showFilter, setShowFilter] = useState(false);
  const [pageSize] = useState(10);
  const [filterData, setFilterData] = useState({
    sort_by: "",
    sort_order: "",
    selectColumn: "",
    from: "",
    to: "",
    inputValue: "",
  });
  useEffect(() => {
    dispatch(
      journalEntryFetchAction(
        {
          pageNumber: currentPage,
          pageSize: pageSize,
          filterData,
        },
        onSuccess,
        onError
      )
    );
  }, [currentPage, filterData, reload]);
  const onSuccess = (data) => {
    setError(false);
    setLoading(false);
    setJournalEntryList(data?.data?.journalentryList ?? []);
    setDataCount(data?.data?.totalCount);
  };
  const onError = (data) => {
    setError(true);
    setLoading(false);
  };

  const navigate = useNavigate();
  const tableBodyData = journalEntryList?.map((item) => {
    return {
      id: item?.transcation_id,
      journal_entry_no:
        (
          <span className="fw_500 cursor-pointer">
            {item?.journal_entry_no ?? "--"}
          </span>
        ) ?? "--",
      origin_id: item?.origin_id ?? "--",
      origin_type: item?.origin_type ?? "--",

      created_date: moment(item?.created_date).format("MMM D, YYYY"),
      update_date: moment(item?.update_date).format("MMM D, YYYY"),
    };
  });
  const paginationProps = {
    pageSize: pageSize,
    dataCount: dataCount,
    setCurrentPage: setCurrentPage,
    currentPage: currentPage,
  };
  const handleFilterClick = useCallback(() => {
    setShowFilter((prevShowFilter) => !prevShowFilter);
  }, []);

  const filterby = showFilter ? (
    <InvoiceFilter
      setCurrentPage={setCurrentPage}
      filterData={filterData}
      setFilterData={setFilterData}
      setShowFilter={setShowFilter}
    />
  ) : null;

  const handleVoidAction = (id) => {
    setLoading(true);
    dispatch(
      invoiceDeleteAction(
        {
          sot_id: id,
        },
        onSuccessDel,
        onErrorDel
      )
    );
  };

  const onSuccessDel = () => {
    setReload(!reload);
    setLoading(false);
  };
  const onErrorDel = () => {};

  return (
    <InnerLayout
      permissionFn={permissionFn}
      module="invoice"
      list={true}
      title="Journal"
    >
      {loading ? (
        <LoadingSpinner />
      ) : error ? (
        <></>
      ) : (   
        <>
          <DynamicTable
            onClickItemFn={(id) => navigate(`/journal-entry/${id}`)}
            list={false}
            filter={false}
            menu={false}
            permissionFn={permissionFn}
            module="invoice"
            voidFn={handleVoidAction} // Pass the function to handle void action
            showFilter={showFilter}
            filterFn={handleFilterClick}
            setFilterData={setFilterData}
            filterData={filterData}
            paginationProps={paginationProps}
            tableHeading={tableHeading}
            tableBodyData={tableBodyData}
            placeholder="Search"
            filterBy={filterby}
            initialFilter={{
              sort_by: "",
              sort_order: "",
              selectColumn: "",
              from: "",
              to: "",
              inputValue: "",
              order_status: "",
              payment_status: "",
            }}
          />
        </>
      )}
    </InnerLayout>
  );
};

export default JournalTable;
const tableHeading = [
  {
    label: "JOURNAL ENTRY NUMBER",
    value: "journal_entry_no",
    display: true,
    column: true,
  },
  {
    label: "ORIGIN ID",
    value: "origin_id",
    display: true,
    column: true,
  },
  {
    label: "ORIGIN TYPE",
    value: "origin_type",
    display: true,
    column: true,
  },
  {
    label: "CREATED AT",
    value: "created_date",
    display: true,
    column: false,
  },
  {
    label: "UPDATED DATE",
    value: "update_date",
    display: true,
    column: false,
  },
  {
    label: "",
    value: "",
    display: false,
    column: false,
  },
];
