import React from "react";

const ChartOfAccIcon = () => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 16 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.4 0.399994H1.6C0.72 0.399994 0 1.11999 0 1.99999V18C0 18.88 0.72 19.6 1.6 19.6H14.4C15.28 19.6 16 18.88 16 18V1.99999C16 1.11999 15.28 0.399994 14.4 0.399994ZM4.8 16.4C4.8 16.84 4.44 17.2 4 17.2H2.4C1.96 17.2 1.6 16.84 1.6 16.4V16C1.6 15.56 1.96 15.2 2.4 15.2H4C4.44 15.2 4.8 15.56 4.8 16V16.4ZM4.8 13.2C4.8 13.64 4.44 14 4 14H2.4C1.96 14 1.6 13.64 1.6 13.2V12.8C1.6 12.36 1.96 12 2.4 12H4C4.44 12 4.8 12.36 4.8 12.8V13.2ZM4.8 9.99999C4.8 10.44 4.44 10.8 4 10.8H2.4C1.96 10.8 1.6 10.44 1.6 9.99999V9.59999C1.6 9.15999 1.96 8.79999 2.4 8.79999H4C4.44 8.79999 4.8 9.15999 4.8 9.59999V9.99999ZM9.6 16.4C9.6 16.84 9.24 17.2 8.8 17.2H7.2C6.76 17.2 6.4 16.84 6.4 16.4V16C6.4 15.56 6.76 15.2 7.2 15.2H8.8C9.24 15.2 9.6 15.56 9.6 16V16.4ZM9.6 13.2C9.6 13.64 9.24 14 8.8 14H7.2C6.76 14 6.4 13.64 6.4 13.2V12.8C6.4 12.36 6.76 12 7.2 12H8.8C9.24 12 9.6 12.36 9.6 12.8V13.2ZM9.6 9.99999C9.6 10.44 9.24 10.8 8.8 10.8H7.2C6.76 10.8 6.4 10.44 6.4 9.99999V9.59999C6.4 9.15999 6.76 8.79999 7.2 8.79999H8.8C9.24 8.79999 9.6 9.15999 9.6 9.59999V9.99999ZM14.4 16.4C14.4 16.84 14.04 17.2 13.6 17.2H12C11.56 17.2 11.2 16.84 11.2 16.4V16C11.2 15.56 11.56 15.2 12 15.2H13.6C14.04 15.2 14.4 15.56 14.4 16V16.4ZM14.4 13.2C14.4 13.64 14.04 14 13.6 14H12C11.56 14 11.2 13.64 11.2 13.2V12.8C11.2 12.36 11.56 12 12 12H13.6C14.04 12 14.4 12.36 14.4 12.8V13.2ZM14.4 9.99999C14.4 10.44 14.04 10.8 13.6 10.8H12C11.56 10.8 11.2 10.44 11.2 9.99999V9.59999C11.2 9.15999 11.56 8.79999 12 8.79999H13.6C14.04 8.79999 14.4 9.15999 14.4 9.59999V9.99999ZM14.4 6.39999C14.4 6.83999 14.04 7.19999 13.6 7.19999H2.4C1.96 7.19999 1.6 6.83999 1.6 6.39999V3.19999C1.6 2.75999 1.96 2.39999 2.4 2.39999H13.6C14.04 2.39999 14.4 2.75999 14.4 3.19999V6.39999Z"
        fill="white"
      />
    </svg>
  );
};

export default ChartOfAccIcon;
