import { all } from "redux-saga/effects";
import {
  adminCustomerAccountingWatcher,
  adminCustomerAddressWatcher,
  adminCustomerBnkDetailsWatcher,
  adminCustomerContactPersonWatcher,
  adminCustomerCreateWatcher,
  adminCustomerDeleteWatcher,
  adminCustomerPaymentTermsWatcher,
  adminCustomerTableWatcher,
  adminCustomerUpdateAccountingWatcher,
  adminCustomerUpdateAddressWatcher,
  adminCustomerUpdateBnkDetailsWatcher,
  adminCustomerUpdateContactPersonWatcher,
  adminCustomerUpdatePaymentTermsWatcher,
  adminCustomerUpdateWatcher,
  adminCustomerViewWatcher,
} from "../admin-customer-saga";

export default function* adminCustomerRootSaga() {
  yield all([
    adminCustomerCreateWatcher(),
    adminCustomerUpdateWatcher(),
    adminCustomerContactPersonWatcher(),
    adminCustomerBnkDetailsWatcher(),
    adminCustomerPaymentTermsWatcher(),
    adminCustomerAccountingWatcher(),
    adminCustomerAddressWatcher(),
    adminCustomerTableWatcher(),
    adminCustomerViewWatcher(),
    adminCustomerDeleteWatcher(),
    adminCustomerUpdateContactPersonWatcher(),
    adminCustomerUpdateBnkDetailsWatcher(),
    adminCustomerUpdatePaymentTermsWatcher(),
    adminCustomerUpdateAccountingWatcher(),
    adminCustomerUpdateAddressWatcher(),
  ]);
}
