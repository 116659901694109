import { Form, Formik } from "formik";
import React, { useState } from "react";
import * as Yup from "yup";
import AddButton, { ApproveButton } from "../../buttons/button";
import InputField from "../../forms/input-field";
import TabsComponent from "../../helper-components/tabs-component";

import { useDispatch } from "react-redux";
import { StorePaymentCreateAction } from "../../../redux/actions/store-action";
import successToast from "../../helper-components/success-toast";
import errorToast from "../../helper-components/error-toast";
import SelectField from "../../forms/select-field";
import StorePayerInfoTab from "./payer-info";
import StorePayeeInfoTab from "./payee-info";
import StoreGstInfoTab from "./gst-info";
import StoreRemarksInfoTab from "./remarks-info";
import ImageField from "../../forms/images-field";

const StorePaymentTab = ({
  id,
  setStoreData,
  storeData,
  initialValue,
  permissionFn = () => {},
  disabled = false,
}) => {
  const [currentTab, setCurrentTab] = useState("Payer Info");
  const dispatch = useDispatch();
  const initialValues = {
    store_id: id ?? null,
    is_payment_terms_approve: initialValue?.is_payment_terms_approve ?? false,
    store_type: initialValue?.store_type ?? "",
    payment_method: initialValue?.payment_method ?? "",
    amount: initialValue?.amount ?? null,
    transaction_id: initialValue?.transaction_id ?? "",
    remaining_amount: initialValue?.remaining_amount ?? null,
    received_amount: initialValue?.received_amount ?? null,
    payment_recevie_time: initialValue?.payment_recevie_time ?? null,
    payment_receipt: initialValue?.payment_receipt ?? "",
    payer_name_as_per_bank: initialValue?.payer_name_as_per_bank ?? "",
    payer_bank_name: initialValue?.payer_bank_name ?? "",
    payer_bank_account_no: initialValue?.payer_bank_account_no ?? "",
    payer_remaining_amount: initialValue?.payer_remaining_amount ?? "",
    payee_name_as_per_bank: initialValue?.payee_name_as_per_bank ?? "",
    payee_bank_name: initialValue?.payee_bank_name ?? "",
    payee_bank_account_no: initialValue?.payee_bank_account_no ?? "",
    isfc_code: initialValue?.isfc_code ?? "",
    extra_payee: initialValue?.extra_payee ?? "",
    gst_number: initialValue?.gst_number ?? "",
    payment_table_remarks: initialValue?.payment_table_remarks ?? "",
  };

  const validationSchema = Yup.object().shape({
    payer_name_as_per_bank: Yup.string().required("Payer name is required"),
    payer_bank_name: Yup.string().required("Bank name is required"),
    payer_bank_account_no: Yup.string()
      .required("Bank account number is required")
      .matches(/^[0-9]{9,18}$/, "Invalid bank account number"),
    payer_remaining_amount: Yup.number()
      .required("Remaining amount is required")
      .positive("Remaining amount must be positive"),
    payee_name_as_per_bank: Yup.string().required("Payee name is required"),
    payee_bank_name: Yup.string().required("Bank name is required"),
    payee_bank_account_no: Yup.string()
      .required("Bank account number is required")
      .matches(/^[0-9]{9,18}$/, "Invalid bank account number"),
    isfc_code: Yup.string().required("IFSC code is required"),
    extra_payee: Yup.string().required("Required"), // No validation, optional field
    gst_number: Yup.string()
      .required("GST number is required")
      .matches(
        /^([0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1})?$/,
        "Invalid GST number"
      ),
  });

  const onSubmit = (values, actions) => {
    dispatch(StorePaymentCreateAction(values, onSuccess, onError));
  };

  const onSuccess = (data) => {
    setStoreData({
      ...storeData,
      payment: data?.data,
    });
    successToast(data?.data?.message);
  };
  const onError = (data) => {
    errorToast(data?.data?.message);
  };

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ values, setFieldValue, isSubmitting, errors }) => {
        
        
        return (
          <Form style={{ height: "calc(100% - 5rem)" }} className="pt-5">
            <div className="flex flex-col justify-between h-full ">
              <div>
                <div className="w-full 2xl:w-[35%] lg:w-[60%] px-5 xl:px-10">
                  <InputField
                    isDisabled={disabled}
                    type="text"
                    name="store_type"
                    placeholder="Store Type"
                    label="Store Type"
                    isRequired={true}
                    labelWidth="11rem"
                  />
                  <SelectField
                    isDisabled={disabled}
                    placeholder={"Select payment method"}
                    options={paymentTypeList}
                    label={"Choose Payment Method"}
                    id={"payment_method"}
                    name="payment_method"
                    isSearchable={true}
                    errMsgContainerClass="ps-2"
                    labelWidth="11rem"
                  />
                  <InputField
                    isDisabled={disabled}
                    type="text"
                    name="amount"
                    placeholder=""
                    label="Amount"
                    labelWidth="11rem"
                  />
                  <InputField
                    isDisabled={disabled}
                    type="text"
                    name="transaction_id"
                    placeholder=""
                    label="Transaction ID"
                    labelWidth="11rem"
                  />
                  <InputField
                    isDisabled={disabled}
                    type="text"
                    name="remaining_amount"
                    placeholder=""
                    label="Remaining Amount"
                    labelWidth="11rem"
                  />
                  <InputField
                    isDisabled={disabled}
                    type="text"
                    name="received_amount"
                    placeholder=""
                    label="Received Amount"
                    labelWidth="11rem"
                  />
                  <InputField
                    isDisabled={disabled}
                    type="datetime-local"
                    name="payment_recevie_time"
                    placeholder=""
                    label="Payment Receive Time"
                    labelWidth="11rem"
                  />
                  {/* <InputField
                    type="date"
                    name="addres_remarks"
                    placeholder=""
                    label="Payment Receive Date"
                    labelWidth="11rem"
                  /> */}

                  <ImageField
                    isDisabled={disabled}
                    label="Chalan Receipt"
                    isRequired={false}
                    labelWidth="11rem"
                    name="payment_receipt"
                    mainLabel="Payment Receipt"
                  />

                  {permissionFn("store_information")?.approver && (
                    <div className="flex gap-5 my-5">
                      <ApproveButton
                        onclkFn={() => {
                          setFieldValue("is_payment_terms_approve", true);
                        }}
                        type="submit"
                        text={
                          values?.is_payment_terms_approve
                            ? "Approved"
                            : "Approve"
                        }
                      />
                    </div>
                  )}
                </div>
                <TabsComponent
                  handleClickfn={(item) => {
                    setCurrentTab(item);
                  }}
                  itemTabs={itemTabs}
                  setCurrentTab={setCurrentTab}
                  currentTab={currentTab}
                />
                {currentTab === "Payer Info" && (
                  <StorePayerInfoTab disabled={disabled} />
                )}
                {currentTab === "Payee Info" && (
                  <StorePayeeInfoTab disabled={disabled} />
                )}
                {currentTab === "Gst Details" && (
                  <StoreGstInfoTab disabled={disabled} />
                )}
                {currentTab === "Remarks" && (
                  <StoreRemarksInfoTab disabled={disabled} />
                )}
              </div>

              {!disabled && (
                <div
                  style={{
                    bottom: "0",
                  }}
                  className="flex justify-end px-10 mb-5x sticky bg-white py-4 invoice-bottom-container"
                >
                  <AddButton
                    onclkFn={() => {
                      if (Object.keys(errors).length !== 0) {
                        const currentIndex = itemTabs.indexOf(currentTab);
                        let nextTab;

                        if (currentIndex !== -1) {
                          // Go to the next tab based on the current tab index
                          nextTab =
                            currentIndex === itemTabs.length - 1
                              ? itemTabs[0] // Loop back to the first tab if the last tab is reached
                              : itemTabs[currentIndex + 1];

                          setCurrentTab(nextTab);
                        }
                      }
                    }}
                    prefixIcon={false}
                    text="Save"
                    type="submit"
                  />
                </div>
              )}
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default StorePaymentTab;

const paymentTypeList = [{ label: "UPI", value: "upi" }];
const itemTabs = ["Payer Info", "Payee Info", "Gst Details", "Remarks"];
