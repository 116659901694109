import { Field } from "formik";
import React, { Fragment, useState } from "react";
import EyeCloseIcon from "../../assets/icons/eye-close-icon";
import EyeOpenIcon from "../../assets/icons/eye-open-icon";
import StarIcon from "../../assets/icons/req-star";
import ErrorMsg from "./error-msg";
import "./form.css";

export default function LoginInputField({
  isDisabled = false,
  name = "",
  containerClass = "mb-2 position-relative",
  labelClass = "whitespace-nowrap flex fs_14 fw_600 text-secondary",
  placeholder = "Enter your email address",
  label = "Email Address",
  id,
  type = "text",
  errMsgContainerClass = "",
  isContainer = true,
  defaultInputClass = "inputField focus_outline__none",
  unitContainerClass = "flex unit_input__container",
  unitContainerErrorClass = "flex unit_input_container__error",
  isUnit = false,
  unitPosition = "left",
  passwordEyeClass = "absolute end-0 translate-start  cursor_pointer",
  passwordEyeStyle = { top: "35%", right: "5%" },
  isPasswordEye = false,
  autoComplete = "",
  children,
  isRequired = false,
  labelWidth = "10rem",
}) {
  const finalInputClass = defaultInputClass; // replaceInputCSS true means inputClass(prop) will completely replace default class else it will attach with default
  const Container = isContainer ? "div" : Fragment; // isContainer true means a container div will be there
  const UnitContainer = isUnit ? "div" : Fragment; // isContainer true means a container div will be there
  const PasswordEyeContainer = isPasswordEye ? "div" : Fragment;

  const [passInputType, setPassInputType] = useState("password");

  return (
    <Container
      {...(isContainer && containerClass ? { className: containerClass } : {})}
    >
      {" "}
      <Field name={name}>
        {({ field, meta, form }) => (
          <div className="flex flex-col m-0 p-0 w-full">
            {label && (
              <label
                style={{
                  minWidth: labelWidth,
                }}
                {...(id ? { htmlFor: id } : {})}
                className={`${labelClass} flex`}
              >
                {label}{" "}
                {isRequired && (
                  <span className="ml-2">
                    <StarIcon />
                  </span>
                )}
              </label>
            )}
            <UnitContainer
              {...(unitContainerClass && isUnit
                ? {
                    className: `  ${
                      meta.touched && meta.error
                        ? unitContainerErrorClass
                        : unitContainerClass
                    } ${
                      unitPosition === "right" ? "flex-row-reverse" : ""
                    } w-full`,
                  }
                : {})}
            >
              <PasswordEyeContainer
                {...(isPasswordEye
                  ? {
                      className: `relative flex`,
                    }
                  : {})}
              >
                <input
                  {...(isDisabled ? { disabled: true } : {})}
                  {...(autoComplete ? { autoComplete: autoComplete } : {})}
                  type={type === "password" ? passInputType : type}
                  {...field}
                  placeholder={placeholder}
                  {...(id ? { id: id } : {})}
                  className={
                    isUnit
                      ? `inputField border-0 focus_outline__none w-100`
                      : `${
                          meta.touched && meta.error
                            ? `${finalInputClass} inputField_border__error`
                            : `${finalInputClass} inputField_border`
                        } input-field-no-spin-buttons w-full`
                  }
                />
                {isPasswordEye &&
                  (passInputType === "password" ? (
                    <button
                      style={passwordEyeStyle}
                      type="button"
                      className={`${passwordEyeClass} `}
                    >
                      <EyeCloseIcon onClick={() => setPassInputType("text")} />
                    </button>
                  ) : (
                    <button
                      type="button"
                      style={passwordEyeStyle}
                      className={`${passwordEyeClass} border-0`}
                    >
                      {" "}
                      <EyeOpenIcon
                        onClick={() => setPassInputType("password")}
                      />
                    </button>
                  ))}
              </PasswordEyeContainer>
            </UnitContainer>
          </div>
        )}
      </Field>
      <ErrorMsg containerClass={errMsgContainerClass} name={name} />
      {children}
    </Container>
  );
}