import React, {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import InnerLayout from "../../components/layouts/inner-layout";
import emptyInvoice from "../../assets/images/empty-invoice.png";
import AddButton from "../../components/buttons/button";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import DynamicTable from "../../components/tables/dynamic-table";
import LoadingSpinner from "../../components/helper-components/loading-spinner";
import moment from "moment";
import {
  customerDeleteAction,
  customerTableAction,
} from "../../redux/actions/customer-action";
import CustomerFilter from "../../components/tables/table-filter/customer-filter";
import { DataContext } from "../../components/layouts/main-layout";
import { useHotkeys } from "react-hotkeys-hook";

const InvoiceTable = () => {
  const { permissionFn } = useContext(DataContext);

  const dispatch = useDispatch();
  const [customers, setCustomers] = useState([]);
  const [dataCount, setDataCount] = useState(0);
  const [reload, setReload] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [showFilter, setShowFilter] = useState(false);
  const [pageSize] = useState(10);
  const [filterData, setFilterData] = useState({
    sort_by: "",
    sort_order: "",
    selectColumn: "cmr_phone_number",
    from: "",
    to: "",
    inputValue: "",
    customer_status: "",
  });

  useEffect(() => {
    dispatch(
      customerTableAction(
        {
          pageNumber: currentPage,
          pageSize: pageSize,
          filterData,
        },
        onSuccess,
        onError
      )
    );
  }, [currentPage, filterData, reload]);
  const onSuccess = (data) => {
    setError(false);
    setLoading(false);
    setCustomers(data?.data?.customerList);
    setDataCount(data?.data?.totalCount);
  };
  const onError = (data) => {
    setError(true);
    setLoading(false);
  };

  const navigate = useNavigate();

  const tableBodyData = customers?.map((item) => {
    return {
      id: item?.cmr_id,
      cmr_phone_number:
        (
          <span className="cursor-pointer text-secondary fw_500">
            {item?.cmr_phone_number ?? "--"}
          </span>
        ) ?? "--",

      cmr_first_name: item?.cmr_first_name
        ? item?.cmr_first_name + (item?.cmr_last_name ?? "")
        : "--",
      is_corporate_cmr: item?.is_corporate_cmr ? "Corporate" : "--",
      created_date: moment(item?.created_date).format("DD-MM-YYYY"),
      total_sale_amount: {
        label: (
          <span className="flex justify-end pe-5">
            {"₹ " + item?.total_order_value}{" "}
          </span>
        ),
        value: item?.total_order_value,
      }
    };
  });
  const paginationProps = {
    pageSize: pageSize,
    dataCount: dataCount,
    setCurrentPage: setCurrentPage,
    currentPage: currentPage,
  };
  const handleFilterClick = useCallback(() => {
    setShowFilter((prevShowFilter) => !prevShowFilter);
  }, []);

  const filterby = showFilter ? (
    <CustomerFilter
      filterData={filterData}
      setFilterData={setFilterData}
      setShowFilter={setShowFilter}
    />
  ) : null;
  useHotkeys("escape", () => {
    setShowFilter(false);
  });
  console.log(tableBodyData, "dfdfsdfsdfsdfsdf");
  return (
    <InnerLayout
      list={true}
      onclkFn={() => navigate("/add-customer")}
      title="Customers"
      module="customer"
      permissionFn={permissionFn}
    >
      {loading ? (
        <LoadingSpinner />
      ) : error ? (
        <></>
      ) : customers?.length === 0 ? (
        <div className="flex flex-col justify-center items-center h-full">
          <div>
            <img src={emptyInvoice} alt="empty-invoice" />
            <h3 className="flex justify-center text-000000 fw_600 fs_18">
              No customers found{" "}
            </h3>
            <p className="flex justify-center text-6C7184 fw_400 mt-4 fs_14">
              Let’s start by adding your first customer.{" "}
            </p>
            <div className="flex justify-center mt-3">
              <AddButton
                text="Add Customer"
                onclkFn={() => navigate("/add-customer")}
              />
            </div>
          </div>
        </div>
      ) : (
        <>
          <DynamicTable
            onClickItemFn={(id) => navigate(`/view-customer/${id}`)}
            list={true}
            onclkFn={() => {
              navigate("/add-customer");
            }}
            permissionFn={permissionFn}
            module={"customer"} 
            path="customer"
            reload={reload}
            setReload={setReload}
            loading={loading}
            setLoading={setLoading}
            deleteAction={customerDeleteAction}
            label="Customer"
            showFilter={showFilter}
            filterFn={handleFilterClick}
            setFilterData={setFilterData}
            filterData={filterData}
            paginationProps={paginationProps}
            tableHeading={tableHeading}
            tableBodyData={tableBodyData}
            placeholder="Search"
            filterBy={filterby}
            initialFilter={{
              sort_by: "",
              sort_order: "",
              selectColumn: "cmr_phone_number",
              from: "",
              to: "",
              inputValue: "",
              customer_status: "",
            }}
          />
        </>
      )}
    </InnerLayout>
  );
};

export default InvoiceTable;
const tableHeading = [
  {
    label: "CUSTOMER PHONE",
    value: "cmr_phone_number",
    display: false,
    column: true,
  },
  {
    label: "CUSTOMER NAME",
    value: "cmr_first_name",
    display: true,
    column: true,
  },
  {
    label: "TYPE",
    value: "sot_payment_status",
    display: false,
    column: false,
  },
  {
    label: "ACTIVE FROM",
    value: "created_date",
    display: true,
    column: false,
  },
  {
    label: "TOTAL SALE AMOUNT",
    value: "total_order_value",
    display: true,
    column: false,
    className: "justify-end pe-5",
  },
  {
    label: "",
    value: "",
    column: false,
  },
];
