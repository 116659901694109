import React, { useRef, useState } from "react";
import InnerLayout from "../../components/layouts/inner-layout";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import InputField from "../../components/forms/input-field";
import SelectField from "../../components/forms/select-field";
import CheckBox from "../../components/forms/check-box-field";
import {
  binlocationAddAction,
  inventoryCreateAction,
} from "../../redux/actions/inventory-action";
import { useDispatch } from "react-redux";
import successToast from "../../components/helper-components/success-toast";
import errorToast from "../../components/helper-components/error-toast";
import AddButton from "../../components/buttons/button";

function CreateWarehouse() {
  const initialValues = {
    warehouse_code: "",
    warehouse_name: "",
    tax_code: "",
    warehouse_location: "",
    bin_location_enabled: false,
  };

  const validationSchema = Yup.object().shape({
    warehouse_code: Yup.string().required("warehouse code is required"),
    warehouse_name: Yup.string().required("warehouse name is required"),
    tax_code: Yup.string().required("tax code is required"),
    warehouse_location: Yup.string().required("tax location is required"),
  });

  const [warehouseId, setWarehouseId] = useState(null);
  const dispatch = useDispatch();

  const onSubmit = (values, actions) => {
    let actionType = inventoryCreateAction;

    dispatch(actionType(values, onSuccess, onError));
    actions.setSubmitting(false);
  };
  const onSuccess = (data) => {
    setWarehouseId(data?.data?.warehouse_id);
    setCurrentTab("Bin Location");
    successToast("Warehouse created Successfully");
  };
  const onError = (data) => {
    errorToast(data?.message);
  };
  const [currentTab, setCurrentTab] = useState("General");
  const containerRef = useRef(null);

  const handleClick = (item) => {
    setCurrentTab(item);
    const container = containerRef.current;
    const selectedTab = document.getElementById(item);
    if (container && selectedTab) {
      const containerWidth = container.offsetWidth;
      const selectedTabWidth = selectedTab.offsetWidth;
      const selectedTabLeft = selectedTab.offsetLeft;
      const scrollTo =
        selectedTabLeft - (containerWidth - selectedTabWidth) / 2;
      container.scrollTo({
        left: scrollTo,
        behavior: "smooth",
      });
    }
  };

  const itemTabs = ["General", "Bin Location"];

  return (
    <InnerLayout onclkFn={"/create-warehouse"} title="Warehouse Creation">
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ values, setFieldValue, isSubmitting }) => {
          return (
            <Form className="h-full pt-5">
              <div className="flex gap-10 ml-10 mt-5">
                <InputField
                  type="text"
                  name="warehouse_code"
                  placeholder="Enter warehouse code"
                  label="Warehouse Code"
                  isRequired={true}
                  labelWidth="9rem"
                />
                <InputField
                  type="text"
                  name="warehouse_name"
                  placeholder="Enter warehouse name"
                  label="Warehouse Name"
                  isRequired={true}
                  labelWidth="9rem"
                />
              </div>
              <div
                ref={containerRef}
                style={{
                  height: "5rem",
                }}
                className="overflow-x-auto"
              >
                {" "}
                <div className="flex gap-10 relative mt-5">
                  {/* General grey line below all tabs */}
                  <div className="absolute left-0 right-0 top-8 h-1 bg-[#f3f3f3]"></div>

                  {/* Tabs */}
                  {itemTabs.map((item) => (
                    <div
                      key={item}
                      onClick={() => handleClick(item)}
                      className={`cursor-pointer relative group ml-5`}
                    >
                      <span
                        className={`py-3 px-6 fs_14 relative z-5 ${
                          currentTab === item
                            ? "text-primary bg-primary bg-opacity-10 rounded-lg fw_700"
                            : "text-000000 fw_500"
                        }`}
                      >
                        {item}
                      </span>
                      {/* Highlight line for the selected tab */}
                      {currentTab === item && (
                        <span className="absolute top-8 left-0 right-0 h-1 bg-[#277DFE] mx-3"></span>
                      )}
                    </div>
                  ))}
                </div>
              </div>

              {currentTab === "General" && (
                <>
                  <div className="flex flex-wrap mt-3">
                    <div className="w-full lg:w-[30%]  px-5 xl:px-10">
                      <InputField
                        type="text"
                        name="tax_code"
                        placeholder="Enter Tax Code"
                        label="Tax Code"
                        isRequired={true}
                        labelWidth="6rem"
                      />
                    </div>
                    <div className="w-full lg:w-[30%]  px-5 xl:px-10">
                      <SelectField
                        placeholder="select tax location"
                        options={locationList}
                        label="Tax Location"
                        id="warehouse_location"
                        name="warehouse_location"
                        isSearchable={true}
                        isRequired={true}
                        labelWidth="7rem"
                      />
                    </div>
                    <div className="w-full lg:w-[30%]  px-5 xl:px-10 mt-3">
                      <CheckBox isDisabled={false} items={checkboxItems} />
                    </div>
                  </div>
                </>
              )}
              {currentTab === "Bin Location" && (
                <BinLocationTab warehouseId={warehouseId} />
              )}
              {currentTab === "General" && (
                <div
                  style={{
                    bottom: "0",
                    right: "0",
                  }}
                  className="flex justify-end px-10 mb-5x fixed bg-white py-4 invoice-bottom-container"
                >
                  <AddButton prefixIcon={false} text="Save" type="submit" />
                </div>
              )}
            </Form>
          );
        }}
      </Formik>
    </InnerLayout>
  );
}

export default CreateWarehouse;

const locationList = [
  {
    value: "Bengalore",
    label: "Bengalore",
  },
  {
    value: "Chennai",
    label: "Chennai",
  },
  {
    value: "Hyderabad",
    label: "Hyderabad",
  },
];

const checkboxItems = [
  {
    label: "Enable Bin Locations",
    name: "bin_location_enabled",
    isRequired: false,
  },
];

const BinLocationTab = ({ warehouseId }) => {
  const dispatch = useDispatch();
  const initialValues = {
    warehouse_id: warehouseId,
    bin_location_code_separator: null,
    default_bin_location: null,
    auto_alloc_on_issue: null,
    auto_alloc_on_receipt: null,
    enable_receiving_bin_location: false,
    enforce_default_bin_location: false,
    receive_up_to_max_quantity: false,
    receive_up_to_max_weight: false,
  };
  const validationSchema = Yup.object().shape({});

  const onSubmit = (values, actions) => {
    let actionType = binlocationAddAction;

    dispatch(actionType(values, onSuccess, onError));
    actions.setSubmitting(false);
  };
  const onSuccess = (data) => {
    successToast("Bin Locations created Successfully");
  };
  const onError = (data) => {
    errorToast(data?.message);
  };
  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ values, handleSubmit, errors }) => {
        return (
          <>
            <div className="flex flex-wrap mt-3">
              <div className="w-full lg:w-[50%]  px-5 xl:px-10">
                <InputField
                  type="text"
                  name="bin_location_code_separator"
                  placeholder="Enter location"
                  label="Bin Loc. Code Separator"
                  isRequired={false}
                  labelWidth="12rem"
                />
                {/* <InputField
          type="text"
          name="auto_alloc_on_receipt"
          placeholder="Enter location"
          label="No. of Bin Locations"
          isRequired={true}
          labelWidth="12rem"
        /> */}
                <InputField
                  type="text"
                  name="default_bin_location"
                  placeholder="Enter location"
                  label="Default Bin Location"
                  isRequired={false}
                  labelWidth="12rem"
                />
                <SelectField
                  placeholder="Single Choice"
                  options={autoAlloc}
                  label="Auto Alloc. on Issue"
                  id="auto_alloc_on_issue"
                  name="auto_alloc_on_issue"
                  isSearchable={true}
                  isRequired={false}
                  labelWidth="12rem"
                />
                <SelectField
                  placeholder="Default Bin Location"
                  options={receipt}
                  label="Auto Alloc. on Receipt"
                  id="auto_alloc_on_receipt"
                  name="auto_alloc_on_receipt"
                  //   isSearchable={true}
                  isRequired={false}
                  labelWidth="12rem"
                />
              </div>
              <div className="w-full lg:w-[30%]  px-5 xl:px-10">
                <CheckBox
                  conClass="flex flex-wrap justify-between mt-3 gap-14"
                  items={itemoptions}
                />
              </div>
            </div>
            <div
              style={{
                bottom: "0",
                right: "0",
              }}
              className="flex justify-end px-10 mb-5x fixed bg-white py-4 invoice-bottom-container"
            >
              <AddButton
                onclkFn={handleSubmit}
                prefixIcon={false}
                text="Save"
                type="submit"
              />
            </div>
          </>
        );
      }}
    </Formik>
  );
};

const autoAlloc = [
  {
    lable: "Single Choice",
    value: "SingleChoice",
  },
];
const receipt = [
  {
    lable: "Default Bin Location",
    value: "defaultBinLocation",
  },
];

const itemoptions = [
  {
    label: "Enable Receiving Bin Locations",
    name: "enable_receiving_bin_location",
    isRequired: false,
  },
  {
    label: "Enforce Default Bin Loc.",
    name: "enforce_default_bin_location",
    isRequired: false,
  },
  {
    label: "Receive Up to Max. Qty",
    name: "receive_up_to_max_quantity",
    isRequired: false,
  },
  {
    label: "Receive Up to Max. Weight",
    name: "receive_up_to_max_weight",
    isRequired: false,
  },
];
