import { Field, Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import AddButton from "../../buttons/button";
import CheckBox from "../../forms/check-box-field";
import errorToast from "../../helper-components/error-toast";
import successToast from "../../helper-components/success-toast";
import "../discount-group.css";
import { disItemsCreateAction } from "../../../redux/actions/discount-action";
import moment from "moment";
import search from "../../../assets/icons/searchIcon.svg";
import LoadingSpinner from "../../helper-components/loading-spinner";
import DisItemModal from "../../modals/discounts-modals/dis-items-sel-modal";
import "../discount-group.css";
import DeleteIcon from "../../../assets/icons/delete-icon";
import { useHotkeys } from "react-hotkeys-hook";

const ItemsTab = ({
  mastervalues,
  initialData,
  fetchDisGrpFn = () => {},
  loading,
  permissionFn = () => {},
}) => {
  const dispatch = useDispatch();
  const [action, setAction] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(null);
  const initialValues = {
    item_id: "",
    item_group_name: "",
    discount_percentage: "",
    is_active: initialData?.is_active,
    from_date: initialData?.from_date
      ? moment(initialData?.from_date).format("YYYY-MM-DD")
      : null,
    to_date: initialData?.from_date
      ? moment(initialData?.to_date).format("YYYY-MM-DD")
      : null,
    ItemsData:
      initialData?.items?.length > 0
        ? initialData?.items?.map((item) => ({
            item_code: item?.item_code,
            item_name: item?.item_name,
            item_id: item?.item_id,
            discount_percentage: item?.discount_percentage,
          }))
        : [],
  };

  const validationSchema = Yup.object().shape({});
  const innerOnSubmit = (values) => {
    dispatch(
      disItemsCreateAction(
        {
          DiscountGroupData: {
            is_active: values?.is_active,
            discount_type_id: mastervalues?.discount_type_id,
            customer_group_id: mastervalues?.customer_group_id,
            from_date: values?.from_date,
            to_date: values?.to_date,
          },
          ItemsData: values?.ItemsData.map((grp) => ({
            item_id: grp?.item_id,
            discount_percentage: grp?.discount_percentage,
          })),
        },
        onSuccess,
        onError
      )
    );
  };

  const onSuccess = (data) => {
    successToast(data?.message);
    fetchDisGrpFn(
      mastervalues?.discount_type_id,
      mastervalues?.customer_group_id,
      "Items"
    );
  };
  const onError = (data) => {
    errorToast(data?.message);
  };
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        action &&
        currentIndex != null &&
        !event.target.closest(".relative")
      ) {
        setAction(false);
        setCurrentIndex(null);
      }
    };
    document.addEventListener("click", handleOutsideClick);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [action, currentIndex]);
  const discountPercentageRef = useRef(null);
  useHotkeys("escape", () => {
    setIsModalOpen(false);
  });

  return (
    <>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <Formik
          key={JSON.stringify(initialData)}
          enableReinitialize={true}
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={innerOnSubmit}
        >
          {({ values, handleSubmit, setFieldValue }) => {
            
            return (
              <>
                <DisItemModal
                  itemData={values}
                  setFieldValue={setFieldValue}
                  setIsModalOpen={setIsModalOpen}
                  isModalOpen={isModalOpen}
                  mastervalues={mastervalues}
                />
                <div className="flex justify-between flex-col grow">
                  <div>
                    <div className="flex lg:flex-row flex-col whitespace-wrap justify-between px-4">
                      <div
                        style={{ minWidth: "18rem", height: "2.5rem" }}
                        className={`input_border flex items-center rounded-md pb-1`}
                      >
                        <span className="px-2">
                          {" "}
                          <img width={"16px"} src={search} alt="search icon" />
                        </span>
                        <input
                          placeholder={`Search here`}
                          className=" table_search_input w-full rounded-md fs_12 placeholder:text-fs_12"
                          type="text"
                          value={inputValue}
                          onChange={(e) => {
                            setInputValue(e?.target?.value);
                          }}
                        />
                      </div>

                      <div
                        style={{
                          width: "fit-content",
                        }}
                        className="dis-border h-[2.4rem] rounded-md text-344054 fw_600 flex items-center md:flex-wrap"
                      >
                        <button
                          onClick={() => setIsModalOpen(true)}
                          className="px-2"
                        >
                          Add
                        </button>
                        <div className="h-[2.3rem] w-0.5 border-r-D0D5DD border-r-2 mx-2"></div>
                        <button
                          onClick={() => {
                            setFieldValue("ItemsData", []);
                          }}
                          className="px-2"
                        >
                          Clear Table
                        </button>
                        <div className="h-[2.3rem] w-0.5 border-r-D0D5DD border-r-2 mx-2"></div>
                        <button
                          onClick={() =>
                            fetchDisGrpFn(
                              mastervalues?.customer_group_id,
                              mastervalues?.discount_type_id,
                              "Items"
                            )
                          }
                          className="px-2"
                        >
                          Reset
                        </button>
                      </div>
                    </div>
                    <div className="price-tab-container">
                      <table className="price-my-tab h-full">
                        <thead>
                          <tr>
                            {tableHeading.map((heading, index) => (
                              <th
                                key={index}
                                className={`${"price-tab-header"} ${
                                  heading?.label === "Action" &&
                                  "flex justify-end pe-10"
                                } whitespace-nowrap fs_12 fw_500 text-475467 bg-F2F4F5`}
                              >
                                {heading?.label}
                              </th>
                            ))}
                          </tr>
                        </thead>
                        <tbody className="h-full">
                          {values?.ItemsData?.length > 0 ? (
                            <>
                              {values?.ItemsData?.filter((item) =>
                                item?.item_name
                                  .toLowerCase()
                                  .includes(inputValue.toLowerCase())
                              )?.map((rowData, rowIndex) => (
                                <tr key={rowIndex}>
                                  <td
                                    className={`price-tab-cell fs_14 fw_400 text-secondary whitespace-nowrap`}
                                  >
                                    {rowData?.item_code}
                                  </td>
                                  <td
                                    className={`price-tab-cell fs_14 fw_400 text-secondary whitespace-nowrap`}
                                  >
                                    {rowData?.item_name ?? "--"}
                                  </td>

                                  <td
                                    className={`price-tab-cell fs_14 fw_400 text-secondary whitespace-nowrap`}
                                  >
                                    <Field
                                      onKeyDown={(e) => {
                                        if (e.key === "Enter") {
                                          e.preventDefault();
                                        }
                                      }}
                                      ref={discountPercentageRef}
                                      name={`ItemsData[${rowIndex}].discount_percentage`}
                                      type="number" // Set type to 'number' for input field
                                      className="w-20 input-field-no-spin-buttons dis-per-focus  py-2 px-2" // Adjust width as needed
                                    />
                                  </td>
                                  <td
                                    className={`price-tab-cell  fs_14 fw_400 text-secondary whitespace-nowrap`}
                                  >
                                    {" "}
                                    <button
                                      onClick={() => {
                                        setFieldValue(
                                          "ItemsData",
                                          values.ItemsData.filter(
                                            (_, i) => i !== rowIndex
                                          )
                                        );
                                      }}
                                    >
                                      <DeleteIcon />
                                    </button>
                                  </td>
                                </tr>
                              ))}
                              <tr style={{ height: "10rem" }}></tr>
                            </>
                          ) : (
                            <tr
                              style={{
                                height: "20rem",
                              }}
                            >
                              <td
                                colSpan={tableHeading.length + 1}
                                className="text-center text-primary"
                              >
                                No Items found
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div
                    style={{
                      bottom: "0",
                    }}
                    className="flex items-start justify-between px-10 mb-5x sticky bg-white py-4 invoice-bottom-container"
                  >
                    <div
                      style={{
                        width: "fit-content",
                      }}
                      className="dis-border h-[2.8rem] px-4 rounded-md text-344054 fw_600 flex items-center md:flex-wrap"
                    >
                      <CheckBox items={items} />
                      <div className="h-[2.8rem] w-0.5 border-r-D0D5DD border-r-2 mx-2"></div>
                      <span className="mr-2">From :{"  "}</span>
                      <Field name={`from_date`}>
                        {({ field }) => (
                          <input
                            placeholder="  "
                            {...field}
                            type="date"
                            className={`disPerInput w-[7rem]  focus_outline__none input-field-no-spin-buttons`}
                          />
                        )}
                      </Field>
                      <div className="h-[2.8rem] w-0.5 border-r-D0D5DD border-r-2 mx-2"></div>
                      <span className="mr-2">to :{"  "}</span>
                      <Field name={`to_date`}>
                        {({ field }) => (
                          <input
                            placeholder="  "
                            {...field}
                            type="date"
                            className={`disPerInput w-[7rem]  focus_outline__none input-field-no-spin-buttons`}
                          />
                        )}
                      </Field>
                    </div>
                    {permissionFn("discount_groups")?.update && ( <AddButton
                      onclkFn={handleSubmit}
                      prefixIcon={false}
                      text="Save"
                      type="submit"
                    />)}
                   
                  </div>
                </div>
              </>
            );
          }}
        </Formik>
      )}
    </>
  );
};

export default ItemsTab;


const items = [{ label: "Active", name: "is_active", isRequired: false }];
const tableHeading = [
  {
    label: "Item Code",
  },
  {
    label: "Product Name",
  },
  {
    label: "Discount %",
  },
  {
    label: "",
  },
];
