import { STORE_FORGOT_PASSWORD,  STORE_LOGIN,  STORE_RESET_PASSWORD } from "../types";

export const storeLoginAction = (data, onSuccess, onError) => {
  return {
    type: STORE_LOGIN,
    data,
    onSuccess,
    onError,
  };
};
export const forgotPasswordAction = (data, onSuccess, onError) => {
  return {
    type: STORE_FORGOT_PASSWORD,
    data,
    onSuccess,
    onError,
  };
};
export const resetPasswordAction = (data, onSuccess, onError) => {
  return {
    type: STORE_RESET_PASSWORD,
    data,
    onSuccess,
    onError,
  };
};
