import React from "react";

const FilterRadioButtons = ({
  options,
  handleChange = () => {},
  value,
  label,
  name = "",
  className = "flex gap-2 flex-col mt-3 ml-1",
}) => {
  return (
    <div className={className}>
      <label className="text-344054 pb-2 fw_500 fs_14">{label}</label>
      {options?.map((option) => (
        <div key={option?.value} className="flex items-center">
          <input
            type="radio"
            id={option.value + name}
            name={name}
            value={option.value}
            checked={value === option?.value}
            onChange={handleChange}
          />
          <label
            htmlFor={option.value + name}
            className="ml-2 text-344054 fw_500 fs_14 whitespace-nowrap"
          >
            {option.label}
          </label>
        </div>
      ))}
    </div>
  );
};

export default FilterRadioButtons;
