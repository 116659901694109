import React, { useState } from "react";
import TabsComponent from "../../helper-components/tabs-component";
import InnerLayout from "../../layouts/inner-layout";
import { ChevronLeftIcon } from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";
import BillingInvoice from "./tabs/billing-invoice";
import SalesInvoice from "./tabs/sales-invoice";
import PurchaseInvoice from "./tabs/purchase-invoice";
import SalesOrder from "./tabs/sales-order";
import DeliveryNote from "./tabs/delivery-note";
import SalesReturn from "./tabs/sales-return";
import SalesCreditNote from "./tabs/sales-credit-note";
import PurchaseOrder from "./tabs/purchase-order";
import GoodReceiptNote from "./tabs/good-receipt-note";
import PurchaseReturn from "./tabs/purchase-return";
import PurchaseCreditNote from "./tabs/purchase-credit-note";

const PrintPreference = ({

}) => {
    const [currentTab, setCurrentTab] = useState("Billing Invoice");
    const navigate = useNavigate()

    return (
        <InnerLayout
            list={true}
            title="Settings"
            module="print-preference"
        // permissionFn={permissionFn}
        >
            <div className="pl-4 h-full">
                <div className='flex justify-between pt-3'>
                    <div className="pl-2 pb-4 flex items-center gap-2">
                        <button
                            className="flex items-center gap-2"
                            onClick={() => {
                                navigate('/settings')
                            }}
                        >
                            <ChevronLeftIcon className="h-5 w-5 inline-block" />
                            Settings /
                        </button>
                        <span className="cursor-pointer" >{" " + "Print Preference"}</span>
                    </div>
                </div>

                <TabsComponent
                    handleClickfn={(item) => {
                        setCurrentTab(item);
                    }}
                    itemTabs={itemTabs}
                    setCurrentTab={setCurrentTab}
                    currentTab={currentTab}
                />
                {currentTab === "Billing Invoice" && (
                    <BillingInvoice />
                )}
                {currentTab === "Sales Invoice" && (
                    <SalesInvoice />
                )}
                {currentTab === "Sales Order" && (
                    <SalesOrder />
                )}
                {currentTab === "Delivery Note" && (
                    <DeliveryNote />
                )}
                {currentTab === "Sales Return" && (
                    <SalesReturn />
                )}
                {currentTab === "Sales Credit Note" && (
                    <SalesCreditNote />
                )}
                {currentTab === "Purchase Order" && (
                    <PurchaseOrder />
                )}
                {currentTab === "Good Receipt Note" && (
                    <GoodReceiptNote />
                )}
                {currentTab === "Purchase Invoice" && (
                    <PurchaseInvoice />
                )}
                {currentTab === "Purchase Return" && (
                    <PurchaseReturn />
                )}
                  {currentTab === "Purchase Credit Note" && (
                    <PurchaseCreditNote />
                )}
            </div>
        </InnerLayout>
    );
};

export default PrintPreference;

const itemTabs = [
    "Billing Invoice",
    "Sales Invoice",
    "Sales Order",
    "Delivery Note",
    "Sales Return",
    "Sales Credit Note",
    "Purchase Order",
    "Good Receipt Note",
    "Purchase Invoice",
    "Purchase Return",
    "Purchase Credit Note"
];
