import React, { useEffect, useState } from 'react';
import ReportsLayout from '../../../components/reports/reports-layout';
import { purchaseBehaviorReportAction } from '../../../redux/actions/report-action';
import { useDispatch } from 'react-redux';
import moment from 'moment';

const CustomerPurchaseBehaviorReport = () => {

    const dispatch = useDispatch()
    const [groupByOptions, setgroupByOptions] = useState([
        { label: "None", value: '' },
        { label: "Drug Code", value: 'soi.item_code' },
        { label: "Bill Date", value: 'sott.created_date' },
        { label: "Customer Name", value: 'sott.cmr_name' },
        { label: "Customer Code", value: 'sott.cmr_code' },
    ]);

    const [loading, setLoading] = useState(false);
    const [filterOptions, setFilterOptions] = useState(Object.entries(tableColoumsn).map(([label, value]) => ({
        label,
        value
    })));
    const [selectedColumns, setSelectedColumns] = useState(tableHeading);
    const [filters, setFilters] = useState([])
    const [tableData, setTableData] = useState([]);
    const [dateRange, setDateRange] = useState('');
    const [groupBy, setGroupBy] = useState('')

    useEffect(() => {
        const selectedColumnValues = selectedColumns.map(column => tableColoumsn[column]);
        const requestData = {
            selectedColumns: selectedColumnValues,
            filters: filters.map(({ id, ...rest }) => rest),
            filter_by: dateRange,
            group_by: groupBy
        };
        dispatch(purchaseBehaviorReportAction(requestData, onSuccess, onError))
        setLoading(true);

    }, [selectedColumns, filters, dateRange, groupBy]);


    const isIso8601Date = (dateString) => {
        const iso8601Regex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/;
        return iso8601Regex.test(dateString);
    };

    const formatDate = (value) => {
        return moment(value).format('DD-MM-YYYY');
    };

    const transformData = (data) => {
        return data.map(item => {
            let transformedItem = {};
            for (let [key, value] of Object.entries(tableColoumsn)) {
                const rawValue = item[value.split('.').pop()];
                transformedItem[key] = isIso8601Date(rawValue) ? formatDate(rawValue) : rawValue;
            }
            return transformedItem;

        });
    };

    const onSuccess = (data) => {
        console.log("data is", data);
        const transformedData = transformData(data?.data?.result);
        setTableData(transformedData);
        setLoading(false)
    }
    const onError = (data) => {
        console.log("error is", data);
        setLoading(false)
    }

    return (
        <ReportsLayout
            title={"Customer Purchase Behavior Report"}
            tableHeading={selectedColumns}
            tableData={tableData}
            groupByOptions={groupByOptions}
            filterOptions={filterOptions}
            availableColumns={tableHeading}
            selectedColumns={selectedColumns}
            setSelectedColumns={setSelectedColumns}
            setFilters={setFilters}
            loading={loading}
            setDateRange={setDateRange}
            setGroupBy={setGroupBy}
        />
    );
};

const tableHeading = ["Customer Code", "Customer Name", "Bill Date", "Bill Num", "Branch Name", "Branch Code", "Branch Pincode",
    "Drug Code", "Drug Name", "Qty", "Rate", "Tax Amount", "Discounts", "Total Amount", "Net Amount", "Order Status"];

const tableColoumsn = {
    "Customer Code": "sott.cmr_code",
    "Customer Name": "sott.cmr_name",
    "Bill Date": "sott.created_date",
    "Bill Num": "sott.soit_invoice_number",
    "Branch Name": "si.firm_name",
    "Branch Code": "si.firm_address",
    "Branch Pincode": "si.firm_pin_code",
    "Drug Code": "soi.item_code",
    "Drug Name": "soi.item_name",
    "Qty": "soi.item_quantity",
    "Rate": "soi.item_unit_price",
    "Total Amount": "soi.item_total_amount",
    "Net Amount": "sott.soit_sub_total",
    "Tax Amount": "soi.item_tax_amount",
    "Discounts": "soi.item_discount_amount",
    "Order Status": "sott.soit_order_status"
}


export default CustomerPurchaseBehaviorReport;