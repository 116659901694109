import React, { useEffect, useState } from "react";
import InnerLayout from "../../components/layouts/inner-layout";
import AdminCusCreateEditView from "../../components/admin-customer/admin-cus-create-edit-view";
import { useDispatch } from "react-redux";
import { adminCustomerViewAction } from "../../redux/actions/admin-customer-action";

const AdminAddCustomer = () => {
  const [reload, setReload] = useState(false);
  const [customerId, setCustomerId] = useState(null);
  const dispatch = useDispatch();
  const [customerDetails, setCustomerDetails] = useState({});

  useEffect(() => {
    if (customerId) {
      dispatch(adminCustomerViewAction(customerId, onSuccess, onError));
    }
  }, [customerId, reload]);

  const onSuccess = (data) => {
    console.log(data, "customer Details");
    setCustomerDetails(data?.data);
  };
  const onError = (data) => {
    console.log(data);
  };

  return (
    <InnerLayout onclkFn={"/admin/add-customer"} title="Add Customer">
      <AdminCusCreateEditView
        customerId={customerId}
        setCustomerId={setCustomerId}
        reload={reload}
        setReload={setReload}
        customerDetails={customerDetails}
      />
    </InnerLayout>
  );
};

export default AdminAddCustomer;
