import React, { useEffect, useState } from "react";
import InnerLayout from "../../../components/layouts/inner-layout";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import LoadingSpinner from "../../../components/helper-components/loading-spinner";
import { purchaseCreditViewAction } from "../../../redux/actions/purchase-action";
import CreateEditViewPurchaseCredit from "../../../components/purchase-section/purchase-credit-note/create-edit-view-purchase-credit";

const EditPurchaseCredit = () => {
  const { id } = useParams();

  const [orderDetail, setOrderDetail] = useState({});
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    setLoading(true);
    dispatch(purchaseCreditViewAction(id, onSuccPur, onErrPur));
  }, []);

  const onSuccPur = (data) => {
    setOrderDetail(data?.data?.[0]);
    setLoading(false);
  };

  const onErrPur = (data) => {
    setLoading(false);
  };

  return (
    <InnerLayout backBtn={true} title="Purchase Credit Note View">
      {loading ? (
        <LoadingSpinner />
      ) : (
        <CreateEditViewPurchaseCredit
          page="edit"
          disabled={true}
          orderDetail={orderDetail?.itemData}
          vendorDetails={[orderDetail?.orderData]}
        />
      )}
    </InnerLayout>
  );
};

export default EditPurchaseCredit;
