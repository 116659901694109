import React, { useState } from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import ForgotPasswordKey from "../../assets/icons/forgot-pass-key";
import LoginInputField from "../../components/forms/login-input-field";
import { SignInButton } from "../../components/buttons/button";
import LoginMessageIcon from "../../assets/icons/login-message-icon";
import { useNavigate } from "react-router-dom";
import MainContent from "../../components/auth-section/main-content";
import LoginNavigate from "../../components/auth-section/login-navigate";
import { useDispatch } from "react-redux";
import { forgotPasswordAction } from "../../redux/actions/auth-action";
import successToast from "../../components/helper-components/success-toast";
import errorToast from "../../components/helper-components/error-toast";
import LoadingButtonSpinner from "../../components/helper-components/loading-button-spinner";

export default function ForgotPassword() {
  const [emailVerified, setEmailVerified] = useState(false);
  const [email, setEmail] = useState("");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const initialValues = {
    user_email: "",
  };
  const validationSchema = {
    user_email: Yup.string()
      .email("Must be a valid email")
      .required("Email is required"),
  };

  const onSuccess = (data) => {
    setEmailVerified(true);
    successToast(data?.message);
    setLoading(false);
  };
  const onError = (data) => {
    errorToast(data?.data?.message);
    setLoading(false);
  };

  function sendEmailFn(email) {
    dispatch(
      forgotPasswordAction(
        {
          user_email: email,
        },
        onSuccess,
        onError
      )
    );
  }

  const onSubmit = (values, { resetForm }) => {
    setLoading(true);
    setEmail(values.user_email);
    sendEmailFn(values.user_email);
  };
  return emailVerified ? (
    <div className="flex justify-center mt-12">
      <div className="flex flex-col gap-2">
        <MainContent
          heading="Check your email"
          content="We sent a password reset link to "
          logo={<LoginMessageIcon />}
        >
          <span className="fw_800">{email}</span>
        </MainContent>
        <div className="flex flex-col">
          {/* <SignInButton onclkFn={() => {}} text="Open mail app" type="button" /> */}
          <a
            href={`mailto:${email}`}
            className="bg-secondary text-white border_0A111A px-7 py-2 rounded flex justify-center fw_500 fs_14"
          >
            {loading ? <LoadingButtonSpinner /> : "Open mail app"}
          </a>
        </div>
        <div className="flex gap-2 mt-2 fs_14 justify-center">
          <p className="text-344054">Didn't receive the email?</p>
          <button
            onClick={() => {
              sendEmailFn(email);
            }}
            className="text-black underline"
          >
            Click to resend
          </button>
        </div>
        <LoginNavigate
          text="Back to log in"
          onclkFn={() => navigate("/login")}
          isExpanded={true}
        />
      </div>
    </div>
  ) : (
    <div className="flex justify-center mt-12">
      <div className="flex flex-col gap-2 ">
        <MainContent
          heading="Forgot Password?"
          content="No worries, we'll send you reset instructions."
          logo={<ForgotPasswordKey />}
        />
        <div>
          <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            validationSchema={Yup.object(validationSchema)}
            onSubmit={onSubmit}
          >
            {({ values }) => {
              return (
                <Form className="flex flex-col">
                  <LoginInputField
                    type="text"
                    name="user_email"
                    placeholder="Enter your email"
                    label="Email"
                  />
                  <SignInButton
                    loading={loading}
                    text="Sign in"
                    type="submit"
                  />
                </Form>
              );
            }}
          </Formik>
        </div>
        <LoginNavigate
          text="Back to log in"
          onclkFn={() => navigate("/login")}
          isExpanded={true}
        />
      </div>
    </div>
  );
}
