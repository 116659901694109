
import React from "react";

const ColoumnsButton = ({ onClickFn = () => { } }) => {
  return (
    <svg width="45" height="44" viewBox="0 0 45 44" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d_5890_38735)">
        <rect x="2" y="1" width="41" height="40" rx="5" fill="white" />
        <rect x="2.5" y="1.5" width="40" height="39" rx="4.5" stroke="#CECECE" />
        <g clip-path="url(#clip0_5890_38735)">
          <path d="M28.3824 11H16.6176C15.526 11.0013 14.4794 11.4356 13.7075 12.2075C12.9356 12.9794 12.5013 14.026 12.5 15.1176V26.8824C12.5012 27.9741 12.9354 29.0207 13.7074 29.7926C14.4793 30.5646 15.5259 30.9988 16.6176 31H28.3824C29.4741 30.9988 30.5207 30.5646 31.2926 29.7926C32.0646 29.0207 32.4988 27.9741 32.5 26.8824V15.1176C32.4987 14.026 32.0644 12.9794 31.2925 12.2075C30.5206 11.4356 29.474 11.0013 28.3824 11ZM16.6176 12.9005H28.3824C28.9702 12.9011 29.5338 13.1348 29.9495 13.5505C30.3652 13.9662 30.5989 14.5298 30.5995 15.1176V17.2661H14.4005V15.1176C14.4011 14.5298 14.6348 13.9662 15.0505 13.5505C15.4662 13.1348 16.0298 12.9011 16.6176 12.9005ZM14.4005 26.8824V19.1665H19.3081V29.0995H16.6176C16.0298 29.0989 15.4662 28.8652 15.0505 28.4495C14.6348 28.0338 14.4011 27.4702 14.4005 26.8824ZM28.3824 29.0995H21.2086V19.1665H30.5995V26.8824C30.5989 27.4702 30.3652 28.0338 29.9495 28.4495C29.5338 28.8652 28.9702 29.0989 28.3824 29.0995Z" fill="#494949" />
        </g>
      </g>
      <defs>
        <filter id="filter0_d_5890_38735" x="0" y="0" width="45" height="44" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.05 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_5890_38735" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_5890_38735" result="shape" />
        </filter>
        <clipPath id="clip0_5890_38735">
          <rect width="20" height="20" fill="white" transform="translate(12.5 11)" />
        </clipPath>
      </defs>
    </svg>

  );
};

export default ColoumnsButton;
