import React, { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import InnerLayout from "../../components/layouts/inner-layout";
import SelectField from "../../components/forms/select-field";
import InputField from "../../components/forms/input-field";

const columnsData = [
  { id: 1, name: "Column 1" },
  { id: 2, name: "Column 2" },
  { id: 3, name: "Column 3" },
  { id: 4, name: "Column 4" },
  { id: 5, name: "Column 5" },
  { id: 6, name: "Column 6" },
  { id: 7, name: "Column 7" },
  { id: 8, name: "Column 8" },
  { id: 9, name: "Column 9" },
  { id: 10, name: "Column 10" },
  { id: 11, name: "Column 11" },
  { id: 12, name: "Column 12" },
  { id: 13, name: "Column 13" },
  { id: 14, name: "Column 14" },
  { id: 15, name: "Column 15" },
  { id: 16, name: "Column 16" },
  { id: 17, name: "Column 17" },
  { id: 18, name: "Column 18" },
  { id: 19, name: "Column 19" },
  { id: 20, name: "Column 20" },
];

const initialValues = {
  columnName: "",
  condition: "",
  operator: "",
  inputValue: "",
};

const validationSchema = Yup.object().shape({
  columnName: Yup.number().required("Column Name is required"),
  condition: Yup.string().required("Condition is required"),
  operator: Yup.string().required("Operator is required"),
  inputValue: Yup.string().required("Input Value is required"),
});

function SalesReports() {
  const [selectedColumns, setSelectedColumns] = useState([]);
  const [customConditions, setCustomConditions] = useState([]);
  const [editingIndex, setEditingIndex] = useState(null);

  useEffect(() => {
    if (editingIndex !== null) {
      const conditionToEdit = customConditions[editingIndex];
      setSelectedColumns([conditionToEdit.columnName]);
      // Set form values based on the condition being edited
      setFormValues({
        columnName: conditionToEdit.columnName,
        condition: conditionToEdit.condition,
        operator: conditionToEdit.operator,
        inputValue: conditionToEdit.inputValue,
      });
    }
  }, [editingIndex, customConditions]);

  const handleAddCustomCondition = (values) => {
    if (editingIndex !== null) {
      // Update existing entry
      setCustomConditions((prevConditions) => {
        const updatedConditions = [...prevConditions];
        updatedConditions[editingIndex] = values;
        return updatedConditions;
      });
      setEditingIndex(null);
    } else {
      // Add new entry
      setCustomConditions((prevConditions) => [...prevConditions, values]);
    }
    // Reset form values
    setFormValues(initialValues);
  };

  const handleEditCustomCondition = (index) => {
    // Set the editing state
    setEditingIndex(index);
  };

  const handleDeleteCustomCondition = (index) => {
    if (editingIndex === index) {
      // Reset form values when deleting the currently edited entry
      setEditingIndex(null);
      setFormValues(initialValues);
    }
    // Delete the entry
    setCustomConditions((prevConditions) =>
      prevConditions.filter((_, i) => i !== index)
    );
  };

  const handleColumnToggle = (column) => {
    const isSelected = selectedColumns.some((col) => col.id === column.id);

    if (isSelected) {
      setSelectedColumns((prevColumns) =>
        prevColumns.filter((col) => col.id !== column.id)
      );
    } else {
      setSelectedColumns((prevColumns) => [...prevColumns, column]);
    }
  };

  const handleLogSelectedColumns = () => {
    
  };

  const [formValues, setFormValues] = useState(initialValues);

  return (
    <InnerLayout onclkFn={handleLogSelectedColumns} title="Sales Reports">
      <div>
        <h2 className="m-5 mb-0">Select Columns:</h2>
        <div className="mt-5 border p-3 m-3 flex gap-3 rounded-xl border-[#EAECF5] flex-wrap">
          {columnsData.map((column) => (
            <span
              key={column.id}
              className={`cursor-pointer border border-[#D0D5DD] p-1 px-2 rounded-lg shadow-sm hover:shadow-md transition duration-300 ease-in-out ${
                selectedColumns.some((col) => col.id === column.id)
                  ? "bg-38bdf8 text-white"
                  : "bg-white"
              } `}
              onClick={() => handleColumnToggle(column)}
            >
              {column.name}
            </span>
          ))}
        </div>

        <div className="mt-5 ml-5">
          <Formik
            initialValues={formValues}
            validationSchema={validationSchema}
            enableReinitialize={true}
            onSubmit={(values, { resetForm }) => {
              handleAddCustomCondition(values);
              resetForm();
            }}
          >
            <Form>
              <div className="flex gap-3">
                <SelectField
                  name="columnName"
                  placeholder={"Select Column"}
                  options={columnList}
                  id="columnName"
                  isSearchable={true}
                  containerClass="w-1/5"
                />

                <SelectField
                  name="condition"
                  placeholder={"Select Condition"}
                  options={conditionList}
                  id="condition"
                  isSearchable={true}
                  containerClass="w-1/5"
                />
                <InputField
                  type="text"
                  label=" "
                  name="inputValue"
                  placeholder="Input Value"
                  containerClass="w-1/5"
                  labelWidth="0"
                />
                <SelectField
                  name="operator"
                  placeholder={"Select Operator"}
                  options={operatorList}
                  id="operator"
                  isSearchable={true}
                  containerClass="w-1/5"
                />
                <button
                  type="submit"
                  className="bg-primary text-white px-6 py-3 rounded-lg flex justify-center h-fit"
                >
                  {editingIndex !== null ? "Update" : "+"}
                </button>
              </div>
            </Form>
          </Formik>
        </div>

        <div className="mt-5 ml-5">
          <h2 className="mb-2">Filters Applied:</h2>
          <div className="">
            {customConditions.map((condition, index) => (
              <span key={index} className="flex gap-3 mb-3">
                <p className={selectedFilterClasses}>
                  {
                    columnList.find(
                      (column) => column.value === condition.columnName
                    )?.label
                  }
                </p>
                <p className={selectedFilterClasses}>{condition.condition}</p>
                <p className={selectedFilterClasses}>{condition.inputValue}</p>
                <p className={selectedFilterClasses}>{condition.operator}</p>
                <button
                  type="button"
                  onClick={() => handleEditCustomCondition(index)}
                  className="ml-2 text-blue opacity-80 underline cursor-pointer"
                >
                  Edit
                </button>
                <button
                  type="button"
                  onClick={() => handleDeleteCustomCondition(index)}
                  className="ml-2 text-red underline cursor-pointer"
                >
                  Delete
                </button>
              </span>
            ))}
          </div>
        </div>
        <button
          className="bg-primary text-white px-6 py-2 rounded-lg flex justify-center fw_500 fs_16 ml-3"
          onClick={handleLogSelectedColumns}
        >
          Apply
        </button>
      </div>
    </InnerLayout>
  );
}

export default SalesReports;

const columnList = columnsData.map((column) => {
  return {
    label: column.name,
    value: column.id.toString(),
  };
});

const conditionList = [
  { label: "Equals", value: "equals" },
  { label: "Not Equals", value: "not-equals" },
  { label: "Contains", value: "contains" },
  { label: "Does not contain", value: "does-not-contain" },
  { label: "Starts with", value: "starts-with" },
  { label: "Ends with", value: "ends-with" },
  { label: "Is Empty", value: "is-empty" },
  { label: "Is Not Empty", value: "is-not-empty" },
];

const operatorList = [
  { label: "AND", value: "and" },
  { label: "OR", value: "or" },
];

const selectedFilterClasses =
  "border border-[#D0D5DD] p-1 px-2 rounded-lg shadow-sm w-fit ";
