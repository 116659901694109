import React, { useEffect, useState } from "react";
import InnerLayout from "../../../components/layouts/inner-layout";
import LoadingSpinner from "../../../components/helper-components/loading-spinner";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import CreateEditViewDelivery from "../../../components/sales-section/delivery-section/create-edit-view-delivery";
import {
  salesOrderItemsGetAction,
  salesReturnItemsGetAction,
} from "../../../redux/actions/sales-action";

const AddDelivery = () => {
  const { itemId, type } = useParams();
  const itemIdArray = JSON.parse(decodeURIComponent(itemId));

  const allitems = itemIdArray.map((item) => {
    const { cmr_id, ...rest } = item;
    return rest;
  });
  const [orderDetail, setOrderDetail] = useState({});
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    if (type === "return") {
      setLoading(true);
      dispatch(salesReturnItemsGetAction(allitems, onSuccPur, onErrPur));
    } else {
      setLoading(true);
      dispatch(salesOrderItemsGetAction(allitems, onSuccPur, onErrPur));
    }
  }, []);

  const onSuccPur = (data) => {
    setOrderDetail(data?.data);
    setLoading(false);
  };

  const onErrPur = (data) => {
    setLoading(false);
  };

  return (
    <>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <InnerLayout title="Add Delivery">
          <CreateEditViewDelivery
            type={type}
            orderDetail={orderDetail?.itemData}
            vendorDetails={orderDetail?.vendorDetails}
          />
        </InnerLayout>
      )}
    </>
  );
};
export default AddDelivery;
