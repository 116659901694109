import React from "react";

function WerehouseCreation() {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      className=" scale-105"
    >
      <g clipPath="url(#clip0_2025_12851)">
        <path
          d="M13.5 6.94C13.501 6.80119 13.4731 6.6637 13.418 6.53629C13.3629 6.40887 13.2818 6.29434 13.18 6.2L7.00002 0.5L0.820023 6.2C0.718248 6.29438 0.637236 6.40892 0.582143 6.53632C0.52705 6.66372 0.499084 6.8012 0.500023 6.94V12.5C0.500023 12.7652 0.60538 13.0196 0.792916 13.2071C0.980452 13.3946 1.23481 13.5 1.50002 13.5H12.5C12.7652 13.5 13.0196 13.3946 13.2071 13.2071C13.3947 13.0196 13.5 12.7652 13.5 12.5V6.94Z"
          stroke="white"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.00004 13.25C7.00004 13.5 7.25004 13.5 7.25004 13.5H10.249C10.499 13.5 10.499 13.25 10.499 13.25V10.251C10.499 10.001 10.249 10.001 10.249 10.001H7.25004C7.00004 10.001 7.00004 10.251 7.00004 10.251M7.00004 13.25V10.251M7.00004 13.25C7.00004 13.25 7.00004 13.5 6.75004 13.5H3.75104C3.75104 13.5 3.50104 13.5 3.50104 13.25V10.251C3.50104 10.251 3.50104 10.001 3.75104 10.001H6.75004C6.75004 10.001 7.00004 10.001 7.00004 10.251M5.50004 6.50101H8.49904C8.49904 6.50101 8.74904 6.50101 8.74904 6.75101V9.75001C8.74904 9.75001 8.74904 10 8.49904 10H5.50004C5.50004 10 5.25004 10 5.25004 9.75001V6.75101C5.25004 6.75101 5.25004 6.50101 5.50004 6.50101Z"
          stroke="white"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2025_12851">
          <rect width="14" height="14" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default WerehouseCreation;
