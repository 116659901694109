import { all } from "redux-saga/effects";
import {
  addStrAccWatcher,
  addStrBrandSoftwareWatcher,
  addStrDispatchWatcher,
  addStrFollowUpWatcher,
  addStrInfraWatcher,
  addStrOpeningWatcher,
  addStrbrandWatcher,
  storeAddressCreateWatcher,
  storeAddressUpdateWatcher,
  storeAgreementCreateWatcher,
  storeDrugCreateWatcher,
  storeFirmCreateWatcher,
  storePaymentCreateWatcher,
  storePersonalCreateWatcher,
  storeTableWatcher,
  strExeGetWatcher,
  strFollowUpGetWatcher,
} from "../store-saga";

export default function* storeRootSaga() {
  yield all([
    storeAddressCreateWatcher(),
    storePersonalCreateWatcher(),
    storeFirmCreateWatcher(),
    storeAgreementCreateWatcher(),
    storePaymentCreateWatcher(),
    storeDrugCreateWatcher(),
    storeAddressUpdateWatcher(),
    storeTableWatcher(),
    addStrInfraWatcher(),
    addStrbrandWatcher(),
    addStrBrandSoftwareWatcher(),
    addStrAccWatcher(),
    addStrDispatchWatcher(),
    addStrOpeningWatcher(),
    addStrFollowUpWatcher(),
    strFollowUpGetWatcher(),
    strExeGetWatcher(),
  ]);
}
