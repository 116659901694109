import { api } from "./api";

/**Sales Report */
export const overallSalesReportApi = (params) => {
    return api.post(`/report/over-all-sales`, JSON.stringify(params));
};

export const dailySalesReportApi = (params) => {
    return api.post(`/report/daily-sales`, JSON.stringify(params));
};

export const productWiseSalesReportApi = (params) => {
    return api.post(`/report/product-wise-sales`, JSON.stringify(params));
};

export const branchWiseSalesReportApi = (params) => {
    return api.post(`/report/branch-wise-sales`, JSON.stringify(params));
};

export const doctorWiseSalesReportApi = (params) => {
    return api.post(`/report/doctor-wise-sales`, JSON.stringify(params));
};

export const partyWiseSalesReportApi = (params) => {
    return api.post(`/report/party-wise-sales`, JSON.stringify(params));
};

export const paymentModeWiseSalesReportApi = (params) => {
    return api.post(`/report/payment-mode-wise-sales`, JSON.stringify(params));
};

/**Financial & Revenue */
export const partyWiseProfitReportApi = (params) => {
    return api.post(`/report/party-wise-profit`, JSON.stringify(params));
};

export const discountAnalysisReportApi = (params) => {
    return api.post(`/report/discount-analysis`, JSON.stringify(params));
};

/**Sales Return Reports */
export const salesReturnReportApi = (params) => {
    return api.post(`/report/sales-return`, JSON.stringify(params));
};

/**Customer Reports */
export const customerPurchaseFreqReportApi = (params) => {
    return api.post(`/report/customer-purchase-frequency`, JSON.stringify(params));
};

export const customerPurchaseBehaviorReportApi = (params) => {
    return api.post(`/report/customer-purchase-behavior`, JSON.stringify(params));
};

export const bounceReportApi = (params) => {
    return api.post(`/report/bounce`, JSON.stringify(params));
};

export const inventorySalesReportApi = (params) => {
    return api.post(`/report/sales-inventory`, JSON.stringify(params));
};

export const inventoryReportApi = (params) => {
    return api.post(`/report/inventory`, JSON.stringify(params));
};

export const scheduledHDrugRegReportApi = (params) => {
    return api.post(`/report/scheduled-hdrug-reg`, JSON.stringify(params));
};

export const expiryLossReportApi = (params) => {
    return api.post(`/report/expiry-loss`, JSON.stringify(params));
};

export const slowAndFastMovingProductsReportApi = (params) => {
    return api.post(`/report/slow-fast-moving-products`, JSON.stringify(params));
};

// gst report

export const gstR1ReportApi = (params) => {
    return api.post(`/report/slow-fast-moving-products`, JSON.stringify(params));
};
export const gstr20ReportApi = (params) => {
    return api.post(`/report/slow-fast-moving-products`, JSON.stringify(params));
};
export const gstRHsnCodeSummaryReportApi = (params) => {
    return api.post(`/report/slow-fast-moving-products`, JSON.stringify(params));
};
export const gstr3bReportApi = (params) => {
    return api.post(`/report/slow-fast-moving-products`, JSON.stringify(params));
};
export const  gstr9ReportApi = (params) => {
    return api.post(`/report/slow-fast-moving-products`, JSON.stringify(params));
};
export const gstr9cReportApi = (params) => {
    return api.post(`/report/slow-fast-moving-products`, JSON.stringify(params));
};
export const gstItcMismatchReportApi = (params) => {
    return api.post(`/report/slow-fast-moving-products`, JSON.stringify(params));
};
export const gstEWayBillReportApi = (params) => {
    return api.post(`/report/slow-fast-moving-products`, JSON.stringify(params));
};
export const gstPaymentSummaryReportApi = (params) => {
    return api.post(`/report/slow-fast-moving-products`, JSON.stringify(params));
};
export const gstItcAgingReportApi = (params) => {
    return api.post(`/report/slow-fast-moving-products`, JSON.stringify(params));
};

