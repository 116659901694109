import React, { useRef } from "react";

const TabsComponent = ({
  itemTabs = [],
  currentTab,
  handleClickfn = () => {},
}) => {
  const containerRef = useRef(null);
  const handleClick = (item) => {
    const container = containerRef.current;
    const selectedTab = document.getElementById(item);
    if (container && selectedTab) {
      const containerWidth = container.offsetWidth;
      const selectedTabWidth = selectedTab.offsetWidth;
      const selectedTabLeft = selectedTab.offsetLeft;
      const scrollTo =
        selectedTabLeft - (containerWidth - selectedTabWidth) / 2;
      container.scrollTo({
        left: scrollTo,
        behavior: "smooth",
      });
    }
  };
  return (
    <div
      ref={containerRef}
      style={{
        minHeight: "5rem",
      }}
      className="overflow-x-auto mt-4"
    >
      {" "}
      <div className="flex gap-10 relative">
        <div className="absolute left-0 right-0 top-[35px] h-1 bg-[#f3f3f3]"></div>
        {itemTabs.map((item) => (
          <div
            key={item}
            onClick={() => {
              handleClick(item);
              handleClickfn(item);
            }}
            className={`cursor-pointer relative group whitespace-nowrap`}
          >
            <div
              className={`py-2 px-5 mx-1   fs_14 relative z-5 ${
                currentTab === item
                  ? "text-primary bg-primary bg-opacity-10 rounded-lg fw_700 "
                  : "text-000000 fw_500"
              }`}
            >
              {item}
            </div>
            {currentTab === item && (
              <span className="absolute top-[35px] left-0 right-0 h-1 bg-[#277DFE] mx-1"></span>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default TabsComponent;
