import { all } from "redux-saga/effects";
import { bounceReportWatcher, branchWiseSalesReportWatcher, customerPurchaseBehaviorReportWatcher, customerPurchaseFreqReportWatcher, dailySalesReportWatcher, discountAnalysisReportWatcher, doctorWiseSalesReportWatcher, expiryLossReportWatcher, inventoryReportWatcher, inventorySalesReportWatcher, overallSalesReportWatcher, partyWiseProfitReportWatcher, partyWiseSalesReportWatcher, paymentModeWiseSalesReportWatcher, productWiseSalesReportWatcher, salesReturnReportWatcher, scheduledHDrugRegReportWatcher, slowAndFastMovingProductsReportWatcher } from "../report-saga";


export default function* reportRootSaga() {
    yield all([
        overallSalesReportWatcher(),
        dailySalesReportWatcher(),
        branchWiseSalesReportWatcher(),
        productWiseSalesReportWatcher(),
        doctorWiseSalesReportWatcher(),
        paymentModeWiseSalesReportWatcher(),
        partyWiseSalesReportWatcher(),

        partyWiseProfitReportWatcher(),
        discountAnalysisReportWatcher(),

        salesReturnReportWatcher(),

        customerPurchaseFreqReportWatcher(),
        customerPurchaseBehaviorReportWatcher(),

        bounceReportWatcher(),
        inventorySalesReportWatcher(),
        inventoryReportWatcher(),
        scheduledHDrugRegReportWatcher(),
        expiryLossReportWatcher(),
        slowAndFastMovingProductsReportWatcher()

    ]);
}