import { api } from "./api";

/**UOM */
export const uomCreateApi = (params) => {
  return api.post(`/settings/uom/add`, JSON.stringify(params));
};

export const uomUpdateApi = (params) => {
  return api.post(`/settings/uom/update`, JSON.stringify(params));
};

export const uomListApi = (params) => {
  return api.get(`/settings/uom/list`, { params: params });
};

/**UOM GROUPS */
export const uomGroupDataListApi = (params) => {
  return api.get(`/settings/uom-group/list`, { params: params });
};
export const uomGroupDataAddApi = (params) => {
  return api.post(`/settings/uom-group/add`, JSON.stringify(params));
};
export const uomGroupDataUpdateApi = (params) => {
  return api.post(`/settings/uom-group/update`, JSON.stringify(params));
};
export const uomGroupDeleteApi = (params) => {
  return api.post(`/settings/uom-group/delete`, JSON.stringify(params));
};
export const uomGroupItemDeleteApi = (params) => {
  return api.post(`/settings/uom-group/item-delete`, JSON.stringify(params));
};
export const uomGroupUomListApi = (params) => {
  return api.get(`/settings/uom-group/uom-list`, { params: params });
};

/**Item Setup */
export const itemTypeAddApi = (params) => {
  return api.post(`/settings/item-type/add`, JSON.stringify(params));
};
export const itemTypeUpdateApi = (params) => {
  return api.post(`/settings/item-type/update`, JSON.stringify(params));
};
export const itemTypeListApi = (params) => {
  return api.get(`/settings/item-type/list`, { params: params });
};
export const itemTypeDeleteApi = (params) => {
  return api.post(`/settings/item-type/delete`, JSON.stringify(params));
};

export const itemCategoryAddApi = (params) => {
  return api.post(`/settings/item-category/add`, JSON.stringify(params));
};
export const itemCategoryUpdateApi = (params) => {
  return api.post(`/settings/item-category/update`, JSON.stringify(params));
};
export const itemCategoryDeleteApi = (params) => {
  return api.post(`/settings/item-category/delete`, JSON.stringify(params));
};
export const itemCategoryListApi = (params) => {
  return api.get(`/settings/item-category/list`, { params: params });
};

export const itemGroupAddApi = (params) => {
  return api.post(`/settings/item-group/add`, JSON.stringify(params));
};
export const itemGroupUpdateApi = (params) => {
  return api.post(`/settings/item-group/update`, JSON.stringify(params));
};
export const itemGroupDeleteApi = (params) => {
  return api.post(`/settings/item-group/delete`, JSON.stringify(params));
};
export const itemGroupListApi = (params) => {
  return api.get(`/settings/item-group/list`, { params: params });
};

/**Manufacturer setup */
export const manufacturerAddApi = (params) => {
  return api.post(`/settings/manufacturer/add`, JSON.stringify(params));
};
export const manufacturerUpdateApi = (params) => {
  return api.post(`/settings/manufacturer/update`, JSON.stringify(params));
};
export const manufacturerListApi = (params) => {
  return api.get(`/settings/manufacturer/list`, { params: params });
};
export const manufacturerDeleteApi = (params) => {
  return api.post(`/settings/manufacturer/delete`, JSON.stringify(params));
};

/**Tax setup */
export const taxTypeAddApi = (params) => {
  return api.post(`/settings/tax-type/add`, JSON.stringify(params));
};
export const taxTypeUpdateApi = (params) => {
  return api.post(`/settings/tax-type/update`, JSON.stringify(params));
};
export const taxTypeDeleteApi = (params) => {
  return api.post(`/settings/tax-type/delete`, JSON.stringify(params));
};
export const taxTypeListApi = (params) => {
  return api.get(`/settings/tax-type/list`, { params: params });
};

export const taxCombinationAddApi = (params) => {
  return api.post(`/settings/tax-combination/add`, JSON.stringify(params));
};
export const taxCombinationUpdateApi = (params) => {
  return api.post(`/settings/tax-combination/update`, JSON.stringify(params));
};
export const taxCombinationListApi = (params) => {
  return api.get(`/settings/tax-combination/list`, { params: params });
};
export const taxCombinationDeleteApi = (params) => {
  return api.post(`/settings/tax-combination/delete`, JSON.stringify(params));
};
export const taxCombinationItemDeleteApi = (params) => {
  return api.post(
    `/settings/tax-combination-item/delete`,
    JSON.stringify(params)
  );
};
export const taxTypeDropDownApi = (params) => {
  return api.get(`/settings/tax-combination/tax-types`, { params: params });
};

/**Shipping Type */
export const shippingTypeAddApi = (params) => {
  return api.post(`/settings/shipping-type/add`, JSON.stringify(params));
};
export const shippingTypeUpdateApi = (params) => {
  return api.post(`/settings/shipping-type/update`, JSON.stringify(params));
};
export const shippingTypeDeleteApi = (params) => {
  return api.post(`/settings/shipping-type/delete`, JSON.stringify(params));
};
export const shippingTypeListApi = (params) => {
  return api.get(`/settings/shipping-type/list`, { params: params });
};

/**Document Numbering */
export const documentNumberingList = (params) => {
  return api.get(`/document/numbering/document/getAll`, { params: params });
};
export const documentNumberingUpdateApi = (params) => {
  return api.post(
    `/settings/document-numbering/update`,
    JSON.stringify(params)
  );
};

export const documentNumberingSeriesList = (params) => {
  return api.get(`/document/numbering/series/get`, { params: params });
};
export const documentNumberingSeriesAddApi = (params) => {
  return api.post(`/document/numbering/series/add`, JSON.stringify(params));
};
export const documentNumberingSeriesUpdateApi = (params) => {
  return api.post(
    `/settings/document-numbering-series/update`,
    JSON.stringify(params)
  );
};

/**Communication */

//SMTP
export const smtpViewApi = (params) => {
  return api.get(`/settings/smtp/view`, { params: params });
};
export const smtpAddApi = (params) => {
  return api.post(`/settings/smtp/add`, JSON.stringify(params));
};
export const smtpUpdateApi = (params) => {
  return api.post(`/settings/smtp/update`, JSON.stringify(params));
};

//Whatsapp
export const whatsappViewApi = (params) => {
  return api.get(`/settings/whatsapp/view`, { params: params });
};
export const whatsappAddApi = (params) => {
  return api.post(`/settings/whatsapp/add`, JSON.stringify(params));
};
export const whatsappUpdateApi = (params) => {
  return api.post(`/settings/whatsapp/update`, JSON.stringify(params));
};
//SMS
export const smsViewApi = (params) => {
  return api.get(`/settings/sms/view`, { params: params });
};
export const smsAddApi = (params) => {
  return api.post(`/settings/sms/add`, JSON.stringify(params));
};
export const smsUpdateApi = (params) => {
  return api.post(`/settings/sms/update`, JSON.stringify(params));
};

/**Payment Config */
//Razorpay
export const razorpayViewApi = (params) => {
  return api.get(`/settings/razorpay/view`, { params: params });
};
export const razorpayAddApi = (params) => {
  return api.post(`/settings/razorpay/add`, JSON.stringify(params));
};
export const razorpayUpdateApi = (params) => {
  return api.post(`/settings/razorpay/update`, JSON.stringify(params));
};

//Stripe
export const stripeViewApi = (params) => {
  return api.get(`/settings/stripe/view`, { params: params });
};
export const stripeAddApi = (params) => {
  return api.post(`/settings/stripe/add`, JSON.stringify(params));
};
export const stripeUpdateApi = (params) => {
  return api.post(`/settings/stripe/update`, JSON.stringify(params));
};

//Phonepe
export const phonepeViewApi = (params) => {
  return api.get(`/settings/phonepe/view`, { params: params });
};
export const phonepeAddApi = (params) => {
  return api.post(`/settings/phonepe/add`, JSON.stringify(params));
};
export const phonepeUpdateApi = (params) => {
  return api.post(`/settings/phonepe/update`, JSON.stringify(params));
};

//Paytm
export const paytmViewApi = (params) => {
  return api.get(`/settings/paytm/view`, { params: params });
};
export const paytmAddApi = (params) => {
  return api.post(`/settings/paytm/add`, JSON.stringify(params));
};
export const paytmUpdateApi = (params) => {
  return api.post(`/settings/paytm/update`, JSON.stringify(params));
};

//PineLabs
export const pineLabsViewApi = (params) => {
  return api.get(`/settings/pine-labs/view`, { params: params });
};
export const pineLabsAddApi = (params) => {
  return api.post(`/settings/pine-labs/add`, JSON.stringify(params));
};
export const pineLabsUpdateApi = (params) => {
  return api.post(`/settings/pine-labs/update`, JSON.stringify(params));
};

/**Print Preference */
export const printPreferenceListApi = (params) => {
  return api.get(`/settings/print-preference/list`, { params: params });
};
export const printPreferenceUpdateApi = (params) => {
  return api.post(`/settings/print-preference/update`, JSON.stringify(params));
};
export const currentPrintPreferenceApi = (params) => {
  return api.get(`/settings/print-preference/current`, { params: params });
};

/**Print Preference */
export const storeDetailsGetApi = (params) => {
  return api.get(`/store/details/getById?store_id=${params}`);
};
export const storeDetailsUpdateApi = (params) => {
  return api.post(`/store/details/update`, JSON.stringify(params));
};

// alternativeItem setup

export const mapAlternativerItemApi = (params) => {
  return api.post(`/product/alternative/add`, JSON.stringify(params));
};
export const alternativeItemsListApi = (params) => {
  return api.get(
    `/product/alternative/getList?pageNumber=${params?.pageNumber}&pageSize=${params?.pageSize}`
  );
};
export const deleteAlternativeTransApi = (params) => {
  return api.post(`/product/alternative/delete`, JSON.stringify(params));
};
