import React from "react";
import InnerLayout from "../../../components/layouts/inner-layout";
import CreateEditViewSales from "../../../components/sales-section/sales-order/create-edit-view-sales";

const AddSales = () => {
  return (
    <>
      <InnerLayout title="Sales order">
        <CreateEditViewSales />
      </InnerLayout>
    </>    
  );
};
export default AddSales;
