import { all } from "redux-saga/effects";
import {
  financialAccAllocUpdateWatcher,
  financialAccListWatcher,
  financialAccountAddWatcher,
  financialAccountDeleteWatcher,
  financialAccountGetWatcher,
  financialAccountUpdateWatcher,
  financialAllAccountWatcher,
  financialPeriodSelectionWatcher,
  journalEntryFetchWatcher,
  journalEntryUpdateWatcher,
  journalEntryViewWatcher,
} from "../financial-saga";

export default function* financialRootSaga() {
  yield all([
    financialAccountAddWatcher(),
    financialAccountGetWatcher(),
    financialAccountDeleteWatcher(),
    financialAccountUpdateWatcher(),
    financialPeriodSelectionWatcher(),
    financialAllAccountWatcher(),
    financialAccListWatcher(),
    financialAccAllocUpdateWatcher(),
    journalEntryFetchWatcher(),
    journalEntryUpdateWatcher(),
    journalEntryViewWatcher(),
  ]);
}
