import React from "react";
import InnerLayout from "../../components/layouts/inner-layout";
import WerehouseCreation from "../../assets/icons/warehouse-creation";
import BinLocationManagementIcon from "../../assets/icons/bin-location-management";
import { Link } from "react-router-dom";

function InventoryBase() {
  return (
    <InnerLayout onclkFn={"/inventory"} title="Inventory Management">
      <div className="p-5 flex flex-wrap gap-10">
        <Link
          className="flex flex-col gap-3 bg-[#F9FAFB] p-8 rounded-lg max-w-96"
          to={"/create-warehouse"}
        >
          <div className="bg-[#277DFE] h-9 flex justify-center items-center w-9 rounded-md mb-5">
            <WerehouseCreation />
          </div>
          <h2 className=" text-lg font-semibold">Warehouse Creation</h2>
          <p className="text-base ">
            Create a new Warehouse with address and bin locations
          </p>
        </Link>
        <Link
          className="flex flex-col gap-3 bg-[#F9FAFB] p-8 rounded-lg max-w-96"
          to={"/bin-location-management"}
        >
          <div className="bg-[#277DFE] h-9 flex justify-center items-center w-9 rounded-md mb-5">
            <BinLocationManagementIcon />
          </div>
          <h2 className=" text-lg font-semibold">Bin Location Management</h2>
          <p className="text-base ">
            End to end bin location management with multiple sub levels
          </p>
        </Link>
      </div>
    </InnerLayout>
  );
}

export default InventoryBase;
