import React from "react";

const PriceListDisIcon = () => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M20 10C20 12.6522 18.9464 15.1957 17.0711 17.0711C15.1957 18.9464 12.6522 20 10 20C7.34784 20 4.8043 18.9464 2.92893 17.0711C1.05357 15.1957 0 12.6522 0 10C0 7.34784 1.05357 4.8043 2.92893 2.92893C4.8043 1.05357 7.34784 0 10 0C12.6522 0 15.1957 1.05357 17.0711 2.92893C18.9464 4.8043 20 7.34784 20 10ZM14.5543 5.44571C14.4714 5.36278 14.3729 5.29699 14.2646 5.25211C14.1563 5.20722 14.0401 5.18412 13.9229 5.18412C13.8056 5.18412 13.6895 5.20722 13.5811 5.25211C13.4728 5.29699 13.3743 5.36278 13.2914 5.44571L5.44571 13.2914C5.36279 13.3743 5.29702 13.4728 5.25214 13.5811C5.20727 13.6895 5.18417 13.8056 5.18417 13.9229C5.18417 14.0401 5.20727 14.1562 5.25214 14.2646C5.29702 14.3729 5.36279 14.4714 5.44571 14.5543C5.52864 14.6372 5.62708 14.703 5.73542 14.7479C5.84376 14.7927 5.95988 14.8158 6.07714 14.8158C6.19441 14.8158 6.31053 14.7927 6.41887 14.7479C6.52721 14.703 6.62565 14.6372 6.70857 14.5543L14.5543 6.70857C14.6372 6.62566 14.703 6.52722 14.7479 6.41888C14.7928 6.31054 14.8159 6.19441 14.8159 6.07714C14.8159 5.95987 14.7928 5.84375 14.7479 5.73541C14.703 5.62707 14.6372 5.52863 14.5543 5.44571ZM6.86143 5.18571C6.41643 5.18571 5.98966 5.36249 5.675 5.67715C5.36035 5.99181 5.18357 6.41858 5.18357 6.86357C5.18357 7.30857 5.36035 7.73534 5.675 8.05C5.98966 8.36465 6.41643 8.54143 6.86143 8.54143C7.30642 8.54143 7.73319 8.36465 8.04785 8.05C8.36251 7.73534 8.53929 7.30857 8.53929 6.86357C8.53929 6.41858 8.36251 5.99181 8.04785 5.67715C7.73319 5.36249 7.30642 5.18571 6.86143 5.18571ZM13.1386 11.4614C12.6936 11.4614 12.2668 11.6382 11.9521 11.9529C11.6375 12.2675 11.4607 12.6943 11.4607 13.1393C11.4607 13.5843 11.6375 14.0111 11.9521 14.3257C12.2668 14.6404 12.6936 14.8171 13.1386 14.8171C13.5836 14.8171 14.0103 14.6404 14.325 14.3257C14.6397 14.0111 14.8164 13.5843 14.8164 13.1393C14.8164 12.6943 14.6397 12.2675 14.325 11.9529C14.0103 11.6382 13.5836 11.4614 13.1386 11.4614Z"
        fill="white"
      />
    </svg>
  );
};

export default PriceListDisIcon;
