import { api } from "./api";

export const productCreateApi = (params) => {
  return api.post(`/item/generalDetails/add`, JSON.stringify(params));
};

export const productTableApi = (params) => {
  const searchParams = params?.filterData?.inputValue
    ? `&searchColumn=${params?.filterData?.selectColumn}&searchValue=${params?.filterData?.inputValue}`
    : "";
  // const itemgroupString = (params?.filterData?.itemgroup ?? [])
  //   .map((item) => encodeURIComponent(item.label))
  //   .join(",");
  return api.get(
    `/itemslist/get/?pageNumber=${params?.pageNumber}&pageSize=${params?.pageSize}&sortBy=${params?.filterData?.sort_by}&sortOrder=${params?.filterData?.sort_order}&fromDate=${params?.filterData?.from}&toDate=${params?.filterData?.to}${searchParams}&filterValue=${params?.filterData?.stock}`,
    JSON.stringify(params)
  );
};

export const productPurchasingCreateApi = (params) => {
  return api.post(`/item/purchasingDetails/update`, JSON.stringify(params));
};
export const productSalesCreateApi = (params) => {
  return api.post(`/item/salesDetails/update`, JSON.stringify(params));
};
export const productInventoryCreateApi = (params) => {
  return api.post(`/item/inventoryDetails/update`, JSON.stringify(params));
};
export const productPlanningCreateApi = (params) => {
  return api.post(`/item/planningDetails/update`, JSON.stringify(params));
};
export const productRestrictionCreateApi = (params) => {
  return api.post(`/item/restrictionDetails/update`, JSON.stringify(params));
};
export const productRemarksCreateApi = (params) => {
  return api.post(`/item/remarks/update`, JSON.stringify(params));
};

export const productViewApi = (params) => {
  return api.get(`/item/getItemById?item_id=${params}`, JSON.stringify(params));
};
export const productEditApi = (params) => {
  return api.post(`/item/generalDetails/update`, JSON.stringify(params));
};
export const productDeleteApi = (params) => {
  return api.post(`/item/delete`, JSON.stringify(params));
};
export const productBarcodeApi = (params) => {
  return api.post(`/item/barcode/update`, JSON.stringify(params));
};
export const productBarcodesApi = (params) => {
  return api.get(`/product/geuombarcode/get?item_id=${params}`);
};
