import { all } from "redux-saga/effects";
import {
  updatePermissionsWatcher,
  userCreateWatcher,
  userDeleteWatcher,
  userEditWatcher,
  userPermissionsWatcher,
  userTableWatcher,
  userViewWatcher,
} from "../user-saga";

export default function* userRootSaga() {
  yield all([
    userCreateWatcher(),
    userTableWatcher(),
    userViewWatcher(),
    userEditWatcher(),
    userDeleteWatcher(),
    userPermissionsWatcher(),
    updatePermissionsWatcher(),
  ]);
}
