import React from "react";
import InnerLayout from "../../../components/layouts/inner-layout";
import CreateEditViewPaymentIn from "../../../components/sales-section/sales-payment-in/create-edit-view-payment-in";

const AddSalesPaymentIn = () => {
  return (
    <>
      <InnerLayout title="Add Payment In">
        <CreateEditViewPaymentIn />
      </InnerLayout>
    </>
  );
};
export default AddSalesPaymentIn;
