import React, { useEffect, useState } from "react";
import InnerLayout from "../../../components/layouts/inner-layout";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import LoadingSpinner from "../../../components/helper-components/loading-spinner";
import GoodsRecComp from "../../../components/purchase-section/goods-receipt/goods-receipt";
import { goodsViewAction } from "../../../redux/actions/purchase-action";

const ViewGoodsReceipt = () => {
  const { id } = useParams();

  const [orderDetail, setOrderDetail] = useState({});
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    setLoading(true);
    dispatch(goodsViewAction(id, onSuccPur, onErrPur));
  }, []);

  const onSuccPur = (data) => {
    setOrderDetail(data?.data?.[0]);
    setLoading(false);
  };

  const onErrPur = (data) => {
    setLoading(false);
  };

  return (
    <InnerLayout backBtn={true} title="Goods Receipt View">
      {loading ? (
        <LoadingSpinner />
      ) : (
        <GoodsRecComp
          page="view"
          disabled={true}
          data={orderDetail}
          orderDetail={orderDetail?.itemData}
          vendorDetails={[orderDetail?.orderData]}
        />
      )}
    </InnerLayout>
  );
};

export default ViewGoodsReceipt;
