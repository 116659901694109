import React, { useCallback, useContext, useEffect, useState } from "react";
import InnerLayout from "../../../components/layouts/inner-layout";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import DynamicTable from "../../../components/tables/dynamic-table";
import LoadingSpinner from "../../../components/helper-components/loading-spinner";
import { productDeleteAction } from "../../../redux/actions/product-action";
import { DataContext } from "../../../components/layouts/main-layout";
import { useHotkeys } from "react-hotkeys-hook";
import moment from "moment";
import PaymentInOutFilter from "../../../components/tables/table-filter/payment-in-out-filter";
import { paymentOutTableAction } from "../../../redux/actions/purchase-action";
import { formatString } from "../../../components/helper-components/helper-function";
import StatusIcons from "../../../assets/icons/check-icon";

const PurchasePaymentOut = () => {
  const { permissionFn } = useContext(DataContext);
  const dispatch = useDispatch();
  const [paymentList, setPaymentList] = useState([]);
  const [dataCount, setDataCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [showFilter, setShowFilter] = useState(false);
  const [reload, setReload] = useState(false);
  const [pageSize] = useState(10);
  const [filterData, setFilterData] = useState({
    sort_by: "",
    sort_order: "",
    selectColumn: "pot_invoice_number",
    inputValue: "",
    pot_order_status: "",
    from: "",
    to: "",
  });

  useEffect(() => {
    dispatch(
      paymentOutTableAction(
        {
          pageNumber: currentPage,
          pageSize: pageSize,
          filterData,
        },
        onSuccess,
        onError
      )
    );
  }, [currentPage, filterData, reload]);
  const onSuccess = (data) => {
    setError(false);
    setLoading(false);
    setPaymentList(data?.data?.orderList);
    setDataCount(data?.data?.totalCount);
  };
  const onError = (data) => {
    setError(true);
    setLoading(false);
  };

  const navigate = useNavigate();

  const tableBodyData = paymentList?.map((item) => {
    return {
      id: item?.pot_id,
      pot_invoice_number:
        <span className="fw_500">{item?.pot_invoice_number ?? "--"}</span> ??
        "--",
      cmr_name: item?.cmr_name ? item?.cmr_name : "--",
      pot_posting_date: item?.pot_posting_date
        ? moment(item?.pot_posting_date).format("MMM D, YYYY")
        : "--",
      pot_order_status: item?.pot_order_status ? (
        <span
          className={`${
            item?.pot_order_status === "paid"
              ? "text-027A48 bg-027A48 bg-opacity-15"
              : "text-B42318 bg-B42318 bg-opacity-15"
          } fw_500 flex items-center gap-2 px-3 py-1 rounded-full w-fit`}
        >
          <StatusIcons status={item?.pot_order_status} />
          {formatString(item?.pot_order_status)}
        </span>
      ) : (
        "--"
      ),
      pot_current_payment: (
        <span className="flex justify-end pe-5">
          {"₹ " + parseFloat(item?.pot_current_payment ?? 0)?.toFixed(2)}
        </span>
      ),
    };
  });
  const paginationProps = {
    pageSize: pageSize,
    dataCount: dataCount,
    setCurrentPage: setCurrentPage,
    currentPage: currentPage,
  };
  const handleFilterClick = useCallback(() => {
    setShowFilter((prevShowFilter) => !prevShowFilter);
  }, []);

  const filterby = showFilter ? (
    <PaymentInOutFilter
      setCurrentPage={setCurrentPage}
      filterData={filterData}
      setFilterData={setFilterData}
      setShowFilter={setShowFilter}
    />
  ) : null;
  useHotkeys("escape", () => {
    setShowFilter(false);
  });

  return (
    <InnerLayout
      list={true}
      module="product"
      permissionFn={permissionFn}
      onclkFn={() => navigate("/add-product")}
      title="Payment Out"
    >
      {loading ? (
        <LoadingSpinner />
      ) : error ? (
        <></>
      ) : (
        <>
          <DynamicTable
            edit={false}
            onClickItemFn={(id) => navigate(`/view-payment-out/${id}`)}
            list={true}
            onclkFn={() => {
              navigate("/add-purchase-payment-out");
            }}
            permissionFn={permissionFn}
            module={"product"}
            path="product"
            id_name="item_id"
            reload={reload}
            setReload={setReload}
            loading={loading}
            setLoading={setLoading}
            deleteAction={productDeleteAction}
            label="Payment In"
            showFilter={showFilter}
            filterFn={handleFilterClick}
            setFilterData={setFilterData}
            filterData={filterData}
            paginationProps={paginationProps}
            tableHeading={tableHeading}
            tableBodyData={tableBodyData}
            placeholder="Search"
            filterBy={filterby}
            initialFilter={{
              sort_by: "",
              sort_order: "",
              selectColumn: "pot_invoice_number",
              inputValue: "",
              pot_order_status: "",
              from: "",
              to: "",
            }}
          />
        </>
      )}
    </InnerLayout>
  );
};

export default PurchasePaymentOut;
const tableHeading = [
  {
    label: "Document Number",
    value: "pot_invoice_number",
    display: true,
    column: true,
  },
  {
    label: "Vendor / Customer Name",
    value: "cmr_name",
    display: true,
    column: true,
  },
  {
    label: "Posting Date",
    value: "pot_posting_date",
    display: true,
    column: true,
  },
  {
    label: "Status",
    value: "pot_order_status",
    display: false,
    column: false,
  },
  {
    label: "Amount",
    value: "pot_current_payment",
    display: true,
    column: false,
    className: "justify-end pe-5",
  },
  {
    label: "",
    value: "",
    column: false,
  },
];
