import React, { useEffect, useRef, useState } from "react";
import {
  uomGroupDeleteAction,
  uomGroupTableListAction,
} from "../../../../redux/actions/settings-action";
import { useDispatch } from "react-redux";
import KibabMenu from "../../../../assets/icons/kibab-menu";
import checkBoxRightArrow from "../../../../assets/icons/checkBoxRightArrow.svg";
import checkBoxDownArrow from "../../../../assets/icons/checkBoxDownArrow.svg";
import AddEditUom from "./add-edit-uom-group";
import EditIcon from "../../../../assets/icons/editIconPencil.svg";
import DeleteIcon from "../../../../assets/icons/delete-icon";
import search from "../../../../assets/icons/searchIcon.svg";
import errorToast from "../../../helper-components/error-toast";
import LoadingSpinner from "../../../helper-components/loading-spinner";
import InnerLayout from "../../../layouts/inner-layout";
import { ChevronLeftIcon } from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";
import successToast from "../../../helper-components/success-toast";
import Select from "react-select";
import { customStyles } from "../../../tables/filtering-section";

const UomGroup = () => {
  const searchRef = useRef();
  const searchFilter = [
    {
      label: "Group Name",
      value: "name",
    },
  ];
  const [isFocused, setIsFocused] = useState(true);
  const [filterData, setFilterData] = useState({
    searchColumn: "name",
    searchValue: "",
  });

  const tableHeading = ["Group Name", "Group Description"];
  const itemTableHeaders = ["Alt Qty", "Alt UOM", "Base Qty", "Base UOM"];
  const [uomGroupData, setUomGroupData] = useState([]);
  const [showItemsOf, setShowItemsOf] = useState(null);
  const [showAddEdit, setShowAddEdit] = useState(false);
  const [editingData, setEditingData] = useState({});
  const [selectedDOtIndex, setSelectedDotIndex] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  //   useEffect(() => {
  //     dispatch(
  //       uomGroupTableListAction(
  //         {},
  //         onFetchUomGroupListSuccess,
  //         onFetchUomGroupListError
  //       )
  //     );
  //     setLoading(true);
  //   }, []);

  useEffect(() => {
    setLoading(true);
    dispatch(
      uomGroupTableListAction(
        filterData,
        onFetchUomGroupListSuccess,
        onFetchUomGroupListError
      )
    );
  }, [filterData]);

  const reload = () => {
    dispatch(
      uomGroupTableListAction(
        {},
        onFetchUomGroupListSuccess,
        onFetchUomGroupListError
      )
    );
    setLoading(true);
  };

  const onFetchUomGroupListSuccess = (data) => {
    setUomGroupData(data?.data?.uomList);
    setLoading(false);
  };

  const onFetchUomGroupListError = (data) => {
    setLoading(false);
    setUomGroupData([]);
    errorToast(data?.message);
  };

  const toogleChange = () => {
    setShowAddEdit(!showAddEdit);
  };

  const onCLickEdit = (data) => {
    setEditingData(data);
    setShowAddEdit(true);
    setSelectedDotIndex(null);
  };

  const onClickDelete = (id) => {
    dispatch(uomGroupDeleteAction({ group_id: id }, onDeletSuccess, onError));
    setSelectedDotIndex(null);
  };
  const onDeletSuccess = (data) => {
    successToast(data?.message);
    reload();
  };

  const onError = (data) => {};

  const componentRef = useRef(null);

  const handleClickOutside = (event) => {
    if (componentRef.current && !componentRef.current.contains(event.target)) {
      setSelectedDotIndex(null);
    }
  };
  const handleSelectChange = (event) => {
    setFilterData({ ...filterData, searchColumn: event?.value });
  };

  return (
    <InnerLayout
      list={true}
      title="Settings"
      module="uom-group-setup"
      // permissionFn={permissionFn}
    >
      <div className="pl-2 mx-3">
        <div
          className="flex flex-col"
          style={{ height: "80vh" }}
          onClick={handleClickOutside}
        >
          <div className="flex justify-between pt-3 items-center px-4 py-3">
            <div className="flex items-center gap-2">
              <button
                className="flex items-center gap-2"
                onClick={() => {
                  navigate("/settings");
                }}
              >
                <ChevronLeftIcon className="h-5 w-5 inline-block" />
                Settings /
              </button>
              <span className="cursor-pointer">{" " + "UOM Group Setup"}</span>
            </div>
            {!showAddEdit && (
              <div className="flex items-start gap-2">
                <div
                  style={{ maxWidth: "30rem", height: "2.6rem" }}
                  className={`w-full  flex items-center rounded-md   ${
                    isFocused ? "border border-primary" : "input_border"
                  }`}
                >
                  <Select
                    className="z-30"
                    styles={customStyles}
                    value={
                      filterData?.searchColumn
                        ? searchFilter?.find(
                            (item) => item?.value === filterData?.searchColumn
                          )
                        : null
                    }
                    onChange={(e) => {
                      handleSelectChange(e);
                      setIsFocused(true);
                      if (searchRef && searchRef?.current) {
                        searchRef?.current?.focus();
                      }
                    }}
                    options={searchFilter}
                    placeholder="Select Column"
                    isSearchable={false}
                  />{" "}
                  <div
                    className={`h-full w-0.5 ${
                      isFocused
                        ? "border-r border-r-primary"
                        : "border-r border-r-D0D5DD"
                    }`}
                  ></div>{" "}
                  <input
                    ref={searchRef}
                    autoFocus={true}
                    onBlur={() => setIsFocused(false)}
                    onFocus={() => setIsFocused(true)}
                    placeholder={`Search`}
                    className=" table_search_input w-full rounded-md fs_14 h-full placeholder:text-fs_14 placeholder:text-gray pl-3"
                    type="text"
                    value={filterData?.searchValue}
                    onChange={(e) => {
                      setFilterData({
                        ...filterData,
                        searchValue: e.target.value,
                      });
                    }}
                  />
                  <span className="px-2">
                    <img
                      style={{
                        height: "25px",
                        width: "25px",
                      }}
                      src={search}
                      alt="search icon"
                    />
                  </span>
                </div>
                <button
                  type="submit"
                  className="bg-primary text-white px-6 py-2 rounded-md flex justify-center fw_500 fs_16 whitespace-nowrap"
                  onClick={() => setShowAddEdit(true)}
                >
                  <span className="mx-1">+ New</span>
                </button>
              </div>
            )}
          </div>

          {loading ? (
            <LoadingSpinner />
          ) : !showAddEdit ? (
            <>
              <div className="w-full">
                <table className="mt-2 w-full">
                  <thead className="sticky top-0 z-10 bg-F9F9FB">
                    <tr>
                      {tableHeading.map((heading) => (
                        <th
                          className="settings-table-header whitespace-nowrap fs_14 fw_500 text-secondary text-start p-6 bg-F9F9FB"
                          key={heading}
                        >
                          {heading}
                        </th>
                      ))}
                      <th className="settings-table-header whitespace-nowrap fs_14 fw_500 text-secondary text-start p-6 bg-F9F9FB"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {uomGroupData?.map((item, index) => (
                      <>
                        <tr key={index}>
                          <td className="setting-table-cell fs_14 fw_400 text-secondary w-50 p-8">
                            <div className="flex gap-2">
                              <button
                                onClick={() =>
                                  setShowItemsOf(
                                    showItemsOf === index ? null : index
                                  )
                                }
                                className="mr-2"
                              >
                                <img
                                  src={
                                    showItemsOf === index
                                      ? checkBoxDownArrow
                                      : checkBoxRightArrow
                                  }
                                  alt="Toggle"
                                />
                              </button>
                              <span className="">{item.name}</span>
                            </div>
                          </td>
                          <td className="setting-table-cell fs_14 fw_400 text-secondary w-50 p-8">
                            {item.description}
                          </td>
                          <td className="setting-table-cell fs_14 fw_400 text-secondary w-50 p-8">
                            <button onClick={() => setSelectedDotIndex(index)}>
                              <KibabMenu />
                            </button>

                            {selectedDOtIndex === index && (
                              <div
                                ref={componentRef}
                                style={{
                                  position: "absolute",
                                  right: "2rem",
                                  boxShadow:
                                    "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                                }}
                                className="bg-white rounded w-52 z-20"
                              >
                                <div className="w-full p-4">
                                  <button
                                    className="w-full flex align-middle gap-2 px-2 py-2"
                                    onClick={() => onCLickEdit(item)}
                                  >
                                    <img src={EditIcon} />
                                    <span className="fs_16">Edit</span>
                                  </button>
                                  <button
                                    className="w-full flex align-middle gap-2 px-2 py-1"
                                    onClick={() =>
                                      onClickDelete(item?.group_id)
                                    }
                                  >
                                    <div className="mt-1">
                                      <DeleteIcon />
                                    </div>
                                    <span className="fs_16">Delete</span>
                                  </button>
                                </div>
                              </div>
                            )}
                          </td>
                        </tr>
                        {showItemsOf === index && (
                          <tr key={`${index}-details`} className="">
                            <td colSpan="4" className="">
                              <div className="hide-scrollbar border-EAECF0 ml-10 border-2 rounded-md">
                                <table className="w-full ">
                                  <thead className="sticky top-0 z-10 bg-F9F9FB">
                                    <tr>
                                      {itemTableHeaders.map((heading) => (
                                        <th
                                          className="settings-table-header whitespace-nowrap fs_14 fw_500 text-secondary bg-F9F9FB text-start p-2"
                                          key={heading}
                                        >
                                          {heading}
                                        </th>
                                      ))}
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {item?.group_items?.map(
                                      (subItem, subIndex) => (
                                        <tr key={subIndex}>
                                          <td className="setting-table-cell fs_14 fw_400 text-secondary p-2 w-24">
                                            {subItem.alt_quantity}
                                          </td>
                                          <td className="setting-table-cell fs_14 fw_400 text-secondary p-2 w-24">
                                            {subItem.alt_uom}
                                          </td>
                                          <td className="setting-table-cell fs_14 fw_400 text-secondary p-2 w-24">
                                            {subItem.base_quantity}
                                          </td>
                                          <td className="setting-table-cell fs_14 fw_400 text-secondary p-2 w-24">
                                            {subItem.base_uom?.name}
                                          </td>
                                        </tr>
                                      )
                                    )}
                                  </tbody>
                                </table>
                              </div>
                            </td>
                          </tr>
                        )}
                      </>
                    ))}
                  </tbody>
                </table>
              </div>
            </>
          ) : (
            <AddEditUom
              editingData={editingData}
              toogleChange={toogleChange}
              reload={reload}
            />
          )}
        </div>
      </div>
    </InnerLayout>
  );
};

export default UomGroup;
