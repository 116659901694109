import React from "react";

const IntoIcon = ({ fill = "black" }) => {
  return (
    <svg
      width="11"
      height="12"
      viewBox="0 0 8 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.01435 7.09923L0.582485 1.66736L1.66323 0.586613L7.0951 6.01848L6.01435 7.09923ZM1.66323 7.09521L0.582486 6.01446L6.01435 0.582596L7.0951 1.66334L1.66323 7.09521Z"
        fill={fill}
      />
    </svg>
  );
};

export default IntoIcon;
