import { call, takeLatest } from "redux-saga/effects";
import {
  addStrAccApi,
  addStrBrandSoftwareApi,
  addStrDispatchApi,
  addStrFollowUpApi,
  addStrInfraApi,
  addStrOpeningApi,
  addStrbrandApi,
  storeAddressCreateApi,
  storeAddressUpdateApi,
  storeAgreementCreateApi,
  storeDrugCreateApi,
  storeFirmCreateApi,
  storePaymentCreateApi,
  storePersonalCreateApi,
  storeTableApi,
  storeViewApi,
  strExeGetApi,
  strFollowUpGetApi,
} from "../apis/store-api";
import {
  ADD_STR_ACCOUNT_DETAILS,
  ADD_STR_BRANDING_DETAILS,
  ADD_STR_BRAND_SFT_DETAILS,
  ADD_STR_DISPATCH_DETAILS,
  ADD_STR_FOLLOW_UP_REMARKS,
  ADD_STR_INFRA_DETAILS,
  ADD_STR_OPENING_DETAILS,
  FOLLOW_UP_REMARKS_GET,
  STORE_ADDRESS_CREATE,
  STORE_ADDRESS_UPDATE,
  STORE_AGREEMENT_CREATE,
  STORE_DRUG_CREATE,
  STORE_EXE_GET,
  STORE_FIRM_CREATE,
  STORE_PAYMENT_CREATE,
  STORE_PERSONAL_CREATE,
  STORE_TABLE,
  STORE_VIEW,
} from "../types";

export function* storeAddressCreateWorker(action) {
  try {
    const res = yield call(storeAddressCreateApi, action.data);
    if (res.data?.code === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* storePersonalCreateWorker(action) {
  try {
    const res = yield call(storePersonalCreateApi, action.data);
    if (res.data?.code === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* storeFirmCreateWorker(action) {
  try {
    const res = yield call(storeFirmCreateApi, action.data);
    if (res.data?.code === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* storeAgreementCreateWorker(action) {
  try {
    const res = yield call(storeAgreementCreateApi, action.data);
    if (res.data?.code === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* storePaymentCreateWorker(action) {
  try {
    const res = yield call(storePaymentCreateApi, action.data);
    if (res.data?.code === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* storeDrugCreateWorker(action) {
  try {
    const res = yield call(storeDrugCreateApi, action.data);
    if (res.data?.code === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* storeAddressUpdateWorker(action) {
  try {
    const res = yield call(storeAddressUpdateApi, action.data);
    if (res.data?.code === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* storeTableWorker(action) {
  try {
    const res = yield call(storeTableApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* storeViewWorker(action) {
  try {
    const res = yield call(storeViewApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}

export function* addStrInfraWorker(action) {
  try {
    const res = yield call(addStrInfraApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* addStrbrandWorker(action) {
  try {
    const res = yield call(addStrbrandApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* addStrBrandSoftwareWorker(action) {
  try {
    const res = yield call(addStrBrandSoftwareApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* addStrAccWorker(action) {
  try {
    const res = yield call(addStrAccApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* addStrDispatchWorker(action) {
  try {
    const res = yield call(addStrDispatchApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* addStrOpeningWorker(action) {
  try {
    const res = yield call(addStrOpeningApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* addStrFollowUpWorker(action) {
  try {
    const res = yield call(addStrFollowUpApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* strFollowUpGetWorker(action) {
  try {
    const res = yield call(strFollowUpGetApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* strExeGetWorker(action) {
  try {
    const res = yield call(strExeGetApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}

export function* strExeGetWatcher() {
  yield takeLatest(STORE_EXE_GET, strExeGetWorker);
}
export function* strFollowUpGetWatcher() {
  yield takeLatest(FOLLOW_UP_REMARKS_GET, strFollowUpGetWorker);
}
export function* addStrFollowUpWatcher() {
  yield takeLatest(ADD_STR_FOLLOW_UP_REMARKS, addStrFollowUpWorker);
}
export function* addStrOpeningWatcher() {
  yield takeLatest(ADD_STR_OPENING_DETAILS, addStrOpeningWorker);
}
export function* addStrDispatchWatcher() {
  yield takeLatest(ADD_STR_DISPATCH_DETAILS, addStrDispatchWorker);
}
export function* addStrAccWatcher() {
  yield takeLatest(ADD_STR_ACCOUNT_DETAILS, addStrAccWorker);
}
export function* addStrBrandSoftwareWatcher() {
  yield takeLatest(ADD_STR_BRAND_SFT_DETAILS, addStrBrandSoftwareWorker);
}
export function* addStrbrandWatcher() {
  yield takeLatest(ADD_STR_BRANDING_DETAILS, addStrbrandWorker);
}
export function* addStrInfraWatcher() {
  yield takeLatest(ADD_STR_INFRA_DETAILS, addStrInfraWorker);
}

export function* storeAddressCreateWatcher() {
  yield takeLatest(STORE_ADDRESS_CREATE, storeAddressCreateWorker);
}

export function* storePersonalCreateWatcher() {
  yield takeLatest(STORE_PERSONAL_CREATE, storePersonalCreateWorker);
}
export function* storeFirmCreateWatcher() {
  yield takeLatest(STORE_FIRM_CREATE, storeFirmCreateWorker);
}
export function* storeAgreementCreateWatcher() {
  yield takeLatest(STORE_AGREEMENT_CREATE, storeAgreementCreateWorker);
}
export function* storePaymentCreateWatcher() {
  yield takeLatest(STORE_PAYMENT_CREATE, storePaymentCreateWorker);
}
export function* storeDrugCreateWatcher() {
  yield takeLatest(STORE_DRUG_CREATE, storeDrugCreateWorker);
}
export function* storeAddressUpdateWatcher() {
  yield takeLatest(STORE_ADDRESS_UPDATE, storeAddressUpdateWorker);
}

export function* storeTableWatcher() {
  yield takeLatest(STORE_TABLE, storeTableWorker);
}
export function* storeViewWatcher() {
  yield takeLatest(STORE_VIEW, storeViewWorker);
}
