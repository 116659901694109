import { Form, Formik } from "formik";
import React from "react";
import FilterRadioButtons from "../../forms/filter-radio-buttons";
import { stockOptions } from "../../helper-components/data";

const ProductFilter = ({ setFilterData, filterData, itemGroup }) => {

  const handlecustomerStatusChange = (e) => {
    setFilterData({
      ...filterData,
      stock: e.target.value,
    });
  };
  return (
    <div
      menuPortalTarget={document.body}
      style={{
        minWidth: "12rem",
        right: "-30px", // Adjust this value as needed
      }}
      className="absolute top-4 right-0 mt-8 mr-8 py-4 border border-EAECF0 border-solid border-1  bg-white  rounded shadow-lg z-10"
    >
      <span className="color_6C7184 fs_12 fw_600 px-5">FILTER BY</span>
      <h className="border border-EAECF0 mt-3" />
      <div className="px-5 mt-3">
        <FilterRadioButtons
          name="filter_value"
          label={"Stock"}
          options={stockOptions}
          handleChange={handlecustomerStatusChange}
          value={filterData?.stock}
        />
      </div>
    </div>
  );
};

export default ProductFilter;
