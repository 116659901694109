import { Formik } from "formik";
import React from "react";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { productRestrictionCreateAction } from "../../redux/actions/product-action";
import AddButton from "../buttons/button";
import CheckBox from "../forms/check-box-field";
import successToast from "../helper-components/success-toast";

const RestrictionsTab = ({
  productId,
  uomList,
  setFormValues,
  formValues,
  setCurrentTab,
  product,
  disabled = false,
  itemGeneralData = {},
}) => {
  const dispatch = useDispatch();
  const initialValues = {
    item_id: product?.item_id ?? productId,
    item_sales_invoice: product?.item_sales_invoice ?? false,
    item_sales_returns: product?.item_sales_returns ?? false,
    item_purchase_request: product?.item_purchase_request ?? false,
    item_discontiue: product?.item_discontiue ?? false,
    item_purchasing_order: product?.item_purchasing_order ?? false,
    item_credit_note: product?.item_credit_note ?? false,
    item_debit_note: product?.item_debit_note ?? false,
    item_stock_in: product?.item_stock_in ?? false,
    item_stock_out: product?.item_stock_out ?? false,
  };
  const validationSchema = Yup.object().shape({});
  const innerOnSubmit = (values) => {
    setFormValues({
      ...formValues,
      restrictions: values,
    });
    dispatch(
      productRestrictionCreateAction(
        {
          itemGeneralData: itemGeneralData,
          itemRestrictionData: values,
        },
        onSuccess,
        onError
      )
    );
  };

  const onSuccess = (data) => {
    successToast(data?.message);
    setCurrentTab("Remarks");
  };
  const onError = (data) => {};

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={formValues?.restrictions ?? initialValues}
        validationSchema={validationSchema}
        onSubmit={innerOnSubmit}
      >
        {({ handleSubmit }) => {
          return (
            <>
              <div className="w-full xl:w-[80%] px-5 xl:px-10 mt-0 pt-0">
                <CheckBox
                  isDisabled={disabled}
                  height="4rem"
                  width="10rem"
                  conClass="flex justify-between flex-wrap "
                  items={restrictionsOptions}
                />
                <CheckBox
                  isDisabled={disabled}
                  height="4rem"
                  width="10rem"
                  conClass="flex justify-between flex-wrap"
                  items={restrictions2Options}
                />
                <CheckBox
                  isDisabled={disabled}
                  height="4rem"
                  width="10rem"
                  conClass="flex justify-between flex-wrap"
                  items={restrictions3Options}
                />
              </div>
              {!disabled && (
                <div
                  style={{
                    bottom: "0",
                  }}
                  className="flex justify-end px-10 mb-5x sticky bg-white py-4 invoice-bottom-container"
                >
                  <AddButton
                    onclkFn={handleSubmit}
                    prefixIcon={false}
                    text="Save"
                    type="submit"
                  />
                </div>
              )}
            </>
          );
        }}
      </Formik>
    </>
  );
};

export default RestrictionsTab;

export const serialBatchesOptns = [
  { label: "Item Group", value: "item_group" },
];
const restrictionsOptions = [
  {
    label: "Sales Invoice",
    name: "item_sales_invoice",
    isRequired: false,
  },
  {
    label: "Sales Returns",
    name: "item_sales_returns",
    isRequired: false,
  },
  {
    label: "Purchase Request",
    name: "item_purchase_request",
    isRequired: false,
  },
  {
    label: "Discontinue",
    name: "item_discontiue",
    isRequired: false,
  },
];
const restrictions2Options = [
  {
    label: "Purchasing Order",
    name: "item_purchasing_order",
    isRequired: false,
  },
  {
    label: "Credit Note",
    name: "item_credit_note",
    isRequired: false,
  },
  {
    label: "Debit Note",
    name: "item_debit_note",
    isRequired: false,
  },
  {
    label: "Stock In",
    name: "item_stock_in",
    isRequired: false,
  },
];
const restrictions3Options = [
  {
    label: "Stock Out",
    name: "item_stock_out",
    isRequired: false,
  },
];
