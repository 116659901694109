import React from "react";
import numberToWords from "number-to-words";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import moment from "moment";
import { formatString } from "../../helper-components/helper-function";

// Font.register({
//   family: "Bitter",
//   fonts: [
//     { src: bitterFontRegular, fontWeight: 400 },
//     { src: bitterFontBold, fontWeight: 700 },
//   ],
// });
const styles = StyleSheet.create({
  page: {
    padding: 20,
  },
  heading: {
    fontSize: 10,
    fontWeight: "bold",
    marginTop: "3px",

    // fontFamily: "Bitter",
  },
  text: {
    fontSize: 10,
    fontWeight: "normal",
    // fontFamily: "Bitter",
    letterSpacing: 1,
  },
  sectionContainer: {
    border: "2 solid black",
    height: "100%",
  },
  table: {
    display: "table",
    width: "100%",
  },
  tableRow: {
    flexDirection: "row",
  },
  tableCell: {
    flex: 1,
  },
  tableCellWithRightBorder: {
    borderRight: "1.5 solid black",
  },
  image: {
    width: 100,
    height: 40,
  },

  itemtable: {
    // fontFamily: "Bitter",
    fontSize: 10,
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 2,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    borderLeftWidth: 0,
  },
  itemtableRow: { margin: "auto", flexDirection: "row" },
  itemtableColHeader: {
    borderStyle: "solid",
    borderWidth: 2,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    padding: 1,
    textAlign: "center",
    fontWeight: "bold",
  },
  itemtableCol: {
    fontWeight: "normal",
    borderStyle: "solid",
    borderWidth: 2,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    padding: 1,
    display: "flex",
    flexDirection: "column",
    textAlign: "center",
    paddingVertical: 2,
  },
  lastCol: {
    borderRightWidth: 0,
  },
  firstCol: {
    borderLeftWidth: 0,
  },
  width1: {
    width: "5%",
  },
  width2: {
    width: "6%",
  },
  width3: {
    width: "10%",
  },
  width4: {
    width: "20%",
  },
  width5: {
    width: "40%",
  },
});

const StockPdfContent = ({ data, storeDetails }) => {
  return (
    <Document>
      <Page wrap size="A4" orientation="landscape" style={styles.page}>
        <View wrap={true} style={styles.sectionContainer}>
          <View style={styles.table}>
            <View style={styles.tableRow}>
              <View
                style={[
                  styles.tableCell,
                  // , styles.tableCellWithRightBorder
                ]}
              >
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    padding: 5,
                    alignItems: "start",
                    justifyContent: "between",
                  }}
                >
                  <View
                    style={{
                      width: "70%",
                    }}
                  >
                    <Text
                      style={{
                        fontSize: "10",
                        fontWeight: "normal",
                        letterSpacing: "2px",
                        marginTop: "3px",
                      }}
                    >
                      WareHouse :
                      <Text> {data?.stocktransferData?.warehouse_name}</Text>
                    </Text>

                    <Text
                      style={{
                        fontSize: "10",
                        fontWeight: "normal",
                        // fontFamily: "Bitter",
                        letterSpacing: "2px",
                        marginTop: "3px",
                      }}
                    >
                      Stock Management Action :
                      <Text>
                        {formatString(
                          data?.stocktransferData?.stock_management_action
                        )}
                      </Text>
                    </Text>
                    <Text
                      style={{
                        fontSize: "10",
                        fontWeight: "normal",
                        // fontFamily: "Bitter",
                        letterSpacing: "2px",
                        marginTop: "3px",
                      }}
                    >
                      Posting Date:{" "}
                      <Text>{data?.stocktransferData?.posting_date}</Text>
                    </Text>
                    <Text
                      style={{
                        fontSize: "10",
                        fontWeight: "normal",
                        // fontFamily: "Bitter",
                        letterSpacing: "2px",
                        marginTop: "3px",
                      }}
                    >
                      Stock Transfer Document Number:{" "}
                      <Text>
                        {" "}
                        {data?.stocktransferData?.stock_transfer_doc_number}
                      </Text>
                    </Text>
                  </View>
                  <View style={{ marginLeft: 10, width: "30%" }}>
                    {/* <Image
                      src={storeDetails?.store_logo}
                      style={styles.image}
                    /> */}
                  </View>
                </View>
                {/* <Text
                  style={{
                    ...styles?.heading,
                    borderTop: "2 solid black",
                    paddingVertical: 5,
                    paddingHorizontal: 5,
                  }}
                >
                  From Ware house :{" "}
                  <Text style={styles?.text}>
                    {data?.stocktransferData?.from_warehouse_name}
                  </Text>
                </Text>
                <Text
                  style={{
                    ...styles?.heading,
                    borderTop: "2 solid black",
                    paddingVertical: 5,
                    paddingHorizontal: 5,
                  }}
                >
                  To Ware house :{" "}
                  <Text style={styles?.text}>
                    {data?.stocktransferData?.to_warehouse_name}
                  </Text>
                </Text> */}
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <Text
                    style={{
                      ...styles?.heading,
                      borderTop: "1 solid black",
                      width: "50%",
                      paddingVertical: 5,
                      paddingHorizontal: 5,
                      borderRight: "1 solid black",
                    }}
                  >
                    From Ware house :{" "}
                    <Text style={styles?.text}>
                      {data?.stocktransferData?.from_warehouse_name}
                    </Text>
                  </Text>
                  <Text
                    style={{
                      ...styles?.heading,
                      width: "50%",
                      borderTop: "1 solid black",
                      paddingVertical: 5,
                      paddingHorizontal: 5,
                    }}
                  >
                    To Ware house :{" "}
                    <Text style={styles?.text}>
                      {" "}
                      {data?.stocktransferData?.to_warehouse_name}
                    </Text>
                  </Text>
                </View>
              </View>
            </View>
          </View>

          <View break style={styles.itemtable}>
            <View style={styles.itemtableRow}>
              <Text
                style={[
                  styles.itemtableColHeader,
                  styles.firstCol,
                  styles?.width1,
                ]}
              >
                SR. No.
              </Text>
              <Text style={[styles.itemtableColHeader, styles?.width5]}>
                Product Name
              </Text>
              <Text style={[styles.itemtableColHeader, styles?.width3]}>
                Item Code
              </Text>
              <Text style={[styles.itemtableColHeader, styles?.width4]}>
                Batch Details
              </Text>
              <Text style={[styles.itemtableColHeader, styles?.width1]}>
                Qty
              </Text>
              <Text style={[styles.itemtableColHeader, styles?.width3]}>
                UOM
              </Text>
              <Text style={[styles.itemtableColHeader, styles?.width3]}>
                Current Avail.
              </Text>
            </View>
            {data?.itemData.map((item, index) => (
              <View key={index} style={styles.itemtableRow}>
                <Text
                  style={[styles.itemtableCol, styles?.width1, styles.firstCol]}
                >
                  {index + 1}
                </Text>
                <Text style={[styles.itemtableCol, styles?.width5]}>
                  {item?.item_name}
                </Text>
                <Text style={[styles.itemtableCol, styles?.width3]}>
                  {item?.item_code}
                </Text>
                <Text style={[styles.itemtableCol, styles?.width4]}>
                  {item?.itemBatchData?.map((batch, batchIndex) => (
                    <View key={batchIndex} style={styles.batchRow}>
                      <Text style={styles.batchCol}>
                        {batch.item_batch_number || "--"}
                      </Text>
                      <Text>
                        {"-"}({" "}
                        <Text style={styles.batchCol}>
                          {batch.from_bin_location || "--"}
                        </Text>
                        {"=>"}
                        <Text style={styles.batchCol}>
                          {batch.to_bin_location || "--"}
                        </Text>
                        ){"-"}
                      </Text>

                      <Text style={styles.batchCol}>
                        {batch.item_batch_quantity || "--"},{" "}
                      </Text>
                    </View>
                  ))}
                </Text>
                <Text style={[styles.itemtableCol, styles?.width1]}>
                  {item?.item_quantity}
                </Text>

                <Text style={[styles.itemtableCol, styles?.width3]}>
                  {item?.item_uom}
                </Text>

                <Text style={[styles.itemtableCol, styles?.width3]}></Text>
              </View>
            ))}
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default StockPdfContent;
