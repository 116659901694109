import React from "react";
import moment from "moment";

const ExpirationProductTable = ({ expStockPro = [] }) => {
  const isExpiringSoon = (expDate) => {
    const currentDate = moment();
    const expirationDate = moment(expDate);
    const diffInDays = expirationDate.diff(currentDate, "days");
    return diffInDays <= 15;
  };
  return (
    <>
      <h2 className="text-center fw_500 text-000000">Expiration</h2>
      <table className="w-full border-D0D5DD border border-1 rounded-lg mt-3">
        <thead>
          <tr className="bg-F6F5F9">
            <th className="py-2 px-3 w-[50%] border-b-D0D5DD border-b  text-left  text-475467 fw_400 fs_12">
              Product Name
            </th>{" "}
            <th className="py-2 px-3 border-b-D0D5DD border-b text-left  text-475467 fw_400 fs_12">
              Batch Number
            </th>
            <th className="py-2 px-3 border-b-D0D5DD border-b text-left  text-475467 fw_400 fs_12">
              Exp Date
            </th>
          </tr>
        </thead>
        <tbody>
          {expStockPro?.map((item) => (
            <tr>
              <td className=" py-4 px-3 text-secondary border-b-D0D5DD border-b fw_400 fs_12">
                {item?.name}
              </td>
              <td className="py-4 px-3 text-secondary border-b-D0D5DD border-b fw_400 fs_12">
                {item?.item_batch_number}
              </td>
              <td className="py-4 px-3 text-secondary border-b-D0D5DD border-b fw_400 fs_12 flex gap-4">
                {moment(item?.item_exp_date).format("YYYY-MM-DD")}
                <div
                  className={`rounded-sm w-[1.8rem] h-[1.1rem] ${
                    isExpiringSoon(item?.item_exp_date) ? "bg-red" : "bg-green"
                  }`}
                ></div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

export default ExpirationProductTable;
