import { Field, useFormikContext } from "formik";
import React, { useEffect, useRef } from "react";
import SelectField from "../../forms/select-field";
import AddButton from "../../buttons/button";
import DeleteIcon from "../../../assets/icons/delete-icon";
import moment from "moment";
import { useDispatch } from "react-redux";
import { stockTransferBatchAction } from "../../../redux/actions/inventory-action";

const BatchQuantityBinPopover = ({
  setAddbatch,
  itemId,
  setItemId,
  disabled = false,
  binLocationList = [],
}) => {
  const batchRef = useRef();
  const today = new Date();
  const sixMonthsLater = new Date(today.setMonth(today.getMonth() + 6));
  const { values, setFieldValue } = useFormikContext();
  const dispatch = useDispatch();
  const defaultSelectedItem = values?.data?.find(
    (item) => itemId === item?.item_id
  );
  const defaultSelectedItemIndex = values?.data?.findIndex(
    (item) => itemId === item?.item_id
  );

  useEffect(() => {
    dispatch(stockTransferBatchAction(itemId, onSuccess, onError));
  }, [itemId]);
  const onSuccess = (data) => {
    setFieldValue(
      `data[${defaultSelectedItemIndex}].itemBatchList`,
      data?.data
    );
  };
  const onError = () => {};

  const selectedBatchNumbers = values.data
    .find((_, index) => index === defaultSelectedItemIndex)
    .item_batches.map((row) => row?.item_batch_number);
  // const selectedFromBins = values.data
  //   .find((_, index) => index === defaultSelectedItemIndex)
  //   .item_batches.map((row) => row?.from_bin_id);
  const selectedFromBins = values.data
    .find((_, index) => index === defaultSelectedItemIndex)
    .item_batches.map((row) => row?.created_date);
  const initData = {
    item_id: defaultSelectedItem?.item_id,
    item_batch_number: "",
    item_batch_quantity: 0,
    from_bin_location: "",
    to_bin_location: "receiving-bin",
    to_bin_id:
      values?.stock_management_action === "stock_in" ||
      values?.stock_management_action === "in_store_transfer"
        ? binLocationList?.find((bin) => bin?.actualLabel === "receiving-bin")
            ?.value
        : "",
    from_bin_id: "",
    created_date: "",
    item_exp_date: moment(sixMonthsLater).format("YYYY-MM-DD"),
  };

  const handleQtyChangeFn = (currentBatchIndex, newValue) => {
    const totalQuantity = values.data[
      defaultSelectedItemIndex
    ]?.item_batches.reduce((acc, batch, index) => {
      if (index === currentBatchIndex) {
        return acc + (parseFloat(newValue) || 0);
      }
      return acc + (parseFloat(batch.item_batch_quantity) || 0);
    }, 0);
    setFieldValue(`data[${defaultSelectedItemIndex}].quantity`, totalQuantity);
  };

  return (
    <div className="absolute w-[-webkit-fill-available]  z-40">
      <div className="h-[72vh] bg-white flex flex-col justify-between  overflow-y-scroll">
        <div>
          <div className="bg-[#F4F6F9] w-full p-3 gap-2 font-semibold text-xl  flex items-center">
            Batch details -
            <SelectField
              isDisabled={disabled}
              handleSelected={(selected) => {
                setItemId(selected?.value);
              }}
              containerClass="w-[20rem]"
              placeholder={"Select product"}
              defaultValue={
                defaultSelectedItem
                  ? {
                      label: defaultSelectedItem.item_name,
                      value: defaultSelectedItem.item_id,
                    }
                  : null
              }
              options={values?.data?.map((item) => ({
                label: item?.item_name,
                value: item?.item_id,
              }))}
              label={""}
              id={"item_id"}
              name="item_id"
              isSearchable={true}
              errMsgContainerClass="hidden"
              labelWidth="10.5rem"
            />
          </div>
          <div className="p-3">
            <table className="lg:w-[80%] w-full border-D0D5DD border border-1 rounded-lg ">
              <thead>
                <tr className="bg-F6F5F9">
                  <th className="py-2 px-1 border-D0D5DD border border-1 text-left  text-secondary fw_400 fs_14 min-w-[10rem]">
                    Batch Nos.
                  </th>{" "}
                  <th className="py-2 px-1 border-D0D5DD border border-1 text-left  text-secondary fw_400 fs_14 min-w-[10rem]">
                    Qty
                  </th>
                  {values?.stock_management_action === "stock_in" && (
                    <th className="py-2 px-1 border-D0D5DD border border-1 text-left  text-secondary fw_400 fs_14 min-w-[10rem]">
                      Exp. Date
                    </th>
                  )}
                  <th className="py-2 px-1 border-D0D5DD border border-1 text-left  text-secondary fw_400 fs_14 min-w-[10rem]">
                    From Bin
                  </th>
                  <th className="py-2 px-1 border-D0D5DD border border-1 text-left  text-secondary fw_400 fs_14 min-w-[10rem]">
                    To Bin
                  </th>
                  <th className="py-2 px-1 border-D0D5DD border border-1 text-left  text-secondary fw_400 fs_14"></th>
                </tr>
              </thead>
              <tbody>
                {defaultSelectedItem?.item_batches?.map((batch, batchindex) => (
                  <tr key={batchindex}>
                    <td className="border-D0D5DD border border-1 py-2 px-1 text-secondary fw_400 fs_14 w-[15rem]">
                      {values?.stock_management_action === "stock_in" ? (
                        <Field
                          name={`data.${defaultSelectedItemIndex}.item_batches.${batchindex}.item_batch_number`}
                        >
                          {({ field }) => (
                            <input
                              autoFocus={true}
                              placeholder=""
                              {...field}
                              type="text"
                              className={`w-full focus_outline__none focus:border-primary focus:border rounded-[10px] py-2 px-2`}
                              onKeyDown={(e) => {
                                const regex = /[a-zA-Z0-9]/;
                                if (e.key === " " || !regex.test(e.key)) {
                                  e.preventDefault();
                                }
                              }}
                            />
                          )}
                        </Field>
                      ) : (
                        <SelectField
                          ref={batchRef}
                          autoFocus={true}
                          isDisabled={disabled}
                          handleSelected={(sel) => {
                            if (
                              values?.stock_management_action === "stock_out" ||
                              values?.stock_management_action ===
                                "in_store_transfer"
                            ) {
                              setFieldValue(
                                `data[${defaultSelectedItemIndex}].item_batches[${batchindex}].from_bin_id`,
                                sel?.from_bin_id ?? ""
                              );

                              setFieldValue(
                                `data[${defaultSelectedItemIndex}].item_batches[${batchindex}].from_bin_location`,
                                sel?.from_bin_location ?? ""
                              );

                              setFieldValue(
                                `data[${defaultSelectedItemIndex}].item_batches[${batchindex}].created_date`,
                                sel?.created_date ?? ""
                              );
                            }
                          }}
                          // borderclass=""
                          // defaultSelectClass="border-0"
                          selectClass="w-full"
                          defaultValue={{
                            label:
                              values.data[defaultSelectedItemIndex]
                                ?.item_batches[batchindex]?.item_batch_number,
                            value: "",
                          }}
                          errMsgContainerClass="hidden"
                          id={`data[${defaultSelectedItemIndex}].item_batches[${batchindex}].item_batch_number`}
                          isContainer={false}
                          options={values.data[
                            defaultSelectedItemIndex
                          ].itemBatchList
                            ?.filter((item) => {
                              // Ensure the batch number exists
                              if (!item?.item_batch_number) return false;

                              const createdDate = item?.created_date;
                              const itembatchnumber = item?.item_batch_number;
                              const itemQuantity =
                                parseFloat(item?.item_quantity) || 0;

                              // Calculate the total quantity already used for this created_date
                              const sumBatchQuantity = values.data[
                                defaultSelectedItemIndex
                              ].item_batches
                                .filter(
                                  (batch) =>
                                    batch?.created_date === createdDate &&
                                    batch?.item_batch_number === itembatchnumber
                                )
                                .reduce(
                                  (sum, batch) =>
                                    sum +
                                    (parseFloat(batch?.item_batch_quantity) ||
                                      0),
                                  0
                                );

                              // Calculate remaining quantity
                              const remainingQty =
                                itemQuantity - sumBatchQuantity;

                              // Include the batch if there's remaining quantity and it's not already selected
                              return remainingQty > 0;
                            })
                            .map((item) => {
                              const sumBatchQuantity = values.data[
                                defaultSelectedItemIndex
                              ].item_batches
                                .filter(
                                  (batch) =>
                                    batch?.created_date ===
                                      item?.created_date &&
                                    batch?.item_batch_number ===
                                      item?.item_batch_number
                                )
                                .reduce(
                                  (sum, batch) =>
                                    sum +
                                    (parseFloat(batch?.item_batch_quantity) ||
                                      0),
                                  0
                                );

                              // Calculate remaining quantity
                              const remainingQty =
                                parseFloat(item?.item_quantity) -
                                sumBatchQuantity;

                              return {
                                label: (
                                  <span className="flex flex-col">
                                    <span>
                                      Avail Qty: {remainingQty}
                                      {/* Avail Qty: {item?.item_quantity} */}
                                    </span>
                                    <span>
                                      Btch Num: {item?.item_batch_number}
                                    </span>
                                  </span>
                                ),
                                value: item?.item_batch_number,
                                from_bin_id: item?.to_bin_id,
                                from_bin_location: item?.item_rack_location,
                                created_date: item?.created_date,
                              };
                            })}
                          name={`data[${defaultSelectedItemIndex}].item_batches[${batchindex}].item_batch_number`}
                          placeholder={"Select Batch"}
                        />
                      )}
                    </td>
                    <td className="border-D0D5DD border border-1 py-2 px-1 text-secondary fw_400 fs_14 w-[15rem]">
                      <Field
                        name={`data.${defaultSelectedItemIndex}.item_batches.${batchindex}.item_batch_quantity`}
                      >
                        {({ field, form }) => (
                          <input
                            min={0}
                            disabled={disabled}
                            placeholder=""
                            {...field}
                            type="number"
                            className={`w-full focus_outline__none focus:border-primary focus:border rounded-[10px] py-2 pl-2`}
                            onChange={(e) => {
                              let value = parseInt(e.target.value, 10);
                              const currentBatch =
                                values.data[defaultSelectedItemIndex]
                                  .item_batches[batchindex];
                              const createdDate = currentBatch.created_date;
                              // Find the item in itemBatchList corresponding to the createdDate
                              const itemBatch = values.data[
                                defaultSelectedItemIndex
                              ].itemBatchList?.find(
                                (bch) => bch.created_date === createdDate
                              );

                              const totalItemQuantity =
                                parseFloat(itemBatch?.item_quantity) || 0;

                              // Calculate the sum of all item_batch_quantity for this createdDate, excluding the current batch
                              const sumOtherBatches = values.data[
                                defaultSelectedItemIndex
                              ].item_batches
                                .filter(
                                  (batch, idx) =>
                                    batch.created_date === createdDate &&
                                    idx !== batchindex
                                )
                                .reduce(
                                  (sum, batch) =>
                                    sum +
                                    (parseFloat(batch.item_batch_quantity) ||
                                      0),
                                  0
                                );

                              // Calculate the remaining quantity available for this batch
                              const remainingQty =
                                totalItemQuantity - sumOtherBatches;

                              // If the entered value exceeds the remaining quantity, cap it
                              if (value > remainingQty) {
                                value =
                                  values?.stock_management_action === "stock_in"
                                    ? value
                                    : remainingQty;
                              }

                              // Update the form state with the new value
                              form.setFieldValue(field.name, value);

                              // Optionally, update the total quantity if needed
                              handleQtyChangeFn(batchindex, value);

                              // if (
                              //   value >
                              //   parseInt(
                              //     // values.data[
                              //     //   defaultSelectedItemIndex
                              //     // ].itemBatchList?.find(
                              //     //   (bch) =>
                              //     //     bch?.to_bin_id ===
                              //     //     values.data[defaultSelectedItemIndex]
                              //     //       ?.item_batches[batchindex]?.from_bin_id
                              //     values.data[
                              //       defaultSelectedItemIndex
                              //     ].itemBatchList?.find(
                              //       (bch) =>
                              //         bch?.created_date ===
                              //         values.data[defaultSelectedItemIndex]
                              //           ?.item_batches[batchindex]?.created_date
                              //     )?.item_quantity
                              //   )
                              // ) {
                              //   // value = parseInt(
                              //   //   values.data[
                              //   //     defaultSelectedItemIndex
                              //   //   ].itemBatchList?.find(
                              //   //     (bch) =>
                              //   //       bch?.to_bin_id ===
                              //   //       values.data[defaultSelectedItemIndex]
                              //   //         ?.item_batches[batchindex]?.from_bin_id
                              //   //   )?.item_quantity
                              //   // );
                              //   value = parseInt(
                              //     values.data[
                              //       defaultSelectedItemIndex
                              //     ].itemBatchList?.find(
                              //       (bch) =>
                              //         bch?.created_date ===
                              //         values.data[defaultSelectedItemIndex]
                              //           ?.item_batches[batchindex]?.created_date
                              //     )?.item_quantity
                              //   );
                              // }

                              // field.onChange(e); // Update form state
                              // form.setFieldValue(field.name, value); // Ensure the value doesn't exceed 1

                              // if (e.target === document.activeElement) {
                              //   handleQtyChangeFn(batchindex, e.target.value); // Call quantity function
                              // }
                            }}
                            onInput={(e) => {
                              const value = e.target.value;
                              if (value < 0) {
                                form.setFieldValue(field.name, 0); // Prevent negative value
                              }
                            }}
                            onKeyDown={(e) => {
                              if (["-", "e", " "].includes(e.key)) {
                                e.preventDefault(); // Prevent negative sign, scientific notation, and space
                              }
                            }}
                          />
                        )}
                      </Field>
                    </td>
                    {values?.stock_management_action === "stock_in" && (
                      <td className="border-D0D5DD border border-1 py-2 px-1 text-secondary fw_400 fs_14">
                        <Field
                          name={`data.${defaultSelectedItemIndex}.item_batches.${batchindex}.item_exp_date`}
                        >
                          {({ field }) => (
                            <input
                              placeholder=""
                              {...field}
                              type="date"
                              className={`w-full focus_outline__none focus:border-primary focus:border rounded-[10px] py-2`}
                            />
                          )}
                        </Field>
                      </td>
                    )}
                    <td className="border-D0D5DD border border-1 py-2 px-1 text-secondary fw_400 fs_14">
                      <SelectField
                        autoFocus={
                          values.data[defaultSelectedItemIndex]?.item_batches[
                            batchindex
                          ]?.item_batch_number
                        }
                        defaultValue={{
                          label:
                            binLocationList?.find(
                              (bin) =>
                                bin?.value ===
                                values.data[defaultSelectedItemIndex]
                                  ?.item_batches[batchindex]?.from_bin_id
                            )?.label ?? "",
                          value: "",
                        }}
                        handleSelected={(selectedoption) => {
                          setFieldValue(
                            `data.${defaultSelectedItemIndex}.item_batches.${batchindex}.from_bin_location`,
                            selectedoption?.actualLabel
                          );
                        }}
                        labelClass="whitespace-nowrap flex fs_14 fw_600 text-secondary"
                        containerClass="w-full"
                        isDisabled={
                          disabled ||
                          values?.stock_management_action === "stock_in" ||
                          values?.stock_management_action === "stock_out" ||
                          values?.stock_management_action ===
                            "in_store_transfer"
                        }
                        placeholder={"select Bin"}
                        options={binLocationList}
                        label={""}
                        id={`data.${defaultSelectedItemIndex}.item_batches.${batchindex}.from_bin_id`}
                        name={`data.${defaultSelectedItemIndex}.item_batches.${batchindex}.from_bin_id`}
                        isSearchable={true}
                        errMsgContainerClass="ps-2 hidden"
                        labelWidth="7rem"
                      />
                    </td>
                    <td className="border-D0D5DD border border-1 py-2 px-1 text-secondary fw_400 fs_14">
                      <SelectField
                        autoFocus={
                          values.data[defaultSelectedItemIndex]?.item_batches[
                            batchindex
                          ]?.item_batch_number
                        }
                        defaultValue={{
                          label:
                            binLocationList?.find(
                              (bin) =>
                                bin?.value ===
                                values.data[defaultSelectedItemIndex]
                                  ?.item_batches[batchindex]?.to_bin_id
                            )?.label ?? "",
                          value: "",
                        }}
                        handleSelected={(selectedoption) => {
                          setFieldValue(
                            `data.${defaultSelectedItemIndex}.item_batches.${batchindex}.to_bin_location`,
                            selectedoption?.actualLabel
                          );
                        }}
                        labelClass="whitespace-nowrap flex fs_14 fw_600 text-secondary"
                        containerClass="w-full"
                        isDisabled={
                          disabled ||
                          values?.stock_management_action === "stock_out"
                        }
                        placeholder={"select Bin"}
                        options={binLocationList}
                        label={""}
                        id={`data.${defaultSelectedItemIndex}.item_batches.${batchindex}.to_bin_id`}
                        name={`data.${defaultSelectedItemIndex}.item_batches.${batchindex}.to_bin_id`}
                        isSearchable={true}
                        errMsgContainerClass="ps-2 hidden"
                        labelWidth="7rem"
                      />
                    </td>
                    {!disabled && (
                      <td className="border-D0D5DD border border-1 py-2 px-2 text-secondary fw_400 fs_14">
                        <button
                          onClick={() => {
                            const updatedBatches = [
                              ...defaultSelectedItem.item_batches,
                            ];
                            updatedBatches.splice(batchindex, 1);
                            setFieldValue(
                              `data.${defaultSelectedItemIndex}.item_batches`,
                              updatedBatches
                            );
                          }}
                          type="button"
                        >
                          <DeleteIcon />
                        </button>
                        {/* )} */}
                      </td>
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
            {!disabled && (
              <div className="flex justify-end lg:w-[80%] w-full">
                <AddButton
                  className="bg-primary text-white px-8 py-2 mt-2 rounded flex justify-center fw_500 fs_16"
                  onclkFn={() => {
                    if (batchRef && batchRef.current) {
                      batchRef?.current?.focus();
                    }
                    setFieldValue(
                      `data.${defaultSelectedItemIndex}.item_batches`,
                      [
                        ...values?.data?.[defaultSelectedItemIndex]
                          ?.item_batches,
                        initData,
                      ]
                    );
                  }}
                  prefixIcon={true}
                  text=""
                  type="button"
                />
              </div>
            )}
          </div>
        </div>
        <div className="flex justify-between px-10 sticky bottom-0   bg-white py-2 invoice-bottom-container">
          <AddButton
            onclkFn={() => setAddbatch(false)}
            prefixIcon={false}
            text="Done"
            type="button"
          />
        </div>
      </div>
      <div
        className="bg-black h-[23vh] opacity-50"
        onClick={() => setAddbatch(false)}
      ></div>
    </div>
  );
};

export default BatchQuantityBinPopover;
